//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, useEffect, useMemo, useState } from 'react';
import { Grid, Form, Button, Tab } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";
import LRModal from '../../Basics/BasicModal';

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
import { globalWindowInterface, lrWorksheetGroupingInterface, lrWorksheetStateInterface } from '../../../util/callbackTypes';
import GroupByComponent from '../ObjectProperties/GroupByComponent';
import { MultiFieldSearch, PropertyListItemInterface } from '../ObjectProperties/FieldSearch';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

declare const window: globalWindowInterface;

interface compWorksheetInterface {
  WorksheetState: lrWorksheetStateInterface,
  Const: boolean
}

export function compareWorksheetStates(this: unknown, oldWorksheet: compWorksheetInterface, newWorksheet: lrWorksheetStateInterface) {
  let propertyTemplate = oldWorksheet?.WorksheetState
  if (!propertyTemplate) {return false; }
  if (oldWorksheet.Const) {return false; }

  let compareProperties = (array1, array2) => {
    if (array1.length !== array2.length) { return false; }
    for (let i = 0; i < array1.length; i++) {
      let prop = array1[i]
      if (array2.find(elem => (elem.ArrayName || undefined) === (prop.ArrayName || undefined) && elem.PropertyName === prop.PropertyName) === undefined) {
        return false;
      }
    }
    return true;
  }
  let comparePropertyFilter = (array1, array2) => {
    if (array1.length !== array2.length) { return false; }
    for (let i = 0; i < array1.length; i++) {
      let prop = array1[i]
      if (array2.find(elem => (
        elem.PropertyName === prop.PropertyName &&
        elem.StringValue === prop.StringValue &&
        elem.NumberValue === prop.NumberValue &&
        elem.RangeMin === prop.RangeMin &&
        elem.RangeMax === prop.RangeMax &&
        elem.Mode == prop.Mode
      )) === undefined) {
        return false;
      }
    }
    return true;
  }


  let sameProps = compareProperties(propertyTemplate.ExtraEnabledProperties, (newWorksheet as any).propertyList)
  if (!sameProps) { return true; }

  sameProps = compareProperties(propertyTemplate.InventorySummeryProps, (newWorksheet as any).InventorySummeryProps)
  if (!sameProps) { return true; }

  let samePropertyFilter = comparePropertyFilter(propertyTemplate.PropertyBasedFilter, newWorksheet.PropertyBasedFilter)
  if (!samePropertyFilter) { return true; }

  let sameGrouping = comparePropertyFilter(propertyTemplate.GroupByProperty, newWorksheet.GroupByProperty)
  if (!sameGrouping) { return true; }

  return propertyTemplate.ShowFilterConsumer !== newWorksheet.ShowFilterConsumer ||
    propertyTemplate.ShowFilterDistributer !== newWorksheet.ShowFilterDistributer ||
    propertyTemplate.ShowFilterPlugBox !== newWorksheet.ShowFilterPlugBox ||
    propertyTemplate.ShowFilterAssemblyGroup !== newWorksheet.ShowFilterAssemblyGroup ||
    propertyTemplate.ShowFilterGenerator !== newWorksheet.ShowFilterGenerator ||
    propertyTemplate.ShowFilterFixture !== newWorksheet.ShowFilterFixture ||
    propertyTemplate.ShowFilterStructures !== newWorksheet.ShowFilterStructures ||
    propertyTemplate.ShowFilterCablePath !== newWorksheet.ShowFilterCablePath ||
    propertyTemplate.ShowFilterSupport !== newWorksheet.ShowFilterSupport ||
    propertyTemplate.ShowFilterGroundSupport !== newWorksheet.ShowFilterGroundSupport ||
    propertyTemplate.ShowFilterHouseRiggingPoint !== newWorksheet.ShowFilterHouseRiggingPoint ||
    propertyTemplate.ShowFilterAudio !== newWorksheet.ShowFilterAudio ||
    propertyTemplate.ShowFilterMeshes !== newWorksheet.ShowFilterMeshes ||
    propertyTemplate.ShowFilterDockPoints !== newWorksheet.ShowFilterDockPoints ||
    propertyTemplate.ShowFilterVisible !== newWorksheet.ShowFilterVisible ||
    propertyTemplate.ShowFilterSelected !== newWorksheet.ShowFilterSelected
}

function PresetSelector(this: unknown, {
  disabled,
  PresetOptions,
  SelectedPreset,
  onPropertyPresetChanged,
  currentWorksheet,
  newWorksheet,
  onUpdate,
  onCreate
}: {
  disabled: boolean,
  PresetOptions: any[],
  SelectedPreset: string,
  onPropertyPresetChanged: (n: string) => void,
  currentWorksheet: compWorksheetInterface,
  newWorksheet: lrWorksheetStateInterface,
  onUpdate: (name: string, i: lrWorksheetStateInterface) => void,
  onCreate: (name: string, i: lrWorksheetStateInterface) => void
}
) {
  const changed = useMemo(() => compareWorksheetStates(currentWorksheet, newWorksheet), [currentWorksheet, newWorksheet])
  const [createOpen, setCreateOpen] = useState(false)
  const [createName, setCreateName] = useState("New Preset")
  return <>
    <h5>{LocalizedStrings.Properties_Preset}</h5>
    <Form.Dropdown disabled={disabled}
      selection
      compact
      search={(options, search) => options.filter(opt => opt.key.toLowerCase().includes(search.toLowerCase()))}
      options={PresetOptions}
      fluid
      value={SelectedPreset}
      onChange={(_, { value }) => onPropertyPresetChanged(String(value))} />
    <Button.Group style={{ width: "100%", marginTop: "1rem" }}>
      <Button disabled={disabled || !changed} compact size="mini" color="blue" content={LocalizedStrings.UpdatePropertyPresets} onClick={() => onUpdate(SelectedPreset, newWorksheet)} />
      <Button.Or />
      <Button disabled={disabled} compact size="mini" positive content={LocalizedStrings.CreatePropertyPresets} onClick={() => setCreateOpen(true)} />
    </Button.Group>
    <LRModal open={createOpen}
      title={LocalizedStrings.CreatePropertyPresetsHeader}
      onOkClick={() => {
        onCreate(createName, newWorksheet)
        setCreateOpen(false)
        setCreateName("New Preset")
      }}
      onCancelClick={() => {
        setCreateOpen(false)
        setCreateName("New Preset")
      }}>
      <Form>
        <Form.Input value={createName} onChange={(e, { value }) => setCreateName(value)} />
      </Form>
    </LRModal>
  </>
}

export default function InventoryColumnsDisplay(this: unknown, {
  PresetOptions,
  inventoryWorksheetState,
  isInEditMode,
  currentWorksheetSettings: currentWorksheetState,
  propertyList,
  onPropertyPresetChanged,
  onGroupByPropertyChanged,
  onGroupByPropertyDeleted,
  changeUseGroupingIndentation,
  toggleShowInventorySummary,
  changeAccessoriesChanged,
  changeInventoryErrorChanged,
  toggleShowProperties
}:
  {
    PresetOptions: any[],
    inventoryWorksheetState: lrWorksheetStateInterface,
    isInEditMode: boolean,
    currentWorksheetSettings: lrWorksheetStateInterface,
    propertyList: any[],
    onPropertyPresetChanged: (m: string) => void,
    onGroupByPropertyChanged: (n: number, p: lrWorksheetGroupingInterface) => void,
    onGroupByPropertyDeleted: (idx: number) => void,
    changeUseGroupingIndentation: (b: boolean) => void,
    toggleShowInventorySummary: (item: PropertyListItemInterface | PropertyListItemInterface[], checked: boolean) => void,
    toggleShowProperties: (item: PropertyListItemInterface | PropertyListItemInterface[], checked: boolean) => void,
    changeAccessoriesChanged: (b: boolean) => void,
    changeInventoryErrorChanged: (b: boolean) => void
  }
) {
  const [currentWorksheet, setCurrentWorksheet] = useState<compWorksheetInterface>(undefined)
  useEffect(() => {
    globalCallbacks.updateObjectPropertiesTemplateMap = () => {
      window.LR_GetInventoryPropertyTemplate({ Name: inventoryWorksheetState.ActivePropertyPreset }).then(res => {
        if (res.PropertyTemplate) {
          setCurrentWorksheet(res.PropertyTemplate)
        }
      })
    }
    globalCallbacks.updateObjectPropertiesTemplateMap()
  }, [])

  const toLRWorksheet = (data: lrWorksheetStateInterface) => {
    const mapProp = (i)=>({
      PropertyName: i.PropertyName,
      ArrayName: i.ArrayName
    })
    return {
      ExtraEnabledProperties:  (data as any).propertyList.map(mapProp),
      GroupByProperty:         data.GroupByProperty,
      ShowFilterConsumer:      data.ShowFilterConsumer,
      ShowFilterAssemblyGroup: data.ShowFilterAssemblyGroup,
      ShowFilterDistributer:   data.ShowFilterDistributer,
      ShowFilterPlugBox:       data.ShowFilterPlugBox,
      ShowFilterGenerator:     data.ShowFilterGenerator,
      ShowFilterFixture:       data.ShowFilterFixture,
      ShowFilterStructures:    data.ShowFilterStructures,
      ShowFilterCablePath:    data.ShowFilterCablePath,
      ShowFilterSupport:       data.ShowFilterSupport,
      ShowFilterAudio:         data.ShowFilterAudio,
      ShowFilterMeshes:        data.ShowFilterMeshes,
      ShowFilterDockPoints:    data.ShowFilterDockPoints,
      ShowFilterVisible:       data.ShowFilterVisible,
      ShowFilterSelected:      data.ShowFilterSelected,
      PropertyBasedFilter:     data.PropertyBasedFilter,
      ShowFilterGroundSupport: data.ShowFilterGroundSupport,
      ShowFilterHouseRiggingPoint: data.ShowFilterHouseRiggingPoint,
      InventorySummeryProps:   data.InventorySummeryProps.map(mapProp),
    }
  }

  const createFinished = async (Name: string, WorksheetState: lrWorksheetStateInterface) => {
    let req = {
      Name,
      WorksheetState: toLRWorksheet(WorksheetState)
    }
    await window.LR_AddInventoryPropertyTemplate(req)
    propertyPresetChanged(Name)
  }

  const propertyPresetChanged = (n: string) => {
    window.LR_GetInventoryPropertyTemplate({ Name: n }).then(res => {
      if (res.PropertyTemplate) {
        onPropertyPresetChanged(res.PropertyTemplate);
        setCurrentWorksheet(res.PropertyTemplate)
      }
    })
  }

  const updateClick = async (Name: string, WorksheetState: lrWorksheetStateInterface) => {
    let req = {
      Name,
      WorksheetState: toLRWorksheet(WorksheetState)
    }
    await window.LR_SetInventoryPropertyTemplate(req)
    propertyPresetChanged(Name)
  }

  let filteredPropertyList = useMemo(() => propertyList.filter(i => !i.ArrayName), [propertyList])

  const panes = [
    {
      menuItem: LocalizedStrings.Properties_Preset,
      render: () => (
        <Tab.Pane>
          <PresetSelector
            PresetOptions={PresetOptions}
            SelectedPreset={inventoryWorksheetState.ActivePropertyPreset}
            currentWorksheet={currentWorksheet}
            disabled={isInEditMode}
            newWorksheet={currentWorksheetState}
            onCreate={createFinished}
            onPropertyPresetChanged={propertyPresetChanged}
            onUpdate={updateClick}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: LocalizedStrings.Group_Field,
      render: () => (
        <Tab.Pane>
          <h5>{LocalizedStrings.Group_Field}</h5>
          <div style={{ overflowY: "auto", overflowX: "hidden", marginBottom: 10 }}>
            {inventoryWorksheetState.GroupByProperty.map((i, idx) => (
              <GroupByComponent
                key={i.GroupByProperty}
                propertyList={propertyList}
                groupingProp={i}
                onChangeGroupByProperty={(property) => onGroupByPropertyChanged(idx, property)}
                onDelete={() => onGroupByPropertyDeleted(idx)}
                onCopy={p => onGroupByPropertyChanged(currentWorksheetState.GroupByProperty.length, p)}
              />
            ))}
            <GroupByComponent
              propertyList={propertyList}
              onChangeGroupByProperty={(property) => onGroupByPropertyChanged(currentWorksheetState.GroupByProperty.length, property)}
            />
          </div>
          <Form.Checkbox
            label={LocalizedStrings.Setting_UseGroupingIndent}
            checked={currentWorksheetState.UseGroupingIndentation}
            onChange={(e, { checked }) => changeUseGroupingIndentation(checked)}
          />
          <Form.Checkbox
            label={LocalizedStrings.ShowAccessories}
            checked={currentWorksheetState.ShowAccessories}
            onChange={(e, { checked }) => changeAccessoriesChanged(checked)}
          />
          <Form.Checkbox
            label={LocalizedStrings.ShowInventoryError}
            checked={currentWorksheetState.ShowInventoryError}
            onChange={(e, { checked }) => changeInventoryErrorChanged(checked)}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: LocalizedStrings.InventoryOptions,
      render: () => (
        <Tab.Pane>
          <h5>{LocalizedStrings.InventoryOptions}</h5>
          <MultiFieldSearch
            propertyList={filteredPropertyList}
            enabledProperties={inventoryWorksheetState.ExtraEnabledProperties}
            toggleShowProp={toggleShowProperties}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: LocalizedStrings.Summery_Field,
      render: () => (
        <Tab.Pane>
          <h5>{LocalizedStrings.Summery_Field}</h5>
          <MultiFieldSearch
            propertyList={filteredPropertyList}
            enabledProperties={inventoryWorksheetState.InventorySummeryProps}
            toggleShowProp={toggleShowInventorySummary}
          />
        </Tab.Pane>
      )
    }
  ];

  return <>
   { window.IsIOS ?
    ( <Tab menu={{ pointing: true }} panes={panes} size="small"/>) : 
    (<Grid stackable style={{ maxHeight: 240 }}>
      <Grid.Column width="4">
        <PresetSelector
          PresetOptions={PresetOptions}
          SelectedPreset={inventoryWorksheetState.ActivePropertyPreset}
          currentWorksheet={currentWorksheet}
          disabled={isInEditMode}
          newWorksheet={currentWorksheetState}
          onCreate={createFinished}
          onPropertyPresetChanged={propertyPresetChanged}
          onUpdate={updateClick}
        />
      </Grid.Column>
      <Grid.Column width="4">

        <h5>{LocalizedStrings.Group_Field}</h5>
        <div style={{ overflowY: "auto", overflowX: "hidden", height: 115, marginBottom: 10 }}>
          {
            inventoryWorksheetState.GroupByProperty.map((i, idx) => <GroupByComponent
              key={i.GroupByProperty}
              propertyList={propertyList}
              groupingProp={i}
              onChangeGroupByProperty={(property) => onGroupByPropertyChanged(idx, property)}
              onDelete={() => onGroupByPropertyDeleted(idx)}
              onCopy={p => onGroupByPropertyChanged(currentWorksheetState.GroupByProperty.length, p)}
            />
            )
          }
          <GroupByComponent
            propertyList={propertyList}
            onChangeGroupByProperty={(property) => onGroupByPropertyChanged(currentWorksheetState.GroupByProperty.length, property)}
          />
        </div>
        <Form.Checkbox label={LocalizedStrings.Setting_UseGroupingIndent} checked={currentWorksheetState.UseGroupingIndentation} onChange={(e, { checked }) => changeUseGroupingIndentation(checked)} />
        <Form.Checkbox label={LocalizedStrings.ShowAccessories} checked={currentWorksheetState.ShowAccessories} onChange={(e, { checked }) => changeAccessoriesChanged(checked)} />
        <Form.Checkbox label={LocalizedStrings.ShowInventoryError} checked={currentWorksheetState.ShowInventoryError} onChange={(e, { checked }) => changeInventoryErrorChanged(checked)} />
      </Grid.Column>

      <Grid.Column width="4">

        <h5>{LocalizedStrings.InventoryOptions}</h5>
        <MultiFieldSearch
          propertyList={filteredPropertyList}
          enabledProperties={inventoryWorksheetState.ExtraEnabledProperties}
          toggleShowProp={toggleShowProperties}
        />

      </Grid.Column>

      <Grid.Column width="4">

        <h5>{LocalizedStrings.Summery_Field}</h5>
        <MultiFieldSearch
          propertyList={filteredPropertyList}
          enabledProperties={inventoryWorksheetState.InventorySummeryProps}
          toggleShowProp={toggleShowInventorySummary}
        />

      </Grid.Column>
    </Grid>)
   }
  </>
}