//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';

import SignIn from '../component/SignIn/SignIn';
import NewSignIn from '../component/SignIn/NewSignin';
import CreateUser from '../component/CreateUser/CreateUser';
import NewCreateUser from '../component/CreateUser/NewCreateUser';

import ForgotPage from '../component/ResetPage/ForgotPage';

import { RESPONSIVE_PHONE } from '../util/defines';
import StartPageProject from '../component/StartPageProject/StartPageProject'
import UserOverview from '../component/UserOverview/UserOverview'
import UserStartPage from '../component/UserStartPage/UserStartPage'
import UserSettings from '../component/UserSettings/UserSettings'
import DownloadApp from '../component/DownloadApp/DownloadApp'
import NewDownloadApp from '../component/DownloadApp/NewDownloadApp'

import Tutorial from '../component/Tutorial/Tutorial'
import NewTutorial from '../component/Tutorial/NewTutorial'
import Documentation from '../component/Documentation/Documentation'
import NewDocumentation from '../component/Documentation/NewDocumentation'


import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate, useParams} from "react-router-dom";
import Footer from '../component/WebComponents/Footer'
import Header from '../component/WebComponents/Header'
import AdminPage from '../component/AdminPage/AdminPage';
const ProductionAssistLandingPage = React.lazy(()=>import("../component/ProductionAssistLandingPage/ProductionAssistLandingPage"));
const Pricing           = React.lazy(()=>import('../component/ProductionAssistLandingPage/Pricing'))
const ImpressumPage     = React.lazy(()=>import('../component/ProductionAssistLandingPage/Impressum'))
const GetStarted        = React.lazy(()=>import('../component/ProductionAssistLandingPage/GetStarted'))
const Payment           = React.lazy(()=>import('../component/ProductionAssistLandingPage/Payment'))
const FAQPage           = React.lazy(()=>import('../component/ProductionAssistLandingPage/FAQ'))
const AboutUs           = React.lazy(()=>import('../component/ProductionAssistLandingPage/AboutUs'))
const NewAboutUs        = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewAboutUs'))
const StaticPage        = React.lazy(()=>import('../component/ProductionAssistLandingPage/StaticCalculationLandingPage'))
const NewStaticPage     = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewStaticCalculationLandingPage'))
const LoadCellpage      = React.lazy(()=>import('../component/ProductionAssistLandingPage/LoadCellLandingPage'))
const CollaborationPage = React.lazy(()=>import('../component/ProductionAssistLandingPage/CollaborationLandingPage'))
const NewCollaborationPage = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewCollaborationLandingPage'))
const AGBPage           = React.lazy(()=>import('../component/ProductionAssistLandingPage/AGB'))
const LocalizedStrings  = React.lazy(()=>import("../localization/ProductionAssistLandingPage"))
const PricingPage       = React.lazy(()=>import('../component/ProductionAssistLandingPage/Pricing'))
const SupportPage       = React.lazy(()=>import('../component/ProductionAssistLandingPage/Support'))
const NewSupportPage    = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewSupport'))
const VrLandingPage     = React.lazy(()=>import('../component/ProductionAssistLandingPage/VrLandingPage'))
const NewVrLandingPage  = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewVrLandingPage'))
const PowerPlanningPage  = React.lazy(()=>import('../component/ProductionAssistLandingPage/PowerPlanningLandingPage'))
const NewPowerPlanningPage  = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewPowerPlanningLandingPage'))
const NewProductionAssistLandingPage  = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewProductionAssistLandingPage'))
const NewPricing   = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewPricing'))
const NewFAQPage           = React.lazy(()=>import('../component/ProductionAssistLandingPage/NewFAQ'))

import "./App.css";

import NotFound from '../component/NotFound/NotFound';
import { ACTIVE_USER } from '../redux/redux_defines'
import ChangeRequestList from '../component/ChangeRequest/ChangeRequestList';
import ChangeRequest from '../component/ChangeRequest/ChangeRequest';
import ProjectSettings from '../component/ProjectSettings/ProjectSettings';
import Worksheet from '../component/WorkSheet/WorkSheet';
import Timeline from '../component/Timeline/Timeline';
const ElectricalGraph = React.lazy(()=>import("../component/ElectricalGraph/ElectricalGraphComponent"))
import DmxOverview from '../component/DmxOverview/DmxOverview';
import LightingPaperwork from '../component/LightingPaperwork/LightingPaperwork';
import CommitOverview from '../component/CommitOverview/CommitOverview';
import UserTaskOverview from '../component/UserTaskOverview/UserTaskOverview';
import BranchSelection from '../component/StartPageProject/BranchSelection';

import MediaQuery from 'react-responsive'

import SymbolMapDisplay from '../component/RessourceManager/SymbolMapDisplay';
import TruckTemplateDisplay from '../component/RessourceManager/TruckTemplateDisplay';
import CaseTemplateDisplay from '../component/RessourceManager/CaseTemplateDisplay';
import RackTemplateDisplay from '../component/RessourceManager/RackTemplateDisplay';
import VectorworksFieldMapping from '../component/RessourceManager/VectorworksFieldMapping';
import MentenainceReports from '../component/Mentenaince/MentenainceReports';
import SheduledMentenaince from '../component/Mentenaince/SheduledMentenaince';
import Inventory from '../component/Inventory/Inventory';
import ProjectOverview from '../component/UserOverview/ProjectOverview';
import SingleObjectView from '../component/TableViews/ObjectView';
import ResetPage from '../component/ResetPage/ResetPage';
import ChangeListChecks from '../component/ChangeRequestCheck/ChangeListChecks';

import Security from '../component/UserSettings/Security';
import Account from '../component/UserSettings/Account';
import Profile from '../component/UserSettings/Profile';
import ReviewTemplateList from '../component/Review/ReviewTemplateList'
import Cookies  from"js-cookie";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import PaymentHistory from '../component/UserSettings/PaymentHistory';
import MaterialTemplateDisplay from '../component/RessourceManager/MaterialsTemplateDisplay';
import PaperTemplateDisplay from '../component/RessourceManager/PaperTemplateDisplay';
import CrossSectionTemplateDisplay from '../component/RessourceManager/CrossSectionTemplateDisplay';
import MobileFooter from './MobileFooter';
import QrCodeReaderSite from '../component/QrCodeScanner/QrCodeSite';
import { lrServerConnection } from '../redux/light_right_server_connection';
import Blog from '../component/Blog/Blog';
import NewBlog from '../component/Blog/NewBlog';
import AcceptanceTests from '../component/Documentation/AceptanceTestsDisplay';
import { HandleScanInWeb } from '../component/QrCodeScanner/defaultScanning';
const DefaultModalContainer = React.lazy(()=>import('../component/DefaultModalContainer/DefaultModalContainer'))

import { IsUsingWebassembly } from './ipcRenderer';
import UpdateTemplates from '../component/UserSettings/UpdateTemplates';
import WebEditRouter from './WebEditRouter';
import { Dimmer, Loader as SemanticLoader } from 'semantic-ui-react';
import CONST from "../webApp/CONST"
import StructuralCalculationChecks from '../component/RequestStructuralCheck/StructuralCalculationChecks';
import RequestStructuralCheck_Quote from '../component/RequestStructuralCheck/RequestStructuralCheck_Quote';
import NewFooter from '../component/WebComponents/NewFooter';
import NewForgotPage from '@component/ResetPage/NewForgotPage';


const ConfirmPlanSwitch = React.lazy(() => import('../component/UserSettings/ConfirmPlanSwitch'))
const UpdateBilling = React.lazy(() => import('../component/UserSettings/UpdateBilling'))


export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        match={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


function usePageViews() 
{
  let location = useLocation();
  React.useEffect(() => 
  {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({hitType: "pageview", page: location.pathname})

    if (window.EhAPI && window.EhAPI.push)
    {
      window.EhAPI.push(["trackPageView"]);
    }

    // get sharetoken for current project
    let sharetoken = undefined
    const pathElements = location.pathname.split('/')
    const projectname = pathElements.length >= 3 ? pathElements[2] : undefined
    const username = pathElements.length >= 2 ? pathElements[1] : undefined

    if (projectname)
    {
      let t = sessionStorage.getItem(`last_sharetoken`)
      if(t){
        t = JSON.parse(t)
        sharetoken = ( t.token && t.projectname === projectname && t.username === username )? t.token : undefined
      }
    }
    lrServerConnection.setShareToken(sharetoken)

  }, [location]);
}

function WithGa({ children }) {
  usePageViews();
  return children;
}

var Loader = function () {}

Loader.prototype = {
    writeScript: function(src, callback) {

      if( ! document.getElementById(src.data))
      {
        var s = document.createElement('script');
        s.setAttribute("data-tolt", src.data)
        s.type = "text/javascript";
        s.src = src.URL;
        s.id = src.data
        s.addEventListener('load', function (e) { if (callback) {callback(null, e);} }, false);
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(s);
      }
    }
}

//---------------------------------------------------------------------
// LightRightRouter
class LightRightRouter extends Component 
{  

  constructor(props)
  {
    super(props);

    let cookie =  Cookies.get("access_token")

    // set sharetoken in cookies for project from path
    const tokenQuery = window.getQuery("sharetoken")
    const pathElements = window.location.pathname.split('/')
    const projectname = pathElements.length >= 3 ? pathElements[2] : undefined
    const username = pathElements.length >= 2 ? pathElements[1] : undefined
    

    let sharetoken = undefined
    if(tokenQuery && projectname) {
      sessionStorage.setItem(`last_sharetoken`, JSON.stringify({token: tokenQuery, projectname, username}))
      Cookies.set("usingSharetoken", "true")  //Flag, so the server enables WASM
      sharetoken = tokenQuery
    }else{
      if (projectname)
      {
        let t = sessionStorage.getItem(`last_sharetoken`)
        if(t){
          t = JSON.parse(t)
          sharetoken = ( t.token && t.projectname === projectname && t.username === username )? t.token : undefined
          if(!sharetoken){
            sessionStorage.removeItem("last_sharetoken")
            Cookies.remove("usingSharetoken")
          }
        }
      }
    }

    if(sharetoken) {
      lrServerConnection.setShareToken(sharetoken)
    }
    this.state = 
    {
      hasCookie     : cookie !== undefined,
      hasSharetoken : sharetoken !== undefined,
      validating    : true
    };

    this.tolt_integration = new Loader()
    this.tolt_integration.writeScript({URL: "https://cdn.tolt.io/tolt.js", data: "dfba3030-6393-4a9d-9b1d-ff1b9bfdfd82"})

    if(getCookieConsentValue("pa_ga_enabled"))
    {
      console.log("Start Tracking")
      this.startGA()
    }

  }

  componentDidMount = async ()=>
  {
    if(this.state.hasCookie) {
      let res = await window.LR_ValidateToken()
  
      if(!res.Valid)
      {
        this.setState({hasCookie: false });
      }
    } 

    if(this.state.hasSharetoken) {
      let splitPath = window.location.pathname.split("/")
      if(splitPath.length >= 3) {
        let res = await lrServerConnection.testAccess(splitPath[1], splitPath[2])
        if(res !== 'OK') {
          this.setState({hasSharetoken: false})
        }
      } else {
        this.setState({hasSharetoken: false})
      }
    }

    if(IsUsingWebassembly){
      await window.WEB_CORE.isLoaded
    }

    this.setState({validating: false})
  }

  startGA =() => {
    ReactGA.initialize('UA-197965048-1',
    {
      testMode: CONST.DEVELOPMENT_SERVER,
      gtagOptions: {config: "AW-10825383014"},
    });

    window.__ReactGA = ReactGA

    window.EhAPI = {}; 
    window.EhAPI.after_load = function(){
      window.EhAPI.set_account('mlhp6g686ajrbf7ln4oqvtb2q8', 'production-assist');
      window.EhAPI.execute('rules');};(function(d,s,f) {
      var sc=document.createElement(s);sc.type='text/javascript';
      sc.async=true;sc.src=f;var m=document.getElementsByTagName(s)[0];
      m.parentNode.insertBefore(sc,m);   
      })(document, 'script', '//d2p078bqz5urf7.cloudfront.net/jsapi/ehform.js');
  }

  startTolt =() => {

    window.EhAPI = {}; 
    window.EhAPI.after_load = function(){
      window.EhAPI.set_account('mlhp6g686ajrbf7ln4oqvtb2q8', 'production-assist');
      window.EhAPI.execute('rules');};(function(d,s,f) {
      var sc=document.createElement(s);sc.type='text/javascript';
      sc.async=true;sc.src=f;var m=document.getElementsByTagName(s)[0];
      m.parentNode.insertBefore(sc,m);   
      })(document, 'script', '//d2p078bqz5urf7.cloudfront.net/jsapi/ehform.js');
  }

  render ()
  {
    if(this.state.validating) {
      return (
        <Dimmer active>
          <SemanticLoader active>{LocalizedStrings.LoadingWebView}</SemanticLoader>
        </Dimmer>
      )
    }
    
    return (
      <Router>
        <CookieConsent
          location="bottom"
          buttonText= {LocalizedStrings.CookiesOK}
          cookieName="pa_ga_enabled"
          style={{ background: "#2B373B"}}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          onAccept={this.startGA}
        >
          {LocalizedStrings.CookiesText}
        </CookieConsent>
        <WithGa>
        {this.state.hasCookie || this.state.hasSharetoken || this.props.loggedInUser.data ? this.renderApplictionLoggedIn() : this.renderNormalWebPage()}   
        
        </WithGa>
      </Router>)
  }

  renderContaner(child, padding = true)
  {
    let marginTop= (isPhone, isTables) => 
    { 
      if(isPhone) { return "0" }
      return isTables ? '2em':"1em"
    } 



    return(
      <MediaQuery maxWidth={RESPONSIVE_PHONE}>
        {(isPhone) =>
        <>
          <div style={{gridTemplateRows: "min-content auto", height:"100vh", display: "grid"}}>
            <div style={{gridRow: "1 / 2"}}>
              {isPhone ? null : <Header/>}
            </div>
            <div style={{gridRow: "2 / 3", overflow: 'auto'}}>
              <div style={{display:'grid', gridTemplateRows: "auto min-content", minHeight: '100%'}}>
                <div style={{gridRow:'1/2'}}>
                  <MediaQuery maxWidth={RESPONSIVE_PHONE}>
                    {(matches) => 
                    <div style={{ marginTop: marginTop(isPhone, matches) ,marginLeft: matches?'2em':"0.5em",marginRight: matches?'2em':"0.5em", zIndex: 0 }}>
                      {child}
                      <DefaultModalContainer webApp/>   
                    </div>}
                  </MediaQuery>
                </div>
                <div style={{gridRow:'2/3'}}>
                  {isPhone ? null : <Footer/>}
                  {isPhone ? <MobileFooter/> : null}
                </div>
              </div>
            </div>
          </div>
          </>}
      </MediaQuery>
    )
  }

  renderApplictionLoggedIn =() =>
  {
    return(
      <MediaQuery maxWidth={RESPONSIVE_PHONE}>
        {(isPhone) =>
      <div style={{zIndex: 0,position: 'relative', height: '100%'}}>
      <Routes>
        <Route path="/"                  element={ this.renderContaner(<UserStartPage  />)} />
        <Route path="/main"              element={ this.renderContaner(<UserStartPage  />)} />
        <Route path="/login"             element={ <Navigate to="/"/>} />
        <Route path="/oldfaq"            element={ this.renderContaner(<FAQPage  />)} />
        <Route path="/faq"               element={ this.renderContaner(<NewFAQPage  />)} />

        <Route path="/oldsupport"        element={ this.renderContaner(<SupportPage  />)} />
        <Route path="/support"           element={ this.renderContaner(<NewSupportPage  />)} />

        <Route path="/register"          element={ this.renderContaner(<UserStartPage  />)} />
        <Route path="/oldforgot"         element={ this.renderContaner(<ForgotPage  webApp/>)} />
        <Route path="/forgot"            element={ this.renderContaner(<NewForgotPage webApp/>)} />

        <Route path="/resetpassword"     element={ this.renderContaner(<ResetPage  webApp/>)} />
        <Route path="/admin"             element={ this.renderContaner(<AdminPage      />)} />
        <Route path="/about"             element={                   <NewProductionAssistLandingPage  />} />
        <Route path="/oldblog"           element={ this.renderContaner(<Blog />)} />
        <Route path="/blog"              element={ this.renderContaner(<NewBlog />)} />
        <Route path="/blog/:page"        element={ this.renderContaner(<Blog />)} />
        <Route path="/olddownload"       element={ this.renderContaner(<DownloadApp    />)} />
        <Route path="/download"          element={ this.renderContaner(<NewDownloadApp    />)} />
        <Route path="/get-snacked"       element={ this.renderContaner(<NewAboutUs  />)} />
        <Route path="/structuralquote"   element={ this.renderContaner(<RequestStructuralCheck_Quote  />)} />
        <Route path="/tutorial"          element={ this.renderContaner(<NewAboutUs   />)} />
        <Route path="/settings"          element={ this.renderContaner(<UserSettings activeMenu={"profile"} ><Profile/></UserSettings>)} />
        <Route path="/settings/profile"  element={ this.renderContaner(<UserSettings activeMenu={"profile"} ><Profile/></UserSettings>)} />
        <Route path="/settings/account"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><Account/></UserSettings>)} />
        <Route path="/settings/account/billing"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><UpdateBilling /></UserSettings>)} loader={()=>{
          if(IsUsingWebassembly){
            window.location.reload()
          }
        }}
          />
        <Route path="/settings/account/addseat"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><Account/></UserSettings>)} />
        <Route path="/settings/account/removeseat"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><Account/></UserSettings>)} />
        <Route path="/settings/account/subscription"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><PricingPage inApp={true}/></UserSettings>)} />
        <Route path="/settings/account/paymenthistory"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><PaymentHistory/></UserSettings>)} />
        <Route path="/settings/security"              element={ this.renderContaner(<UserSettings activeMenu={"security"} ><Security/></UserSettings>)} />
        <Route path="/settings/reviews"               element={ this.renderContaner(<UserSettings activeMenu={"reviews"} ><ReviewTemplateList/></UserSettings>)} />
        <Route path="/settings/settings"              element={ this.renderContaner(<UserSettings activeMenu={"settings"}  />)}/>
        
        <Route path="/:username/settings/profile"  element={ this.renderContaner(<UserSettings activeMenu={"profile"} ><Profile/></UserSettings>)} />
        <Route path="/:username/settings/account"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><Account/></UserSettings>)} />
        <Route path="/:username/settings/account/billing"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><UpdateBilling /></UserSettings>) } loader={()=>{
          if(IsUsingWebassembly){
            window.location.reload()
          }
        }}/>
        <Route path="/:username/settings/account/addseat"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><Account/></UserSettings>)} />
        <Route path="/:username/settings/account/removeseat"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><Account/></UserSettings>)} />
        <Route path="/:username/settings/account/subscription"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><PricingPage inApp={true}/></UserSettings>)} />
        <Route path="/:username/settings/account/paymenthistory"  element={ this.renderContaner(<UserSettings activeMenu={"account"} ><PaymentHistory/></UserSettings>)} />
        <Route path="/:username/settings/security"              element={ this.renderContaner(<UserSettings activeMenu={"security"} ><Security/></UserSettings>)} />
        <Route path="/:username/settings/updatetemplates"              element={ this.renderContaner(<UserSettings activeMenu={"updatetemplates"} ><UpdateTemplates/></UserSettings>)} />
        <Route path="/:username/settings/reviews"               element={ this.renderContaner(<UserSettings activeMenu={"reviews"} ><ReviewTemplateList/></UserSettings>)} />
        <Route path="/:username/settings/settings"              element={ this.renderContaner(<UserSettings activeMenu={"settings"}  />)}/>

        <Route path="oldvideo-tutorial"                  element={ this.renderContaner(<Tutorial />)} />
        <Route path="/video-tutorial"                    element={ this.renderContaner(<NewTutorial />)} />
        <Route path="/olddocumentation"                  element={ this.renderContaner(<Documentation type="documentation"   />)} />
        <Route path="/documentation"                  element={ this.renderContaner(<NewDocumentation type="documentation"   />)} />
        <Route path="/acceptance-test"                  element={ this.renderContaner(<AcceptanceTests type="acceptance-test"   />)} />
        <Route path="/olddocumentation/:topic"           element={ this.renderContaner(<Documentation type="documentation"   />)} />
        <Route path="/documentation/:topic"           element={ this.renderContaner(<NewDocumentation type="documentation"   />)} />
        <Route path="/acceptance-test/:topic"           element={ this.renderContaner(<AcceptanceTests type="acceptance-test"   />)} />
        <Route path="/oldknowhow"                        element={ this.renderContaner(<Documentation type="knowhow"   />)} />
        <Route path="/knowhow"                        element={ this.renderContaner(<NewDocumentation type="knowhow"   />)} />
        <Route path="/oldknowhow/:topic"                 element={ this.renderContaner(<Documentation type="knowhow"   />)} />
        <Route path="/knowhow/:topic"                 element={ this.renderContaner(<NewDocumentation type="knowhow"   />)} />

        <Route path="/oldpricing"                     element={ this.renderContaner(<Pricing  />)} />
        <Route path="/pricing"                        element={ this.renderContaner(<NewPricing />)} />
        <Route path="/impressum"                      element={ this.renderContaner(<ImpressumPage  />)} />
        <Route path="/agb"                            element={ this.renderContaner(<AGBPage  />)} />
        <Route path="/camera"                         element={ this.renderContaner(<QrCodeReaderSite onScan={HandleScanInWeb}/>)} />
        <Route path="/getstarted"                     element={ this.renderContaner(<GetStarted  />)} />
        <Route path="/:username/Plans/:plan"          element={ this.renderContaner(<ConfirmPlanSwitch  />)} loader={()=>{
          if(IsUsingWebassembly){
            window.location.reload()
          }
        }}/>
        <Route path="/payment"                        element={ this.renderContaner(<Payment  />)} />
        <Route path="/signingjobs"                    element={ this.renderContaner(<StructuralCalculationChecks adminView={true} />)} />
        <Route path="/oldaboutus"                     element={ this.renderContaner(<AboutUs  />)} />
        <Route path="/aboutus"                        element={ this.renderContaner(<NewAboutUs  />)} />
        <Route path="/oldstatic"                      element={ this.renderContaner(<StaticPage  />)} />
        <Route path="/static"                         element={ this.renderContaner(<NewStaticPage  />)} />
        <Route path="/loadCells"                      element={ this.renderContaner(<LoadCellpage  />)} />
        <Route path="/oldvrLanding"                   element={ this.renderContaner(<VrLandingPage  />)} />
        <Route path="/vrLanding"                      element={ this.renderContaner(<NewVrLandingPage  />)} />
        <Route path="/oldpowerPlanning"               element={ this.renderContaner(<PowerPlanningPage  />)} /> 
        <Route path="/powerPlanning"                  element={ this.renderContaner(<NewPowerPlanningPage  />)} />  
        <Route path="/:username"                      element={ this.renderContaner(<UserOverview activeMenu="projects" ><ProjectOverview/></UserOverview>)} />
        <Route path="/:username/symbolmap"            element={ this.renderContaner(<UserOverview activeMenu="symbolmap" ><SymbolMapDisplay userBasedInWebBrowser/></UserOverview>)} />
        <Route path="/:username/fieldmap"             element={ this.renderContaner(<UserOverview activeMenu="fieldmap" ><VectorworksFieldMapping/></UserOverview>)} />
        <Route path="/:username/trucktemplate"        element={ this.renderContaner(<UserOverview activeMenu="trucktemplate" ><TruckTemplateDisplay/></UserOverview>)} />
        <Route path="/:username/checks"               element={ this.renderContaner(<UserOverview activeMenu="checks" ><ChangeListChecks project={false}/></UserOverview>)} />
        <Route path="/:username/casetemplate"         element={ this.renderContaner(<UserOverview activeMenu="casetemplate" ><CaseTemplateDisplay/></UserOverview>)} />
        <Route path="/:username/racktemplate"         element={ this.renderContaner(<UserOverview activeMenu="racktemplate" ><RackTemplateDisplay/></UserOverview>)} />
        <Route path="/:username/crosssection"         element={ this.renderContaner(<UserOverview activeMenu="crosssection" ><CrossSectionTemplateDisplay/></UserOverview>)} />
        <Route path="/:username/material"             element={ this.renderContaner(<UserOverview activeMenu="material" ><MaterialTemplateDisplay/></UserOverview>)} />
        <Route path="/:username/papertemplates"       element={ this.renderContaner(<UserOverview activeMenu="papertemplates" ><PaperTemplateDisplay/></UserOverview>)} />
        <Route path="/:username/inventory"            element={ this.renderContaner(<UserOverview activeMenu="inventory" ><Inventory/></UserOverview>)} />
        <Route path="/:username/sheduledmentainance"  element={ this.renderContaner(<UserOverview activeMenu="sheduledmentainance" ><SheduledMentenaince/></UserOverview>)} />
        <Route path="/:username/mentenaince"          element={ this.renderContaner(<UserOverview activeMenu="mentenaince" ><MentenainceReports/></UserOverview>)} />
        <Route path="/:username/mentenaince/:id"      element={ this.renderContaner(<UserOverview activeMenu="mentenaince" ><MentenainceReports/></UserOverview>)} />
        <Route path="/:username/signingjobs/"          element={ this.renderContaner(<UserOverview activeMenu="signingjob" ><StructuralCalculationChecks/></UserOverview>)} />
        <Route path="/:username/signingjobs/:id"      element={ this.renderContaner(<UserOverview activeMenu="signingjob" ><StructuralCalculationChecks/></UserOverview>)} />
    
        <Route path="/:username/:projectname/"                      element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/object/:uuid"          element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection><SingleObjectView/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/changelists"                     element={ this.renderContaner(<StartPageProject activeMenu="changelists"  location={location}><ChangeRequestList/></StartPageProject>)} />
        
        <Route path="/:username/:projectname/changelists/:changenumber"               element={ this.renderContaner(<StartPageProject activeMenu="changelists"  location={location}><ChangeRequest comments/></StartPageProject>)} />
        <Route path="/:username/:projectname/changelists/:changenumber/:changeview"   element={ this.renderContaner(<StartPageProject activeMenu="changelists"  location={location}><ChangeRequest/></StartPageProject>)} />

        <Route path="/:username/:projectname/worksheet/"              element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/view"          element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet renderOnly/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/properties"    element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/preset"        element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/supports"      element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/trucks"        element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/cases"         element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/gels"          element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/racks"         element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/positions"     element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/electric"      element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/overview"      element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/uuid/:uuid"    element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/worksheet/:branch"       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="worksheet"><Worksheet/></BranchSelection></StartPageProject>)} />
        
        <Route path="/:username/:projectname/editview/"       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><WebEditRouter/></StartPageProject>, false)} />
        <Route path="/:username/:projectname/editview/:branch"       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><WebEditRouter/></StartPageProject>, false)} />

        
        <Route path="/:username/:projectname/timeline/"                       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="timeline"><Timeline /></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/timeline/:branch"                element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="timeline"><Timeline/></BranchSelection></StartPageProject>)} />
        
        <Route path="/:username/:projectname/electric/"                       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="electric"><ElectricalGraph/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/electric/:branch"                element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="electric"><ElectricalGraph/></BranchSelection></StartPageProject>)} />
        
        <Route path="/:username/:projectname/symbolmap/"                       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="symbolmap"><SymbolMapDisplay/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/symbolmap/:branch"                element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="symbolmap"><SymbolMapDisplay/></BranchSelection></StartPageProject>)} />

        <Route path="/:username/:projectname/fieldmap/"                       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="fieldmap"><VectorworksFieldMapping/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/fieldmap/:branch"                element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="fieldmap"><VectorworksFieldMapping/></BranchSelection></StartPageProject>)} />


        <Route path="/:username/:projectname/data"                            element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="data"><DmxOverview/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/data/:branch"                    element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="data"><DmxOverview/></BranchSelection></StartPageProject>)} />
        
        <Route path="/:username/:projectname/lightplot"                       element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="lightplot"><LightingPaperwork/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/lightplot/:branch"               element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="lightplot"><LightingPaperwork/></BranchSelection></StartPageProject>)} />
        
        <Route path="/:username/:projectname/commits"                         element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="commits"><CommitOverview selectedBranch={"master"}/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/commits/:branch"                 element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="commits"><CommitOverview/></BranchSelection></StartPageProject>)} />
        
        <Route path="/:username/:projectname/tasks"                           element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="tasks"><UserTaskOverview/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/tasks/:branch"                   element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="tasks"><UserTaskOverview/></BranchSelection></StartPageProject>)} />

        <Route path="/:username/:projectname/checks"                          element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="checks"><ChangeListChecks project={true}/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/checks/:branch"                  element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="checks"><ChangeListChecks/></BranchSelection></StartPageProject>)} />

        <Route path="/:username/:projectname/signingjobs"                          element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="signingjob"><StructuralCalculationChecks project={true}/></BranchSelection></StartPageProject>)} />
        <Route path="/:username/:projectname/signingjobs/:branch"                  element={ this.renderContaner(<StartPageProject activeMenu="worksheet"  location={location}><BranchSelection branchview="signingjob"><StructuralCalculationChecks project={true}/></BranchSelection></StartPageProject>)} />



        <Route path="/:username/:projectname/settings"                        element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="general" /></StartPageProject>)} />
        <Route path="/:username/:projectname/settings/general"                element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="general" /></StartPageProject>)} />
        <Route path="/:username/:projectname/settings/access"                 element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="access" /></StartPageProject>)} />
        <Route path="/:username/:projectname/settings/security"               element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="security" /></StartPageProject>)} />
        <Route path="/:username/:projectname/settings/offline"                element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="offline" /></StartPageProject>)} />
        <Route path="/:username/:projectname/settings/review"                 element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="review" /></StartPageProject>)} />
        <Route path="/:username/:projectname/settings/slack"                  element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="slack" /></StartPageProject>)} />
        <Route path="/:username/:projectname/settings/customData"             element={ this.renderContaner(<StartPageProject activeMenu="settings"  location={location}><ProjectSettings activeMenu="customData" /></StartPageProject>)} />
        <Route path="/*"                  element={ this.renderContaner(<UserStartPage  />)} />

      </Routes>
      </div>       
      }
      </MediaQuery> 
         )
  }

  renderNormalWebPage =() =>
  {
    return(<div style={{overflow: 'auto', height:"100%"}}>
            <Routes>
              <Route path="/"               element={ <NewProductionAssistLandingPage   />} />
              <Route path="/newlandingpage" element={ <NewProductionAssistLandingPage/>} />
              <Route path="/oldlandingpage" element={ <ProductionAssistLandingPage/>} />
              <Route path="/oldaboutus"       element={  <><AboutUs  /><Footer /></>} />
              <Route path="/aboutus"       element={  <NewAboutUs  />} />
              <Route path="/oldsupport"       element={ <><SupportPage  /><Footer /></>} />
              <Route path="/support"          element={ <NewSupportPage  /> } />
              <Route path="/about"          element={ <NewProductionAssistLandingPage  />} />
              <Route path="/oldstatic"       element={ <StaticPage  />} />
              <Route path="/static"          element={ <NewStaticPage  />} />
              <Route path="/oldvrLanding"       element={ <VrLandingPage  />} />
              <Route path="/vrLanding"          element={ <NewVrLandingPage  />} />
              <Route path="/oldpowerPlanning"   element={ <PowerPlanningPage  />} />
              <Route path="/powerPlanning"      element={ <NewPowerPlanningPage  />} />
              
              <Route path="/loadCells"          element={ <LoadCellpage  />} />
              <Route path="/oldcollaboration"   element={ <CollaborationPage  />} />
              <Route path="/collaboration"      element={ <NewCollaborationPage  />} />
              <Route path="/oldfaq"            element={ <><FAQPage  /><Footer /></>} />
              <Route path="/faq"            element={ <><NewFAQPage  /><NewFooter /></>} />

              <Route path="/oldpricing"        element={ <><Pricing  /></>} />
              <Route path="/pricing"        element={ <><NewPricing  /></>} />       
              <Route path="/getstarted"     element={ <><GetStarted  /></>} />
              <Route path="/payment"        element={ <><Payment  /></>} />
              <Route path="/get-snacked"       element={ <><NewAboutUs  /><Footer /></>} />
              <Route path="/tutorial"       element={ <><NewAboutUs  /><Footer /></>} />
              <Route path="/oldlogin"          element={ <><SignIn   webApp/></>} />
              <Route path="/login"          element={ <><NewSignIn   webApp/></>} />
              <Route path="/oldregister"       element={ <><CreateUser  webApp /></>} />
              <Route path="/oldregister/:template"       element={ <><CreateUser  webApp /></>} />
              <Route path="/register"       element={ <><NewCreateUser  webApp /></>} />
              <Route path="/register/:template"       element={ <><NewCreateUser  webApp /></>} />
              <Route path="/oldforgot"         element={ <><ForgotPage  webApp /></>} />
              <Route path="/forgot"         element={ <><NewForgotPage  webApp /></>} />
              <Route path="/oldvideo-tutorial"    element={ <><Tutorial   /><Footer /></>} />
              <Route path="/video-tutorial"       element={ <NewTutorial   /> } />
              <Route path="/impressum"       element={ <><Header/><ImpressumPage   /><Footer /></>} />
              <Route path="/agb"           element={ <><Header/><AGBPage   /><Footer /></> }/>
              <Route path="/olddocumentation"  element={ <><Documentation type="documentation"   /><Footer /></>} />
              <Route path="/olddocumentation/:topic"element={ <><Documentation type="documentation"   /><Footer /></>} />
              <Route path="/documentation"  element={ <><NewDocumentation type="documentation"   /><NewFooter /></>} />
              <Route path="/documentation/:topic"element={ <><NewDocumentation type="documentation"   /><NewFooter /></>} />
              <Route path="/oldknowhow"  element={ <><Documentation type="knowhow"   /><Footer /></>} />
              <Route path="/oldknowhow/:topic"element={ <><Documentation type="knowhow"   /><Footer /></>} />
              <Route path="/knowhow"  element={ <><NewDocumentation type="knowhow"   /><NewFooter /></>} />
              <Route path="/knowhow/:topic"element={ <><NewDocumentation type="knowhow"   /><NewFooter /></>} />
              <Route path="/olddownload"       element={ <><DownloadApp    /><Footer /></>} />
              <Route path="/download"       element={ <NewDownloadApp    />} />
              <Route path="/resetpassword"  element={ <><ResetPage  webApp/></>} />
              <Route path="/oldblog"        element={ <><Blog /><Footer /></>} />
              <Route path="/blog"           element={ <NewBlog />} />
              <Route path="/blog/:page"     element={ <><Blog /><Footer/></>} />
              <Route path="/structuralquote"       element={ this.renderContaner(<RequestStructuralCheck_Quote  />)} />

              <Route       path="/*"              element={ <><Header /><NotFound   /><Footer /></>} />
            </Routes>
          </div>)
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      loggedInUser: (state[ACTIVE_USER]),
    };
}

export default connect(mapStateToProps)(LightRightRouter)


