
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Icon, Label, Segment } from 'semantic-ui-react'
import TimePhaseGroup from "./TimePhaseGroup";
import LocalizedStrings from "../../localization/SceneTimeLineComponent";
import PresetSelection from './PresetSelection'
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import { IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class SceneTimeLine extends Component 
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      timePhases  : [],

      // search
      isLoading: false,
      results  : [], 
      value    : "",
      Modal: {
        open: false,
        uuid: undefined,
        type: undefined // "timeline" | "step"
      }
    };
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();

    if (globalCallbacks.getTimePhases) {
      globalCallbacks.getTimePhases();
    }
  }

  render() 
  {
    let showData = !this.state.value ? this.state.timePhases : this.state.isLoading ? this.state.timePhases : this.state.results
    const mobileStyle = {position: "relative", marginBottom: "8rem"}

    return(
      <div style={{width:"100%", height:"100%"}}>
        <PresetSelection data={this.state.Modal} onClose={()=>this.setState({Modal: {...this.state.Modal, open: false}})}/>

        <Table style={{borderBottom: "none", margin:0}}>
          <Table.Header>
              <Table.Row>
                  <Table.HeaderCell colSpan='9'>
                      <Search open    = {false}
                              loading = {this.state.isLoading}
                              value   = {this.state.value}
                              onSearchChange = {(e, {value}) => this.onSearchChange(value, this.state.timePhases)}
                              size = {window.IsIOS ? "mini" : "large"}
                              aligned = "right"/>
                  </Table.HeaderCell>
              </Table.Row>
          </Table.Header>
        </Table>

        <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 27em)" :"calc(100vh - 32em)", overflowX:"hidden", overflowY:"auto", marginBottom:"5em"}}>
          <Table striped structured compact='very' size="small">
            <Table.Body>
              {showData.length > 0 && showData.map((phase, i) =>
                {
                  return(

                      <TimePhaseGroup key={phase.UUID}
                                      openEditTimeLineStepChange = {(uuid)=>{this.openModal(uuid, "step")}}
                                      openEditTimeLineChange = {(uuid)=>{this.openModal(uuid, "timeline")}}
                                      phase={phase}
                                      onDragStart     = {() => {this.draggedIndex = i;}}
                                      onDragEnter     = {() => {this.reorderTimePhase(i);}}
                                      onDragEnd       = {() => {this.draggedIndex = -1; this.setTimePhaseOrder();}}/>)
                })
              }
            </Table.Body>
          </Table>
        </div>

        <Segment vertical textAlign="center" style={window.IsIOS ? mobileStyle : {position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width: "100%", border:"none"}}>
          <Label as="a" color="green" onClick={this.onAddNewTimePhase}>
          <Icon name="plus"/>{LocalizedStrings.AddNewTimeLineEntry}
          </Label>
        </Segment>
      </div>
    );
  }

  openModal(uuid, type){
    this.setState({
      Modal: {
        open: true,
        uuid: uuid,
        type: type
      }
    })
  }

  onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

  onAddNewTimePhase = () =>
  {
    window.LR_AddNewTimePhase();
  }

  setUpCallbacks()
  {
    globalCallbacks.getTimePhases = async () =>
    {
      let ret  = await window.LR_GetTimePhases();
      let ret2 = await window.LR_GetWorksheets();

      let timePhases = ret.TimePhases
      timePhases.sort(function(a, b) {return a.Order - b.Order});
      timePhases.forEach((timePhase, i) =>
      {
        timePhase.Order = i;
        //Also sort the TimePhaseChanges
        timePhase.TimePhaseChangeObjects.sort(function(a, b) {return a.Order - b.Order});
        timePhase.TimePhaseChangeObjects.forEach((change, j) => 
        { 
          change.Order = j; 
          change.LinkedWorksheetArray = []
          ret2.Worksheets.forEach((worksheet, k) =>
          {
            if(worksheet.LinkedTimeLinePhaseChange === change.UUID)
            {
              change.LinkedWorksheetArray.push(worksheet)
            }
          })
        
        });


       
      });
      this.setState({timePhases});
    }
  }

  // Drag and Drop the Phases
  reorderTimePhase = (currentPlace) =>
  {
    if (this.draggedIndex < 0) { return; }
    let timePhases = [...this.state.timePhases];
    let draggedIndex = this.draggedIndex;
    let j = 0;
    let temp = 0;

    if(currentPlace > draggedIndex)
    {
      for(j = currentPlace; j > draggedIndex; j--)
      {
        temp = timePhases[j].Order;
        timePhases[j].Order   = timePhases[j-1].Order;
        timePhases[j-1].Order = temp;
      }
    } else if(currentPlace < draggedIndex)
    {
      for(j = currentPlace; j < draggedIndex; j++)
      {
        temp = timePhases[j].Order;
        timePhases[j].Order   = timePhases[j+1].Order;
        timePhases[j+1].Order = temp;
      }
    }

    timePhases.sort(function(a, b) {return a.Order - b.Order});
    this.draggedIndex = currentPlace;
  }

  setTimePhaseOrder = () =>
  {
    window.LR_SetTimePhaseOrder(this.state.timePhases);
  }

}

export default SceneTimeLine;

