
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Label, Popup} from 'semantic-ui-react'

import LocalizedStrings from "../../localization/NavigationContainer";
import BasicSelect from '../Basics/BasicSelect';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class CaseNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = this.props.case;
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.case))
        if(oldProps.case[name] !== value) 
        {
            this.setState({[name]: value});
        }
    }

    render() 
    {
        let caseObj = this.state;
        return (
            <>
            { window.IsIOS ? this.renderTableRowInIos(caseObj) 
            : <Table.Row>
                <Table.Cell collapsing>
                    {!this.props.small ? <Popup content={LocalizedStrings.CaseLinkedTemplate}
                     trigger={<Label size="mini">{caseObj.TemplateName}</Label>}/> : null}
                </Table.Cell>
                <Table.Cell>
                    <Input  transparent
                            name      = "Name" 
                            value     = {caseObj.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", caseObj.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                {!this.props.small ? <Table.Cell collapsing>
                    <BasicSelect normalDropdown placeholder="No Truck" options={this.props.truckOptions} onStateUpdate={this.onCaseTruckChange} value={this.props.case.LinkedContainer}/>
                </Table.Cell> : null}
                <Table.Cell textAlign="center" collapsing>
                <Popup content={LocalizedStrings.EditCasePopup}
                    trigger={
                    <Icon   name="edit"
                            onClick={() => this.onEditClick()} />
                    }/>
                    <Popup content={LocalizedStrings.DeleteCase}
                    trigger={
                    <Icon   name    = "delete"
                            color   = "red"
                            onClick = {() => this.onDelete(caseObj.UUID)}
                            />
                    }/>
                </Table.Cell>
            </Table.Row>
            }</>
        );
    }

    renderTableRowInIos = (caseObj) => {
        return (
            <Table.Row >
                <div style={{ display: "flex", marginLeft: "1rem"}}>
                    <Input  transparent
                            name      = "Name" 
                            value     = {caseObj.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", caseObj.Name)}
                            onKeyDown = {this.onKeyDown}/>
                    <Popup content={LocalizedStrings.EditCasePopup}
                    trigger={
                    <Icon   name="edit"
                            onClick={() => this.onEditClick()} />
                    }/>
                    <Popup content={LocalizedStrings.DeleteCase}
                    trigger={
                    <Icon   name    = "delete"
                            color   = "red"
                            onClick = {() => this.onDelete(caseObj.UUID)}
                            />
                    }/>
                </div>
            </Table.Row>
        )
    }

    onCaseTruckChange = (name, value, e) =>
    {  
        window.LR_SetCase({UUID: this.state.UUID, LinkedContainer: value})
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        
        window.LR_SetCase(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        window.LR_DeleteCase(request);
    }

    onEditClick = () =>
    {
        if (globalCallbacks.openCaseEdit) { globalCallbacks.openCaseEdit(this.state); }
    }

}

export default CaseNode;

