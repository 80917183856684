
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Form, Header, Popup, Button } from 'semantic-ui-react'
import PresetNode from "./PresetNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import ColorInputField from '../ColorPicker/ColorInputField';
import { IsElectronContext, radToDeg } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class ClassTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            presets : [],
            editPresetHandle: undefined,
            editPresetObj: {},
            content: {},

            // search
            isLoading: false,
            results  : [], 
            value    : "",

            sorting: null,
            indexMap: [],
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.refreshPresets();
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    render() 
    {
        let presets = this.state.presets;
        const mobileStyle = {position: "relative", marginBottom: "8rem"}

        return (
            <div style={{width:"100%", height:"100%"}}>
                <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{zIndex:0, position: 'sticky'}} colSpan='4' onClick={this.onHeaderClick}>
                                {<Icon link style={{floated: 'left', position: 'absolute', zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                                <Search open    = {false}
                                        loading = {this.state.isLoading}
                                        value   = {this.state.value}
                                        onClick = {(e)=>{e.stopPropagation()}}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value, presets)}
                                        size = {window.IsIOS ? "mini" : "large"}
                                        aligned = "right"/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}>
                    <Table striped structured compact='very' size="small">
                        <Table.Body>
                            {this.renderRows()}
                        </Table.Body>
                    </Table>
                    
                    <LRModal    open={this.state.editPresetHandle !== undefined}
                                title={this.state.editPresetObj.Name}
                                onCancelClick={this.closeEditPreset}
                                onOkClick={this.applyEditPreset}>
                        <Form>
                            <Form.Group widths="equal">
                                <ColorInputField 
                                    label           = {LocalizedStrings.Color} 
                                    colorX          = {this.state.editPresetObj.Color ? this.state.editPresetObj.Color.X : 0} 
                                    colorY          = {this.state.editPresetObj.Color ? this.state.editPresetObj.Color.Y : 0} 
                                    colorL          = {this.state.editPresetObj.Color ? this.state.editPresetObj.Color.Z : 0} 
                                    onColorChanged  = {(cie) => {this.setState({editPresetObj:{...this.state.editPresetObj,Color: {X : cie.fx, Y : cie.fy, Z : cie.f_Y}}});}} />
                            </Form.Group>

                            <Form.Group widths="equal">
                                <Form.Input name            = "FadeIn"
                                            label           = {LocalizedStrings.FadeIn}
                                            inline
                                            fluid
                                            type            = "Number"
                                            labelPosition   = "right" 
                                            value           = {this.state.FadeIn}
                                            onChange        = {this.onNumberChanges}
                                            step            = {"1"}>
                                                <input />
                                                <Popup content={LocalizedStrings.FramesPerSecond} trigger={<Label>{LocalizedStrings.Frames}</Label>}></Popup>
                                </Form.Input>
                                <Form.Input name            = "FadeOut"
                                            label           = {LocalizedStrings.FadeOut}
                                            inline
                                            fluid
                                            type            = "Number"
                                            labelPosition   = "right" 
                                            value           = {this.state.FadeOut}
                                            onChange        = {this.onNumberChanges}
                                            step            = {"1"}>
                                                <input />
                                                <Popup content={LocalizedStrings.FramesPerSecond} trigger={<Label>{LocalizedStrings.Frames}</Label>}></Popup>
                                </Form.Input>
                                </Form.Group>
                                <Form.Group widths="equal">
                                <Form.Input name            = "DelayIn"
                                            label           = {LocalizedStrings.DelayIn}
                                            inline
                                            fluid
                                            type            = "Number"
                                            labelPosition   = "right" 
                                            value           = {this.state.DelayIn}
                                            onChange        = {this.onNumberChanges}
                                            step            = {"1"}>
                                                <input />
                                                <Popup content={LocalizedStrings.FramesPerSecond} trigger={<Label>{LocalizedStrings.Frames}</Label>}></Popup>
                                </Form.Input>
                                <Form.Input name            = "DelayOut"
                                            label           = {LocalizedStrings.DelayOut}
                                            inline
                                            fluid
                                            type            = "Number"
                                            labelPosition   = "right" 
                                            value           = {this.state.DelayOut}
                                            onChange        = {this.onNumberChanges}
                                            step            = {"1"}>
                                                <input />
                                                <Popup content={LocalizedStrings.FramesPerSecond} trigger={<Label>{LocalizedStrings.Frames}</Label>}></Popup>
                                </Form.Input>
                            </Form.Group>
                        </Form>
                        { this.renderContentTable(this.state.content.Content, this.state.content.HasValue)}
                    </LRModal>

                </div>

                <Segment vertical textAlign="center" style={window.IsIOS ? mobileStyle : {position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width: "100%", border:"none"}}>
                    <Label as="a" color="green" onClick={this.onAddNewPreset}>
                        <Icon name="plus"/>{LocalizedStrings.AddNewPreset}
                    </Label>
                </Segment>
            </div>
        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    renderRows = () => {
        let presets = this.state.presets;
        let showData = !this.state.value ? presets : this.state.isLoading ? presets : this.state.results
        
        if(this.state.sorting) {
            let indexMap = []
            showData.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => showData[a][SORT_BY] === showData[b][SORT_BY]  ? 0 : (showData[a][SORT_BY]  < showData[b][SORT_BY]  ? -1 : 1))
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return showData[this.state.indexMap[calcIndex]]
            }).map((preset, i) => 
            {
                return(
                    <PresetNode  key={preset.UUID} 
                                preset={preset}
                                onDragStart     = {() => {this.draggedIndex = i;}}
                                onDragEnter     = {() => {this.reorderPreset(i);}}
                                onDragEnd       = {() => {this.setPresetOrder();}}
                    />)
            })
        }
        
        return showData.map((preset, i) => 
        {
            return(
                <PresetNode  key={preset.UUID} 
                            preset={preset}
                            onDragStart     = {() => {this.draggedIndex = i;}}
                            onDragEnter     = {() => {this.reorderPreset(i);}}
                            onDragEnd       = {() => {this.setPresetOrder();}}
                />)
        })
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

    renderContentTable = (content, HasValue) =>
    {
        if(! content) { return null}

        return (
            <React.Fragment>
            <Header as='h5' attached='top'>
                {LocalizedStrings.PresetContent}
            </Header>
            <Segment attached placeholder={!HasValue}>
                {content.map(entry => { return this.renderEntry(entry) })}
                {HasValue ? null : <Header textAlign="center">{LocalizedStrings.NoContent}</Header>}
            </Segment>
            </React.Fragment>

        )

    }



    renderEntry = (entry) =>
    {   
        if(Object.entries(entry.Properties).length === 0)
        {
            return null
        }
        return(
        <Table fixed size="small" compact striped key={entry.Object.UUID}>
            <Table.Header>
                <Table.Row>
                <Table.HeaderCell>{entry.Object.NameIdentifier}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Value}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {Object.entries(entry.Properties).map( (key, value) => { return this.renderRow(key[0], key[1], entry.Object)})}
            </Table.Body>
        </Table>
        )
    }

    renderRow = (name, value, entry) =>
    {
        let DeletePresetEntry  = async() =>
        {
            await window.LR_RemovePresetEntry({PresetUUID: this.state.editPresetHandle, PropertyName: name, Objects: [entry.UUID]});
            globalCallbacks.ShowPresetEdit({UUID: this.state.editPresetHandle})
        }
        //----------------------------------------------------------------------
        // Render Geometry Rotate Overwrite
        if (name.endsWith("RX") || name.endsWith("RY") || name.endsWith("RZ") || name.endsWith("TX") || name.endsWith("TY") || name.endsWith("TZ")) 
        {
            return this.renderGoemetryTransform(name, value, DeletePresetEntry)
        }

        //----------------------------------------------------------------------
        // Render Geometry Rotate Overwrite
        if (name.startsWith("{") && name.endsWith("}")) 
        {
            return this.renderElectricalEntry(name, value, DeletePresetEntry)
        }

        return(
        <Table.Row key={name}>
            <Table.Cell>
            {name}
            
            </Table.Cell>
            <Table.Cell>
            {Object.entries(value).map(([key, val]) => 
            {
                if(key.startsWith("Preset"))
                {
                    return null
                }
                let displayValue = this.getPropertyValueAsStringArray(val);
                return displayValue.map((disVal, i) => 
                {
                    return <div key={i}>{disVal} </div>
                })
            })}
            </Table.Cell>
            <Table.Cell>
            <Button negative onClick={DeletePresetEntry}>{LocalizedStrings.Delete_Header}</Button>
            </Table.Cell>
        </Table.Row>
        )
    }

    renderGoemetryTransform = (name, value, DeletePresetEntry) => 
    {
        let currentTransformValue = value[name]
        let geometryTransformName = this.getPropertyGeometryTransformName(name) + " - " + currentTransformValue.GeometryName
        let geometryDisplayVaule = this.getGeometryTransformValue(name, value)

        return <Table.Row key={name}>
            <Table.Cell>
                {geometryTransformName}
            </Table.Cell>
            <Table.Cell>
                {geometryDisplayVaule}
            </Table.Cell>
            <Table.Cell>
            <Button negative onClick={DeletePresetEntry}>{LocalizedStrings.Delete_Header}</Button>
            </Table.Cell>

        </Table.Row>
    }

    renderElectricalEntry = (name, value, DeletePresetEntry) => 
    {
        return <Table.Row key={name}>
            <Table.Cell>
                {value[name].Name}
            </Table.Cell>
            <Table.Cell>
                {value[name].Connections.length}
            </Table.Cell>
            <Table.Cell>
            <Button negative onClick={DeletePresetEntry}>{LocalizedStrings.Delete_Header}</Button>
            </Table.Cell>
        </Table.Row>
    }

    getPropertyGeometryTransformName = (name) =>
    {
        if (name.endsWith("RX")) { return "Tilt"; }
        else if (name.endsWith("RY")) { return "Roll"; }
        else if (name.endsWith("RZ")) { return "Pan"; }
        else if (name.endsWith("TX")) { return "X"; }
        else if (name.endsWith("TY")) { return "Y"; }
        else if (name.endsWith("TZ")) { return "Z"; }
        return name;
    }

    getGeometryTransformValue = (name, value) =>
    {
        let currentValue = value[name].Value

        if (name.endsWith("RX") || name.endsWith("RY") || name.endsWith("RZ"))
        {
            currentValue = radToDeg(currentValue);
        }

        return currentValue
    }

    getPropertyValueAsStringArray(value)
    {
      let result = [];
      if (value instanceof Object) { result = Object.keys(value).map(valName => `${valName}:${value[valName]}`) }
      else { result = [value.toString()]; }
      return result;
    }

    onNumberChanges = (e, {name, value}) =>
    {
        this.setState({[name]:Number(value)});
    }

    applyEditPreset = () =>
    {
        let preset =
        {
            ...this.state.editPresetObj,
            FadeIn      : this.state.FadeIn,
            FadeOut     : this.state.FadeOut,
            DelayIn     : this.state.DelayIn,
            DelayOut    : this.state.DelayOut
        }

        window.LR_SetPreset(preset);
        this.closeEditPreset()
    }

    closeEditPreset = () =>
    {
        this.setState({editPresetHandle: undefined, editPresetObj:{}})
    }

    setUpCallbacks()
    {
        globalCallbacks.refreshPresets = async () =>
        {
            let presetObject = await window.LR_GetPresets();
            let presets = presetObject.Presets;
            presets.sort(function(a, b) {return a.Order - b.Order});
            presets.forEach((preset, i) =>
            {
                preset.Order = i;
            });

            

            this.setState({presets});
        }

        globalCallbacks.ShowPresetEdit = async (request) =>
        {
            let content = await window.LR_GetPresetContent({UUID: request.UUID})

            this.state.presets.forEach((preset) => 
            {
                if (preset.UUID === request.UUID)
                {
                    this.setState(
                    {
                        editPresetHandle    : request.UUID,
                        editPresetObj       : preset,
                        content             : content,
                        FadeIn              : preset.FadeIn,
                        FadeOut             : preset.FadeOut,
                        DelayIn             : preset.DelayIn,
                        DelayOut            : preset.DelayOut,
                    });
                    return;
                }
            });

        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.refreshPresets = undefined;
    }

    onAddNewPreset = () =>
    {
        window.LR_AddNewPreset();
    }

    setPresetOrder = () =>		
    {		
        window.LR_SetPresetOrder(this.state.presets);		
    }

    // Drag and Drop the Presets 
    reorderPreset = (currentPlace) =>
    {
        let presets = [...this.state.presets];
        let draggedIndex = this.draggedIndex;
        let j = 0;
        let temp = 0;

        if(currentPlace > draggedIndex)
        {
            for(j = currentPlace; j > draggedIndex; j--)
            {
                temp = presets[j].Order;
                presets[j].Order   = presets[j-1].Order;
                presets[j-1].Order = temp;
            }
        } else if(currentPlace < draggedIndex)
        {
            for(j = currentPlace; j < draggedIndex; j++)
            {
                temp = presets[j].Order;
                presets[j].Order   = presets[j+1].Order;
                presets[j+1].Order = temp;
            }
        }

        presets.sort(function(a, b) {return a.Order - b.Order});
        this.draggedIndex = currentPlace;
        this.setState({presets}); 
    }

}

export default ClassTable;

