
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Form, Label, Popup} from 'semantic-ui-react'
import LocalizedStrings from "../../localization/NavigationContainer";
import UnitInput from '../Basics/BasicUnitInput';
import { BASE_UNIT_DATE, BASE_UNIT_LENGTH, BASE_UNIT_WEIGHT } from '../../util/defines';
import LRModal from '../Basics/BasicModal';


class ContainerNode extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            ...this.props.container,

            openModal           : false,
            tempName            : this.props.container.Name,
            tempSizeX           : this.props.container.TruckSizeX,
            tempSizeY           : this.props.container.TruckSizeY,
            tempSizeZ           : this.props.container.TruckSizeZ,
            tempAllowablePayload : this.props.container.AllowablePayload,

            tempLoadingLocation   : this.props.container.LoadingLocation,
            tempDriver   : this.props.container.Driver,
            tempLicence   : this.props.container.Licence,

            tempLoadingDateStart   : this.props.container.LoadingDateStart,
            tempLoadingDateEnd   : this.props.container.LoadingDateEnd,
            tempDeLoadingDate   : this.props.container.DeLoadingDate,
        };
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.container))
        if(oldProps.container[name] !== value) 
        {
            this.setState({[name]: value});
        }
    }

    render() 
    {
        let container = this.state;
        return (
            <>
            { window.IsIOS ? this.renderTableRowInIos(container) 
            :<Table.Row >
                <Table.Cell collapsing>
                    {!this.props.small ?
                        <Popup content={LocalizedStrings.TruckLinkedTemplate}
                            trigger={
                            <Label size="mini">{container.TemplateName}</Label>
                        }/> : null}
                </Table.Cell>
                <Table.Cell>
                    <Input  transparent
                            name      = "Name" 
                            value     = {container.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", container.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                <Table.Cell collapsing>
                    <Popup content={LocalizedStrings.EditTruckPopup}
                    trigger={
                    <Icon   name    = "edit" 
                            link
                            onClick = {this.openEditModal}/>
                    }/>
                    <Popup content={LocalizedStrings.DeleteTruck}
                    trigger={
                    <Icon   name    = "delete"
                            color   = "red"
                            link
                            onClick = {() => this.onDelete(container.UUID)}
                            />
                    }/>
                   
                </Table.Cell>
            </Table.Row>}

            {this.renderModal()}

            </>
        );
    }

    renderTableRowInIos = (container) => {
        return (
            <Table.Row >
                <div style={{ display: "flex", marginLeft: "1rem"}}>
                <Input  transparent
                        name      = "Name" 
                        value     = {container.Name} 
                        onChange  = {this.onValueChanged} 
                        onBlur    = {() => this.updateData("Name", container.Name)}
                        onKeyDown = {this.onKeyDown}
                        />
                <Popup content={LocalizedStrings.EditTruckPopup}
                        trigger={
                        <Icon   name    = "edit" 
                                link
                                onClick = {this.openEditModal}/>
                        }/>
                <Popup content={LocalizedStrings.DeleteTruck}
                trigger={
                        <Icon   name    = "delete"
                                color   = "red"
                                link
                                onClick = {() => this.onDelete(container.UUID)}
                        />
                }/>
                </div>
            </Table.Row>
        )
    }

    renderModal = () =>
    {
        return (
            <LRModal    open={this.state.openModal}
                        title={LocalizedStrings.EditInventoryContainer}
                        onOkClick={this.editInventoryContainer}
                        onCancelClick={this.closeEditModal}>
                <Form>
                    <Form.Input name = "tempName"
                    label     = {LocalizedStrings.NewContainerName}
                    inline
                    fluid
                    value     = {this.state.tempName}
                    onChange  = {this.onValueChanged}/>

                    <Form.Group widths="equal">
                        <UnitInput name = "tempSizeX"
                        baseUnit  = {BASE_UNIT_LENGTH}
                        label     = {LocalizedStrings.NewContainerSizeX}
                        inline
                        fluid
                        value     = {this.state.tempSizeX}
                        onStateUpdate  = {(name, value, e) => {this.onValueChanged(e, {name, value})}}/>

                        <UnitInput name = "tempSizeY"
                        baseUnit  = {BASE_UNIT_LENGTH}
                        label     = {LocalizedStrings.NewContainerSizeY}
                        inline
                        fluid
                        value     = {this.state.tempSizeY}
                        onStateUpdate  = {(name, value, e) => {this.onValueChanged(e, {name, value})}}/>

                        <UnitInput name = "tempSizeZ"
                        baseUnit = {BASE_UNIT_LENGTH}
                        label    = {LocalizedStrings.NewContainerSizeZ}
                        inline
                        fluid
                        value     = {this.state.tempSizeZ}
                        onStateUpdate  = {(name, value, e) => {this.onValueChanged(e, {name, value})}}/>

                        <UnitInput name = "tempAllowablePayload"
                        baseUnit = {BASE_UNIT_WEIGHT}
                        label    = {LocalizedStrings.NewContainerAllowablePayload}
                        inline
                        fluid
                        value    = {this.state.tempAllowablePayload}
                        onStateUpdate = {(name, value, e) => {this.onValueChanged(e, {name, value})}}/>

                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input name = "tempLoadingLocation"
                        label     = {LocalizedStrings.LoadingLocation}
                        inline
                        fluid
                        value     = {this.state.tempLoadingLocation}
                        onChange  = {this.onValueChanged}/>
                        <Form.Input name = "tempDriver"
                        label     = {LocalizedStrings.ShippingCompany}
                        inline
                        fluid
                        value     = {this.state.tempDriver}
                        onChange  = {this.onValueChanged}/>
                        <Form.Input name = "tempLicence"
                        label     = {LocalizedStrings.Licence}
                        inline
                        fluid
                        value     = {this.state.tempLicence}
                        onChange  = {this.onValueChanged}/>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <UnitInput label={LocalizedStrings.LoadingDateStart} name="tempLoadingDateStart" baseUnit={BASE_UNIT_DATE} isClearable onStateUpdate={(name, date) => this.onDateChanged(name, date)} value={this.state.tempLoadingDateStart} />
                        <UnitInput label={LocalizedStrings.LoadingDateEnd} name="tempLoadingDateEnd" baseUnit={BASE_UNIT_DATE} isClearable onStateUpdate={(name, date) => this.onDateChanged(name, date)} value={this.state.tempLoadingDateEnd} />
                        <UnitInput label={LocalizedStrings.DeLoadingDate} name="tempDeLoadingDate" baseUnit={BASE_UNIT_DATE} isClearable onStateUpdate={(name, date) => this.onDateChanged(name, date)} value={this.state.tempDeLoadingDate} />
                    </Form.Group>
                </Form>
            </LRModal>
        )
    }

    openEditModal = () =>
    {
        this.setState(
        {
            openModal   : true,
            tempName    : this.state.Name,
            tempSizeX   : this.state.TruckSizeX,
            tempSizeY   : this.state.TruckSizeY,
            tempSizeZ   : this.state.TruckSizeZ,
        });
    }

    editInventoryContainer = (e) => 
    {
        let request = 
        {
            UUID        : this.state.UUID,
            Name        : this.state.tempName,
            TruckSizeX  : Number(this.state.tempSizeX),
            TruckSizeY  : Number(this.state.tempSizeY),
            TruckSizeZ  : Number(this.state.tempSizeZ),
            AllowablePayload : this.state.tempAllowablePayload,

            LoadingLocation  :this.state.tempLoadingLocation,
            Driver  : this.state.tempDriver,
            Licence  : this.state.tempLicence,

            LoadingDateStart  : this.state.tempLoadingDateStart,
            LoadingDateEnd  : this.state.tempLoadingDateEnd,
            DeLoadingDate  : this.state.tempDeLoadingDate,
        }
        window.LR_SetTruck(request);

        this.closeEditModal();
    }

    closeEditModal = (e) => 
    {
        this.setState({openModal: false});
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    onDateChanged = async (name, date) => 
    {
        this.setState({[name]: date});
    }

    updateData = (name, value) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }
        window.LR_SetTruck(request);
        this.setState({[name]:value});
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }
        window.LR_DeleteTruck(request);
        
    }


}

export default ContainerNode;

