
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Icon, Input, Label, List, Popup, Segment, Table, } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/NavigationContainer";

import { globalWindowInterface } from '../../util/callbackTypes';
import LRModal from '../Basics/BasicModal';
import ColorInputField from '../ColorPicker/ColorInputField';
import { ColorCodeInterface } from './ColorCodeTable';
import { RGB2cie, cie2hex } from '../ColorPicker/utilities';
import { getHexColorToGradient } from '../../util/defines';

declare const window: globalWindowInterface;

export function ColorCodeNode(this: unknown, { colorCode }: { colorCode: ColorCodeInterface }) {
    const [modalOpen, setModalOpen] = useState(false)
    const [colorCodeData, setColorCodeData] = useState<ColorCodeInterface>(colorCode)
    const [isDeleted, setIsDeleted] = useState(false)

    useEffect(() => {
        setColorCodeData(colorCode)
    }, [colorCode])

    const onSave = () => {
        window.LR_SetColorCodeObject(colorCodeData);
        setModalOpen(false)
    }

    const onKeyDown = (event) => {
        if (event.keyCode === 13) { onSave; event.target.blur() }
    }

    const onDelete = () => { window.LR_DeleteColorCodeObject({ UUID: colorCodeData.UUID }); setIsDeleted(true) }

    const onAddNewColor = ()=>setColorCodeData(data => {
        let cie = RGB2cie([
            Math.random() * 255,
            Math.random() * 255,
            Math.random() * 255,
        ])

        data.Color.push({ X: cie.fx, Y: cie.fy, Z: cie.f_Y })
        return {...data}
    })

    const onCopy = () => {
        window.LR_AddNewColorCodeObject({ ...colorCode, Name: undefined })
    }

    const cssStyle = useMemo(()=>getHexColorToGradient(colorCodeData.Color.map(i => cie2hex(i))), [colorCode, colorCodeData])

    const [dragIdx, setDragIdx] = useState<{source: number, target: number}>(undefined)

    const onDragEnd = ()=>{

        setColorCodeData(data => {
            let out = [...data.Color]

            const elm = out.splice(dragIdx.source, 1)[0];

            out.splice(dragIdx.target, 0, elm);
            return {...data, Color: out}; 
        })

        setDragIdx(undefined)
    }

    if (isDeleted) {
        return <></>
    }

    const renderTableRowInIos = () => {
        return (
            <Table.Row >
                <div style={{ display: "flex", marginLeft: "1rem"}}>
                    <Popup content={LocalizedStrings.ColorColorCode}
                        trigger=
                        {
                            <div style={{ width: "4rem", height: "1rem", background: cssStyle /*,backgroundColor: hexColor , backgroundImage: hexColor */ }} />
                        } />
                     <Input transparent
                            name="Name"
                            value={colorCodeData.Name}
                            onChange={(_, { value }) => { setColorCodeData(i => ({ ...i, Name: value })) }}
                            onBlur={onSave}
                            onKeyDown={onKeyDown}/>
                     <Popup content={LocalizedStrings.EditColorCode}
                    trigger={
                        <Icon
                            name="edit"
                            link
                            onClick={() => setModalOpen(true)} />
                    } />
                    <Popup content={LocalizedStrings.DeleteColorCode}
                        trigger={
                            <Icon
                                name="delete"
                                color="red"
                                link
                                onClick={onDelete}
                            />
                        } />
                    <Popup content={LocalizedStrings.CopyColorCode}
                        trigger={
                            <Icon
                                name="copy"
                                link
                                onClick={onCopy} />
                        } />
                </div>
            </Table.Row>
        )
    }


    return <>
        <LRModal open={modalOpen}
            title={colorCodeData.Name}
            onCancelClick={() => setModalOpen(false)}
            onOkClick={onSave}>
                <List>
                    {
                        colorCodeData.Color.map((i, idx) => 
                        <List.Item 
                        key={idx} 
                        style={{display: "flex", flexDirection: "row", alignItems: "center"}}
                        draggable
                        onDragStart={()=>setDragIdx({source: idx, target: idx})}
                        onDragEnter={()=>setDragIdx(i => ({...i, target: idx}))}
                        onDragEnd={onDragEnd}
                        >
                            <Icon name="bars" size='large' style={{cursor: "pointer"}}/>
                            <div style={{width: "100%", marginRight: 5, marginLeft: 5}}>
                                <ColorInputField
                                    label={`${LocalizedStrings.Color} ${idx + 1}`}
                                    colorX={i.X}
                                    colorY={i.Y}
                                    colorL={i.Z}
                                    onColorChanged={(cie) => setColorCodeData(data => {
                                        data.Color[idx] = { X: cie.fx, Y: cie.fy, Z: cie.f_Y }
                                        return { ...data }
                                    })}
                                />
                            </div>
                            <Button icon="trash alternate outline" style={{marginTop: "1em"}} onClick={()=>setColorCodeData(data => {
                                data.Color.splice(idx, 1)
                                return { ...data }
                            })}/>
                            <Button icon="copy alternate outline" style={{ marginTop: "1em" }} onClick={() => setColorCodeData(data => {
                                data.Color.splice(idx, 0, structuredClone(data.Color[idx]))
                                return { ...data }
                            })} />
                        </List.Item>
                    )

                    }
                </List>
                <Segment vertical textAlign="center" style={{ width: "100%", border: "none" }}>
                    <Label as="a" color="green" onClick={onAddNewColor}>
                        <Icon name="plus" />{LocalizedStrings.AddNewColor}
                    </Label>
                </Segment>
        </LRModal>

        { window.IsIOS ? renderTableRowInIos()
         :<Table.Row>
            <Table.Cell collapsing>
                <Popup content={LocalizedStrings.ColorColorCode}
                    trigger=
                    {
                        <div style={{ width: "4rem", height: "1rem", background: cssStyle /*,backgroundColor: hexColor , backgroundImage: hexColor */ }} />
                    } />
            </Table.Cell>
            <Table.Cell>
                <Input transparent
                    name="Name"
                    value={colorCodeData.Name}
                    onChange={(_, { value }) => { setColorCodeData(i => ({ ...i, Name: value })) }}
                    onBlur={onSave}
                    onKeyDown={onKeyDown}
                    fluid />
            </Table.Cell>
            <Table.Cell textAlign="center" collapsing>
                <Popup content={LocalizedStrings.EditColorCode}
                    trigger={
                        <Icon
                            name="edit"
                            link
                            onClick={() => setModalOpen(true)} />
                    } />
                <Popup content={LocalizedStrings.DeleteColorCode}
                    trigger={
                        <Icon
                            name="delete"
                            color="red"
                            link
                            onClick={onDelete}
                        />
                    } />
                <Popup content={LocalizedStrings.CopyColorCode}
                    trigger={
                        <Icon
                            name="copy"
                            link
                            onClick={onCopy} />
                    } />
            </Table.Cell>
        </Table.Row>}
    </>
}

export default ColorCodeNode;

