//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { Breadcrumb, Divider, Form, Dimmer, Loader } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/DiffView";
import LRModal from '../Basics/BasicModal';
import DiffTable from './DiffTable';

import { globalCallbacks } from "../../util/callback";
import { IsDarkTheme } from '../../util/defines';


//-----------------------------------------------------------------------------
// The component for the TreeControl
class DiffView extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open    : false,
      errorEmptyCommit: false,
      message : "",
      branches: [],
      okDisabled:true,
      currentUploading: false
    }

    this.tableRef = createRef()

    this.updateFromServer = true;
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = (differences) => 
  {
    let hasDiff = differences.Diffs && differences.Diffs.ChangeCount > 0 ;

    
    globalCallbacks.getActiveProjectDiff()
    this.setState({
      open    : true,
      message : undefined,
      disbaleCancel: true,
      errorEmptyCommit: false,
      okDisabled: !hasDiff,
      currentUploading: false
    }, () => {
      this.tableRef.current.setDiffs(differences.Diffs)
    });
  }

  onOkButton = async () => 
  {
    if(this.updateFromServer)
    {
      await window.LR_UpdateApprovedChanges(this.tableRef.current.getDiffs());
      await this.close()
    }
    else
    {
      if(!this.state.message?.trim())
      {
        this.setState({errorEmptyCommit: true})
        return 
      }

      let request = { DrawingUUID : this.localDrawingUUID};
      await window.LR_SetActiveDrawing(request)

      let command = "LR_CommitApprovedChanges"
      if(this.ResourceCommit)
      {
        command = "LR_CommitRessourceDrawingApprovedChanges"
      }
      window[command]({Differences: this.tableRef.current.getDiffs(), Message: this.state.message})

      this.setState({currentUploading: true, okDisabled: true})
  
      
    }

    
  }

  close = async () => 
  {
    this.setState({open : false});

    let request =
    {
      DrawingUUID         : this.localDrawingUUID,
      DeleteServerDrawing : true,
    };
    await window.LR_SetActiveDrawing(request);
  }

  onMessageChange = (_, { value }) => {
    this.setState({
      message: value,
      errorEmptyCommit: false
    })
  }
    
  render() 
  {
    let branchname = "MASTER";

    this.state.branches.every((b) =>
    {
      if(this.state.branch === b.key)
      {
        branchname = b.text;
        return false
      }
      return true
    })


    let open = this.state.open;
    if(!open) {return <div/>}
    return (
      <LRModal  open={open}
                size="fullscreen"
                scrolling={false}
                title={this.updateFromServer ? LocalizedStrings.UpdateFromServer : LocalizedStrings.CommitToServer}
                onCancelClick={this.close}
                okDisabled={this.state.okDisabled}
                onOkClick={this.onOkButton}>
          { !this.updateFromServer ? <>
          <Breadcrumb>
            <Breadcrumb.Section style={{color: IsDarkTheme() ? "white" : "black"}} link>{this.state.owner}</Breadcrumb.Section>
            <Breadcrumb.Divider>/</Breadcrumb.Divider>
            <Breadcrumb.Section style={{color: IsDarkTheme() ? "white" : "black"}} link>{this.state.file}</Breadcrumb.Section>
            <Breadcrumb.Divider>/</Breadcrumb.Divider>
            <Breadcrumb.Section active>{branchname}</Breadcrumb.Section>
          </Breadcrumb>
          <Divider/>
            <Form>
              <Form.TextArea style={{color: IsDarkTheme() ? "white" : "black"}}  label={LocalizedStrings.Message} 
              error={ this.state.errorEmptyCommit ?  {
                content: LocalizedStrings.EmptyCommitText,
                pointing: 'below',
              } : undefined}
              onChange={this.onMessageChange}/>
            </Form>
            <br/>
          </> : null}
          <Dimmer active={this.state.currentUploading}>
            <Loader size="massive" active={this.state.currentUploading}>
            </Loader>
          </Dimmer>
          <DiffTable electronApp drawSummary ref={this.tableRef} drawing1UUID={this.drawing1UUID} drawing2UUID={this.drawing2UUID}/>
      </LRModal>
    )

  }
  
  setUpCallbacks()
  {
    globalCallbacks.ShowDiffDialog = (argument) => 
    {
      
      this.updateFromServer = argument.UpdateFromServer;
      this.ResourceCommit   = argument.ResourceCommit;
      this.localDrawingUUID = argument.CurrentDrawingUUID;
      this.drawing1UUID     = this.updateFromServer ? ""                          : argument.CurrentDrawingUUID;
      this.drawing2UUID     = this.updateFromServer ? argument.CurrentDrawingUUID : "";
      
      this.show( argument );
    }

    globalCallbacks.CloseDiffDialog = async () =>
    {
      this.close(); 
    }

    globalCallbacks.getActiveProjectDiff = async () =>
    {
        let result = await window.LR_GetLinkedProject()
            
        this.setState({
            owner: result.Owner,
            file: result.Project,
            branch: result.Branch
        })

        if(result.Owner)
        {
            let res = await window.LR_GetProjectDataAsync(result)
            let branches = res.branches ? res.branches.map((value) => 
            {
                return ({
                    key: value._id,
                    text: value.name,
                    value: value._id,
                });
            }) : [];

            branches.push({
                key: res.defaultBranch._id,
                text: res.defaultBranch.name,
                value: res.defaultBranch._id,
            })

            this.setState({
                branches: branches
            })
        }
        
        
    }

  }

  
}

export default DiffView