
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LocalizedStrings_ObjectProperties from "../../localization/LightRightObjectsFields";
import { Form, Label, Header, Icon, Button, Segment, Portal, Divider, Input, List, Checkbox, Table, Dropdown} from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ObjectPropertyComponent";
import ColorInputField from "../ColorPicker/ColorInputField";
import ElectricalWireField from './ElectricalWireField';
import FilteredFormGroup from './FilteredFormGroup';
import { rgb2hex, cie2hex } from '../ColorPicker/utilities';
import UnitInput from '../Basics/BasicUnitInput';
import LRFilterInput from '../Basics/FilterField';
import { ManufacturerField, GelsField } from '../ColorPicker/GelSelector';
import CollapsableDevider from '../Basics/CollapsableDevider';
const DatePicker = React.lazy(()=>import("react-datepicker"))
import "react-datepicker/dist/react-datepicker.css";
import LRModal from '../Basics/BasicModal';
import "./ObjectPropertiesComponent.css"
import { SETTING_Default,
        SETTING_AlignFirst,
        SETTING_AlignLast,
        SETTING_Increment,
        EMPTY_UUID,
        SupportTypeOptions,
        GEOMETRY_SUPPORT_TYPE_Rope,
        GEOMETRY_SUPPORT_TYPE_Ground,
        GEOMETRY_TRANSFORM_TYPE_TranslationX,
        GEOMETRY_TRANSFORM_TYPE_TranslationY,
        GEOMETRY_TRANSFORM_TYPE_TranslationZ,
        GEOMETRY_TRANSFORM_TYPE_RotationX,
        GEOMETRY_TRANSFORM_TYPE_RotationY,
        GEOMETRY_TRANSFORM_TYPE_RotationZ, 
        BASE_UNIT_LENGTH,
        BASE_UNIT_NUMBER,
        BASE_UNIT_WEIGHT,
        BASE_UNIT_FORCE,
        BASE_UNIT_ONE_BASED,
        BASE_UNIT_STRING,
        BASE_UNIT_VOLTAGE, 
        BASE_UNIT_POWER, 
        BASE_UNIT_ZERO_ONE,
        BASE_UNIT_BOOLEAN,
        RESOURCE_TYPE_PrintLabelField,
        GEOMETRY_StructureType_CenterLineBased,
        GEOMETRY_StructureType_Detail,
        GEOMETRY_CrossSectionType_TemplateCrossSection,
        GEOMETRY_CrossSectionType_Tube,
        GEOMETRY_SUPPORT_TYPE_HouseRigging,
        GetConnectorInfoByName,
        BASE_UNIT_FORCE_PER_AREA,
        degToRad, kWireType_Consumer, 
        kSignalType_DMX512, 
        kSignalType_Network , 
        kSignalType_AES, 
        kWireType_Fuse, 
        GetPayLoadColor_2, 
        kWireType_Generator, 
        kWireType_NetworkProvider, 
        BASE_UNIT_PAGE_LENGTH, 
        LABEL_OBJECT_FILTER_RACK, 
        LABEL_OBJECT_FILTER_CASE, 
        LABEL_OBJECT_FILTER_OBJECT, 
        GEOMETRY_CrossSectionType_Rectangle, 
        BASE_UNIT_TORQUE, 
        BASE_UNIT_MOMENT_PER_DISTANCE, 
        BASE_UNIT_FORCE_PER_DISTANCE, 
        BASE_UNIT_COLOR, 
        RESOURCE_TYPE_PrintLabel, 
        BASE_UNIT_ANGLE, 
        BASE_UNIT_PERCENT, 
        BASE_UNIT_AREA, 
        kWireType_HotpatchInput,
        kWireType_HotpatchOutput,
        PRINT_LABEL_FIELD_TYPE_ITERATOR,
        PRINT_LABEL_FIELD_TYPE,
        POLYGON_LINE_TYPE_ITERATOR,
        BASE_UNIT,
        BASE_UNIT_SELECTABLE,
        GEOMETRY_POLYGON_VIEW_TYPE_ITERATOR,
        GEOMETRY_POLYGON_VIEW_TYPE,
        IsVectorworksContext } from "../../util/defines";
import DrawingNote from '../DrawingNotes/Note';
import UserAvatar from '../WebComponents/UserAvatar';
import SelectResourceModal from '../ToolSelect/SelectRessourceModal';
import {QRCodeCanvas} from "qrcode.react"
import { globalCallbacks } from '../../util/callback';
import { GLOBAL_SETTINGS as JestGS } from "../../redux/redux_defines";
import ElectricalObject from './ObjectPropertiesSubComponents/ElectricalObject';
import { SingleFieldSearch } from '../TableViews/ObjectProperties/FieldSearch';
import RequestStructuralCalculationButton from '../ExportStructuralCalculation/RequestStructualCalculation';
import BridleDrawing from '../BridleDrawing/BridleDrawing';
import { TimeOffsetInput } from './Inputs';
import ConnectionView from '@component/TableViews/ConnectionView/ConnectionView';

let GLOBAL_SETTINGS
if(!process.env.JEST_WORKER_ID) { GLOBAL_SETTINGS  = JestGS  }		


let GLOBAL_TRANSFORM_COLOR = "#FFA500"
let LOCAL_TRANSFORM_COLOR  = "#005AFF"

const FuseRatingOptions = [
  {
    text: "A",
    value: 1
  },
  {
    text: "B",
    value: 2
  },
  {
    text: "C",
    value: 3
  }
]
const InsulationMaterial = [
  {
    text: "H07RN-F",
    value: "H07RN-F"
  },
  {
    text: "PVC Multicore",
    value: "PVC Multicore"
  },
  {
    text: "SOOW",
    value: "SOOW"
  },
  {
    text: "SJOOW",
    value: "SJOOW"
  }
]


const optionsDataTypeSignal = [
  {
      text: "DMX512",
      value: kSignalType_DMX512
  },
  {
      text: "Network",
      value: kSignalType_Network
  },
  {
      text: "AES",
      value: kSignalType_AES
  },
]

const optionInventoryType = [
  {
      text: "Default",
      value: 0
  },
  {
      text: "Cable",
      value: 1
  },
  {
      text: "Bridle Part - Rope",
      value: 2
  },
  {
    text: "Bridle Part - Shackle",
    value: 3
},
{
  text: "Bridle Part - Variable",
  value: 4
},
]

const StructureTypes = [
  {
    text: "Center Line Bases",
    value: GEOMETRY_StructureType_CenterLineBased
  },
  {
    text: "Detail Webbing",
    value: GEOMETRY_StructureType_Detail
  },
]

const TrussCrossType = [
  {
    text: "Flexibel",
    value: 0
  },
  {
    text: "Compression Only",
    value: 1
  },
  {
    text: "Tension Only",
    value: 2
  },
  {
    text: "Fixed",
    value: 3
  },
  {
    text: "None",
    value: 4
  },
]

const CrossSectionType = [
  {
    text: "Truss Cross Section",
    value: GEOMETRY_CrossSectionType_TemplateCrossSection
  },
  {
    text: "Tube Cross Section",
    value: GEOMETRY_CrossSectionType_Tube
  },
  {
    text: "Rectangle Cross Section",
    value: GEOMETRY_CrossSectionType_Rectangle
  },
]

const PaperFormats = [
  { key: 'A0',  text: LocalizedStrings.A0,  value: 'A0',  selected: false},
  { key: 'A1',  text: LocalizedStrings.A1,  value: 'A1',  selected: false},
  { key: 'A2',  text: LocalizedStrings.A2,  value: 'A2',  selected: false},
  { key: 'A3',  text: LocalizedStrings.A3,  value: 'A3',  selected: false},
  { key: 'A4',  text: LocalizedStrings.A4,  value: 'A4',  selected: false},
  { key: 'A5',  text: LocalizedStrings.A5,  value: 'A5',  selected: false},
  { key: 'A6',  text: LocalizedStrings.A6,  value: 'A6',  selected: false},
  { key: 'A7',  text: LocalizedStrings.A7,  value: 'A7',  selected: false},
  { key: 'A8',  text: LocalizedStrings.A8,  value: 'A8',  selected: false},
  { key: 'A9',  text: LocalizedStrings.A9,  value: 'A9',  selected: false},
  { key: 'A10', text: LocalizedStrings.A10, value: 'A10', selected: false},
]


const friction_options_base = 
[
  { Name: LocalizedStrings_ObjectProperties.SteelNonGrainy, Value: 0.5, },
  { Name: LocalizedStrings_ObjectProperties.SteelSprayGalvanized, Value: 0.4, },
  { Name: LocalizedStrings_ObjectProperties.SteelAlkaliZincSilicate, Value: 0.4, },
  { Name: LocalizedStrings_ObjectProperties.SteelWireBrushRustRemoved, Value: 0.3 },
  { Name: LocalizedStrings_ObjectProperties.SteelRolledSurface, Value: 0.2, },
  { Name: LocalizedStrings_ObjectProperties.WoodMetalDry, Value: 0.5,  },
  { Name: LocalizedStrings_ObjectProperties.WoodMetalLubricated, Value: 0.11, },
  { Name: LocalizedStrings_ObjectProperties.WoodWoodDry, Value: 0.4 },
  { Name: LocalizedStrings_ObjectProperties.WoodWoodLubricated, Value: 0.16 },
  { Name: LocalizedStrings_ObjectProperties.OakOakDry, Value: 0.54, },
  { Name: LocalizedStrings_ObjectProperties.SteelAluminumAlloy, Value: 0.15,  },
]

const friction_options = friction_options_base.map((e, i) => 
{
  return{
    text: e.Name,
    key: i,
    value: i}
})




const objectFilter = 
[
  { key: 'Rack',   text: LocalizedStrings.Rack,   value: LABEL_OBJECT_FILTER_RACK,   selected: false },
  { key: 'Case',   text: LocalizedStrings.Case,   value: LABEL_OBJECT_FILTER_CASE,   selected: false },
  { key: 'Object', text: LocalizedStrings.Object, value: LABEL_OBJECT_FILTER_OBJECT, selected: false },
]

const whitelists = {
  [RESOURCE_TYPE_PrintLabelField]: [
    "Name",
    "OffsetX",
    "OffsetY",
    "ScaleX",
    "ScaleY",
    "MarginX",
    "MarginY",
    "SpacingX",
    "SpacingY",
    "DrawBackground",
    "BackgroundColor",
    "LineType",
    "LineWidth",
    "LineColor",
    "FillAlpha",
    "LineAlpha",

    "SizeX",
    "SizeY",
    "PropertyName",
    "TextValue",
    "MeshUuid",
    "TextureUuid",

    "PrintLabelFieldType",
    "IncludePropertyName",
    "IncludeBaseUnit",
    "PrefixText",
    "ArrayPosition",
    "PostfixText",
    "FontSize",
    "Underline",
    "Fat",
    "Cursive",
    "FieldLength",
    "ForegroundColor",
    "DrawBackground",
    "RotationZ",
    "PaperFormatTemplate",
    "PaperFormat",
    "ObjectFilter"
  ],
  [RESOURCE_TYPE_PrintLabel]: [
    "Name",
    "OffsetX",
    "OffsetY",
    "SizeX",
    "SizeY",
    "MarginX",
    "MarginY",
    "RoundEdge",
    "SpacingX",
    "SpacingY",
    "RotationZ",
    "PaperFormatTemplate",
    "PaperFormat",
    "ObjectFilter",
    "PaperSizeX",
    "PaperSizeY",
    "PLHasElectrical",
    "PLHasFixture",
    "PLHasStructures",
    "PLHasSupport",
    "PLHasAudio",
    "PLHasVisible",
  ]
}

class ObjectProperties extends Component 
{
  constructor(props)
  {
    super(props);

    this.rootDiv = React.createRef()
    this.fontNameOptions = []

    this.state = 
    {
      changedName       : undefined,
      changedValue      : undefined,

      layers            : [],
      classes           : [],
      containers        : [],
      externalDocuments : [],
      colorCodes        : [],
      users             : [],
      trussCrossSection : [],
      ropeCossSection   : [],
      GeneratorList : [],

      Name              : "",
      GlobaleTransform  : {},
      LocaleTransform   : {},

      departments: [],
      SymbolDefs: [],

      Notes: [],
      ObjectPathCurrentPoint: 0,

      Layer             : "",
      Class             : "",
      LinkedContainer   : "",
      NeededDocument    : "",
      ColorCode         : "",
      searchFilter      : "",
      Existing          : true,
      PartNumber        : "",
      User              : "",
      TimeOffset        : 0,

      Dimmer            : 0,
      LightColor:
      {
        X   : 0.31273,
        Y   : 0.32902,
        Z   : 100,
      },

      GeometryTransformList : [],
      AvailableCrossSection : [],

      DmxFootPrint1 : 0,
      DmxFootPrint2 : 0,
      DmxFootPrint3 : 0,
      DmxFootPrint4 : 0,
      DmxAddress1   : 0,
      DmxAddress2   : 0,
      DmxAddress3   : 0,
      DmxAddress4   : 0,
      FixtureId     : 0,

      NewDmxModeName    : "",
      NewDmxFootPrint1  : 0,
      NewDmxFootPrint2  : 0,
      NewDmxFootPrint3  : 0,
      NewDmxFootPrint4  : 0,

      isSymDef      : false,

      ElectricalObjects   : [], 
      WireType            : 0, 
      ConnectorType       : 0,
      ActiveConnections   : [],

      AllWireInputs       : [], 
      AllWireOutputs      : [], 
      ObjectConnectorsIn  : [],
      ObjectConnectorsOut : [], 
      CurrentInventory    : [],

      LinkedStructure     : EMPTY_UUID,
      AllStructures       : [],

      CaseTemplates: [],

      SelectedObjectCount : 0, 
      propertySettingMode : SETTING_Default,

      SupportInfos        : [],
      InventoryObjects: [],
      loadedInventoryObjects:false,
      ParentTransform     : {},

      SelectedPreset: EMPTY_UUID,

      RelativeDmxAdress   : false,
      ShowAdressWarn      : false,

      MeshesOptions: [],
      TextureOptions: [],


      printLabelFields: [],

      paperFormatPresets: [],
      paperFormatPresetOpts: [],

      createPaperPresetOpen: false,
      createPaperPresetName: "",

      ObjectFilter: 0, // value of selected element in 'objectFilter' array

      loggedUser: "",
      completeNote: "",
      resourceSelectorOpen: false,

      printLabelsOptions: [],
      openBridleDrawing: false,
      forcedCrossSectionSearch: "",
      nestedSearch: "",
      isDropdownOpen: false     
    };
  
  }

    _stateChanges = {}
    timeoutSyncState
    syncState(changes, delay = 30){
        this._stateChanges = {...this._stateChanges, ...changes}
        if (this.timeoutSyncState){
            clearTimeout(this.timeoutSyncState)
        }
        this.timeoutSyncState = setTimeout(()=>{
            this.setState({...this.state, ...this._stateChanges})
            this._stateChanges = {}
        },delay)
    }

  componentDidMount = async () => 
  {
    this.setUpCallbacks();

    window.addEventListener("wheel", (e) => 
    {
      if(document.activeElement.type === "number"){
        document.activeElement.blur();
    }
    })

    const possibleFieldsRacks = await window.LR_GetPossibleFieldsRacks()
    const possibleFieldsCases = await window.LR_GetPossibleFieldsCases()
    const possibleFieldsObjects = await window.LR_GetPossibleFields({IncludeGlobalProperties: true})
    this.syncState({possibleFieldsRacks, possibleFieldsCases, possibleFieldsObjects})  
    
    this.fontNameOptions = await globalCallbacks.GetFontMap()    

    globalCallbacks.getObjectPropertiesLayers();
    globalCallbacks.getStructuralResultsForOIP();
    globalCallbacks.getObjectPropertiesCaseTemplates();
    globalCallbacks.getObjectPropertiesLoadGroups();
    globalCallbacks.getObjectPropertiesTrussCrossSection();
    globalCallbacks.getObjectPropertiesGetOrigins();
    globalCallbacks.getObjectPropertiesClasses();
    globalCallbacks.getObjectPropertiesInventoryContainers();
    globalCallbacks.getObjectPropertiesExternalDocuments();
    globalCallbacks.getObjectPropertiesColorCodes();
    globalCallbacks.getObjectPropertiesDepartments();
    globalCallbacks.getObjectPropertiesSymbolDefs();
    globalCallbacks.getMeshesAndTextures()
    globalCallbacks.getPresetOptions()
    globalCallbacks.updatePaperFormatTemplatesOPC()
    globalCallbacks.updatePrintLabelsOPC()
    await globalCallbacks.getObjectPropertiesUsers();
    await globalCallbacks.getSelectedProperties();

    if(this.props.node) { this.props.node.setEventListener("close", (p) => {  this.props.onClose() }) }

  }

  renderPresetSearch = () =>
  {
    let changeActivePreset = async (e, {value}) =>
    {
      this.syncState({SelectedPreset: value})
      await window.LR_SetActivePreset({UUID: value, Active : true});
      await window.LR_SetEditablePreset({UUID: value})
    }

    let handleAddition = async(e, {name, value}) =>
    {
      let newPreset = await window.LR_AddNewPreset({Name: value})
      this.syncState({SelectedPreset: newPreset.UUID})
      await window.LR_SetActivePreset({UUID: newPreset.UUID, Active : true});
      await window.LR_SetEditablePreset({UUID: newPreset.UUID})
    }
    return(
      <Form.Dropdown 
        label={LocalizedStrings.Preset} 
        fluid
        search
        allowAdditions
        value={this.state.SelectedPreset}
        onChange  = {changeActivePreset}
        onAddItem = {handleAddition}
        options={this.state.PresetOptions}
      />

    )
  }

  renderSearchAndProperty()
 {
  return <>
          <LRFilterInput class="OIPsearch" value = {this.state.searchFilter} onChange={(value)=> {this.syncState({searchFilter: value})}}/>
          {this.renderPresetSearch()}
        </>
 } 

  render() 
  {
    return(      
    <div className="ObjectInfoProperties" style={{height: "100%", display: "flex", flexDirection: "column", overflow: "hidden", position: "relative", paddingBottom: IsVectorworksContext() ? 0 : "15rem"}} ref={this.rootDiv}>  
      <div  style={{ position: "sticky", top: 0,  zIndex: 10 }}>
        <Segment size="mini" basic style={{padding: '0.3em 0.5em 0 0.5em'}}>
          <Header as="h5" floated='left'>{LocalizedStrings.Header}</Header>
          <Header as="h5" floated='right'>{this.state.SelectedObjectCount}</Header>
          <Form>
            {this.renderSearchAndProperty()}
            <Divider/>
          </Form>
        </Segment>
      </div>

      <div style={{overflowY: "auto"}}>
        <Segment size="mini" basic style={{paddingTop: 0}}>
          <Form>
            {this.state.HasEntries ? this.getContent() : this.getEmptyContent()}
          </Form>
        </Segment>
        {this.renderAddModeModal()}
        <Button.Group style={{bottom: "1px", position: 'sticky'}} fluid size="mini">

            <Button active  = {this.state.propertySettingMode === SETTING_Default}
                    onClick = {() => this.syncState({propertySettingMode : SETTING_Default})}
                    compact>
              <Icon name="pencil"/>
              {LocalizedStrings.SetAllToSame}
            </Button>
            <Button active  = {this.state.propertySettingMode === SETTING_Increment}
                    onClick={() => this.syncState({ propertySettingMode: SETTING_Increment })}
                    compact>
              <Icon name="thumbtack"/>
              {LocalizedStrings.AutoIncrement}
            </Button>
            <Button active  = {this.state.propertySettingMode === SETTING_AlignFirst}
                    onClick={() => this.syncState({ propertySettingMode: SETTING_AlignFirst })}
                    compact>
              <Icon name="sort amount up"/>
              {LocalizedStrings.AlignLeft}
            </Button>
            <Button active  = {this.state.propertySettingMode === SETTING_AlignLast}
                    onClick={() => this.syncState({ propertySettingMode: SETTING_AlignLast })}
                    compact>
              <Icon name="sort amount down"/>
              {LocalizedStrings.AlignRight}
            </Button>
          </Button.Group>
          {this.state.resourceSelectorOpen ? <SelectResourceModal 
                        open={this.state.resourceSelectorOpen} 
                        close={()=>{this.syncState({resourceSelectorOpen:false})}} 
                        resourceType={RESOURCE_TYPE_PrintLabel}
                        /> : null}
      </div>
    </div>
    )
  }

  async getInventoryObjects(){
    const inventoryObjects = {}
    for ( let {UUID} of this.state.CurrentInventory){
      const info = await window.LR_GetObject({UUID})
      inventoryObjects[UUID] =  info
    }
    this.syncState({loadedInventoryObjects:true, inventoryObjects})
  }

  getEmptyContent = () =>
  {
    return <Segment placeholder style={{opacity: 0.5  }}>
              <Header icon>
                <Icon name='searchengin' />
                {LocalizedStrings.NoObjectSelectedHeader}
                <br/>
                {LocalizedStrings.NoObjectSelected}
              </Header>
            </Segment>
  }

  filterBySearch = (component, tag) =>
  { 
    let UniversalName = tag;
    let LocalizedName = LocalizedStrings[tag];
    if(!LocalizedName)
    {
      //console.warn("filterBySearch tag not found", tag)
      LocalizedName = UniversalName;
    }

    let excludeByWhiteList = false;

    this.state.RessourceTypes.forEach(e=>
    {
      if(!excludeByWhiteList && whitelists[e])
      {
        excludeByWhiteList |= whitelists[e].every(entry => entry !== UniversalName)
      }
      
    })
    
    if((this.state.searchFilter !== "" && ! LocalizedName.toLowerCase().includes(this.state.searchFilter.toLowerCase())) || excludeByWhiteList )

    {
      return null
    }
    return component
  }

  renderNotes = () => 
  {
    return <>
      {this.state.Notes.length === 0 ?
      <Segment placeholder>
      <Header icon>
        {LocalizedStrings.NoNotes}

      </Header>
        <Button onClick={()=>{window.LR_ShowCreateDrawingNote()}} fluid positive>{LocalizedStrings.CreateNoteForObject}</Button>
    </Segment> :           
    <Button onClick={()=>{window.LR_ShowCreateDrawingNote()}} fluid primary>{LocalizedStrings.CreateNoteForObject}</Button>
}
    <List divided relaxed>
    {this.state.Notes.map(note => 
      {
        return<>
        <List.Item style={{background:`${note.NoteState.Complete ? "#5ee672" : "#ffffff" }`, padding:"0.2rem"}}>
          <List.Icon>
            <UserAvatar user={note.User}/>
          </List.Icon>
        <List.Content style={{position: "relative"}}>
          <DrawingNote text={note.Note} />
         <div  style={{position:"absolute",right: 0, top: 0, fontSize:"0.9rem"}}>
            <Checkbox 
                style={{paddingRight: "0.5rem"}}
                checked={Boolean(note.NoteState.Read[this.state.loggedUser])}     
                label={<label>{LocalizedStrings.Read}</label>} 
                onChange={(_, {checked}) => {this.handleRead(note, checked)}} />
            <Checkbox 
                checked={note.NoteState.Complete} 
                label={<label style={{ color:`${note.NoteState.Complete ? "rgba(0, 0, 0, 0.87)" : "#f58d1d" }`}}>{LocalizedStrings.Done}</label>} 
                onChange={(_, {checked}) => {this.handleComplete(note, checked)}} />
         </div>
          <List.Description style={{fontSize:"0.9rem"}} as='i'>{note.NoteState.Complete ? LocalizedStrings.NoteCompletedBy.replace("$1", note.User).replace("$2", note.CreateDate) : "" }</List.Description>
          <List.Description style={{fontSize:"0.9rem"}} as='i'>{LocalizedStrings.NoteCreatedBy.replace("$1", note.User).replace("$2", note.CreateDate)}</List.Description>
        </List.Content>

        </List.Item>
        </>
      })}
      </List>
    </>
  }

  handleRead = (note, checked) =>
  {
      if(checked)
      {
          window.LR_SetNote({
              UUID:note.UUID, 
              NoteState: {
                  ...note.NoteState,
                  Read: {
                      ...note.NoteState.Read,
                      [this.state.loggedUser] : Date.now()
                  }
              }})
      } else {

        let readUsers = note.NoteState.Read
        delete readUsers[this.state.loggedUser]
          window.LR_SetNote({
              UUID:note.UUID, 
              NoteState: {
                  ...note.NoteState,
                  Read: readUsers
              }})
      }
    
  }

  handleComplete = (note, checked) =>
  {
      if(checked)
      {
          window.LR_SetNote({
              UUID:note.UUID, 
              NoteState: {
                  Read: note.NoteState.Read, 
                  Complete: checked, 
                  User:this.state.loggedUser, 
                  Date: Date.now()
              }})
             
      } else {
          window.LR_SetNote({
              UUID:note.UUID, 
              NoteState: {
                  Read: note.NoteState.Read, 
                  Complete: checked, 
                  User:"", 
                  Date: Date.now()
              }})
      }
   }
   
   renderRenderingOptions = () =>
   {
      return <>
      <FilteredFormGroup>
          {this.getInputFieldLength("LabelOffsetX", "100", "red", BASE_UNIT_LENGTH)}
          {this.getInputFieldLength("LabelOffsetY", "100", "green", BASE_UNIT_LENGTH)}
        </FilteredFormGroup>
        <FilteredFormGroup>          
          {this.getInputFieldLength("LabelScale", "1", "red", BASE_UNIT_NUMBER)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldBool("ShowLabel3D")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldSelect("Label3D", this.state.printLabelsOptions)}
        </FilteredFormGroup>
      </>
   }

  getContent = () => 
  {
    let small =  this.rootDiv.current && this.rootDiv.current.offsetWidth < 400
    let noGroup =  this.rootDiv.current && this.rootDiv.current.offsetWidth < 300

    let classes = [...this.state.classes]
    if(this.state.HasGeometry)
    {
      classes.push({
        key: EMPTY_UUID,
        text: LocalizedStrings.NoClass,
        value:EMPTY_UUID
      })
    }
    return (
      <React.Fragment>
        <FilteredFormGroup>
          {this.getInputFieldString("Name")}
          {this.getInputFieldString("ObjectId")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.state.HadClass ? this.getInputFieldSelect("Class", classes, undefined, true) : null}
          {this.state.HadLayer ? this.getInputFieldSelect("Layer", this.state.layers, undefined, true) : null}
        </FilteredFormGroup>
        {!this.state.IsSymDef && !this.state.IsFixtureType ? 
        <FilteredFormGroup widths="equal">
          {this.getInputFieldString("AssemblyGroupName")}
        </FilteredFormGroup> : null}
        {!this.state.IsSymDef && !this.state.IsFixtureType ? 
        <FilteredFormGroup widths="equal">
          {this.getInputFieldString("Position")}
        </FilteredFormGroup> : null}
        <FilteredFormGroup widths="equal">
          {this.getInputFieldString("Note")}
        </FilteredFormGroup>


        <FilteredFormGroup>
          {this.getInputFieldSelect("ColorCode", this.state.colorCodes, undefined, false)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldSelect("LinkedDepartments", this.state.departments, undefined, false, true)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.state.IsSymDef || this.state.IsFixtureType ? this.getInputFieldBool("HangToTrussCenter") : null}
        </FilteredFormGroup>
        {this.state.HasRendererView && this.state.ObjectPath ? this.GetRendererViewProperties() : null}
        {this.state.HasPrintLabelField ? this.GetPrintLabelFieldProperties() : null}
        {this.state.IsPrintLabel      ? this.GetPrintLabelProperties()      : null}
        {
          !this.state.IsEditMode ? 
          <CollapsableDevider name="Note" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Notes} icon="sticky note" open={this.state.OpenNotes} update={()=> this.syncState({OpenNotes: !this.state.OpenNotes})}>
          {this.filterBySearch(this.renderNotes(), "Notes")}
        </CollapsableDevider>
        :
        null
        }
        {
          !this.state.IsEditMode ? 
          <CollapsableDevider name="RenderingOptions" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.RenderingOptions} icon="pencil" open={this.state.OpenRenderingOptions} update={()=> this.syncState({OpenRenderingOptions: !this.state.OpenRenderingOptions})}>
          {this.renderRenderingOptions()}
        </CollapsableDevider>
        :
        null
        }
        {!this.state.IsSymDef
              ? <CollapsableDevider 
              name = "GlobalPosition"
              omit={ this.state.searchFilter !== ""} 
              header={this.props?.globalSettings?.App_GlobalTransform ? LocalizedStrings.TransformGlobal : LocalizedStrings.TransformLocal} 
              headerColor={this.props?.globalSettings?.App_GlobalTransform ? GLOBAL_TRANSFORM_COLOR : LOCAL_TRANSFORM_COLOR}
              icon="arrows alternate" 
              open={this.state.OpenTransform} 
              update={()=> this.syncState({OpenTransform: !this.state.OpenTransform})}
              >
                {this.filterBySearch(<Button style={{backgroundColor: this.props?.globalSettings?.App_GlobalTransform ? GLOBAL_TRANSFORM_COLOR : LOCAL_TRANSFORM_COLOR}} fluid onClick={()=>{window.LR_SetGlobalSettings({App_GlobalTransform: ! this.props?.globalSettings?.App_GlobalTransform})}}>{LocalizedStrings.ChangeCoordinateSystem}</Button>, "")}
              {(noGroup) ? 
              <React.Fragment>
                {this.getInputFieldTransform("OffsetX", "mm", "100", "red", BASE_UNIT_LENGTH)}
                {this.getInputFieldTransform("OffsetY", "mm", "100", "green", BASE_UNIT_LENGTH)}
                {this.getInputFieldTransform("OffsetZ", "mm", "100", "blue", BASE_UNIT_LENGTH)}
                {!this.state.IsScaleLocked ? this.getInputFieldTransform("ScaleX", "%", "0.01", "red", BASE_UNIT_NUMBER) : null}
                {!this.state.IsScaleLocked ? this.getInputFieldTransform("ScaleY", "%", "0.01", "green", BASE_UNIT_NUMBER) : null}
                {!this.state.IsScaleLocked ? this.getInputFieldTransform("ScaleZ", "%", "0.01", "blue", BASE_UNIT_NUMBER) : null}
                {this.getInputFieldTransform("RotationX", "°", "0.017453","red", BASE_UNIT_ANGLE)}
                {this.getInputFieldTransform("RotationY", "°", "0.017453","green", BASE_UNIT_ANGLE)}
                {this.getInputFieldTransform("RotationZ", "°", "0.017453", "blue", BASE_UNIT_ANGLE)}
                </React.Fragment>
              :
              <React.Fragment>
              <FilteredFormGroup>
                {this.getInputFieldTransform("OffsetX", "mm", "100", "red", BASE_UNIT_LENGTH, small)}
                {this.getInputFieldTransform("OffsetY", "mm", "100", "green", BASE_UNIT_LENGTH, small)}
                {this.getInputFieldTransform("OffsetZ", "mm", "100", "blue", BASE_UNIT_LENGTH, small)}
              </FilteredFormGroup>
              <FilteredFormGroup>
                {!this.state.IsScaleLocked ? this.getInputFieldTransform("ScaleX", "%", "0.01", "red", BASE_UNIT_NUMBER, small) : null}
                {!this.state.IsScaleLocked ? this.getInputFieldTransform("ScaleY", "%", "0.01", "green", BASE_UNIT_NUMBER, small) : null}
                {!this.state.IsScaleLocked ? this.getInputFieldTransform("ScaleZ", "%", "0.01", "blue", BASE_UNIT_NUMBER, small) : null}
              </FilteredFormGroup>
              <FilteredFormGroup>
                {this.getInputFieldTransform("RotationX", "°", "0.017453","red", BASE_UNIT_ANGLE, small)}
                {this.getInputFieldTransform("RotationY", "°", "0.017453","green", BASE_UNIT_ANGLE, small)}
                {this.getInputFieldTransform("RotationZ", "°", "0.017453", "blue", BASE_UNIT_ANGLE, small)}
              </FilteredFormGroup>
              </React.Fragment>}
              <FilteredFormGroup>
                {this.getInputFieldSelect("ObjectOriginSetter", this.state.origins, undefined, false)}
              </FilteredFormGroup>
              <FilteredFormGroup>
                {this.getInputFieldBool("PsnTrackerEnable")}
              </FilteredFormGroup>
              <FilteredFormGroup>
                {this.state.PsnTrackerEnable ? this.getDisplayValueUnit("PsnTrackerId",this.state.PsnTrackerId, BASE_UNIT_NUMBER) : null}
              </FilteredFormGroup>
              <FilteredFormGroup>
                {this.state.PsnTrackerEnable ? null : this.getInputFieldNumber(BASE_UNIT_NUMBER, "PsnTrackerId")}
              </FilteredFormGroup>



            </CollapsableDevider>
          : null
        }
        
          <CollapsableDevider name="TimeAndUser" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.TimeUserProperties} icon="calendar" open={this.state.OpenTimeUserProperties} update={()=> this.syncState({OpenTimeUserProperties: !this.state.OpenTimeUserProperties})}>
          {!this.state.IsSymDef ? <FilteredFormGroup widths="equal">{this.getInputFieldSelect("User", this.state.users, this.state.UserPreset)}</FilteredFormGroup> : null }
          <FilteredFormGroup widths="equal">
            { 
              this.filterBySearch(
                <TimeOffsetInput 
                  visible={this.state["Had" + "TimeOffset"]}
                  presetColor={
                    this.state["Preset" + "TimeOffset"]?.Color 
                      ? cie2hex(this.state["Preset" + "TimeOffset"]?.Color) 
                      : ""
                  }
                  value={this.state.TimeOffset}
                ></TimeOffsetInput> ,
                "TimeOffset"
              )
            }
          </FilteredFormGroup>
        </CollapsableDevider> 
        <CollapsableDevider 
          name="Structural" 
          omit={ this.state.searchFilter !== ""} 
          header={LocalizedStrings.Structural} 
          icon="calculator" open={this.state.OpenStructural} 
          update={()=> this.syncState({OpenStructural: !this.state.OpenStructural})}
        >
        <FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_WEIGHT, "Weight", "g", "1000")}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldSelect("LoadGroup", this.state.LoadGroups )}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldSelect("LoadGroupForWind", this.state.LoadGroups )}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldSelect("OverwriteTrussCrossSection", 
            [
              {
                key   : "EMPTY",
                value : '',
                text  : LocalizedStrings.UseFromGeometry,
              }, 
              ...this.state.trussCrossSection
            ], null, false, false, true )}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "WindLoadCfValue", undefined, 0.1)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("UseAsWindloadPlane")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("UseStructureAsWindloadPlane")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldNumber(BASE_UNIT_AREA, "WindloadPlaneArea")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldNumber(BASE_UNIT_AREA, "WindloadPlaneEffective")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldString("WindloadMarkdownNotes")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("UseAsAreaLoadPlane")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("UseAsFreeLineLoad")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("AllowTrussCross")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("DisableSpacerProves")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("GroupLoadsToStructure")}
          </FilteredFormGroup>
            {this.state.HasTrussCross ? 
            <>
            <FilteredFormGroup>
            {this.getInputFieldSelect("TrussCrossType", TrussCrossType )}
            </FilteredFormGroup>
            <FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_FORCE, "TrussCrossForce" )}
            {this.getInputFieldNumber(BASE_UNIT_FORCE, "TrussCrossForceCharacteristic" )}
            </FilteredFormGroup>
            </> : null}
            {this.getMagnetStructuralProperties()}
            {!this.state.IsSymDef
            ? 
              <>
              <FilteredFormGroup>
                {this.getInputFieldBool("Existing")}
              </FilteredFormGroup>
              
              <FilteredFormGroup>
              {this.getInputFieldBool("UseAsLoad")}
            </FilteredFormGroup>
            <FilteredFormGroup>
              {this.getInputFieldBool("UsedAsLoad", true)}
            </FilteredFormGroup>
            </>
            : null
          }
          {this.getDisplayValueString("FemObjectIds",this.state.FemObjectIds, LocalizedStrings.FemObjectIds)}
          {this.filterBySearch(<RequestStructuralCalculationButton/>, "")}
        </CollapsableDevider> 
        <CollapsableDevider name="Inventory" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Inventory} icon="cubes" open={this.state.OpenInventory} update={()=> this.syncState({OpenInventory: !this.state.OpenInventory})}>
          <FilteredFormGroup>
            {this.getInputFieldString("Manufacturer")}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldString("Supplier")}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
            {this.getInputFieldString("Purpose")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldString("PartNumber")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldBool("UseAsCableInventory")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "CableThresholdOffset")}
          </FilteredFormGroup>

          <FilteredFormGroup>
            {this.getInputFieldSelect("NeededDocument", this.state.externalDocuments, undefined, true)}
          </FilteredFormGroup>


          <FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_WEIGHT, "CO2Footprint", "g", "1000")}
          </FilteredFormGroup>


          <FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_NUMBER, "Cost", "", "1")}
          </FilteredFormGroup>
          {(this.state.LinkedSymbolDef && this.state.LinkedSymbolDef.UUID) ? this.getDisplayValueString("LinkedSymbolDef", this.state.LinkedSymbolDef.Name, LocalizedStrings.EditLinkedSymbolDef,()=>{this.openEditMode(this.state.LinkedSymbolDef.UUID)}) : null}
          {(this.state.LinkedFixtureType && this.state.LinkedFixtureType.UUID) ? this.getDisplayValueString("LinkedFixtureType", this.state.LinkedFixtureType.Name, LocalizedStrings.LinkedFixtureType,()=>{this.openEditMode(this.state.LinkedFixtureType.UUID)}) : null}
          {(this.state.LinkedMesh && this.state.LinkedMesh.UUID) ? this.getDisplayValueString("LinkedMesh", this.state.LinkedMesh.Name) : null}
          {!this.state.IsSymDef
            ? 
            <>
              <FilteredFormGroup>
                {this.getInputFieldBool("Existing")}
              </FilteredFormGroup>
            </>
            : null
          }
                    {this.GetDisplayInventory()}

          {this.state.HasInventory      ? this.GetInventoryProperty()         : null}
          <CollapsableDevider 
            class="container-support"
            name="ContainerSupport" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.ContainerSupport} 
            icon="truck" open={this.state.ContainerSupportOpen} update={() => this.syncState({ContainerSupportOpen: !this.state.ContainerSupportOpen})}
          >
          <FilteredFormGroup>
            {this.state.SelectedObjectCount === 0 ? this.getInputFieldSelect("LinkedCaseTemplate", this.state.CaseTemplates ) : null}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldSelect("LinkedContainer", this.state.containers, undefined, true)}
            {this.state.HadPositionInRack && this.getInputFieldNumber(BASE_UNIT_ONE_BASED, "PositionInRack", "HE", "1", undefined, undefined, 0)}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldBool("HasCase")}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldBool("HasWheels")}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldBool("AllowTip")}
          </FilteredFormGroup>
          {!this.state.IsEditMode ? <FilteredFormGroup>
            {this.getInputFieldLength("InventoryOffsetX", "100", "red", BASE_UNIT_LENGTH)}
            {this.getInputFieldLength("InventoryOffsetY", "100", "green", BASE_UNIT_LENGTH)}
            {this.getInputFieldLength("InventoryOffsetZ", "100", "blue", BASE_UNIT_LENGTH)}
          </FilteredFormGroup> : null}
          {this.state.HasCase ? <> <FilteredFormGroup>
                                    {this.getInputFieldLength("CaseSizeX", "100", "red", BASE_UNIT_LENGTH)}
                                    {this.getInputFieldLength("CaseSizeY", "100", "green", BASE_UNIT_LENGTH)}
                                    {this.getInputFieldLength("CaseSizeZ", "100", "blue", BASE_UNIT_LENGTH)}
                                  </FilteredFormGroup>
                                  <FilteredFormGroup>
                                    {this.getInputFieldLength("InventoryRotationX", "100", "red", BASE_UNIT_ANGLE, "°")}
                                    {this.getInputFieldLength("InventoryRotationY", "100", "green", BASE_UNIT_ANGLE, "°")}
                                    {this.getInputFieldLength("InventoryRotationZ", "100", "blue", BASE_UNIT_ANGLE, "°")}
                                  </FilteredFormGroup></> : null}
          <FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_NUMBER, "CountHE", "HE", "1")}
          </FilteredFormGroup>
          </CollapsableDevider> 

          {this.getQrCode()}
        <CollapsableDevider name="History" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.OpenInternal} icon="history" open={this.state.OpenInternal} update={()=> this.syncState({OpenInternal: !this.state.OpenInternal})}>
        {this.getDisplayValueString("ObjectUUID", this.state.ActiveUUID, LocalizedStrings.ObjectUUID)}
        {this.getDisplayValueString("CreationId",this.state.CreationId !== "" ? this.state.CreationId : LocalizedStrings.NewCommitId , LocalizedStrings.CreationId)}
        {this.getDisplayValueString("ProjectOrigin",this.state.ProjectOrigin, LocalizedStrings.CreationId)}
        {this.state.ObjectUUID !== EMPTY_UUID ? this.getDisplayValueString("ObjectUUID",this.state.ObjectUUID, LocalizedStrings.ObjectUUID) : null}
        {this.filterBySearch(this.renderChangedContext(), "")}
        </CollapsableDevider>
        </CollapsableDevider>                
        {this.state.ObjectPath.length > 0     ? this.GetObjectPath()         : null}
        {this.state.HasDimension      ? this.GetDimensionProperty()         : null}
        {this.state.HasFixture        ? this.GetFixtureProperty()           : null}
        {this.state.IsFixtureType     ? this.GetFixtureTypeProperty()       : null}
        {this.state.HasElectrical     ? this.GetElectricalProperty()        : null}
        {this.state.HasWire           ? this.GetWireProperty()              : null}
        {this.state.HasStructure      ? this.GetStructureProperty()         : null}
        {this.state.HasLoad           ? this.GetLoadProperty()              : null}
        {this.state.HasSupport        ? this.GetSupportProperties()         : null}
        {this.state.HasCableDock      ? this.GetCableDockProperties()         : null}
        {this.state.HasInfluenceLine  ? this.GetInfluenceLine()             : null}
        {this.state.HasGeometryOrigin ? this.GetGeometryOriginProperties()  : null}
        {this.state.SupportInfos.length > 0 ? this.ShowSupportInfo()        : null}
        {this.state.MagnetInfo.IsMagnet ? this.getMagnetProperties()        : null}
        {this.GetTransformComponents()}
        {this.GetLinkedMeshAndTexture()}
        {this.state.HasPolygon ? this.getPolygonInput() : null}
        {this.state.ObjectsOnCablePathSerialized ?.length  ? this.getCablePathCableList() : null}
      </React.Fragment>
    )
  }

  renderChangedContext = () =>
  {
    let changes = []
    for (const [key, value] of Object.entries(this.state))
    {
      if(key.startsWith("CommitID"))
      {
        let name = key.replace("CommitID", "")
        if(this.state["Had"+name])
        {
          changes.push( this.getDisplayValueString(name,value, name))
        }

      }
    }
    
    return<>
    {changes}
    </>
  }

  getMagnetStructuralProperties = () =>
  {


    return(<>
    {this.state.MagnetProperties.map((val, i)=>
      {
        let changeMagnetProperty = (name, index, value) =>
        {
          this.runSetArrayCommand({ArrayName: "MagnetProperties", ArrayIndex: index, PropertyName: name, Value: value})
        }
        return <>
        <Divider horizontal>
        {this.filterBySearch( 
        <UnitInput  name          = "StructureExtensionX"
                        baseUnit      = {BASE_UNIT_BOOLEAN}
                        size          = "mini"
                        label         = {LocalizedStrings.ExtendStructure + " " +val.Name}
                        labelPosition = "right" 
                        value         = {val.ExtendStructure}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeMagnetProperty("ExtendStructure", i, value)}}/>,"ExtendStructure")}
        </Divider>
        {
          val.ExtendStructure ? <>
        
        <FilteredFormGroup>
        {this.filterBySearch( 
        <UnitInput  name          = "StructureExtensionX"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.ExtendOffsetX}
                        labelPosition = "right" 
                        value         = {val.ExtendOffset.X}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeMagnetProperty("ExtendOffsetX", i, value)}}/>,"ExtendOffsetX")}
        </FilteredFormGroup>
        <FilteredFormGroup>
        {this.filterBySearch( 
        <UnitInput  name          = "ExtendOffsetY"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.ExtendOffsetY}
                        labelPosition = "right" 
                        value         = {val.ExtendOffset.Y}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeMagnetProperty("ExtendOffsetY", i, value)}}/>,"ExtendOffsetY")}
        </FilteredFormGroup>

        <FilteredFormGroup>
        {this.filterBySearch( 
        <UnitInput  name          = "ExtendOffsetZ"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.ExtendOffsetZ}
                        labelPosition = "right" 
                        value         = {val.ExtendOffset.Z}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeMagnetProperty("ExtendOffsetZ", i, value)}}/>,"ExtendOffsetZ")}
        </FilteredFormGroup>
        </> : null}
        </>
      })}
    </>)
  }    

  getCablePathCableList = () => {

    let WorksheetScale = 1;
    let tableRef = undefined;
    let margins = {
      Bottom: 25,
      Top: 25,
      Left: 25,
      Right: 25,
    };

    let showPages = false;
    let searchFilter = '';
    let firstUuid = 0;
    let pages = {
      width: 210,
      height: 297,
    };
    let worksheetCellData = {};
    
    let objects = this.state.ObjectsOnCablePathSerialized.map( (e)=>
      {
          return {"Resolved" : e}
      });

    return <CollapsableDevider
      name="CableList" 
      omit={this.state.searchFilter !== ""} 
      header={LocalizedStrings.CableList}
      icon="power cord"
      open={this.state.OpenCableList}      
      update={ () =>  {
        this.syncState(
        {
          OpenCableList: !this.state.OpenCableList,          
        },         
        )}}
    >
      <FilteredFormGroup>
        {this.filterBySearch(          
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <ConnectionView
            ref={tableRef}
            worksheetScale={WorksheetScale}
            printMargin={margins}
            showPages={showPages}
            searchFilter={searchFilter}
            worksheet={firstUuid}
            pages={pages}
            headerData={worksheetCellData}
            objects={objects}
          />
          </div>
        )}
    </FilteredFormGroup>
    </CollapsableDevider >
  };

  getMagnetProperties = () =>
  {
    let typeChanged = (_, {value}) =>
    {
      let newMagnetInfo = {...this.state.MagnetInfo, Type: value}

      this.syncState({MagnetInfo: newMagnetInfo, changedName: "MagnetInfo", changedValue: newMagnetInfo})
    }

    return <CollapsableDevider name="Magnet" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Magnet} icon="cubes" open={this.state.OpenMagnet} update={()=> this.syncState({OpenMagnet: !this.state.OpenMagnet})}>
      <FilteredFormGroup>
        {this.filterBySearch( 
        <Form.Input name      = {"MagnetType"}    
                    fluid 
                    size        = "mini"
                    placeholder = {""}
                    label       = {LocalizedStrings.MagnetType} 
                    value       = {this.state.MagnetInfo.Type}
                    onChange    = {typeChanged} 
                    onBlur      = {(e)=>this.updateData(e, "MagnetInfo", this.state.MagnetInfo)} 
                    onKeyDown   = {(e) => {if (e.keyCode === 13) { e.target.blur(e);}}}
                    data-removepropname = {"MagnetInfo"}>
                      <input />
                    </Form.Input>
                  , "MagnetType")}
      </FilteredFormGroup>
    </CollapsableDevider>
  }

  getPolygonInput = () =>
  {
    let printLabelTypeOptions = POLYGON_LINE_TYPE_ITERATOR.map((i, idx) => ({
      key: idx,
      value: idx,
      text: LocalizedStrings_ObjectProperties[`LineType_${i}`] ?? i
    }))

    return <CollapsableDevider name="Line" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Line} icon="cubes" open={this.state.OpenLine} update={()=> this.syncState({OpenLine: !this.state.OpenLine})}>
        <FilteredFormGroup>
          {this.getInputFieldBool("ClosedLine")}
          {this.getInputFieldBool("FillPolygon")}
          {this.getInputFieldBool("DrawBackground")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "LineWidth")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldSelect("LineType", printLabelTypeOptions)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldColor("LineColor")}
          {this.getInputFieldColor("FillColor")}
          {this.getInputFieldColor("BackgroundColor")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "LineAlpha")}
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "FillAlpha")}
          </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "ExtrusionHeight")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldSelect("PolygonTexture", this.state.TextureOptions)}
        </FilteredFormGroup>
        {this.state.PolygonTexture !== EMPTY_UUID ? 
          <><FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_PERCENT, "PolygonTextureOffsetX", "", "0.01")}
            {this.getInputFieldNumber(BASE_UNIT_PERCENT, "PolygonTextureOffsetY", "", "0.01")}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_PERCENT, "PolygonTextureScale")}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.getInputFieldNumber(BASE_UNIT_ANGLE, "PolygonTextureRotation", "°", "0.017453")}
          </FilteredFormGroup></> : null}
        <FilteredFormGroup>
          {
            this.state.IsPrintLabel ? null : <Button content={LocalizedStrings.ExportPolygon} onClick={() => {window.LR_ExportPolygon({Polygon: this.state.ActiveUUID})}}/>
          }
        </FilteredFormGroup>
    </CollapsableDevider>
  }

  getQrCode = () =>
  {
    if(this.state.PAOnlineHref === "") { return null }
    return this.filterBySearch(
      <>
        <Form.Field>
          <label>{LocalizedStrings.QRCode}</label>
          <QRCodeCanvas value={this.state.PAOnlineHref} />
        </Form.Field>
      </>, "QRCode")
  }

  openEditMode = (uuid) =>
  {
    window.LR_OpenGeometryEditMode({UUID: uuid})
  }

  GetTransformComponents = () =>
  {
    if(this.state.HasGeometry)
    {
      return <React.Fragment>
        <CollapsableDevider name="CustomTransform" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.CustomTransfrom} icon="move" open={this.state.OpenCustomTransfrom} update={()=> this.syncState({OpenCustomTransfrom: !this.state.OpenCustomTransfrom})}>
          {this.getInputFieldBool("TranslateX")}
          {this.getInputFieldBool("TranslateY")}
          {this.getInputFieldBool("TranslateZ")}
          {this.getInputFieldBool("RotateX")}
          {this.getInputFieldBool("RotateY")}
          {this.getInputFieldBool("RotateZ")}
          {this.getInputFieldBool("HasBeam")}
        </CollapsableDevider>
        </React.Fragment>
    }
    return null    
  }

  _LinkedMeshWithoutDivider = () => {
    return <>
      {this.getInputFieldSelect("MeshUuid", this.state.MeshesOptions)}
      {this.getInputFieldSelect("TextureUuid", this.state.TextureOptions)}
    </>
  }

  GetLinkedMeshAndTexture = () => {
    if (!this.state.HasGeometry || this.state.HasPrintLabelField) {
      return null
    }

    return <React.Fragment>
      <CollapsableDevider name="LinkedGraphic" omit={this.state.searchFilter !== ""} header={LocalizedStrings.GraphicalRepresentation} icon="images" open={this.state.GraphicalRepresentation} update={() => this.syncState({ GraphicalRepresentation: !this.state.GraphicalRepresentation })}>
        {this._LinkedMeshWithoutDivider()}
      </CollapsableDevider>
    </React.Fragment>
  }

  GetElectricalProperty = () =>
  {
    let getRelevantWireConns = (thisWire, wireList) => {
      let thisConnType = thisWire[thisWire.UUID].ConnectorType
      let it = GetConnectorInfoByName(thisConnType)
      if(!it?.CompatibleWith)
      {
        return wireList[thisConnType]
      }
      return Object.entries(wireList).filter(([key]) => key === thisConnType || it.CompatibleWith.some(i => i.OtherConnectorName === key)).flatMap(([_, value]) => value)
    }

    return(
    <React.Fragment>
      <CollapsableDevider name="ElectricalProperties" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Electric} icon="power cord" open={this.state.OpenElectric} update={()=> this.syncState({OpenElectric: !this.state.OpenElectric})}>
        {this.getInputFieldBool("FullyConnected")}
        {this.getInputFieldSelect("DesiredGenerator", this.state.GeneratorList)}
        {this.state.HasGenerator ?  this.getInputFieldDate("AvailableStart") : null}
        {this.state.HasGenerator ?  this.getInputFieldDate("AvailableEnd") : null}
          {this.state.ObjectConnectorsIn.map((val, index) =>  { return <>{this.getElectricalInputField(val.Name, val, this.state.AllWireOutputs[val[val.UUID].ConnectorType], "ObjectConnectorsIn", index, false)}</> })}
          {this.state.ObjectConnectorsOut.map((val, index) => { return <>{this.getElectricalInputField(val.Name, val, this.state.AllWireInputs[val[val.UUID].ConnectorType], "ObjectConnectorsOut", index, false)} </> })}
        <CollapsableDevider className="hot-patch" name="HotPatch" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.HotPatch} icon="power cord" open={this.state.OpenHotPatch} update={()=> this.syncState({OpenHotPatch: !this.state.OpenHotPatch})}>
          {this.filterBySearch(<Button style={{marginBottom: "1em"}} fluid primary onClick={()=>window.LR_ShowHotpatchForSelectedObjects()}>{LocalizedStrings.HotpatchSelected}</Button>, LocalizedStrings.HotpatchSelected)}
          {this.state.ObjectConnectorsIn.map((val, index) =>  { return <>{this.getElectricalInputField(val.Name, val, getRelevantWireConns(val, this.state.AllWireOutputs), "ObjectConnectorsIn", index, true)}</>})}
          {this.state.ObjectConnectorsOut.map((val, index) => { return <>{this.getElectricalInputField(val.Name, val, getRelevantWireConns(val, this.state.AllWireInputs), "ObjectConnectorsOut", index, true)}</>})}
        </CollapsableDevider>

      </CollapsableDevider>
    </React.Fragment>)
  }

  GetWireProperty = () =>
  {
    return(
    <React.Fragment>
      <CollapsableDevider name="WireProperties" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Electric} icon="power cord" open={this.state.OpenElectric} update={()=> this.syncState({OpenElectric: !this.state.OpenElectric})}>
        {this.getElectricalWireField("1")}
        {this.getInputFieldNumber(BASE_UNIT_LENGTH, "DefaultCableLength")}
        {this.GetMaxPayloadProperty()}
        {this.GetVoltageProperty()}
        {this.GetSignalTypeProperty()}
        {this.GetWireProperty_Consumer()}
      </CollapsableDevider>
    </React.Fragment>)
  }

  GetMaxPayloadProperty()
  {
    switch(this.state.WireType)
    {
      case kWireType_Generator: return this.getInputFieldNumber(BASE_UNIT_POWER, "MaxPayload");
      default: return null
    }
    
  }

  GetVoltageProperty()
  {
    switch(this.state.WireType)
    {
      case kWireType_Generator: return this.getInputFieldNumber(BASE_UNIT_VOLTAGE, "Voltage", "V", "5");
      default: return null
    }
    
  }

  GetSignalTypeProperty()
  {
    switch(this.state.WireType)
    {
      case kWireType_NetworkProvider: return this.getInputFieldSelect("SignalType", optionsDataTypeSignal)
      default: return null
    }
    
  }

  GetInventoryProperty = () => 
  {

    return (
      <React.Fragment>
        {this.getInputFieldNumber(BASE_UNIT_NUMBER, "InventoryCount", "", "1", "", this.state.InventoryCountPreset)}
        {this.getInputFieldNumber(BASE_UNIT_LENGTH, "Length")}
        {this.getInputFieldNumber(BASE_UNIT_LENGTH, "LengthMin")}
        {this.getInputFieldNumber(BASE_UNIT_LENGTH, "LengthMax")}
        {this.getInputFieldNumber(BASE_UNIT_LENGTH, "LengthDelta")}
        {this.getInputFieldNumber(BASE_UNIT_AREA, "CableCrossSectionArea")}
        {this.getInputFieldSelect("InsulationMaterial", InsulationMaterial)}
        {this.getInputFieldSelect("InventoryType", optionInventoryType)}
      </React.Fragment>
    );
  }

  GetDisplayInventory = () =>
  {
    let array = this.state.CurrentInventory
    if( !  (array.length > 0))
    {
      array = []
    }

    return (
      <>
      {this.state.CurrentInventory && this.state.CurrentInventory.length > 0 ? 
      <CollapsableDevider name="InventoryGeometries" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.InventoryGeometries} icon="plus circle" open={this.state.InventoryGeometriesOpen} update={()=> this.syncState({InventoryGeometriesOpen: !this.state.InventoryGeometriesOpen})}>
            {array.map((invItem, index) => {
              return Object.entries(invItem.InventoryMap).map(([key, value]) => {
                return this.filterBySearch(<UnitInput
                  key={index}
                  fluid
                  label={key + " " + invItem.Name}
                  baseUnit={BASE_UNIT_NUMBER}
                  value={value.Inv_Count}
                  onUpdateData={(name, value, e) => { this.updateInventoryCount(invItem, value, invItem.Name, key, e) }}
                />, key + " " + invItem.Name)
              })
            })}

        </CollapsableDevider>
       : null}
      </>

    )

  }

  changeInventoryCount = (value, index) =>
  {
    let numberValue = Number(value); 
    
    let newInventory = this.state.CurrentInventory.map((invItem, i) =>
    {
      return {...invItem, InventoryCount: i === index ? numberValue : invItem.InventoryCount}
    })

    this.syncState({CurrentInventory: newInventory})
  }

  updateInventoryCount = (invItem, count, indexName, prefixName, e) =>
  {
    let toSetValues = {
      PropertyName: `"InventoryList" "${indexName}" "${prefixName}" "Entry"`,
      Value: count,
      isInventory: true
    }

    this.runSetCommand(toSetValues, e, true)
  }

  ShowSupportInfo() 
  {
    if (!this.state.SupportInfos) { return null; }

    if(!this.state.loadedInventoryObjects){
      this.getInventoryObjects()
      return <> loading...</>
    }

    let changeSupportProperty = (name, index, value) =>
    {
      this.runSetArrayCommand({ArrayName: "SupportProperties", ArrayIndex: index, PropertyName: name, Value: value})
    }
    
    let allParts = []

    let hasGroundSupport = false
    
    let automPopParts = this.state.AutomaticalPopulateParts;
    let chainLengthReadOnly = automPopParts && (this.state.SupportInfos.length > 1); // Add the moment only make this read only for multileg bridles.

    let Display = this.state.SupportInfos.map((val, index) =>
      {

        if(val.SupportType === GEOMETRY_SUPPORT_TYPE_Rope)
        {
          allParts.push(...val.SelectedBridleParts)
          allParts.push(...val.SelectedBasketParts)

          return (
          <>
            <CollapsableDevider 
              name="Support" 
              omit={ this.state.searchFilter !== ""} 
              header={LocalizedStrings.SupportNamePrefix + " "+ val.Name} 
              icon="upload" 
              open={this.state["_Support_"+index] || this.state.SupportInfos.length === 1} 
              update={()=> this.syncState({["_Support_"+index]: !this.state["_Support_"+index]})}
            >
            <FilteredFormGroup>
            {
              this.filterBySearch(
                <UnitInput  name          = "SupportName"
                          baseUnit      = {BASE_UNIT_STRING}
                          size          = "mini"
                          label         = {LocalizedStrings.SupportName}
                          labelPosition = "right" 
                          value         = {val.Name}
                          fluid
                          onUpdateData  ={(name, value, e) => { changeSupportProperty("Name", index, value)}}
                />,
                "SupportName"
              )
            }
            {this.filterBySearch(
            <UnitInput  name          = "LoadMeasurementCellId"
                        baseUnit      = {BASE_UNIT_STRING}
                        size          = "mini"
                        label         = {LocalizedStrings.LoadMeasurementCellId}
                        labelPosition = "right" 
                        value         = {val.LoadMeasurementCellId}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeSupportProperty("LoadMeasurementCellId", index, value)}}/>,"LoadMeasurementCellId")}

            </FilteredFormGroup>
            <FilteredFormGroup>
            {this.filterBySearch( <Form.Dropdown
                                  fluid
                                  search
                                  size      = "mini"
                                  name      = {"RopeCrossSection"}   
                                  value     = {val.RopeCrossSection}
                                  label     = {LocalizedStrings.SupportCrossSection}
                                  options   = {[...this.state.ropeCossSection,{
                                    text: LocalizedStrings.NoneColorCode,
                                    value: "",
                                  }]}  
                                  onChange  = {(e, {value})=>
                                  {
                                    this.runSetArrayCommand([
                                      {ArrayName: "SupportProperties", ArrayIndex: index, PropertyName: "RopeCrossSection", Value: value},
                                  ])
                                  }}
                                  style={{width: '50%'}}>
                                  </Form.Dropdown>,
                          "FrictionOptions")}
            </FilteredFormGroup>
            <FilteredFormGroup>
            {this.filterBySearch( 
            <UnitInput  name          = "ChainLength"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.ChainLength}
                        labelPosition = "right" 
                        value         = {val.ChainLength}
                        fluid                        
                        readOnly      = {chainLengthReadOnly}
                        onUpdateData  ={(name, value, e) => { changeSupportProperty("RopeOffset", index, value)}}/>,"ChainLength")}
          {this.filterBySearch( 
            <UnitInput  name          = "ChainShorten"
                        baseUnit      = {BASE_UNIT_PAGE_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.ChainShorten}
                        labelPosition = "right" 
                        value         = {val.ChainShorten}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeSupportProperty("ChainShorten", index, value)}}/>,"ChainShorten")}
            </FilteredFormGroup>
            <FilteredFormGroup>
                        {this.filterBySearch( 
            <UnitInput  name          = "Capacity"
                        baseUnit      = {BASE_UNIT_WEIGHT}
                        size          = "mini"
                        label         = {LocalizedStrings.Capacity}
                        labelPosition = "right" 
                        value         = {val.Capacity}
                        fluid
                        onUpdateData  = {(name, value, e) => { changeSupportProperty("Capacity", index, value) }}/>,"Capacity")}
          {this.filterBySearch( 
            <UnitInput  name          = "Speed"
                        baseUnit      = {BASE_UNIT_NUMBER}
                        unitStr       = {"m/min"}
                        size          = "mini"
                        label         = {LocalizedStrings.Speed}
                        labelPosition = "right" 
                        value         = {val.Speed}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeSupportProperty("Speed", index, value) }}/>,"Speed")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch(
           <UnitInput   name          = "ReactionForce"
                        baseUnit      = {BASE_UNIT_FORCE}
                        size          = "mini"
                        label         = {LocalizedStrings.ReactionForce}
                        labelPosition = "right" 
                        value         = {val.ReactionForce}
                        fluid
                        readOnly
                        onUpdateData  = {(name, value, e) => { changeSupportProperty("ReactionForce", index, value) }}/>,"ReactionForce")}
            {this.filterBySearch(
            <UnitInput  name          = "HookForce"
                        baseUnit      = {BASE_UNIT_FORCE}
                        size          = "mini"
                        label         = {LocalizedStrings.HookForce}
                        labelPosition = "right" 
                        value         = {val.HookForce}
                        fluid
                        readOnly
                        onUpdateData  = {(name, value, e) => { changeSupportProperty("HookForce", index, value)}}/>,"HookForce")}
          </FilteredFormGroup>
          <FilteredFormGroup>
            {this.filterBySearch(
            <UnitInput  name          = "LoadMeasurementValue"
                        baseUnit      = {BASE_UNIT_FORCE}
                        size          = "mini"
                        label         = {LocalizedStrings.LoadMeasurementValue}
                        labelPosition = "right" 
                        value         = {val.LoadMeasurementValue}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeSupportProperty("LoadMeasurementValue", index, value)}}/>,"LoadMeasurementValue")}
           </FilteredFormGroup>  
          <FilteredFormGroup>
          {this.getDisplayValueUnit("BeamForceX",val.BeamForceX, BASE_UNIT_FORCE)}
          {this.getDisplayValueUnit("BeamForceY",val.BeamForceY, BASE_UNIT_FORCE)}
          {this.getDisplayValueUnit("BeamForceZ",val.BeamForceZ, BASE_UNIT_FORCE)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getDisplayValueUnit("ChainShortenEffect",val.ChainShortenEffect, BASE_UNIT_PERCENT)}
          {this.getDisplayValueUnit("ChainShortenEffectWorkload",val.ChainShortenEffectWorkload, BASE_UNIT_PERCENT)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getDisplayValueUnit("ChainShortenMaxReactionForce",val.ChainShortenMaxReactionForce, BASE_UNIT_FORCE)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getDisplayValueUnit("ChainShortenEffectTruss",val.ChainShortenEffectTruss, BASE_UNIT_PERCENT)}
          {this.getDisplayValueUnit("ChainShortenEffectTrussWorkload",val.ChainShortenEffectTrussWorkload, BASE_UNIT_PERCENT)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getDisplayValueUnit("TrussBottom",val.TrussBottom, BASE_UNIT_LENGTH)}
          {this.getDisplayValueUnit("TrussTop",val.TrussTop, BASE_UNIT_LENGTH)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch( 
            <UnitInput  name          = "MotorHeight"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.MotorHeight}
                        labelPosition = "right" 
                        value         = {val.MotorHeight}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeSupportProperty("MotorHeight", index, value)}}/>,"MotorHeight")}
                                  {this.filterBySearch( 
            <UnitInput  name          = "SlingHeight"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.SlingHeight}
                        labelPosition = "right" 
                        value         = {val.SlingHeight}
                        fluid
                        onUpdateData  ={(name, value, e) => { changeSupportProperty("SlingHeight", index, value)}}/>,"SlingHeight")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getDisplayValueUnit("DistanceTrimTopTruss",val.DistanceTrimTopTruss, BASE_UNIT_LENGTH)}
          {this.getDisplayValueUnit("LoadTrim",val.LoadTrim, BASE_UNIT_LENGTH)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.getDisplayValueUnit("LowHook",val.LowHook, BASE_UNIT_LENGTH)}
          {this.getDisplayValueUnit("HighHook",val.HighHook, BASE_UNIT_LENGTH)}
          {this.getDisplayValueUnit("HookToHook",val.HookToHook, BASE_UNIT_LENGTH)}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"UseTopPointFromDefault"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.UseTopPointFromDefault}</label>} 
              checked   = {val.UseTopPointFromDefault}
              onChange  = {(e,{checked})=> changeSupportProperty("UseTopPointFromDefault", index, checked)} 
              />, "UseTopPointFromDefault")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"ForceDropConnection"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.ForceDropConnection}</label>} 
              checked   = {val.ForceDropConnection}
              onChange  = {(e,{checked})=> changeSupportProperty("ForceDropConnection", index, checked)} 
              />, "ForceDropConnection")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"ShowResult"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.ShowResult}</label>} 
              checked   = {val.ShowResult}
              onChange  = {(e,{checked})=> changeSupportProperty("ShowResult", index, checked)} 
              />, "ShowResult")}
            </FilteredFormGroup>
            <FilteredFormGroup>
            {this.filterBySearch( 
              <Form.Checkbox name      = {"CompensateChainShorten"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.CompensateChainShorten}</label>} 
              checked   = {val.CompensateChainShorten}
              onChange  = {(e,{checked})=> changeSupportProperty("CompensateChainShorten", index, checked)} 
              />, "CompensateChainShorten")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"UpsideDown"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.UpsideDown}</label>} 
              checked   = {val.UpsideDown}
              onChange  = {(e,{checked})=> changeSupportProperty("UpsideDown", index, checked)} 
              />, "UpsideDown")}
          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"ConnectedToRoof"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.ConnectedToRoof}</label>} 
              checked   = {val.ConnectedToRoof}
              onChange  = {(e,{checked})=> changeSupportProperty("ConnectedToRoof", index, checked)} 
              />, "ConnectedToRoof")}
          </FilteredFormGroup>
          <CollapsableDevider
            name="BridleParts" 
            omit={ this.state.searchFilter !== ""} 
            header={LocalizedStrings.BridleParts} 
            icon="upload" 
            open={this.state.OpenBridleParts} 
            update={()=> this.syncState({OpenBridleParts: !this.state.OpenBridleParts})}
          > 
            <FilteredFormGroup>
            {
              this.filterBySearch(
                <UnitInput  name          = "BridleSet"
                          baseUnit      = {BASE_UNIT_SELECTABLE}
                          size          = "mini"
                          label         = {LocalizedStrings.BridleSet}
                          labelPosition = "right" 
                          options={this.state.bridleSets}
                          value         = {val.BridleSet}
                          fluid
                          updateData  ={(v) => { changeSupportProperty("BridleSet", index, v)}}
                />,
                "BridleSet"
              )
            }
            </FilteredFormGroup>
            {this.filterBySearch(
            <UnitInput  name          = "BasketHeight"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.BasketHeight}
                        labelPosition = "right" 
                        value         = {val.BasketHeight}
                        fluid
                        onUpdateData  = {(name, value, e) => { changeSupportProperty("BasketHeight", index, value)}}/>,"BasketHeight")}

            {this.filterBySearch(
            <UnitInput  name          = "BasketWidth"
                        baseUnit      = {BASE_UNIT_LENGTH}
                        size          = "mini"
                        label         = {LocalizedStrings.BasketWidth}
                        labelPosition = "right" 
                        value         = {val.BasketWidth}
                        fluid
                        onUpdateData  = {(name, value, e) => { changeSupportProperty("BasketWidth", index, value)}}/>,"BasketWidth")}

            {this.addBridlePartsList(val.SelectedBridleParts, false, index, false, ! automPopParts)}
            <CollapsableDevider
              name="BasketParts" 
              omit={ this.state.searchFilter !== ""} 
              header={LocalizedStrings.BasketParts} 
              icon="upload" 
              open={this.state.OpenBasketParts} 
              update={()=> this.syncState({OpenBasketParts: !this.state.OpenBasketParts})}
            >
              {this.addBridlePartsList(val.SelectedBasketParts, false, index, true, ! automPopParts)}
            </CollapsableDevider>
          </CollapsableDevider>
          </CollapsableDevider>
          </>
        )
        }

        if(val.SupportType === GEOMETRY_SUPPORT_TYPE_HouseRigging)
        {
          return(
            <React.Fragment key={val.UUID}>

            <FilteredFormGroup widths="equal" >
            {this.getDisplayValueUnit("ReactionForceX",  val.ReactionForceX, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceX, val.CapacityForceX))}
            {this.getDisplayValueUnit("ReactionForceY",  val.ReactionForceY, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceY, val.CapacityForceX))}
            {this.getDisplayValueUnit("ReactionForceZ",  val.ReactionForceZ, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceZ, val.CapacityForceZ))}
            </FilteredFormGroup>
            <FilteredFormGroup widths="equal">
            {this.getDisplayValueUnit("CapacityForceX",  val.CapacityForceX, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceX, val.CapacityForceX))}
            {this.getDisplayValueUnit("CapacityForceY",  val.CapacityForceY, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceY, val.CapacityForceX))}
            {this.getDisplayValueUnit("CapacityForceZ",  val.CapacityForceZ, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceZ, val.CapacityForceZ))}
            </FilteredFormGroup>
            </React.Fragment>
          )
        }



        let horizontal_Force = Math.sqrt(val.ReactionTippingForceX * val.ReactionTippingForceX + val.ReactionTippingForceY * val.ReactionTippingForceY) 
        let vertical_Force   = val.ReactionTippingForceZ 

        hasGroundSupport = true
        
        return(
          <>
          <FilteredFormGroup>
          {this.filterBySearch( 
          <Form.Input name = "Name"
                      size = "mini"
                      label = {LocalizedStrings.SupportName}
                      value = {val.Name}
                      fluid
                      inline
                      onChange={(e, {value}) => { val.Name = value; this.syncState({SupportInfos: this.state.SupportInfos}) }}
                      onBlur={(e) => { e.preventDefault(); changeSupportProperty("Name", index, val.Name) }}
                      onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur();} }}>
            <input/>
          </Form.Input>,"Name")}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
                {this.renderResistanceInput(val, "ResistanceX", BASE_UNIT_FORCE_PER_DISTANCE, index)}
                {this.renderResistanceInput(val, "ResistanceY", BASE_UNIT_FORCE_PER_DISTANCE, index)}
                {this.renderResistanceInput(val, "ResistanceZ", BASE_UNIT_FORCE_PER_DISTANCE, index)}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
                {this.renderResistanceInput(val, "ResistanceXX", BASE_UNIT_MOMENT_PER_DISTANCE, index)}
                {this.renderResistanceInput(val, "ResistanceYY", BASE_UNIT_MOMENT_PER_DISTANCE, index)}
                {this.renderResistanceInput(val, "ResistanceZZ", BASE_UNIT_MOMENT_PER_DISTANCE, index)}
          </FilteredFormGroup>
          <Divider/>
          <FilteredFormGroup widths="equal">
                {this.renderResistanceInput(val, "CapacityForceX", BASE_UNIT_FORCE, index)}
                {this.renderResistanceInput(val, "CapacityForceY", BASE_UNIT_FORCE, index)}
                {this.renderResistanceInput(val, "CapacityForceZ", BASE_UNIT_FORCE, index)}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
                {this.renderResistanceInput(val, "CapacityForceXX", BASE_UNIT_TORQUE, index)}
                {this.renderResistanceInput(val, "CapacityForceYY", BASE_UNIT_TORQUE, index)}
                {this.renderResistanceInput(val, "CapacityForceZZ", BASE_UNIT_TORQUE, index)}
          </FilteredFormGroup>
          <Divider/>
          <FilteredFormGroup widths="equal">
                {this.getDisplayValueUnit("ReactionForceX",  val.ReactionForceX, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceX, val.CapacityForceX))}
                {this.getDisplayValueUnit("ReactionForceY",  val.ReactionForceY, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceY, val.CapacityForceX))}
                {this.getDisplayValueUnit("ReactionForceZ",  val.ReactionForceZ, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(val.ReactionForceZ, val.CapacityForceZ))}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
                {this.getDisplayValueUnit("ReactionForceXX",  val.ReactionForceXX, BASE_UNIT_TORQUE, undefined, GetPayLoadColor_2(val.ReactionForceXX, val.CapacityForceXX))}
                {this.getDisplayValueUnit("ReactionForceYY",  val.ReactionForceYY, BASE_UNIT_TORQUE, undefined, GetPayLoadColor_2(val.ReactionForceYY, val.CapacityForceYY))}
                {this.getDisplayValueUnit("ReactionForceZZ",  val.ReactionForceZZ, BASE_UNIT_TORQUE, undefined, GetPayLoadColor_2(val.ReactionForceZZ, val.CapacityForceZZ))}

          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
                {this.getDisplayValueUnit("ReactionTippingForceX",  val.ReactionTippingForceX, BASE_UNIT_FORCE, undefined)}
                {this.getDisplayValueUnit("ReactionTippingForceY",  val.ReactionTippingForceY, BASE_UNIT_FORCE, undefined)}
                {this.getDisplayValueUnit("ReactionTippingForceZ",  val.ReactionTippingForceZ, BASE_UNIT_FORCE, undefined)}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
                {this.getDisplayValueUnit("ReactionTippingForceXX",  val.ReactionTippingForceXX, BASE_UNIT_TORQUE, undefined)}
                {this.getDisplayValueUnit("ReactionTippingForceYY",  val.ReactionTippingForceYY, BASE_UNIT_TORQUE, undefined)}
                {this.getDisplayValueUnit("ReactionTippingForceZZ",  val.ReactionTippingForceZZ, BASE_UNIT_TORQUE, undefined)}

          </FilteredFormGroup>
          <FilteredFormGroup>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"RotatedSupport"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.RotatedSupport}</label>} 
              checked   = {val.RotatedSupport}
              onChange  = {(e,{checked})=> changeSupportProperty("RotatedSupport", index, checked)} 
              />, "RotatedSupport")}
          </FilteredFormGroup>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"AllowNegativeZ"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.AllowNegativeZ}</label>} 
              checked   = {val.AllowNegativeZ}
              onChange  = {(e,{checked})=> changeSupportProperty("AllowNegativeZ", index, checked)} 
              />, "AllowNegativeZ")}
          {this.filterBySearch( 
              <Form.Checkbox name      = {"ShowResult"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.ShowResult}</label>} 
              checked   = {val.ShowResult}
              onChange  = {(e,{checked})=> changeSupportProperty("ShowResult", index, checked)} 
              />, "ShowResult")}
          <Divider/>
          {this.filterBySearch( 
              <Form.Checkbox name      = {"FrictionCheck"} 
              fluid ="true"
              size      = "mini"
              label     = {<label>{LocalizedStrings.FrictionCheck}</label>} 
              checked   = {val.FrictionCheck}
              onChange  = {(e,{checked})=> changeSupportProperty("FrictionCheck", index, checked)} 
              />, "FrictionCheck")}
          {this.filterBySearch( <Form.Dropdown
                                  fluid
                                  search
                                  button
                                  text={LocalizedStrings.FrictionOptions}
                                  size      = "mini"
                                  name      = {"FrictionCheckName"}   
                                  value     = {val.FrictionCheckName}
                                  label     = {LocalizedStrings.FrictionOptions}
                                  options   = {friction_options}  
                                  onChange  = {(e, {value})=>
                                  {
                                    this.runSetArrayCommand([
                                      {ArrayName: "SupportProperties", ArrayIndex: index, PropertyName: "FrictionCheckValue", Value: friction_options_base[value].Value},
                                      {ArrayName: "SupportProperties", ArrayIndex: index, PropertyName: "FrictionCheckName", Value: friction_options_base[value].Name},
                                  ])
                                  }}
                                  style={{width: '50%'}}>
                                  </Form.Dropdown>,
                          "FrictionOptions")}
          <FilteredFormGroup widths="equal">
            {this.renderResistanceInput(val, "FrictionCheckName", BASE_UNIT_STRING, index)}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
            {this.renderResistanceInput(val, "FrictionCheckValue", BASE_UNIT_NUMBER, index)}
          </FilteredFormGroup>
          <FilteredFormGroup>

          {val.FrictionCheck ? this.getDisplayValueUnit("FrictionForce",  vertical_Force * val.FrictionCheckValue , BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(horizontal_Force, vertical_Force * val.FrictionCheckValue)) : null}
          {val.FrictionCheck ? this.getDisplayValueUnit("FrictionCurrentForce",  horizontal_Force, BASE_UNIT_FORCE, undefined, GetPayLoadColor_2(horizontal_Force, vertical_Force * val.FrictionCheckValue)) : null}

          </FilteredFormGroup>
          </>
        )
      })


    return (
      <CollapsableDevider 
        name="Support" 
        omit={ this.state.searchFilter !== ""} 
        header={LocalizedStrings.Support} 
        icon="upload" 
        open={this.state.OpenSupport} 
        update={()=> this.syncState({OpenSupport: !this.state.OpenSupport})}
      >
        <FilteredFormGroup>
          {
            this.getInputFieldBool("AutomaticalPopulateParts")
          }
        </FilteredFormGroup>

        {this.getDisplayValueUnit("TotalLoadZ",this.state.TotalLoadZ, BASE_UNIT_FORCE)}
        {hasGroundSupport ? this.getInputFieldNumber(BASE_UNIT_AREA, "LoadArea") : null}
        {hasGroundSupport ? this.getDisplayValueUnit("ForceLoadArea",this.state.TotalLoadZ / this.state.LoadArea, BASE_UNIT_FORCE_PER_AREA) : null}
        
        {Display}

        <CollapsableDevider 
          name="BridleDrawing"
          header={"Bridle Drawing"}
          icon="picture" 

          open={this.state.openBridleDrawing} 
          update={()=> this.syncState({ openBridleDrawing: !this.state.openBridleDrawing }) }
        >
          <BridleDrawing
            onChange= {(uuid) => this.updateData(undefined, "OverwrittenRope", uuid)}
            inventory={this.state.inventoryObjects}
            legs={this.state.SupportInfos}
            overwrittenRopeId={this.state.OverwrittenRope}
          />
          {this.addBridlePartsList(allParts, false, -1, false, false, true)}
        </CollapsableDevider>
      </CollapsableDevider>
    );
  }


  getLabelFieldSelect = (name, options, disabled = false) => {
    if (this.state["Had" + name] === false) { return null; }


    return this.filterBySearch(
      <Form.Dropdown
        fluid
        search
        size="mini"
        data-removepropname={name}
        name={name}
        disabled={disabled}
        value={this.state[name]}
        label={LocalizedStrings[name]}
        options={options}
        onChange={(e, {name, value}) => this.updateSelectedLabelField(name, value, options, true)}
        style={{ width: "50%" }}
      ></Form.Dropdown>,
      name
    );
  }

  updateSelectedLabelField = (name, value, options, isSelection = false) => {
    const selected = options.find(opt => opt.value === value)
    if(isSelection){
      this.runSetCommand([
        {
          PropertyName: name,
          Value: value
        }
      ])
    }else{
      this.runSetCommand([
        { 
          PropertyName: "IsUnitBased", 
          Value: selected.IsUnitBased
        },
        { 
          PropertyName: "BaseUnit", 
          Value: selected.BaseUnit
        },
        { 
          PropertyName: "PropertyName", 
          Value: value
        },
      ])
    }
  }


  renderPrintLabelFieldTextSettings = () => {
    let textOptions = this.fontNameOptions ? Object.keys(this.fontNameOptions).map( key=>
      {
          return{
            text: this.fontNameOptions[key],
            key: key,
            value: key}
      }) : [];

    return <>      
      <Form.Dropdown        
        search
        button                
        value={this.state.FontName}
        size="mini"
        name={"FontName"}
        label={LocalizedStrings.FontName}
        options={textOptions}
        onChange={(e, { value }) => {
          this.setState({FontName : value})
          this.runSetCommand([{ PropertyName: "FontName", Value : value}])
        }}
      /> 
      {this.getInputFieldNumber(BASE_UNIT_NUMBER, "FontSize", "#", "1")}
      <Button.Group>
        {this.getInputFieldBoolWithIcon("Underline", "underline")}
        {this.getInputFieldBoolWithIcon("Fat", "bold")}
        {this.getInputFieldBoolWithIcon("Cursive", "italic")}
      </Button.Group>{" "}
      <Button.Group>
        <Button
          onClick={(e, d) => { this.updateDataNow(e, { name: "LeftAlign", value: true }); this.updateDataNow(e, { name: "RightAlign", value: false }); }}
          color={this.state["LeftAlign"] && !this.state["RightAlign"] ? "grey" : "white"}>
          <Icon
            name="align left"
            color={this.state["LeftAlign"] && !this.state["RightAlign"] ? "white" : "black"}>
          </Icon>
        </Button>
        <Button
          onClick={(e, d) => { this.updateDataNow(e, { name: "LeftAlign", value: true }); this.updateDataNow(e, { name: "RightAlign", value: true }); }}
          color={this.state["LeftAlign"] === this.state["RightAlign"] ? "grey" : "white"}>
          <Icon
            name="align center"
            color={this.state["LeftAlign"] === this.state["RightAlign"] ? "white" : "black"}>
          </Icon>
        </Button>
        <Button
          onClick={(e, d) => { this.updateDataNow(e, { name: "RightAlign", value: true }); this.updateDataNow(e, { name: "LeftAlign", value: false }); }}
          color={this.state["RightAlign"] && !this.state["LeftAlign"] ? "grey" : "white"}>
          <Icon
            name="align right"
            color={this.state["RightAlign"] && !this.state["LeftAlign"] ? "white" : "black"}>
          </Icon>
        </Button>
      </Button.Group>
      {this.getInputFieldNumber(BASE_UNIT_PAGE_LENGTH, "FieldLength", "#", "1")}
      {this.getInputFieldColor("ForegroundColor")}
    </>
  }

  renderPrintLabelFieldStaticText = () => {
    return <>
      {this.getInputFieldString("TextValue", false)}
      {this.renderPrintLabelFieldTextSettings()}
    </>
  }

  renderPrintLabelFieldDynamicText = () => {
    const selectedObjectType = objectFilter.find(t => t.value === this.state.ObjectFilter).key

    let printLabelFields = []
    switch (selectedObjectType) 
    {
      case "Rack":   printLabelFields = this.state.possibleFieldsRacks; break;
      case "Case":   printLabelFields = this.state.possibleFieldsCases; break;
      case "Object": printLabelFields = this.state.possibleFieldsObjects; break;
      default: break;
    }

    let options = printLabelFields.map(prop => 
    {
      let fieldIdent = prop.ArrayName ? prop.ArrayName + ";" + prop.PropertyName : prop.PropertyName

      let propertyName = ""

      if (!prop.ArrayName) { propertyName = LocalizedStrings_ObjectProperties[prop.PropertyName]}
      else 
      {
        if (LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName])
        {
          propertyName =  LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName]
        }
        else
        {
          propertyName = LocalizedStrings_ObjectProperties[prop.PropertyName]
        }
      }
      return {
        BaseUnit: prop.BaseUnit,
        IsUnitBased: prop.IsUnitBased,
        ArrayName: prop.ArrayName ? prop.ArrayName : undefined,
        IfInArrayWithName: prop.IfInArrayWithName,
        PropertyList: prop.PropertyList,
        PropertyName: prop.PropertyName,
        LocalizedName: propertyName || prop.PropertyName || "",
        value: fieldIdent
      };
    })

    let selected = options.find(i => i.value === this.state.PropertyName)

    return <>
      <SingleFieldSearch 
      style={{maxHeight: 250, marginBottom: 15, marginTop: 5}}
      propertyList={options} 
      chosenItem={selected} 
      onItemChange={(item) => {
        this.updateSelectedLabelField("PropertyName", item.value, options)
      }} 
      />
      {this.getInputFieldNumber(BASE_UNIT_NUMBER, "ArrayPosition")}
      <Divider/>
      <Form.Group inline grouped>
        {this.getInputFieldBool("IncludePropertyName")}
        {this.getInputFieldBool("IncludeBaseUnit")}
      </Form.Group>
      {this.getInputFieldString("PrefixText")}
      {this.getInputFieldString("PostfixText")}
      {this.renderPrintLabelFieldTextSettings()}
    </>
  }

  renderPrintLabelFieldQRCode = () => {
    return <></>
  }

  renderPrintLabelFieldColorCode = () => {
    return <></>
  }

  GetRendererViewProperties = () => {
    let enoughPoints = (this.state.ObjectPath?.length ?? 0) > 2

    let viewTypeOptions = GEOMETRY_POLYGON_VIEW_TYPE_ITERATOR.map((i, idx) => ({
      key: idx,
      value: idx,
      text: LocalizedStrings_ObjectProperties[i] ?? i
    }))

    return (
      <React.Fragment>
        <CollapsableDevider 
        name="PrintLabelFieldProperties" 
        omit={ this.state.searchFilter !== ""} 
        header={LocalizedStrings.RendererViewEdit} 
        icon="expand" 
        open={this.state.OpenRendererViewEdit} 
        update={()=> this.syncState({OpenRendererViewEdit: !this.state.OpenRendererViewEdit})}
        >
          <div
            style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
            data-tooltip={enoughPoints ? undefined : LocalizedStrings.CannotOpenRendererEditViewMode}
          >
            {this.getLabelFieldSelect("RendererViewType", viewTypeOptions, !enoughPoints)}
            <Button disabled={this.state.RendererViewType === GEOMETRY_POLYGON_VIEW_TYPE.None} onClick={()=>window.LR_OpenPolygonRendererViewEditMode({UUID: this.state.ActiveUUID})}>{LocalizedStrings.ModifyRendererView}</Button>
          </div>
        </CollapsableDevider>
      </React.Fragment>
      )
  }

  GetPrintLabelFieldProperties = () =>
  {
    // get the possible property-fields depending on what object type the filter specifies (Rack, Case, Object)

    let fieldType = this.state.PrintLabelFieldType
    let printLabelTypeOptions = PRINT_LABEL_FIELD_TYPE_ITERATOR.map((i, idx) => ({
      key: idx,
      value: idx,
      text: LocalizedStrings_ObjectProperties[i] ?? i
    }))
    /* the alignment buttons cancel each other out and do not toggle but just activate the option as at all times one of them has to be actived */
    return (
      <React.Fragment>
        <CollapsableDevider name="PrintLabelFieldProperties" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.PrintLabelField} icon="print" open={this.state.OpenPrintLabelField} update={()=> this.syncState({OpenPrintLabelField: !this.state.OpenPrintLabelField})}>
          {this.getLabelFieldSelect("PrintLabelFieldType", printLabelTypeOptions)}
          {fieldType === PRINT_LABEL_FIELD_TYPE.StaticText ? this.renderPrintLabelFieldStaticText() : null}
          {fieldType === PRINT_LABEL_FIELD_TYPE.DynamicText ? this.renderPrintLabelFieldDynamicText() : null}
          {fieldType === PRINT_LABEL_FIELD_TYPE.QRCode ? this.renderPrintLabelFieldQRCode() : null}
          {fieldType === PRINT_LABEL_FIELD_TYPE.ColorCode ? this.renderPrintLabelFieldColorCode() : null}
          {fieldType === PRINT_LABEL_FIELD_TYPE.Picture ? <>
            {this._LinkedMeshWithoutDivider()}
          </> 
          : 
          null}
        </CollapsableDevider>
      </React.Fragment>
      )
  }

  onUpdatePaperPreset = () => {
    window.LR_SetPaperFormatTemplate({
      Name: this.state.PaperFormat,
      PaperSize:  { X: this.state.PaperSizeX, Y: this.state.PaperSizeY, Z: 0},
      Margin:     { X: this.state.MarginX,    Y: this.state.MarginX,    Z: 0},
      Spacing:    { X: this.state.SpacingX,   Y: this.state.SpacingY,   Z: 0},
      Size:       { X: this.state.SizeX,      Y: this.state.SizeY,      Z: 0},
      RoundEdge:  this.state.RoundEdge
    })
  }

  canUpdatePaperFormatPreset = () =>
  {
    let foundPaperFormat = this.state.paperFormatPresetOpts.find(entry => {
      if (entry.value === this.state.PaperFormat)
      {
        return true;
      }
      return false;
    })
    if (!foundPaperFormat) { return false; }
    if (foundPaperFormat.template)
    {
      return  foundPaperFormat.template.PaperSize.X !== this.state.PaperSizeX ||
              foundPaperFormat.template.PaperSize.Y !== this.state.PaperSizeY ||
              foundPaperFormat.template.Margin.X !== this.state.MarginX ||
              foundPaperFormat.template.Margin.Y !== this.state.MarginY ||
              foundPaperFormat.template.Spacing.X !== this.state.SpacingX ||
              foundPaperFormat.template.Spacing.Y !== this.state.SpacingY ||
              foundPaperFormat.template.RoundEdge !== this.state.RoundEdge ||
              foundPaperFormat.template.Size.X !== this.state.SizeX ||
              foundPaperFormat.template.Size.Y !== this.state.SizeY
    }
    return false;
  }

  GetPrintLabelProperties = () =>
  {
    let allPaperformats = []
    this.state.paperFormatPresetOpts.forEach(v => {
      allPaperformats.push(v);
    })


    allPaperformats.push({key:"-", text:<Divider/>});
    PaperFormats.forEach(v => {
      allPaperformats.push(v);
    })    
    return <React.Fragment>
      <CollapsableDevider name="PrintLabel" omit={this.state.searchFilter !== ""} header={LocalizedStrings.PrintLabel} icon="print" open={this.state.OpenPrintLabel} update={() => {this.syncState({OpenPrintLabel: !this.state.OpenPrintLabel})}}>
        {this.filterBySearch(
          <FilteredFormGroup>
          {this.getInputFieldSelect("PaperFormat", allPaperformats)}
          <Form.Field>
            <Button.Group>
              <Button primary disabled={!this.canUpdatePaperFormatPreset()} onClick={this.onUpdatePaperPreset}>{LocalizedStrings.Update}</Button>
              <Button.Or/>
              <Button positive onClick={this.openNewPaperPreset}>{LocalizedStrings.Create}</Button>
            </Button.Group>
          </Form.Field>
        </FilteredFormGroup>, "PaperFormatTemplate")}

        <FilteredFormGroup>
          {this.getInputFieldLength("PaperSizeX", "1", "", BASE_UNIT_PAGE_LENGTH)}
          {this.getInputFieldLength("PaperSizeY", "1", "", BASE_UNIT_PAGE_LENGTH)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldLength("MarginX", "1", "", BASE_UNIT_PAGE_LENGTH)}
          {this.getInputFieldLength("MarginY", "1", "", BASE_UNIT_PAGE_LENGTH)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldLength("SpacingX", "1", "", BASE_UNIT_PAGE_LENGTH)}
          {this.getInputFieldLength("SpacingY", "1", "", BASE_UNIT_PAGE_LENGTH)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldLength("SizeX", "1", "", BASE_UNIT_PAGE_LENGTH)}
          {this.getInputFieldLength("SizeY", "1", "", BASE_UNIT_PAGE_LENGTH)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldLength("RoundEdge", "1", "", BASE_UNIT_PAGE_LENGTH)}

        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldSelect("ObjectFilter", objectFilter)}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldBool("PLHasElectrical")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldBool("PLHasStructures")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldBool("PLHasFixture")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldBool("PLHasSupport")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldBool("PLHasAudio")}
        </FilteredFormGroup>
        <FilteredFormGroup>
          {this.getInputFieldBool("PLHasVisible")}
        </FilteredFormGroup>

      </CollapsableDevider>
      <LRModal 
        title={LocalizedStrings.CreateNewPaperFormatTemplate}
        open={this.state.createPaperPresetOpen}
        size="mini"
        onOkClick={this.newPaperPresetOk}
        onCancelClick={this.newPaperPresetCancel}>
        <Input fluid value={this.state.createPaperPresetName} onChange={this.editPaperPresetName}></Input>
      </LRModal>
    </React.Fragment>
  }

  openNewPaperPreset = () => {
    this.syncState({
      createPaperPresetOpen: true,
      createPaperPresetName: "New Paper Preset"
    })
  }

  editPaperPresetName = (_ ,{value}) => {
    this.syncState({
      createPaperPresetName: value
    })
  }

  newPaperPresetOk = async () => {
    await window.LR_AddPaperFormatTemplate({
      Name: this.state.createPaperPresetName,
      PaperSize:  { X: this.state.PaperSizeX, Y: this.state.PaperSizeY, Z: 0},
      Margin:     { X: this.state.MarginX,    Y: this.state.MarginX,    Z: 0},
      Spacing:    { X: this.state.SpacingX,   Y: this.state.SpacingY,   Z: 0},
      Size:       { X: this.state.SizeX,      Y: this.state.SizeY,      Z: 0},
    })
    
    globalCallbacks.updatePaperFormatTemplatesOPC()

    this.syncState({createPaperPresetOpen: false, PaperFormat: this.state.createPaperPresetName})
  }

  newPaperPresetCancel = () => {
    this.syncState({createPaperPresetOpen: false})
  }

  renderResistanceInput(val, name, baseUnit, index, editable = true)
  {
    return this.filterBySearch( 
    <UnitInput    name          = {name}
    baseUnit      = {baseUnit}
    size          = "mini"
    label         = {LocalizedStrings[name]}
    labelPosition = "right" 
    value         = {val[name]}
    fluid
    inline
    readOnly = {!editable}
    onUpdateData  ={(name, value, e) => { this.runSetArrayCommand({ArrayName: "SupportProperties", ArrayIndex: index, PropertyName: name, Value: value}, e)}}/>,name)
  }

  GetStructureProperty = () =>
  {
    return (
      <React.Fragment>
        <CollapsableDevider name="Structure" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Structure} icon="won" open={this.state.OpenStructure} update={()=> this.syncState({OpenStructure: !this.state.OpenStructure})}>
          {this.getInputFieldSelect("LinkedStructure", [...this.state.AllStructures, {
            text: LocalizedStrings.NoneColorCode,
            value: EMPTY_UUID,
          }])}
          {this.getInputFieldSelect("StructureType", StructureTypes)}
          {this.getInputFieldSelect("CrossSectionType", CrossSectionType)}
          {this.getInputFieldSelect("Material", [...this.state.trussMaterial,{
            text: LocalizedStrings.NoneColorCode,
            value: "",
          }])}

          {this.state.CrossSectionType === GEOMETRY_CrossSectionType_TemplateCrossSection ? 
            <>
              {this.getInputFieldSelect("CrossSectionTruss", [...this.state.trussCrossSection,{
            text: LocalizedStrings.NoneColorCode,
            value: "",
          }])}
            </>  
            :
            null
          }
          {this.state.CrossSectionType === GEOMETRY_CrossSectionType_Tube ? 
          <>
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "CrossSectionHeight")}
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "CrossSectionThickness")}
          </>  
          :
          null
          }
           {this.state.CrossSectionType === GEOMETRY_CrossSectionType_Rectangle ? 
          <>
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "CrossSectionHeight")}
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "CrossSectionWidth")}
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "CrossSectionThickness")}
          </>  
          :
          null
          }
          <Divider horizontal><b style={{ color : LOCAL_TRANSFORM_COLOR}}>{LocalizedStrings.LocalTransformStructure}</b></Divider>
         
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxDu")}
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxDx")}
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxDy")}
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxDz")}
          <Divider horizontal><b style={{ color : GLOBAL_TRANSFORM_COLOR}}>{LocalizedStrings.GlobalTransformStructure}</b></Divider>
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxGlobalDx")}
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxGlobalDy")}
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxGlobalDz")}
          <Divider horizontal><b style={{ color : LOCAL_TRANSFORM_COLOR}}>{LocalizedStrings.LocalTransformStructure}</b></Divider>
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxDxLocal")}
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxDyLocal")}
          {this.getInputFieldNumber(BASE_UNIT_LENGTH, "MaxDzLocal")}
          <Divider horizontal><b style={{ color : LOCAL_TRANSFORM_COLOR}}>{LocalizedStrings.LocalTransformStructure}</b></Divider>
          {this.getInputFieldNumber(BASE_UNIT_FORCE, "MaxNx")}
          {this.getInputFieldNumber(BASE_UNIT_FORCE, "MaxVy")}
          {this.getInputFieldNumber(BASE_UNIT_FORCE, "MaxVz")}
          {this.getInputFieldNumber(BASE_UNIT_TORQUE, "MaxMt")}
          {this.getInputFieldNumber(BASE_UNIT_TORQUE, "MaxMby")}
          {this.getInputFieldNumber(BASE_UNIT_TORQUE, "MaxMbz")}
          <Divider horizontal><b style={{ color : GLOBAL_TRANSFORM_COLOR}}>{LocalizedStrings.GlobalTransformStructure}</b></Divider>
          {this.getInputFieldNumber(BASE_UNIT_FORCE, "MaxGlobalNx")}
          {this.getInputFieldNumber(BASE_UNIT_FORCE, "MaxGlobalVy")}
          {this.getInputFieldNumber(BASE_UNIT_FORCE, "MaxGlobalVz")}
          {this.getInputFieldNumber(BASE_UNIT_TORQUE, "MaxGlobalMt")}
          {this.getInputFieldNumber(BASE_UNIT_TORQUE, "MaxGlobalMby")}
          {this.getInputFieldNumber(BASE_UNIT_TORQUE, "MaxGlobalMbz")}
          <Divider />
          {this.getInputFieldBool("HingeNx")}
          {this.getInputFieldBool("HingeVy")}
          {this.getInputFieldBool("HingeVz")}
          {this.getInputFieldBool("HingeMt")}
          {this.getInputFieldBool("HingeMby")}
          {this.getInputFieldBool("HingeMbz")}
          <Divider />
          {this.getInputFieldBool("AssignCableLoad")}
          
        </CollapsableDevider>
      </React.Fragment>
    );
  }

  GetLoadProperty = () =>
  {
    let arr

    if(!this.state.IsLineLoad)
    {
      arr = [
        this.getInputFieldNumber(BASE_UNIT_FORCE, "LoadX"),
        this.getInputFieldNumber(BASE_UNIT_FORCE, "LoadY"),
        this.getInputFieldNumber(BASE_UNIT_FORCE, "LoadZ"),
        this.getInputFieldNumber(BASE_UNIT_TORQUE, "LoadXX"),
        this.getInputFieldNumber(BASE_UNIT_TORQUE, "LoadYY"),
        this.getInputFieldNumber(BASE_UNIT_TORQUE, "LoadZZ")
      ]
    }
    else
    {
      arr = [
        this.getInputFieldNumber(BASE_UNIT_LENGTH, "LoadLength"),
        this.getInputFieldNumber(BASE_UNIT_FORCE_PER_DISTANCE, "LoadX"),
        this.getInputFieldNumber(BASE_UNIT_FORCE_PER_DISTANCE, "LoadY"),
        this.getInputFieldNumber(BASE_UNIT_FORCE_PER_DISTANCE, "LoadZ")
      ]
    }
    return (
      <React.Fragment>
        <CollapsableDevider name="Load" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Load} icon="weight" open={this.state.OpenLoad} update={()=> this.syncState({OpenLoad: !this.state.OpenLoad})}>
          {this.getInputFieldBool("IsLineLoad")}
          {this.getInputFieldBool("GlobalCoordinates")}
          {arr}          
        </CollapsableDevider>
      </React.Fragment>
    );
  }

  GetCableDockProperties = () =>
  {
    if (!this.state.DockInfos) { return null; }

    let changeDockProperty = (name, index, value) =>
    {
      this.runSetArrayCommand({ArrayName: "CableDockConnections", ArrayIndex: index, PropertyName: name, Value: value})
    }

    return (
      <React.Fragment>
                <CollapsableDevider name="CableDockProperties" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.CableDockProperties} icon="venus mars" open={this.state.CableDockPropertiesOpen} update={()=> this.syncState({CableDockPropertiesOpen: !this.state.CableDockPropertiesOpen})}>
                {this.state.DockInfos.map((val, index)=>
                {
                    return (<React.Fragment key={index}>
                      <FilteredFormGroup>
                      {this.filterBySearch( 
                        <UnitInput  name          = "SupportName"
                                    baseUnit      = {BASE_UNIT_BOOLEAN}
                                    size          = "mini"
                                    label         = {LocalizedStrings.CutCables}
                                    labelPosition = "right" 
                                    value         = {val.CutCables}
                                    fluid
                                    onUpdateData  ={(name, value, e) => { changeDockProperty("CutCables", index, value)}}/>,"CutCables")}
                        </FilteredFormGroup>
                        <Header>{LocalizedStrings.CableConnections}</Header>
                        <List>
                          {val.Connections.map(c=><List.Item key={c}>{c}</List.Item>)}
                        </List>
                        {val.Connections.length === 0 ? <i>{LocalizedStrings.NoCableConnections}</i> : null}
                    </React.Fragment>)
                    }
                  )}
                </CollapsableDevider>

      </React.Fragment>
    )
  }
  GetSupportProperties = () =>
  {
    let isGroundSupport = this.state.SupportType !== GEOMETRY_SUPPORT_TYPE_Ground
    return (
      <React.Fragment>
        <CollapsableDevider name="SupportProperties" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.Support} icon="upload" open={this.state.OpenSupport} update={()=> this.syncState({OpenSupport: !this.state.OpenSupport})}>
          {this.getInputFieldSelect("SupportType", SupportTypeOptions)}
          
        {isGroundSupport ? 
        <>
          <FilteredFormGroup>
            {this.getInputFieldSelect("RopeCrossSection",[...this.state.ropeCossSection,{
                                      text: LocalizedStrings.NoneColorCode,
                                      value: "",
                                    }]  )}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "RopeOffsetX", "mm", "1")}
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "RopeOffsetY", "mm", "1")}
            {this.getInputFieldNumber(BASE_UNIT_LENGTH, "RopeOffsetZ", "mm", "1")}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
            {this.getInputFieldNumber(BASE_UNIT_WEIGHT, "Capacity", "kg", "1")}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
            {this.getInputFieldBool("UpsideDown")}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
            {this.getInputFieldBool("CompensateChainShorten")}
          </FilteredFormGroup>
          </>
        :
        <>
          <FilteredFormGroup widths="equal">
            {this.getInputFieldNumber(BASE_UNIT_FORCE_PER_DISTANCE, "ResistanceX", undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_FORCE_PER_DISTANCE, "ResistanceY", undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_FORCE_PER_DISTANCE, "ResistanceZ", undefined, "1")}
            </FilteredFormGroup> 
            
            <FilteredFormGroup widths="equal">
            {this.getInputFieldNumber(BASE_UNIT_MOMENT_PER_DISTANCE, "ResistanceXX", undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_MOMENT_PER_DISTANCE, "ResistanceYY", undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_MOMENT_PER_DISTANCE, "ResistanceZZ", undefined, "1")}
          </FilteredFormGroup>
          <FilteredFormGroup widths="equal">
            {this.getInputFieldNumber(BASE_UNIT_FORCE, "CapacityX",undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_FORCE, "CapacityY", undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_FORCE, "CapacityZ", undefined, "1")}
            </FilteredFormGroup> 
            
            <FilteredFormGroup widths="equal">
            {this.getInputFieldNumber(BASE_UNIT_TORQUE, "CapacityXX", undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_TORQUE, "CapacityYY", undefined, "1")}
            {this.getInputFieldNumber(BASE_UNIT_TORQUE, "CapacityZZ", undefined, "1")}
          </FilteredFormGroup>
            {this.getInputFieldBool("AllowNegativeZ")}
            <Divider/>
            {this.getInputFieldBool("FrictionCheck")}
            {this.filterBySearch( <Form.Dropdown
                                    fluid
                                    search
                                    button
                                    text={LocalizedStrings.FrictionOptions}
                                    size      = "mini"
                                    name      = {"FrictionCheckName"}   
                                    label     = {LocalizedStrings.FrictionOptions}
                                    options   = {friction_options}  
                                    onChange  = {(e, {value})=>
                                    {
                                      this.runSetCommand([
                                        { PropertyName: "FrictionCheckValue", Value: friction_options_base[value].Value},
                                        { PropertyName: "FrictionCheckName", Value: friction_options_base[value].Name},
                                    ])
                                    }}
                                    style={{width: '50%'}}>
                                    </Form.Dropdown>,
                            "FrictionOptions")}
            <FilteredFormGroup widths="equal">
              {this.getInputFieldString("FrictionCheckName")}
            </FilteredFormGroup>
            <FilteredFormGroup widths="equal">
              {this.getInputFieldNumber(BASE_UNIT_NUMBER, "FrictionCheckValue")}
            </FilteredFormGroup>
        </>}
        </CollapsableDevider>
      </React.Fragment>
    );
  }

  GetGeometryOriginProperties = () =>
  {
    return (
      <React.Fragment>
        <CollapsableDevider name="GeometryOrigin" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.GeometryOrigin} icon="map pin" open={this.state.OpenGeometryOrigin} update={()=> this.syncState({OpenGeometryOrigin: !this.state.OpenGeometryOrigin})}>
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "AllowableForceX", "#", "0.1")}
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "AllowableForceY", "#", "0.1")}
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "AllowableForceZ", "#", "0.1")}
        </CollapsableDevider>
      </React.Fragment>
    );
  }

  GetWireProperty_Consumer = () =>
  {
    if(this.state.WireType === kWireType_Consumer)
    {
      return(
        <React.Fragment>
          {this.getInputFieldBool("ConsumerSupportsDimmer")}
          {this.getInputFieldNumber(BASE_UNIT_POWER, "ElectricalPayLoad", "", "0.01", "", this.state.TimeOffsetPresetPreset)}
          {this.getInputFieldNumber(BASE_UNIT_POWER, "AveragePayLoad", "", "0.01", "", this.state.TimeOffsetPresetPreset)}
          {this.getInputFieldNumber(BASE_UNIT_POWER, "BasePayLoad", "", "0.01", "", this.state.TimeOffsetPresetPreset)}
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "CosPhi", "#", "0.01", "", this.state.CosPhiPreset)}
          {this.getInputFieldNumber(BASE_UNIT_VOLTAGE, "VoltageRangeMin", "V", "5", "", this.state.VoltageMinPreset)}
          {this.getInputFieldNumber(BASE_UNIT_VOLTAGE, "VoltageRangeMax", "V", "5", "", this.state.VoltageMaxPreset)}

        </React.Fragment>)
    
    }
    else if(this.state.WireType === kWireType_Fuse)
    {
      return(
        <React.Fragment>
          {this.getInputFieldNumber(BASE_UNIT_NUMBER, "FuseCurrent", "A", "1", "", this.state.FuseCurrentPreset)}
          {this.getInputFieldSelect("FuseRating", FuseRatingOptions, this.state.FuseRatingPreset)}
          {this.getInputFieldBool("Dimmable")}
        </React.Fragment>)
    
    }
  }

  GetFixtureProperty_Gels = () =>
  {
    return(
      <React.Fragment>
     
      </React.Fragment>
    )
  }

  GetFixtureProperty = () =>
  {
    return(<>
            <CollapsableDevider name="FixtureData" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.FixturePropertiesData} icon="lightbulb" open={this.state.OpenFixturePropertiesData} update={()=> this.syncState({OpenFixturePropertiesData: !this.state.OpenFixturePropertiesData})}>
              <FilteredFormGroup>
                {this.getInputFieldNumber(BASE_UNIT_NUMBER, "FixtureId", "#", "1", "", this.state.FixtureIdPreset, 0)}
              </FilteredFormGroup>              
              <FilteredFormGroup>
                {this.getInputFieldSelect("CurrentMode", this.state.Modes, this.state.CurrentModePreset, true)}
              </FilteredFormGroup>
              {
                this.state.DmxFootPrint1 === 0
                ? null
                : this.getFixtureAddressField("1")
              }
              {
                this.state.DmxFootPrint2 === 0
                ? null
                : this.getFixtureAddressField("2")
              }
              {
                this.state.DmxFootPrint3 === 0
                ? null
                : this.getFixtureAddressField("3")
              }
              {
                this.state.DmxFootPrint4 === 0
                ? null
                : this.getFixtureAddressField("4")
              }
              
              </CollapsableDevider>
              <CollapsableDevider name="FixtureDesign" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.FixturePropertiesDesgin} icon="lightbulb outline" open={this.state.OpenFixturePropertiesDesgin} update={()=> this.syncState({OpenFixturePropertiesDesgin: !this.state.OpenFixturePropertiesDesgin})}>
              <FilteredFormGroup>
                {this.getInputFieldString("LampType")}
              </FilteredFormGroup>
              <FilteredFormGroup widths="equal">
                {this.getInputFieldString("Purpose")}
              </FilteredFormGroup>
              {
                this.state.BeamInfos.map((beamInfo, index) => {
                  return this.filterBySearch(this.getInputFieldBeams(beamInfo, index), beamInfo.GeometryName)
                })
              }
              {
                this.state.GeometryTransformList.map((obj, index) => 
                {
                  return(
                    <FilteredFormGroup key={index} >
                      {this.getInputGeometryTransform(obj, index)}
                    </FilteredFormGroup>
                  );
                })
              }
            </CollapsableDevider>
          </>)
  }

  GetDimensionProperty = () =>
  {
    return(<>
            <CollapsableDevider name="Dimension" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.DimensionHeader} icon="map pin" open={this.state.OpenDimensionHeader} update={()=> this.syncState({OpenDimensionHeader: !this.state.OpenDimensionHeader})}>
              <FilteredFormGroup>
                {this.getInputFieldLength("DimensionHeight", "100",)}
              </FilteredFormGroup>

            </CollapsableDevider>
          </>)
  }

  GetObjectPath = () =>
  {
    return(<>
  <CollapsableDevider name="ObjectPath" omit={this.state.searchFilter !== ""} header={LocalizedStrings.ObjectPath} icon="map pin" open={this.state.OpenObjectPath} update={() => this.syncState({ OpenObjectPath: !this.state.OpenObjectPath })}>
        <FilteredFormGroup>
          {this.getInputPropertyObjectPathIndex("ObjectPathCurrentPoint")}
          {this.getDisplayValueUnit("CountObjectPathPoint", this.state.ObjectPath.length, BASE_UNIT_NUMBER)}
          {this.getDisplayValueUnit("PathLength",this.state.PathLength, BASE_UNIT_LENGTH)}
        </FilteredFormGroup>
        {this.getInputPropertyObjectPath("X")}
        {this.getInputPropertyObjectPath("Y")}
        {this.getInputPropertyObjectPath("Z")}

            </CollapsableDevider>
          </>)
  }

  GetInfluenceLine = () =>
  {
    return(<>
            <CollapsableDevider name="InfluenceLine" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.InfluenceLine} icon="map pin" open={this.state.OpenInfluenceLine} update={()=> this.syncState({OpenInfluenceLine: !this.state.OpenInfluenceLine})}>
                {this.getInputFieldBool("ShowDu")}
                {this.getInputFieldBool("ShowDx")}
                {this.getInputFieldBool("ShowDy")}
                {this.getInputFieldBool("ShowDz")}
                {this.getInputFieldBool("ShowPhix")}
                {this.getInputFieldBool("ShowPhiy")}
                {this.getInputFieldBool("ShowPhiz")}
                {this.getInputFieldBool("ShowWorkload")}
                {this.getInputFieldBool("ShowWorkloadDeflection")}
                {this.getInputFieldBool("ShowWorkloadLine")}
                {this.getInputFieldBool("ShowFloorLoad")}
                {this.getInputFieldBool("ShowNx")}
                {this.getInputFieldBool("ShowVy")}
                {this.getInputFieldBool("ShowVz")}
                {this.getInputFieldBool("ShowMt")}
                {this.getInputFieldBool("ShowMby")}
                {this.getInputFieldBool("ShowMbz")}
                {this.getInputFieldBool("ShowWindLoadDirection")}
                {this.getInputFieldBool("ShowText")}
                {this.getInputFieldBool("ShowOnlySelected")}
                {this.getInputFieldSelect("ShowOnlyOneCrossSection",this.state.AvailableCrossSection)}
            </CollapsableDevider>
          </>)
  }

  getFixtureAddressField = (index = "1") =>
  {
      return  <>
                <FilteredFormGroup widths="equal">
                  {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxAddress" + index, "#", "1", this.state["DmxAddress" + index + "Preset"], false, 0, undefined, true /* Auto Increment */)}
                  {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxUniverse" + index, "#", "1",  this.state["DmxUniverse" + index + "Preset"], false, 0, undefined)}
                  {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxRelativeAddress" + index, "#", "1", this.state["DmxRelativeAddress" + index + "Preset"], false, 0, undefined, true/* Auto Increment */)}
                </FilteredFormGroup>
                <FilteredFormGroup widths="equal">
                  {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxFootPrint" + index, "#", "1", "", undefined, true, undefined, 0)}
                </FilteredFormGroup>
                <Portal open={this.state.ShowAdressWarn} onClose={() => {this.syncState({ShowAdressWarn: false})}}>
                  <Segment 
                    style={{
                      left: "40%",
                      position: "fixed",
                      top: "50%",
                      zIndex: 1000,
                    }}>
                      <Header>{LocalizedStrings.Warning}</Header>
                      <p>{LocalizedStrings.WrongFixtureAddressWarningText}</p>
                      <Button
                        content = {LocalizedStrings.Cancel}
                        negative
                        onClick={() => {this.syncState({ShowAdressWarn: false}); if(globalCallbacks.getSelectedProperties){globalCallbacks.getSelectedProperties()}}} />
                      <Button
                        content = {LocalizedStrings.Continue}
                        positive
                        onClick={(e) => {this.syncState({ShowAdressWarn: false}); this.updateFixtureData(e, "DmxRelativeAddress", index, true);}} />
                  </Segment>
                </Portal>
              </>
    
  }

  updateFixtureData = (e, propertyName, dmxIndex = "1", force = false) =>
  {

    if (propertyName === "DmxRelativeAddress" && this.state.changedValue > 512 && !force)
    {
      this.syncState({ShowAdressWarn: true})
      return;
    }

    let absAddress = ((Number(this.state["DmxUniverse" + dmxIndex]) - 1) * 512) + Number(this.state["DmxRelativeAddress" + dmxIndex]);

    this.runSetCommand({PropertyName: "DmxAddress" + dmxIndex, Value: absAddress}, e)
  }

  GetFixtureTypeProperty = () =>
  {
    return(<React.Fragment>
      <CollapsableDevider name="FixtureType" omit={ this.state.searchFilter !== ""} header={LocalizedStrings.FixtureTypeProperties} icon="lightbulb" open={this.state.OpenFixtureTypeProperties} update={()=> this.syncState({OpenFixtureTypeProperties: !this.state.OpenFixtureTypeProperties})}>
        <FilteredFormGroup>
          {this.getInputFieldSelect("CurrentMode", this.state.Modes, undefined, true)}
        </FilteredFormGroup>
        {
          this.state.DmxFootPrint1 === 0
          ? null
          : <FilteredFormGroup>
              {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxFootPrint1", "#", "1", "", undefined, true, undefined, 0)}
            </FilteredFormGroup>
        }
        {
          this.state.DmxFootPrint2 === 0 
          ? null
          : <FilteredFormGroup>
              {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxFootPrint2", "#", "1", "", undefined, true, undefined, 0)}
            </FilteredFormGroup>
        }
        {
          this.state.DmxFootPrint3 === 0
          ? null
          : <FilteredFormGroup>
              {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxFootPrint3", "#", "1", "", undefined, true, undefined, 0)}
            </FilteredFormGroup>
        }
        {
          this.state.DmxFootPrint4 === 0
          ? null
          : <FilteredFormGroup>
              {this.getInputFieldNumber(BASE_UNIT_NUMBER, "DmxFootPrint4", "#", "1", "", undefined, true, undefined, 0)}
            </FilteredFormGroup>
        }
        <FilteredFormGroup>
          {this.getInputFieldSelect("CurrentGobo", this.state.Gobos, undefined)}
        </FilteredFormGroup>
      </CollapsableDevider>
    </React.Fragment>)
  }

  openNewDmxModeModal = (modeName) =>
  {
    this.syncState({NewDmxModeName: modeName, openAddNewMode: true});
  }

  onAddNewDmxMode = (addMode) =>
  {
    if(addMode)
    {
      let properties =
      {
        Name            : this.state.NewDmxModeName,
        UUID            : this.state.ActiveUUID,
        DmxFootPrint1   : this.state.NewDmxFootPrint1,
        DmxFootPrint2   : this.state.NewDmxFootPrint2,
        DmxFootPrint3   : this.state.NewDmxFootPrint3,
        DmxFootPrint4   : this.state.NewDmxFootPrint4,
      };
      
      window.LR_AddNewDmxMode(properties);
      this.syncState({openAddNewMode: false, CurrentMode: this.state.NewDmxModeName});
    }
    else
    {
      this.syncState({openAddNewMode: false});
    }
    
  }

  getElectricalInputField = (name, value, options, arrayName, indexInArray, hotpatch) =>
  {
    let connection = value[value.UUID];

    let isHotpatch = connection.WireType === kWireType_HotpatchInput || connection.WireType === kWireType_HotpatchOutput
    if (hotpatch && !isHotpatch) {
        return null
    }
    if (!hotpatch && isHotpatch) {
        return null
    }

    return this.filterBySearch(<ElectricalObject 
      name={name} 
      value={value} 
      options={options} 
      arrayName={arrayName} 
      indexInArray={indexInArray} 
      searchFilter={this.state.searchFilter} 
      RessourceTypes={this.state.RessourceTypes}
      SymbolDefs={this.state.SymbolDefs}
      presetColor={this.props.presetColor}
      updateObjectConnections={this.updateObjectConnections}
      getInputElectricalProperty={this.getInputElectricalProperty}
    >
      {this.addCablePath(connection)}
    </ElectricalObject>, name)
  }

  addBridlePartsList = (
    parts,
    onlyAddTotalList = false,
    index = -1,
    atBasket = false,
    enableButtons = true,
    hideButtons = false) =>
  {
    let Count = {}
    for(let p of parts)
    {
      if(!Count[p.UUID]){
        Count[p.UUID] = 0
      }
      Count[p.UUID]++
    }

    let updateCount = async(entry, add) => 
    {
      let stuff = parts.map(e=>e.UUID)

      if(add)
      {
        stuff.push( entry.UUID )
      }
      else
      {
        let filtered = false
        stuff = stuff.filter(v=>
        {
          if(!filtered && v === entry.UUID)
          {
            filtered = true;
            return false
          }
          else
          {
            return true
          }
        })
      }
      
      if (index != -1) {
        window.LR_SetUsedSupportParts(
          {
            LegIdx : index,
            atBasket : atBasket, 
            Add: add,
            PartNam: entry.Name,
            PartUid: entry.UUID
          });
      }

    }   

    return(<>
    {
      onlyAddTotalList ? null :
    <Segment placeholder>
      <List as='ol'>
        {parts.map((e, i) => <List.Item as='li' key={i}>{ e.Name}</List.Item>)}  
      </List>
    </Segment>
    }

        <Table>
          <Table.Header>
          <Table.HeaderCell>
              {LocalizedStrings.NameCable}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {LocalizedStrings.CountCable}
            </Table.HeaderCell>
            {
              enableButtons && <Table.HeaderCell>
                {LocalizedStrings.CountCable}
              </Table.HeaderCell>
            }
          </Table.Header>
          <Table.Body>
          {
            this.state.CurrentInventory?.map(e=>
              {
                return <Table.Row key={e.UUID}>
                  <Table.Cell>
                  { " " + e.Name}
                  </Table.Cell>
                  <Table.Cell>
                    {Count[e.UUID]}
                  </Table.Cell>
                  {! hideButtons && <Table.Cell>                  
                    <Button.Group>
                      <Button disabled={!enableButtons} negative onClick={()=>{updateCount(e,false)}}>-</Button>
                      <Button disabled={!enableButtons} positive onClick={()=>{updateCount(e,true)}}>+</Button>
                    </Button.Group>                   
                  </Table.Cell> }
                </Table.Row>
              })
          }
        </Table.Body>
        </Table>
        </>
    )
  }

  addCablePath  = (connection) =>
  {
    let array = this.state.CurrentInventory

    let Count = {}
    for(let c of connection.SelectedCables)
    {
      if(Count[c.UUID])
      {
        Count[c.UUID]++
      }
      else
      {
        Count[c.UUID] = 1
      }
    }

    let UpdateCableCount = async(entry, add) => 
    {
      let stuff = connection.SelectedCables.map(e=>e.UUID)

      if(add)
      {
        stuff.push( entry.UUID )
      }
      else
      {
        let filtered = false
        stuff = stuff.filter(v=>
        {
          if(!filtered && v === entry.UUID)
          {
            filtered = true;
            return false
          }
          else
          {
            return true
          }
        })

      }
      window.LR_SetUsedCables(
        {
          Geometry: connection.Geometry,
          SelectedCables: stuff
        }
      )
    }

    

    return(<>
        <FilteredFormGroup>
        <List as='ol'>
          {connection.SelectedCables.map((e, i) => <List.Item as='li' key={i}>{connection.ConnectorType + " " +e.Name}</List.Item>)}  
        </List>
        </FilteredFormGroup>
        <Table>
          <Table.Header>
          <Table.HeaderCell>
              {LocalizedStrings.NameCable}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {LocalizedStrings.CountCable}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {LocalizedStrings.CountCable}
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
          {
            array.map(e=>
              {
                return <Table.Row key={e.UUID}>
                  <Table.Cell>
                  {connection.ConnectorType + " " + e.Name}
                  </Table.Cell>
                  <Table.Cell>
                    {Count[e.UUID]}
                    </Table.Cell>
                    <Table.Cell>
                    <Button.Group>
                    <Button disabled={!connection.ManuallyBuildCable} negative onClick={()=>{UpdateCableCount(e,false)}}>-</Button>
                    <Button disabled={!connection.ManuallyBuildCable} positive onClick={()=>{UpdateCableCount(e,true)}}>+</Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              })
            }
        </Table.Body>
        </Table>
        </>
    )
  }


  getElectricalWireField = (connectorId) =>
  {
    return(<ElectricalWireField  
      className="electrical-wire-field"                          
      connectorId   = {connectorId}
                                 wireType      = {this.state.WireType}
                                 signalType    = {this.state.SignalType}
                                 connectorType = {this.state.ConnectorType}
                                 connections   = { this.state.ActiveConnections }
                                 onValueChanged= {this.updateConnections}
                                 options       = {this.state.ElectricalObjects}
                                 onKeyDown     = {this.props.onKeyDown}/>)
  }

  getInputFieldNumber = (baseUnit, name, unitStr, step = "1", presetObj, nameOverwrite, min = undefined, max = undefined, increment = false) =>
  {
    if (this.state["Had" + name] === false) { return null; }
    let presetColor = "";
    if (presetObj && presetObj.Color)
    {
      presetColor = rgb2hex([presetObj.Color.X, presetObj.Color.Y, presetObj.Color.Z]);
    }
    
    let placeholder = ""
    if(this.state.SelectedObjectCount > 1)
    {
      placeholder = Math.round(this.state["Min"+name], 2) + "..." + Math.round(this.state["Max"+name], 2)
    }

    let showPlaceHolder = false
    if(this.state["Min" + name] !== this.state["Max" + name])
    {
      showPlaceHolder = true
    }

    let onNumberChanged = (value, min, max) =>
    {
      let changedValue          = Number(value);

      if(min !== undefined && changedValue < min) { changedValue = min; }
      if(max !== undefined && changedValue > max) { changedValue = max; }


      return changedValue
    }

    return this.filterBySearch( 
      <UnitInput  name          = {name} 
                  baseUnit      = {baseUnit}
                  unitStr       = {unitStr}
                  presetColor   = {presetColor}                
                  size          = "mini"
                  placeholder   = {placeholder}
                  label         = { nameOverwrite ? nameOverwrite : LocalizedStrings[name]} 
                  value         = {this.state[name]} 
                  labelPosition = "right" 
                  showPlaceholder = {showPlaceHolder}
                  step          = {step} 
                  fluid
                  min           = {min}
                  max           = {max}
                  onUpdateData  = {(name, value, event) => {this.updateData(event, name, onNumberChanged(value, min, max), increment )}} 
                  data-removepreset   = {presetObj && Object.keys(presetObj).length > 0 ? presetObj.UUID : 0}
                  data-removepropname = {name}
      />,nameOverwrite ? nameOverwrite : name 
    );
  }

  getInputElectricalProperty = (baseUnit,name, ArrayName, index, options) =>
  {
    let changeData = (n, value, event) =>
    {
      this.runSetArrayCommand({ArrayName: ArrayName, ArrayIndex: index, PropertyName: name, Value: value}, event)
    }

    let changeDataSelect = (value, event) =>
    {
      this.runSetArrayCommand({ArrayName: ArrayName, ArrayIndex: index, PropertyName: name, Value: value}, event)
    }
    
    let entry = this.state[ArrayName][index]
    let value = entry[entry.UUID][name]

    return this.filterBySearch( 
      <UnitInput
        className={"unit-input-"+name}  
        name          = {name} 
                  key={ArrayName + name + index}
                  baseUnit      = {baseUnit}
                  size          = "mini"
                  options       = {options}
                  label         = { LocalizedStrings[name]} 
                  value         = {value} 
                  labelPosition = "right" 
                  fluid
                  onUpdateData  = {changeData} 
                  updateData  = {changeDataSelect} 
      />
    , name);

  }

  getInputPropertyObjectPath = (name) =>
  {

    let changeData = (n, value, event) =>
    {
      // Clone ObjectPath deep  
      let newObjectPath = JSON.parse(JSON.stringify(this.state.ObjectPath));
      newObjectPath[this.state.ObjectPathCurrentPoint].Point[name] = value

      window.LR_SetObject({UUID: this.state.ActiveUUID, ObjectPath: newObjectPath})
    }    

    let currentValue = 0
    if( ! (this.state.ObjectPath.length > this.state.ObjectPathCurrentPoint && this.state.ObjectPathCurrentPoint >=0))
    {
      return
    }
    
    currentValue = this.state.ObjectPath[this.state.ObjectPathCurrentPoint].Point[name]

    return this.filterBySearch( 
      <UnitInput  name          = {name} 
                  key={name}
                  
                  baseUnit      = {BASE_UNIT_LENGTH}
                  size          = "mini"
                  label         = { LocalizedStrings[name]} 
                  value         = {currentValue} 
                  labelPosition = "right" 
                  fluid
                  onUpdateData  = {changeData} 
      />
    , name);

  }

  getInputPropertyObjectPathIndex = (name) =>
  {

    let changeData = (n, value, event) =>
    {
      this.syncState({ObjectPathCurrentPoint: value})
    }    
    return this.filterBySearch( 
      <UnitInput  name          = {name} 
                  key={name}
                  min={0}
                  baseUnit      = {BASE_UNIT_NUMBER}
                  size          = "mini"
                  label         = { LocalizedStrings[name]} 
                  value         = {this.state.ObjectPathCurrentPoint} 
                  labelPosition = "right" 
                  fluid
                  onUpdateData  = {changeData} 
      />
    , name);

  }

  getInputFieldDate = (name) =>
  {
    return this.filterBySearch( 
      <Form.Field>
      <label>{LocalizedStrings[name]}</label>
      <DatePicker isClearable
                  className="lr-date-picker"
                  onChange={(date, e)=>{ this.updateDate(name, date, e )}} 
                  selected={(Date.parse(this.state[name]))}  
                  dateFormat={'MM/dd/yyyy h:mm aa'} />
      </Form.Field>
    , name);
  }

  updateDate = (name, value, e) => 
  {
    this.runSetCommand({PropertyName: name, Value: value?.toISOString() ?? ""}, e)
  }

  getInputFieldBeams = (beam, index) =>
  {
    let updateData = (name, value, event) => 
    {
      this.runSetArrayCommand({ArrayName: "BeamProperties", ArrayIndex: index, PropertyName: name, Value: value}, event)
    }

    let updateColor = (cie) =>
    {
      this.runSetArrayCommand([
        { 
          ArrayName: "BeamProperties", 
          ArrayIndex: index, 
          PropertyName: "GelName", 
          Value: cie.gelName
        },
        { 
          ArrayName: "BeamProperties", 
          ArrayIndex: index, 
          PropertyName: "GelID", 
          Value: cie.gelID
        },
        { 
          ArrayName: "BeamProperties", 
          ArrayIndex: index, 
          PropertyName: "GelManufacturer", 
          Value: cie.gelManufacturer
        },
        { 
          ArrayName: "BeamProperties", 
          ArrayIndex: index, 
          PropertyName: "LightColor", 
          Value: cie.fx + "; " + cie.fy + "; " + cie.f_Y},
      ])
    }

    let updateGobo = (e, {value}) =>
    {
      this.runSetArrayCommand({ ArrayName: "BeamProperties", ArrayIndex: index, PropertyName: "CurrentGobo", Value: value}, e)
    }
    return (
      <React.Fragment key={beam.UUID}>
        <Divider horizontal>
          {beam.GeometryName}
        </Divider>
        <FilteredFormGroup widths="equal">
          <UnitInput  name="Dimmer"
                      label         = {LocalizedStrings.Dimmer}
                      baseUnit      = {BASE_UNIT_ZERO_ONE}
                      value         = {beam.Dimmer}
                      step          = {0.1}
                      onUpdateData  = {updateData}/>
          <UnitInput  name="LampRotation"
                  label         = {LocalizedStrings.LampRotation}
                  baseUnit      = {BASE_UNIT_ANGLE}
                  value         = {beam.LampRotation}
                  onUpdateData  = {updateData}/>
        </FilteredFormGroup>
        <FilteredFormGroup widths="equal">
          <UnitInput  name="BeamAngle"
                    label         = {LocalizedStrings.BeamAngle}
                    baseUnit      = {BASE_UNIT_ANGLE}
                    value         = {beam.BeamAngle}
                    onUpdateData  = {updateData}/>
          <UnitInput  name="FieldAngle"
                    label         = {LocalizedStrings.FieldAngle}
                    baseUnit      = {BASE_UNIT_ANGLE}
                    value         = {beam.FieldAngle}
                    onUpdateData  = {updateData}/>
        </FilteredFormGroup>
        <FilteredFormGroup widths="equal">
          <UnitInput  name          = "FrameSizeX"
                      label         = {LocalizedStrings.FrameSizeX}
                      baseUnit      = {BASE_UNIT_LENGTH}
                      value         = {beam.FrameSizeX} 
                      onUpdateData  = {updateData}/>
          <UnitInput  name          = "FrameSizeY"
                      label         = {LocalizedStrings.FrameSizeY}
                      baseUnit      = {BASE_UNIT_LENGTH}
                      value         = {beam.FrameSizeY} 
                      onUpdateData  = {updateData}/>
        </FilteredFormGroup>
        <FilteredFormGroup widths="equal">
          <ColorInputField  name            = "LightColor"
                            size            = "mini"
                            label           = {LocalizedStrings.LightColor} 
                            labelPosition   = 'right' 
                            colorX          = {beam.LightColorX} 
                            colorY          = {beam.LightColorY} 
                            colorL          = {beam.LightColorZ} 
                            onColorChanged  = {updateColor} 
                            icons = {<Icon link name="line graph" onClick={(e) => { e.preventDefault(); window.LR_ShowModalDialog({Dialog:"AlignValueDialog", Property:"BeamProperties " + index + " LightColor", BaseUnit: BASE_UNIT_COLOR, InitValue: beam.LightColorX + ";" + beam.LightColorY + ";" + beam.LightColorZ})}}/>}/>
        </FilteredFormGroup>
        <FilteredFormGroup widths="equal">
        <ManufacturerField Manufacturer={beam.GelManufacturer}
            onManufacturerChange={(arg, e)=>
            {
              updateColor({
                gelManufacturer: arg.selectedManufacturerName,
                gelName: arg.selectedGelName,
                fx: arg.fx,
                fy: arg.fy,
                f_Y: arg.f_Y,
              })
            }}/>
      <GelsField         Manufacturer={beam.GelManufacturer} SelectedGel={beam.GelID}
      onGelChange={(arg, e)=>
        {
          
            updateColor({
              gelManufacturer: arg.selectedManufacturerName,
              gelName: arg.selectedGelName,
              gelID: arg.selectedGelID,
              fx: arg.fx,
              fy: arg.fy,
              f_Y: arg.f_Y,
            })
        }
      }/>
        </FilteredFormGroup>
        <FilteredFormGroup widths="equal">
          <Form.Dropdown  fluid 
                        style     ={{width: 'auto'}}
                        label     = {LocalizedStrings.CurrentGobo}
                        options   ={this.state.Gobos}
                        value     ={beam.CurrentGobo}
                        onChange  ={updateGobo}/>
        </FilteredFormGroup>
        <FilteredFormGroup widths="equal">
          <UnitInput  fluid
                      label = {LocalizedStrings.ThrowDistance}
                      readOnly
                      baseUnit = {BASE_UNIT_LENGTH}
                      value = {beam.ThrowDistance}/>
        </FilteredFormGroup>
      </React.Fragment>
    )
  }
  
  getInputGeometryTransform = (geometryTransform, index) =>
  {
    let unit = (geometryTransform.TransformType < 3) ? BASE_UNIT_LENGTH : BASE_UNIT_ANGLE;
    let presetObj = geometryTransform.Preset;

    let presetColor = "";
    if (presetObj && presetObj.Color)
    {
      presetColor = cie2hex(presetObj.Color);
    }

    return this.filterBySearch(
      <UnitInput    name          = {geometryTransform.PropertyName}
                    label         = {geometryTransform.PropertyLabel}
                    value         = {geometryTransform.Value}
                    baseUnit      = {unit}
                    presetColor   = {presetColor}
                    onUpdateData  = {(name, value, e) => { this.updateGeometryTransform(e, geometryTransform, value)}} 
      />, 
      geometryTransform.PropertyLabel)
  }
  
  getInputFieldTransform = (name, unitStr, step = "1", labelColor, baseUnit, noStepper = false) => 
  {
    
    if (this.state["Had" + name] === false) { return null; }
    let placeholder = ""
    if (this.state.SelectedObjectCount > 1) 
    {
      placeholder = Math.round(this.state["Min" + name], 2) + "..." + Math.round(this.state["Max" + name], 2);
    }
    let presetColor = "";

    let presetObj = this.state["Preset" + name]
    if (presetObj && presetObj.Color)
    {
      presetColor = cie2hex(presetObj.Color);
    }

    let finalValue = 0
    if (this.props.globalSettings && this.props.globalSettings.App_GlobalTransform)
    {
      finalValue = this.state.GlobaleTransform[name];
    }
    else
    {
      finalValue = this.state[name];
    }

    let showPlaceHolder = false
    if(this.state["Min" + name] !== this.state["Max" + name])
    {
      showPlaceHolder = true
    }

    return this.filterBySearch(
      <UnitInput  presetColor = {presetColor}
                  name        = {name}
                  unitStr     = {unitStr}
                  baseUnit    = {baseUnit}
                  value       = {finalValue}
                  label       = {LocalizedStrings[name]}
                  placeholder = {placeholder}
                  showPlaceholder = {showPlaceHolder}
                  noStepper ={noStepper}
                  size        = "mini"
                  step        = {step}
                  labelColor  = {labelColor ? labelColor : undefined}
                  onUpdateData = {(name, value, e) => { this.updateTransform(value, name, e) }}
                  labelClicked = {() => {if (this.state.SelectedObjectCount > 1) {window.LR_ShowModalDialog({Dialog:"AlignValueDialog", Property: name, BaseUnit: baseUnit})}}}>
        
      </UnitInput> 
    ,name)
  }

  getInputFieldLength(name, step="1", labelColor="", baseUnit, unitStr="")
  {
    if (this.state["Had" + name] === false) { return null; }

    let showPlaceHolder = false
    if(this.state["Min" + name] !== this.state["Max" + name])
    {
      showPlaceHolder = true
    }

    let placeholder = ""
    if (this.state.SelectedObjectCount > 1) 
    {
      placeholder = Math.round(this.state["Min" + name], 2) + "..." + Math.round(this.state["Max" + name], 2);
    }

    return (this.filterBySearch(<UnitInput  name        = {name}
                        unitStr     = {unitStr}
                        baseUnit    = {baseUnit}
                        value       = {this.state[name]}
                        label       = {LocalizedStrings[name]}
                        size        = "mini"
                        step        = {step}
                        placeholder = {placeholder}
                        showPlaceholder = {showPlaceHolder}
                        labelColor  = {labelColor ? labelColor : undefined}
                        onUpdateData = {(name, value, e) => { this.updateLength(value, name, e); }}
                        labelClicked = {() => {if (this.state.SelectedObjectCount > 1) {window.LR_ShowModalDialog({Dialog:"AlignValueDialog", Property: name, BaseUnit: baseUnit})}}}>

            </UnitInput>, name))
  }

  updateLength = (newValue, name, e=undefined) => 
  {
    this.syncState({[name]: newValue},0)
    this.runSetCommand({PropertyName: name, Value: newValue}, e)
  }

  updateTransform = (newValue, name, e=undefined) => 
  {
    let propName = name;
    if (this.props.globalSettings.App_GlobalTransform)
    {
      propName = "Global" + name;
    }
    this.runSetCommand({PropertyName: propName, Value: newValue}, e)
  }

  getDisplayValueUnit = (name, value, baseUnit, onClickUnit, backgroundColor = "") => 
  {
    return this.filterBySearch(<UnitInput   name          = {name}
                        baseUnit      = {baseUnit}
                        size          = "mini"
                        label         = {LocalizedStrings[name]}
                        value         = {value}
                        labelPosition = "right"
                        fluid
                        readOnly
                        labelClicked  = {onClickUnit}
                        presetColor   = {backgroundColor} />, name)
  }

  getDisplayValueString = (name, value, labelName, onClickUnit) => 
  {
    return this.filterBySearch( <Form.Input  name            =   {name}
          labelPosition   =   "right"
          size            = "mini"
          fluid   
          readOnly                   
          value           =   {value}
          label           =   {LocalizedStrings[name]}>
              <input style={{backgroundColor: this.props.presetColor ?? ""}}/>
              <Label  onClick={onClickUnit}>{labelName}</Label>
        </Form.Input>, name)
  }

  getInputFieldSelect = (name, option, preset, additions = false, multiple = false, isForcedCrossSection = false) =>
  {
    if (this.state["Had" + name] === false) { return null; }
    let color = "";
    if (preset && preset.Color)
    {
      color = rgb2hex([preset.Color.X, preset.Color.Y, preset.Color.Z]);
    }
    let options = option ?  [...option] : []

    let value = this.state[name]
    if(this.state["Is" + name] === false)
    {
      options.push({
        text:LocalizedStrings.Multiple,
        value:"<multiple>",
        key:"<multiple>",
      })

      value = "<multiple>"
    }

    let update = (e, data) =>
    {
      if(data.value  === "<Download PrintLabel>")
      {
        this.syncState({resourceSelectorOpen:true})
      }
      else if(data.value === "<Add new picture>")
      {
        window.LR_ImportTexture()
      }
      else if(data.value  !== "<multiple>")
      {
        this.updateDataNow(e, data)
      }
    }

    if (isForcedCrossSection) {
      let filteredOptions = [...option]
      
      if(this.state.forcedCrossSectionSearch.length > 0) {
        filteredOptions = filteredOptions.filter((option) => option.text.toLowerCase().includes(this.state.forcedCrossSectionSearch.toLowerCase()));
      }
      
      // Group options by manufacturer
      const groupedOptions = filteredOptions.reduce((groups, option) => {
        const manufacturer = option.text.split(" - ")[0];
        if (!groups[manufacturer]) {
          groups[manufacturer] = [];
        }
        groups[manufacturer].push(option);
        return groups;
      }, {});
  
      return this.filterBySearch(
      <Form.Dropdown 
        search
        fluid
        size     = "mini"
        label    = {LocalizedStrings[name]} 
        style    = {{ backgroundColor: color, padding: 0 }}
        text     = {this.state.forcedCrossSectionSearch ? this.state.forcedCrossSectionSearch : value === "" ? LocalizedStrings.UseFromGeometry : value}
        name     = {name}
        allowAdditions = { additions || {} }
        data-removepreset = {preset && Object.keys(preset).length > 0 ? preset.UUID : 0}
        data-removepropname = {name}
        onSearchChange = {(e) => this.setState({forcedCrossSectionSearch: e.target.value})}
        onOpen={() => this.setState({ isDropdownOpen: true })}
        onClose={() => this.setState({ isDropdownOpen: false , forcedCrossSectionSearch: "", nestedSearch: ""})}
        open={this.state.isDropdownOpen}>
          <Dropdown.Menu style={{width: "100%", maxHeight: "30rem", overflowY : "auto",}}>
            {Object.keys(groupedOptions).map((manufacturer) => {
              let childOptions = [...groupedOptions[manufacturer]];
              if(this.state.nestedSearch.length > 0) {
                childOptions = childOptions.filter((option) => option.text.toLowerCase().includes(this.state.nestedSearch.toLowerCase()));
              }

              if(manufacturer === LocalizedStrings.UseFromGeometry){
                return ( <Dropdown.Item
                  key     = "EMPTY"
                  text    = {LocalizedStrings.UseFromGeometry}
                  value   = ""
                  onClick = {(e, data) => {
                    let newData = {...data, name: name}; 
                    update(e, newData);
                    this.setState({ isDropdownOpen: false });
                    }}
                  />)
              }else{
              return (
              <Dropdown 
                search
                size      = "mini"
                className = "link item" 
                pointing  = "left" 
                key       = {manufacturer} 
                text      = {manufacturer ? manufacturer : LocalizedStrings.UnknownManufacturers}
                onClose={() => this.setState({ nestedSearch: ""})}
                onSearchChange = {(e) => this.setState({nestedSearch: e.target.value})}>
                  <Dropdown.Menu  style = {{maxHeight: "20rem", overflowY : "auto", position: "static", width: "min-content"}} >
                  {childOptions.map((item) => ( 
                    <Dropdown.Item
                      key     = {item.key}
                      value   = {item.value}
                      text    = {item.value} 
                      onClick = {(e, data) => {
                        let newData = {...data, name: name}; 
                        update(e, newData);  this.setState({ isDropdownOpen: false, forcedCrossSectionSearch: "", nestedSearch: "" })}}
                      />
                  ))}
                </Dropdown.Menu> 
              </Dropdown>
            )}})}
          </Dropdown.Menu>
      </Form.Dropdown>, name)
      ;
    }
    
    return this.filterBySearch( 
        <Form.Dropdown
            className = {name === "Layer" ? "fixLayerDropdown" : ""}
            fluid
            search
            allowAdditions = { additions || {} }
            size      = "mini"
            multiple = {multiple}
            data-removepreset = {preset && Object.keys(preset).length > 0 ? preset.UUID : 0}
            data-removepropname = {name}
            name      = {name}   
            value     = {value}
            label     = {LocalizedStrings[name]}
            options   = {options}  
            onChange  = {update}
            onAddItem = {this.handleAddition}
            style={{backgroundColor: color, padding: 0}}>
            </Form.Dropdown>
    , name);
  }

  getInputFieldString = (name, readOnly = false, disabled = false) =>
  {
    if (this.state["Had" + name] === false) { return null; }
    let color = "";
    let preset = this.state["Preset"+name] 
    if (preset && preset.Color)
    {
      color = cie2hex(preset.Color)
    }

    let placeholder =""
    if(this.state.SelectedObjectCount > 1)
    {
      placeholder = LocalizedStrings.Multiple
    }

    let commitID = this.state["CommitID"+name] 

    return this.filterBySearch(
      <UnitInput      name = {name}
                      LRIDFIELD={"_OIP_"+name}
                      commitid={commitID}
                      baseUnit = {BASE_UNIT_STRING}
                      placeholder = {placeholder}
                      size = "mini"
                      disabled = {disabled}
                      label = {LocalizedStrings[name]}
                      labelPosition = "right"
                      value = {this.state[name]}
                      fluid
                      presetColor = {color}
                      onKeyDown = {(e) => {if(e.keyCode === 13){this.updateData(); e.target.blur()}}}
                      onUpdateData = {(name, value, e) => { this.updateData(e, name, value)}}
                      onStateUpdate = {(name, value, e) => {this.syncState({[name]: value})}}/> // If having more than one object selected, it wont have any value in the state, because LR_GetObjectProperties() excludes multiple strings sometimes
    , name)
  }

  getInputFieldPosition = (name) =>
  {
    if (this.state["Had" + name] === false) { return null;}

    return this.filterBySearch(
      <Form.Input   name={name}          
                    fluid
                    size="mini"
                    label = {LocalizedStrings[name]}
                    value={this.state[name]}
                    onChange={this.onValueChanged}
                    onBlur={(e) => {e.preventDefault(); window.LR_GroupSelected({Name: this.state.changedValue})}}
                    onKeyDown={(e) => {if(e.keyCode === 13) { e.target.blur()}}}
                    />
    ,name)
  }

  getInputFieldBool = (name, disabled = false) =>
  {
    if (this.state["Had" + name] === false) { return null; }
    let color = "";
    let preset = this.state["Preset"+name]
    if (preset && preset.Color)
    {
      color = cie2hex(preset.Color)
    }

    let value = this.state[name];
    if(typeof(value) !== "boolean") {value = false}

    if(this.state["Max"+name] !== this.state["Min"+name])
    {
      return  <Checkbox    
                  style     = {{marginLeft:"0.5rem"}}
                  fluid  
                  disabled  = {disabled}
                  name      = {name} 
                  checked   = {value}
                  onChange  = {this.updateDataCheckbox}
                  size      = "mini"
                  label     = {<label style={{color: color}}>{LocalizedStrings[name]}</label>} 
                  data-removepreset = {preset && Object.keys(preset).length > 0 ? preset.UUID : 0}
                  data-removepropname = {name}
                  defaultIndeterminate = {true}>
              </Checkbox>;
    }

    return this.filterBySearch( 
      <Form.Checkbox name      = {name} 
      fluid ="true"
      size      = "mini"
      disabled = {disabled}
      label     = {<label style={{color: color}}>{LocalizedStrings[name]}</label>} 
      checked   = {value}
      onChange  = {this.updateDataCheckbox} 
      data-removepreset = {preset && Object.keys(preset).length > 0 ? preset.UUID : 0}
      data-removepropname = {name}>
      </Form.Checkbox>
    , name);
  }

  getInputFieldBoolWithIcon = (name, iconName) =>
  {
    if (this.state["Had" + name] === false) { return null; }
    let preset = this.state["Preset"+name]


    let value = this.state[name];

    if(typeof(value) !== "boolean") {value = false}

    return this.filterBySearch(
      <Button
        onClick = { (e, d) => this.updateDataNow(e, {name: name, value: !value}) }
        color = { value ? "grey" : "white" }
        type = "button">
        <Icon
          name = {iconName}
          color = {value ? "white" : "black"}

          data-removepreset = {preset && Object.keys(preset).length > 0 ? preset.UUID : 0}
          data-removepropname = {name}>
        </Icon>
      </Button>
    , name);
  }

  getInputFieldColor = (name) =>
  {
    if (this.state["Had" + name] === false) { return null; }
    if( ! this.state[name]) {return null}
    return this.filterBySearch( 
      <ColorInputField  name            = {name}
                        size            = "mini"
                        label           = {LocalizedStrings[name]} 
                        labelPosition   = 'right' 
                        colorX          = {this.state[name]["X"]} 
                        colorY          = {this.state[name]["Y"]} 
                        colorL          = {this.state[name]["Z"]} 
                        onColorChanged  = {this.onColorChanged(name)} 
                        icons = {<Icon name="line graph" onClick={(e) => {e.stopPropagation(); e.preventDefault(); window.LR_ShowModalDialog({Dialog:"AlignValueDialog", Property:name, BaseUnit: BASE_UNIT_COLOR})}}/>}/>
    , name);
  }

  renderAddModeModal = () =>
  {
    return(<LRModal
      open          = {this.state.openAddNewMode} 
      title         = {LocalizedStrings.AddNewDmxMode}
      onOkClick     = {() => {this.onAddNewDmxMode(true)}}
      onCancelClick = {() => {this.onAddNewDmxMode(false)}}>
      <Form>
          <Form.Input name      = "NewDmxModeName"
                      label     = {LocalizedStrings.NewDmxModeName}
                      value     = {this.state.NewDmxModeName}
                      onChange  = {(e, {name, value}) => {this.syncState({[name]: value})}}>
          </Form.Input>
          <Form.Input name      = "NewDmxFootPrint1"
                      type      = "number"
                      label     = {LocalizedStrings.DmxFootPrint1}
                      value     = {this.state.NewDmxFootPrint1}
                      onChange  = {(e, {name, value}) => {this.syncState({[name]: value})}}>
          </Form.Input>
          <Form.Input name      = "NewDmxFootPrint2"
                      type      = "number"
                      label     = {LocalizedStrings.DmxFootPrint2}
                      value     = {this.state.NewDmxFootPrint2}
                      onChange  = {(e, {name, value}) => {this.syncState({[name]: value})}}>
          </Form.Input>
          <Form.Input name      = "NewDmxFootPrint3"
                      type      = "number"
                      label     = {LocalizedStrings.DmxFootPrint3}
                      value     = {this.state.NewDmxFootPrint3}
                      onChange  = {(e, {name, value}) => {this.syncState({[name]: value})}}>
          </Form.Input>
          <Form.Input name      = "NewDmxFootPrint4"
                      type      = "number"
                      label     = {LocalizedStrings.DmxFootPrint4}
                      value     = {this.state.NewDmxFootPrint4}
                      onChange  = {(e, {name, value}) => {this.syncState({[name]: value})}}>
          </Form.Input>
      </Form>
    </LRModal>)
  }

  updateDataNow = (e, {name, value}) =>
  {
    this.runSetCommand({PropertyName: name, Value: value}, e)
  }

  updateDataCheckbox = (e, data) =>
  {
    this.runSetCommand({PropertyName: data.name, Value: data.checked}, e)
  }

  handleAddition = (e, {name, value}) => 
  {
    let prop = {
      Name: value
    };
    
    if (name === "Class")
    {
      window.LR_AddNewClass(prop).then(val => {
        this.runSetCommand({PropertyName: "Class", Value: val.UUID}, e)
      });
    }
    else if (name === "Layer")
    {
      window.LR_AddNewLayer(prop).then(val => {
        this.runSetCommand({PropertyName: "Layer", Value: val.UUID}, e)
      });
    }
    else if (name === "LinkedContainer")
    {
      window.LR_AddNewTruck(prop).then(val => {
        this.runSetCommand({PropertyName: "LinkedContainer", Value: val.UUID}, e)      });
    }
    else if (name === "CurrentMode")
    {
      this.openNewDmxModeModal(value);
    }
    
  }

  //value: index of the ElectricalObjects array
  //connectorId: index of the connector we are changing
  updateConnections = (e, obj) =>
  {
    let name = obj.name;
    let value = obj.value;
    let connectorId = obj.connectorId;
    //Changing a connection
    if (connectorId !== undefined)
    {
      if (Array.isArray(value) && value.length === 0)
      {
        window.LR_ClearElectricalConnection({Objects: this.state.SelectedObjects, Socket: connectorId})
        return;
      }
      else
      {
        //Connection removed
        let changedValue = this.state.ActiveConnections[connectorId].Connections.filter(x => !value.includes(x))
        let prop = []
        //Get the old connection of removed index
        changedValue.forEach((key) => {
          let changedConnection = this.getElectricalObjectByKey(key);
          prop.push(...this.state.SelectedObjects.map(obj => {
            return {
              From: {
                UUID: obj,
                Socket: connectorId
              },
              To: {
                UUID: changedConnection.UUID,
                Socket: changedConnection.Socket
              }
            }
          }))
        });
        if (prop.length > 0)
        {
          window.LR_RemoveElectricalConnection(prop);
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////
  
        //Connection added
        let added = value.filter(x => !this.state.ActiveConnections[connectorId].Connections.includes(x));
        prop = []
        added.forEach((key) => {
          let con = this.getElectricalObjectByKey(key);
          prop.push(...this.state.SelectedObjects.map(obj => {
            return {
              From: {
                UUID: obj,
                Socket: connectorId
              },
              To: {
                UUID: con.UUID,
                Socket: con.Socket
              }
            }
          }))
        });
        if (prop.length > 0)
        {
          window.LR_AddElectricalConnection(prop);
        }
      }

      ////////////////////////////////////////////////////////////////////////////////////////////////

      this.syncState(state => {
        let list = state.ActiveConnections;
        list[connectorId].Connections = value;

        return {
          ActiveConnections: list
        }
      });
    }
    //Changing other electrical properties
    else
    {
      this.runSetCommand({PropertyName: name, Value: value}, e)
    }
  }

  getElectricalObjectByKey(key) {
    return this.state.ElectricalObjects.find(obj => obj.key === key);
  }

  updateObjectConnections = (e, obj, wire, existingConnections) =>
  {
    if (obj.value.length === 0)
    {
      //Delete connection
      let prop = this.state.SelectedObjects.map(obj => {
        return {
          Object: obj,
          Wire: wire
        }
      })

      window.LR_RemoveElectricalObjectConnection(prop);
    }
    else if (obj.value.length < existingConnections.length)
    {
      let diff = existingConnections.filter(x => !obj.value.includes(x.Object + x.Wire))

      //Delete connection
      if (diff.length === 1)
      {
        let prop = this.state.SelectedObjects.map(obj => {
          return {
            Object: obj,
            Wire: wire,
            LinkedObject: diff[0].Object,
            LinkedWire: diff[0].Wire
          }
        }) 
        

        window.LR_RemoveElectricalObjectConnection(prop);
      }
    }
    else
    {
      let theOption = obj.options.find(op => op.key === obj.value[obj.value.length-1])


      let prop = this.state.SelectedObjects.map(obj => {
        return {
          From: {
            Object: obj,
            Wire: wire
          },
          To: {
            Object: theOption.Object,
            Wire: theOption.Wire
          }
        }
      })
      
      window.LR_AddElectricalObjectConnection(prop);
    }
  }

  updateData = (e, name, value, increment) => 
  { 
    this.runSetCommand({PropertyName: name, Value: value, Increment: increment}, e)
    if(e && e.preventDefault){ e.preventDefault() }
  }

  updateGeometryTransform = (e, geometryTransform, newValue) => 
  {
    let value = newValue;
    //let altKey = e ? e.altKey : false

    let transformName = geometryTransform.GeometryName+this.getSuffixForTransformType(geometryTransform.TransformType)

    this.runSetCommand({PropertyName: transformName, Value: value}, e)
  }

  getSuffixForTransformType = (type) =>
  {
    switch(type)
    {
      case 0: return "TX";
      case 1: return "TY";
      case 2: return "TZ";
      case 3: return "RX";
      case 4: return "RY";
      case 5: return "RZ";
      default:  console.error("getSuffixForTransformType failed"); return ""
    }
  }

  onRemovePresetEntry = (presetUuid, propertyName) => 
  {
    window.LR_RemovePresetEntry({PresetUUID: presetUuid, PropertyName: propertyName});
  }

  onColorChanged = (propertyName) => (cie) =>
  {
    this.runSetCommand({PropertyName: propertyName, Value: cie.fx + ";" + cie.fy + ";" + cie.f_Y})
  }

  onValueChanged = (event, {name, value}) =>
  {
    let changedValue = value;
    if(name === "RotationX" || name === "RotationY" || name === "RotationZ")
    {
      changedValue = degToRad(value);
    }
    this.syncState({[name]: value, changedName: name, changedValue: changedValue});
  }
  
  /**
   * @param {{PropertyName: string, Value: Any, isInventory: boolean}|[{PropertyName: string, Value: Any, isInventory: boolean}]} propValue Values to set
   */
  runSetCommand = (propValue, event) =>
  {
    let command = undefined
    let getCommand = (pv) =>
    {
      if (pv.PropertyName && pv.Value !== undefined)
      {
        let valueString = "'" + pv.Value + "'"
        if (Array.isArray(pv.Value))
        {
          valueString = "(";
          for (let index = 0; index < pv.Value.length; index++)
          {
            valueString += pv.Value[index];
            if (index < pv.Value.length - 1)
            {
              valueString += ";"
            }
          }
          valueString += ")"
        }
        let result = pv.isInventory ? `SetInventory (${pv.PropertyName}) ` : `Set (${pv.PropertyName}) `

        if (this.state.propertySettingMode === SETTING_Increment)
        {
          result += " + "
        }
        else if (this.state.propertySettingMode === SETTING_AlignFirst)
        {
          result += " < "
        }
        else if (this.state.propertySettingMode === SETTING_AlignLast)
        {
          result += " > "
        }

        result += valueString

        if(propValue.Increment)
        {
          result += " +"
        }
        return result
      }
      return undefined
    }

    if (Array.isArray(propValue))
    {
      command = []
      for (let prop of propValue)
      {
        let addCommand = getCommand(prop);
        if (addCommand)
        {
          command.push(addCommand)
        }
      }
    }
    else
    {
      command = getCommand(propValue);
    }
     

    if (command)
    {
      let applyToChildObject = false
      if(event) {applyToChildObject = event.altKey}
      window.LR_CommandLine({Command: command, ApplyToChildObject: applyToChildObject})
    }
    else
    {
      console.warn("Could not generate command to set ObjectProperties!");
    }

  }

  /**
   * @param {{ArrayName: string, ArrayIndex: Number, PropertyName: string, Value: Any}|[{ArrayName: string, ArrayIndex: Number, PropertyName: string, Value: Any}]} propValue Values to set
   */
  runSetArrayCommand = (propValue, event) => 
  {
    let getPropertyName = (pv) =>
    {
      return "'" + pv.ArrayName + "' " + pv.ArrayIndex + " '" + pv.PropertyName + "'"
    }

    let toSetValues = {PropertyName: getPropertyName(propValue), Value: propValue.Value}
    if (Array.isArray(propValue))
    {
      toSetValues = propValue.map(pv => {
        return {
          PropertyName: getPropertyName(pv),
          Value: pv.Value
        }
      })
    }

    this.runSetCommand(toSetValues, event)
  }

  setUpCallbacks()
  {
    globalCallbacks.getNotesForProperties = async () => 
    {
      let noteData = await window.LR_GetNotesForObjects({Async: true, Selected:true});
      let loggedInUserInfo = await  window.LR_GetLoggedInUser()
      this.syncState({Notes: noteData.Notes, loggedUser: loggedInUserInfo.User},100)
    }
    globalCallbacks.getSelectedProperties = async () => 
    {
      let req = {Async: true}
      if(this.props.UUID)
      {
        req.UUID = this.props.UUID
      }

      globalCallbacks.getNotesForProperties();
      
      let properties =  await window.LR_GetObjectProperties(req);
      properties.MagnetInfo = properties.MagnetInfo ?? {IsMagnet: false}

      let GeometryTransformList = properties.GeometryTransformList.map((obj, index) => 
      {
        obj.key = index;

        let propertyLabel = "";
        let propertyName  = "";
        switch(obj.TransformType)
        {
          case GEOMETRY_TRANSFORM_TYPE_TranslationX:
          {
            propertyLabel = "TranslationX - "  + obj.GeometryName;
            propertyName  = obj.GeometryUUID + "TX";   
            break;
          }
          case GEOMETRY_TRANSFORM_TYPE_TranslationY:
          {
            propertyLabel = "TranslationY - "  + obj.GeometryName;
            propertyName  = obj.GeometryUUID + "TY";
            break;
          }
          case GEOMETRY_TRANSFORM_TYPE_TranslationZ:
          {
            propertyLabel = "TranslationZ - "  + obj.GeometryName;
            propertyName  = obj.GeometryUUID + "TZ";   
            break;
          }
          case GEOMETRY_TRANSFORM_TYPE_RotationX:
          {
            propertyLabel = "Tilt - " + obj.GeometryName;
            propertyName  = obj.GeometryUUID + "RX";
            break;
          }
          case GEOMETRY_TRANSFORM_TYPE_RotationY:
          {
            propertyLabel = "Roll - " + obj.GeometryName;
            propertyName  = obj.GeometryUUID + "RY";
            break;
          }
          case GEOMETRY_TRANSFORM_TYPE_RotationZ:
          {
            propertyLabel = "Pan - " + obj.GeometryName;
            propertyName  = obj.GeometryUUID + "RZ";
            break;
          }
          default: break;
        }

        obj.PropertyLabel = propertyLabel;
        obj.PropertyName  = propertyName;
        obj.Value         = properties.IsGeometryTransformList[index] ? obj.Value : "";
        return obj;
      });


      let Modes = properties.Modes.map((mode, i) =>
      {
        return(
          {
            key   : mode.Name,
            text  : mode.Name,
            value : mode.Name,
          }
        );
      });

      let Gobos = properties.Gobos.map(gobo =>
      {
        return(
          {
            key   : gobo.UUID,
            value : gobo.UUID,
            text  : gobo.Name,
            
          }
        );
      });

      Gobos.unshift(
        {
          key   : EMPTY_UUID,
          value : EMPTY_UUID,
          text  : LocalizedStrings.NoGobo,
        })
      
      let ElectricalObjects = properties.ElectricalObjects.map((obj, index) => 
      {
        let socketName = ""
        if(obj.WireType === kWireType_Fuse)
        {
          if(obj.Socket === 0)  { socketName = "IN" }
          else                  { socketName = "OUT" }
        }
        else
        {
          let connector = GetConnectorInfoByName(obj.ConnectorType);
          socketName = connector.SocketInfo?.[obj.Socket].Name
        }
        

        
        
        return {
          //Maybe temp
          Socket: obj.Socket,
          UUID: obj.UUID,
          key: obj.UUID + "_" + obj.Socket,
          text: obj.Name + ":" + socketName,
          value: obj.UUID + "_" + obj.Socket
        }
      });

      let funcGenerateOption = (options, result) => 
      {
        options.forEach((val) => 
        {
          if (result[val.ConnectorType] === undefined) {  result[val.ConnectorType] = [] }
          
          let objId      = (val.ObjectId ? (val.ObjectId + ".") : "") + val.WireName
          let searchText = (val.ObjectId ? objId : val.ObjectName).toLowerCase();
  
          
          result[val.ConnectorType].push(
          {
            text: (val.IsUsed ? "✅" : "□") + " " + objId + "  " + val.ObjectName, 
            value: val.Object + val.Wire,
            key: val.Object + val.Wire,
            Wire: val.Wire,
            Object: val.Object,
            SearchFilter: searchText
          });
        });
      }

      let AllWireInputs = []
      funcGenerateOption(properties.AllInputs, AllWireInputs)

      let AllWireOutputs = []
      funcGenerateOption(properties.AllOutputs, AllWireOutputs)
      
      let AllStructures = properties.AllStructures.filter(val => properties.ActiveUUID !== val.UUID).map(val => 
      {
        return {
          text: val.Name,
          value: val.UUID,
        };
      });

      let GeneratorList = properties.GeneratorList.map((obj, index) => 
      {
        return {
          //Maybe temp
          key: obj.UUID,
          text: obj.NameIdentifier,
          value: obj.UUID
        }
      })

      GeneratorList.unshift(
        {
          key   : EMPTY_UUID,
          value : EMPTY_UUID,
          text  : LocalizedStrings.NoDesiredDistributor,
        })

        this.syncState(
      {
        ...properties,
        AllStructures,
        AllWireOutputs,
        AllWireInputs,
        Modes,
        Gobos,
        GeometryTransformList,
        ElectricalObjects,
        changedName: "",
        changedValue: "",
        GeneratorList
      });
    }

    globalCallbacks.getStructuralResultsForOIP = async () =>
    {
      let workloadTrussKeyed =  []

      let res     = await window.LR_GetStructuralResult()
      
      let set = new Set();
      res.forEach(e=>
      {
        if(e.Type ===  4) 
        {
          set.add(e.Key)
        }

      })

      for (const value of set) {
        workloadTrussKeyed.push({
          text: value,
          key: value,
          value: value,
        })
    }

    workloadTrussKeyed.push({
      text: "<None>",
      key: "",
      value: "",
    })

      this.setState({AvailableCrossSection: workloadTrussKeyed})
    }
    
    globalCallbacks.getObjectPropertiesLayers = async () =>
    {
      let ret        = await window.LR_GetLayers();
      let layersList = ret.Layers;
      let layers = layersList.map((layer) =>
      {
        return(
          {
            key   : layer.UUID,
            value : layer.UUID,
            text  : layer.Name,
          }
        );
      });

      this.syncState({layers});
    }

    globalCallbacks.getObjectPropertiesCaseTemplates = async () =>
    {
      let ret        = await window.LR_GetCaseTemplateMap();
      
      let CaseTemplates = ret.ResourceMap.caseTemplate.map((temp) => {
        return (
          {
            key: temp.Name,
            value: temp.Name,
            text: temp.Name,
          }
        );
      });

      CaseTemplates.push({
          key: "",
          value: "",
          text: LocalizedStrings.NoSelection,
      })

      this.syncState({ CaseTemplates });
      
    }

    globalCallbacks.getObjectPropertiesLoadGroups = async () =>
    {
      let ret        = await window.LR_GetLoadGroups();
      
      let LoadGroups = ret.LoadGroups.map((temp) => {
        return (
          {
            key: temp.UUID,
            value: temp.UUID,
            text: temp.Name,
          }
        );
      });

      LoadGroups.push({
          key: EMPTY_UUID,
          value: EMPTY_UUID,
          text: LocalizedStrings.NoSelection,
      })

      this.syncState({ LoadGroups });
      
    }

    globalCallbacks.getObjectPropertiesClasses = async () =>
    {
      let ret         = await window.LR_GetClasses();
      let classesList = ret.Classes;
      let classes = classesList.map((clss) =>
      {
        return(
          {
            key   : clss.UUID,
            text  : clss.Name,
            value : clss.UUID,
          }
        );
      });

      this.syncState({classes});
    }

    globalCallbacks.getObjectPropertiesGetOrigins = async () =>
    {
      let origins = await window.LR_GetAvailableOrigins({Async: true})
      origins = origins.map(v => {return {value: v.ParentUuid + v.GeometryUuid, text: v.text}})
      this.syncState({ origins: [
        ...origins, 
        {
        value: EMPTY_UUID + EMPTY_UUID, 
        text: LocalizedStrings.GlobalCenter
      }] }, 100)
    }

    globalCallbacks.getObjectPropertiesTrussCrossSection = async () =>
    {
      let ret        = await window.LR_GetTrussCrossSection();
      let ropeCossSection = []
      let trussCrossSection = ret.ResourceMap.crossSection.map((tc) =>
      {
        if(tc.Design === 6 /* PIPE Design */)
        {
          ropeCossSection.push(
            {
              key   : tc.Name,
              value : tc.Name,
              text  : <>{tc.Brand + " - " + tc.Name}<br/><i>{tc.Origen}</i></>
            }
          )
        }
        return(
          {
            key   : tc.Name,
            value : tc.Name,
            text  : tc.Brand + " - " +tc.Name
          }
        );
      });

      trussCrossSection.sort((a,b) => a.text.localeCompare(b.text))

      let ret2        = await window.LR_GetMaterialTemplates();
      let trussMaterial = ret2.ResourceMap.material.map((tc) =>
      {
        return(
          {
            key   : tc.Name,
            value : tc.Name,
            text  : tc.Name +tc.Name,
          }
        );
      });

      trussMaterial.push({
        key   : "EMPTY",
        value : '',
        text  : LocalizedStrings.UseFromCrossSection,
      })


      this.syncState({trussCrossSection, trussMaterial, ropeCossSection});
    }

    globalCallbacks.getObjectPropertiesInventoryContainers = async () =>
    {
      let ret        = await window.LR_GetInventoryContainerOptions();
      let containersList = ret.InventoryContainers;

      let getIcon = (container) => 
      {
        switch(container.ContainerType)
        {
          case 2 :  return "box"
          case 1 :  return "th"
          case 0 :  return "truck"
          default: return "truck"
        }
      }

      let containers = containersList.map((container) =>
      {
        return(
          {
            key   : container.UUID,
            value : container.UUID,
            icon: getIcon(container),
            text  : container.Name,
          }
        );
      });

      this.syncState({containers});
    }

    globalCallbacks.getObjectPropertiesExternalDocuments = async () =>
    {
      let ret               = await window.LR_GetExternalDocuments();
      let documentList      = ret.ExternalDocuments;
      let externalDocuments = documentList.map((document) =>
      {
        return(
          {
            key   : document.UUID,
            value : document.UUID,
            text  : document.Name,
          }
        );
      });

      this.syncState({externalDocuments});
    }

    globalCallbacks.getObjectPropertiesColorCodes = async () => {
      let res = await window.LR_GetColorCodeObjects()
      let colorCodes = res.ColorCodeObjects.map(ccObject => {
        return {
          key: ccObject.UUID,
          value: ccObject.UUID,
          text: <UnitInput
            baseUnit={BASE_UNIT.COLOR}
            value={ccObject}
            readOnly
            transparent
            label={false}
            style={{minWidth: 150}}
          />
        }
      })

      this.syncState({
        colorCodes: [
          {
            key: EMPTY_UUID,
            value: EMPTY_UUID,
            text: <UnitInput
            baseUnit={BASE_UNIT.COLOR}
            value={{
              Name: "None"
            }}
            readOnly
            transparent
            label={false}
            style={{minWidth: 150}}
          />
          },
          ...colorCodes
        ]
      })
    }

    globalCallbacks.getObjectPropertiesSymbolDefs = async () =>
    {
      window.LR_GetSymbolDefs().then(res =>
      {
        let SymbolDefs = []
        let bridleSets = []
        for(let sym of res.SymbolDefs)
        {
          if(sym.UseAsCableInventory)
          {
            SymbolDefs.push(
              {  
                key: sym.UUID, 
                value: sym.UUID, 
                text: sym.Name
              })
          }
          if(sym.UseAsBridleInventory){
            bridleSets.push({
              key: sym.UUID,
              value:sym.UUID,
              text: sym.Name
            })
          }            
        }
        this.syncState({SymbolDefs,bridleSets})
      })
    }

    globalCallbacks.getObjectPropertiesDepartments = () =>
    {
      window.LR_GetDepartments().then(res => 
        {
          let departments = res.Departments.map(depObj => {
            return {
              key: depObj.UUID,
              value: depObj.UUID,
              text: depObj.Name
            }
          })
          this.syncState({departments})
        })
    }

    globalCallbacks.getPresetOptions = async () =>
    {
      let res = await window.LR_GetPresets()
      let PresetOptions = res.Presets.map(preset => {
        return  {  
                  key: preset.UUID, 
                  value: preset.UUID, 
                  text: preset.Name
                }
      })

      PresetOptions.push({
        key: EMPTY_UUID, 
        value: EMPTY_UUID, 
        text: LocalizedStrings.ActivePreset
      })

      this.syncState({PresetOptions: PresetOptions, SelectedPreset: res.UUID })
    }

    globalCallbacks.getMeshesAndTextures = async () =>
    {
      window.LR_GetMeshes({}).then(res =>
      {
        let meshes = res.Meshes.map(mesh => {
            return  {  
                      key: mesh.UUID, 
                      value: mesh.UUID, 
                      text: mesh.Name
                    }
          })

          this.syncState({MeshesOptions: [
            {
                  key: EMPTY_UUID, 
                  value: EMPTY_UUID, 
                  text: LocalizedStrings.NoSelection
            },
            ...meshes
          ]
                })
      })

      window.LR_GetTextures().then(res =>
        {
          let textures = res.Textures.map(tex => {
              return  {  
                        key: tex.UUID, 
                        value: tex.UUID, 
                        text: tex.Name
                      }
            })
  
            this.syncState({TextureOptions: [
              {
                    key: "<EMPTY>", 
                    value: EMPTY_UUID, 
                    text: LocalizedStrings.NoSelection
              },
              {
                key: "<NEWPICTURE>", 
                value: "<Add new picture>", 
                text: LocalizedStrings.AddNewPicture
              },
              ...textures
            ]
                  })
        })
    }

    globalCallbacks.getObjectPropertiesUsers = async () =>
    {
      let users   = await window.LR_GetUsers()

      let userArray = users.Users.map((value) => 
      {
        return ({
          key: value.UUID,
          text: <><UserAvatar user={value.LinkedPAUser}/> {value.Name}</>,
          value: value.UUID,
        });
      });

      userArray.push({
        key: "",
        text: LocalizedStrings.NoUser,
        value: "",
      });

      this.syncState({users: userArray});
    }

    globalCallbacks.updatePaperFormatTemplatesOPC = async () =>
    {
      let templates = await window.LR_GetPaperFormatTemplateMap()
      let templateOptions = templates.ResourceMap.paperFormatTemplate.map(temp => ({
        key: temp.Name,
        text: temp.Name,
        value: temp.Name,
        template: temp
      }))
      this.syncState({
        paperFormatPresetOpts: templateOptions,
        paperFormatPresets: templates.ResourceMap.paperFormatTemplate
      })
    }

    globalCallbacks.updatePrintLabelsOPC = async () =>
    {
      let labels = await window.LR_GetPrintLabels()
      let labelOptions = labels.PrintLabels.map(l => ({value: l.UUID, text: l.Name, key: l.UUID}))
      this.syncState({printLabelsOptions: [
      {
        key: EMPTY_UUID, 
        value: EMPTY_UUID, 
        text: LocalizedStrings.NoSelection
      },
      {
        key: "<Download>", 
        value: "<Download PrintLabel>", 
        text: "<Download>"
      }
      ,...labelOptions]})
    }
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

export default connect(mapStateToProps)(ObjectProperties)