//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React,{ Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getAcceloratorName } from './MenuBar';
import { globalCallbacks } from "../../util/callback";
import "./MenuBar.css"


class MenuContainer extends Component 
{
    render()
    {
        return(
                <Dropdown text={this.props.label} pointing className='link item' >    
                    <Dropdown.Menu style={{width: window.IsIOS ? "18rem" : "30rem", maxHeight: window.IsIOS ? "60vh" : "80vh", overflow:"auto", ...this.getDropdownStyle()}}>
                        {this.props.submenu.map( (item,i ) => {  
                            return this.createMenuItem(item, i)
                        })}
                    </Dropdown.Menu>
                </Dropdown>
        )
    }

    getDropdownStyle = () => {
        if (window.IsIOS && this.props?.menuIndex > 2 && this.props?.menuIndex < 4 ) {
            return  { left: "50%", transform: "translateX(-50%)" }     
        }

        if (window.IsIOS &&  this.props?.menuIndex > 4) {
            return  { left: "50%", transform: "translateX(-80%)" }     
        }

        return {}; 
    };

    createMenuItem = (item, index) =>
    {
        if (item.role) { return null; }
        if (item.type === "separator") {
            return <Dropdown.Divider key={"separator" + index}/>
        }
        
        if (Array.isArray(item.submenu))
        {
            if (item.submenu.length > 0)
            {
                return  <Dropdown.Item key={item.label} text={item.label}>
                            <Dropdown simple text={item.label}>
                                <Dropdown.Menu  style={{width:"max-content"}}>
                                    {item.submenu.map(item => this.createMenuItem(item))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Dropdown.Item>
            }
            else
            {
                return null
            }
        }

        return <Dropdown.Item key={item.label} text={item.label} description={getAcceloratorName(item.accelerator)} onClick={()=> 
            {
                if (item.cmd)
                {
                    window[item.cmd](item.arg)
                }
                else if (item.renderCmd)
                {
                    if (globalCallbacks[item.renderCmd]) { globalCallbacks[item.renderCmd](item.arg); }
                }
            }}/>
    }

}

export default MenuContainer;