//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form, Divider, Container, Button } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";

import PropertyBasedFilterModal from './PropertyBasedFilter';


class ObjectPropertiesFilter extends Component
{
  constructor(props)
  {
    super(props)

    this.state = {
      filterOpen: false,
      propertyFilterOpen: false,
    }

    this.propertyFieldOptions = []
  }

  
  shouldSelectAll = () =>
  {
    return !this.props.ShowFilterConsumer ||
           !this.props.ShowFilterDistributer ||
           !this.props.ShowFilterPlugBox ||
           !this.props.ShowFilterGenerator ||
           !this.props.ShowFilterFixture ||
           !this.props.ShowFilterStructures ||
           !this.props.ShowFilterCablePath ||
           !this.props.ShowFilterSupport ||
           !this.props.ShowFilterGroundSupport ||
           !this.props.ShowFilterHouseRiggingPoint ||
           !this.props.ShowFilterAudio ||
           !this.props.ShowFilterDockPoints ||
           !this.props.ShowFilterAssemblyGroup ||
           !this.props.ShowFilterMeshes
  }

    render()
    {
        return(
          <React.Fragment>
              <h5>{LocalizedStrings.Filter}</h5>
                <Container style={{overflow: 'auto', maxHeight: 125, marginTop:"1em", marginBottom:"1em" }}>

                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterConsumer} checked = { this.props.ShowFilterConsumer} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterConsumer", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterDistributer} checked = { this.props.ShowFilterDistributer} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterDistributer", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterPlugBox} checked = { this.props.ShowFilterPlugBox} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterPlugBox", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterGenerator} checked = { this.props.ShowFilterGenerator} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterGenerator", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterFixture} checked = { this.props.ShowFilterFixture} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterFixture", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterStructures} checked = { this.props.ShowFilterStructures} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterStructures", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterCablePath} checked = { this.props.ShowFilterCablePath} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterCablePath", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterSupport} checked = { this.props.ShowFilterSupport} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterSupport", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterGroundSupport} checked = { this.props.ShowFilterGroundSupport} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterGroundSupport", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterHouseRiggingPoint} checked = { this.props.ShowFilterHouseRiggingPoint} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterHouseRiggingPoint", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterAudio} checked = { this.props.ShowFilterAudio} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterAudio", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterMeshes} checked = { this.props.ShowFilterMeshes} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterMeshes", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterDockPoints} checked = { this.props.ShowFilterDockPoints} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterDockPoints", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterAssemblyGroup} checked = { this.props.ShowFilterAssemblyGroup} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterAssemblyGroup", e)}}/>
                    {
                      this.props.showInputOutput ?
                        <>
                          <Divider />
                          <Form.Checkbox disabled={this.props.isInEditMode} label={LocalizedStrings.ShowFilterInputs} checked={this.props.ShowFilterInputs} onClick={(e) => { e.stopPropagation(); e.preventDefault() }} onChange={(e) => { this.props.toggleShowProperties("ShowFilterInputs", e) }} />
                          <Form.Checkbox disabled={this.props.isInEditMode} label={LocalizedStrings.ShowFilterOutputs} checked={this.props.ShowFilterOutputs} onClick={(e) => { e.stopPropagation(); e.preventDefault() }} onChange={(e) => { this.props.toggleShowProperties("ShowFilterOutputs", e) }} />
                        </>
                        :
                        null
                    }
                    <Divider/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterVisible} checked = { this.props.ShowFilterVisible} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterVisible", e)}}/>
                    <Form.Checkbox disabled={this.props.isInEditMode}  label = {LocalizedStrings.ShowFilterSelected} checked = { this.props.ShowFilterSelected} onClick={(e) => {e.stopPropagation(); e.preventDefault()}} onChange = {(e) => {this.props.toggleShowProperties("ShowFilterSelected", e)}}/>
                </Container>

                <Button disabled={this.props.isInEditMode} compact fluid positive={this.props.propertyBasedFilter.length > 0} onClick={()=>{this.setState({propertyFilterOpen: true})}}>{LocalizedStrings.EditPropertyFilter}</Button>
                {this.state.propertyFilterOpen ? 
                    <PropertyBasedFilterModal filter={this.props.propertyBasedFilter}
                                              fields={this.props.fields}
                                              onClose={() => {this.setState({propertyFilterOpen: false})}}
                                              onFinish={this.onPropertyBasedFilterChanged}/> 
                : null}
          </React.Fragment>
        )
    }

    onPropertyBasedFilterChanged = (newFilters) =>
    {
      this.setState({propertyFilterOpen: false})
      if (this.props.onPropertyBasedFilterChange)
      {
        this.props.onPropertyBasedFilterChange(newFilters)
      }
    }

    getPropertyByName(propertyName)
    {
      return this.props.fields.find(prop => prop.PropertyName === propertyName)
    }
}


export default ObjectPropertiesFilter;