
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Checkbox, Divider, Dropdown, Radio, Icon, Header, Loader, Button } from 'semantic-ui-react';
import { SingleFieldSearch } from '../TableViews/ObjectProperties/FieldSearch';
import LocalizedStrings from "../../localization/SceneTreeComponent";
import { EMPTY_UUID } from '../../util/defines';
import LRFilterInput from '../Basics/FilterField';
import TreeNode from './TreeNode';
import { globalCallbacks } from "../../util/callback";
import { Mutex } from 'async-mutex';
import LocalizedStrings_ObjectProperties from "../../localization/LightRightObjectsFields";

import "./SceneTreeHierarchyStyle.css"

const SYNCSTATE_DEFAULT_DELAY = 150
const SEARCH_DELAY=150

const filterOptions_NoSelected = {
  onlyConsumer: false,
  onlyDistributer: false,
  onlyPlugBox: false,
  onlyAssemblyGroup: false,
  onlyGenerator: false,
  onlyFixtures: false,
  onlyStructures: false,
  onlyCablePath: false,
  onlySupport: false,
  onlyAudio: false,
  onlyMeshes: false,
  onlyVisible: false,
  showChildren: false,
  showFilterSelected: false
}

const ELEMENT_HEIGHT = 30
class SceneTreeHierarchy extends Component 
{
  constructor(props)
  {
    super(props);
    this.tree    = [];
    this.treeMap = new Map();
    this.filteredTreeMap = new Map();

    this.selectionList = [];

    this.sortOptions = [
      { LocalizedName: 'No Sorting', PropertyName: "" }
    ];

    ["Name","FixtureId","StructurePosition","ObjectId"].forEach((PropertyName)=>{
      this.sortOptions.push({
        LocalizedName: LocalizedStrings_ObjectProperties[PropertyName],
        PropertyName
      })
    });

    this.state = 
    {
      StructuralCalculationObjects: {},
      loading:      false,
      tree          : [],
      dragged       : undefined,
      change        : false,
      searchText    : "",
      filterOpen: false,
      toRender:[],
      countDisplay:0,
      properties:[],

      filterOptions : {
        onlyConsumer: true,
        onlyDistributer: true,
        onlyPlugBox: true,
        onlyAssemblyGroup: true,
        onlyGenerator: true,
        onlyFixtures: true,
        onlyStructures: true,
        onlyCablePath: true,
        onlySupport: true,
        onlyAudio: true,
        onlyMeshes: true,
        onlyVisible: true,
        showChildren: false,
        showFilterSelected: false
      },

      DuplicatedFixtureIds: [],
      DuplicatedObjectIds: [],

      activeDrawing : "path",
      drawings      : [
        {
          key   : 0,
          value : "path",
          text  : "A Sheep",
        },
      ],

      scrollOffset: 0,
      selectedSortOption: this.sortOptions[0],
      orderReversed: false,
      isUUIDSearch: false
    }

    this.scrollRef = React.createRef();

  }

    _stateChanges = {}
    timeoutSyncState
    syncState(changes, delay = SYNCSTATE_DEFAULT_DELAY){
        this._stateChanges = { ...this._stateChanges, ...changes }
        if (this.timeoutSyncState){
            clearTimeout(this.timeoutSyncState)
        }
        this.timeoutSyncState = setTimeout(()=>{
            this.setState({...this.state, ...this._stateChanges})
            this._stateChanges = {}
        },delay)
    }

  componentDidUpdate(prevProps, prevState)
  {
    if(prevState.filterOptions !== this.state.filterOptions ||
      prevState.change !== this.state.change)
    {
      this.recalculateObjectsToDisplay()
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
    globalCallbacks.getSceneTree();
    globalCallbacks.displayDrawingMenu();
    this.getObjectProperties();
    
    if(this.props.node) { this.props.node.setEventListener("close", (p) => {  this.props.onClose() }) }
  }

  async getObjectProperties(){
      let possibleFields          = await window.LR_GetPossibleFields()
      let getLocalizedNameForProp = (prop) =>
      {
        if (!prop.ArrayName) { return LocalizedStrings_ObjectProperties[prop.PropertyName]}
        
        if (LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName])
        {
          return LocalizedStrings_ObjectProperties[prop.ArrayName + "_" + prop.PropertyName]
        }
  
        let loc = LocalizedStrings_ObjectProperties[prop.PropertyName]
        if(loc)
        {
          return loc
        }
  
        return prop.PropertyName
      }

      this.syncState({properties: possibleFields.map( entry =>
        {
          return {
            LocalizedName: getLocalizedNameForProp(entry),
            PropertyList: entry.PropertyList,
            PropertyName: entry.PropertyName,
            IfInArrayWithName: entry.IfInArrayWithName,
            ArrayName: entry.ArrayName ? entry.ArrayName : undefined,
            LinkedPreset: entry.LinkedPreset ? entry.LinkedPreset : undefined,
            IsUnitBased: entry.IsUnitBased ? entry.IsUnitBased : undefined,
            CustomCellFunction: entry.IsUnitBased , 
            CustomCellFunctionType: entry.CustomCellFunction,
            BaseUnit: entry.BaseUnit,
            CustomFilter: null
          }
        })
      });
  }
  // Get the total count of children considering the Expanted state, when the expanded state is true, than it return the count of all childrens
  getNodeCount = (ignoreExpanded = false) => (node) =>
  {
    let count = 1;

    if (node.Expanded || ignoreExpanded) {
      count += node.children
        .map(this.getNodeCount(ignoreExpanded))
        .reduce(function(total, count) {
          return total + count;
        }, 0);
    }

    return count;
  }

// Returns the count of children that of a non expanded node not regarding the Expanded state of the children. Return 0 when the the object itself is expanted.
  getHiddentNodeCount = (node) =>
  {
    let result = 0;

    if (!node.Expanded) {
      result += node.children
        .map(this.getNodeCount(true))
        .reduce(function(total, count) {
          return total + count;
        }, 0);
    }

    return result;
  }

    //--------------------------------------------------------------------------------------------------------
    // Calculate the count of total display objects matching the filter
    async recalculateObjectsToDisplay() {
      let countDisplay = 0;
      let toRender = [];
    
      // If it's a UUID search, only look for the item that matches the UUID
      if (this.state.isUUIDSearch) {
        for (let i = 0; i < this.tree.length; i++) {
          let item = this.tree[i];
    
          const matchesSearch = item.UUID.includes(this.state.searchText);
          if (matchesSearch) {
            countDisplay++;
            toRender.push(item);
            break; 
          }
        }
      } else {
        for (let i = 0; i < this.tree.length; i++) {
          let item = this.tree[i];

          if (this.state.filterOptions.showFilterSelected && !item.Selected) {
            continue;
          }

          if (!this.shouldItemDisplay(item)) {
            i = i + this.getNodeCount(true)(item) - 1;
            continue;
          }
    
          if (!item.Expanded) {
            i = i + this.getNodeCount(true)(item) - 1;
          }
    
          countDisplay++;
          toRender.push(item);
          
          if (this.state.searchText !== "" && this.state.filterOptions.showChildren && item.Name.toLowerCase().includes(this.state.searchText.toLowerCase()) && item?.children?.length > 0) {
              for (let j = 0; j < item.children.length; j++) {
                  let child = item.children[j];
                  if (!child.VisibleInSceneTree) {
                      toRender.push(child);
                      countDisplay++;
                    }
              }
          }
        }
      }
    
      this.syncState({ toRender, countDisplay });
    }
  async setSortOption(option=this.sortOptions[0]){ // default option = no sorting
    await window.LR_SetProjectSettings({
      SceneTreeSortingKey: option.PropertyName
    })
    globalCallbacks.getSceneTree()
    this.syncState({selectedSortOption: option})
  }

  reverseSortOrder(){ 
    if(this.state.selectedSortOption.PropertyName){
      this.syncState({orderReversed:!this.state.orderReversed}) 
      this.setSortOption(this.state.selectedSortOption); 
    }
  }

  getSortedPropertyValue(treeItem){
    if (["Name","ObjectId"].includes(this.state.selectedSortOption.PropertyName))
      return "";
    
    return treeItem[this.state.selectedSortOption.PropertyName]
  }

  render() 
  {
    //--------------------------------------------------------------------------------------------------------
    // Calculates the first index of display objects based on the current scrolled pixes.
    let startEntryIndex = Math.floor(this.state.scrollOffset / ELEMENT_HEIGHT)
        
    //--------------------------------------------------------------------------------------------------------
    // Render the 50 items following the calculated start entry
    let renderedItems = []
    for (let i = 0; i < 50; i++)
    {
      // Get current rendered node index
      let currentIndex = i + startEntryIndex
      //Get tree node object from tree array
      let item = this.state.toRender[currentIndex]

      if (item)
      {
        // Get current parent of treeNode, if parent is children of higher parent, get higher parent
        let parentCount = 0;
        let currentParent = this.treeMap.get(item.ParentUUID)
        while (currentParent)
        {
          parentCount++
          currentParent = this.treeMap.get(currentParent.ParentUUID)
        }
        //push treeNode to renderedItems array
        renderedItems.push(
          <div 
            key={item.UUID} 
            class="tree-item-container"
            style={{ paddingLeft: 20 * (parentCount + 1), height: ELEMENT_HEIGHT}}
          >
            <TreeNode key         = {item.UUID}
                      change      = {this.state.change}
                      UUID        = {item.UUID}
                      onSelectObject= {this.onSelectObject}
                      selected    = {item.Selected}
                      LoadConnectedMeasured    = {item.LoadConnectedMeasured}
                      LoadConnectedCalculated    = {item.LoadConnectedCalculated}
                      HasCableSelected    = {item.HasCableSelected}
                      existing    = {item.Existing}
                      expanded    = {item.Expanded}
                      calculationEntry = {this.state.StructuralCalculationObjects[item.UUID]}
                      useCalculationEntry = {this.state.UseStructuralCalculationObjects}
                      name        = {item.Name}
                      duplicatedFid = {this.state.DuplicatedFixtureIds}
                      duplicatedOid = {this.state.DuplicatedObjectIds}
                      hasFid      = {!!item.FixtureId}
                      fixtureId   = {item.FixtureId ? item.FixtureId : item.ObjectId}
                      searchText  = {this.state.searchText}
                      filterOptions={this.state.filterOptions}
                      resourceType = {item.ResourceType}
                      isFullyConnected = {item.IsFullyConnected}
                      isElectrical = {item.IsElectrical}
                      dragged     = {this.state.dragged} 
                      onDragStart = {this.onDragStart}
                      onDragEnd   = {this.onDragEnd}>
                      {item.children}
            </TreeNode>
            {
              (this.state.selectedSortOption.PropertyName) && !["Name","ObjectId"].includes(this.state.selectedSortOption.PropertyName) && 
                <div class="tree-item-note ui label" > {  this.getSortedPropertyValue(item)} </div>
            }

          </div>
        )
      }
    }

    return (
        <div  
          id="SceneTree"
          onDragOver  = {(e) => e.preventDefault()} //necessary to fire onDrop
          onDrop      = {this.onDrop}
        > 
          <Loader active={this.state.loading} />
            <Header as="h5" style={{marginTop:"0em"}}>
              {LocalizedStrings.Header}
              {" "}
              <Dropdown
                inline
                closeOnChange
                disabled  ={window.IsVectorworksContext} // On Vectorworks we don't was to switch this
                options   = {this.state.drawings}
                value     = {this.state.activeDrawing}
                onChange  = {this.openDrawing}
              />
            </Header>

            <div id="SceneTreeMenu">
              <Dropdown 
                multiple
                icon="filter"
                labeled 
                text={LocalizedStrings.Filter} 
                onOpen={(e) => {this.syncState({filterOpen: true})}} 
                onClose={(e) => {this.syncState({filterOpen: false})}}
              >
                  <Dropdown.Menu 
                    open={this.state.filterOpen}
                  >
                      <h5>{LocalizedStrings.FilterHeader}</h5>
                      <Checkbox label={LocalizedStrings.onlyConsumer} checked={this.state.filterOptions.onlyConsumer} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyConsumer: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyDistributer} checked={this.state.filterOptions.onlyDistributer} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyDistributer: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyPlugBox} checked={this.state.filterOptions.onlyPlugBox} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyPlugBox: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyGenerator} checked={this.state.filterOptions.onlyGenerator} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyGenerator: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyFixtures} checked={this.state.filterOptions.onlyFixtures} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyFixtures: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyStructures} checked={this.state.filterOptions.onlyStructures} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyStructures: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyCablePath} checked={this.state.filterOptions.onlyCablePath} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyCablePath: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlySupport} checked={this.state.filterOptions.onlySupport} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlySupport: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyAudio} checked={this.state.filterOptions.onlyAudio} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyAudio: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyMeshes} checked={this.state.filterOptions.onlyMeshes} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyMeshes: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.onlyAssemblyGroup} checked={this.state.filterOptions.onlyAssemblyGroup} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyAssemblyGroup: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.showChildren} checked={this.state.filterOptions.showChildren} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.onShowChildrenFilterChecked({showChildren: checked}, e.altKey)}/>
                      <Checkbox label={LocalizedStrings.showFilterSelected} checked={this.state.filterOptions.showFilterSelected} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.showFilterSelectedChecked({showFilterSelected: checked}, e.altKey)}/>

                      <Divider/>
                      <Checkbox label={LocalizedStrings.onlyVisible} checked={this.state.filterOptions.onlyVisible} onClick={e => e.stopPropagation()} onChange={(e, {checked}) => this.setFilterOption({onlyVisible: checked}, e.altKey)}/>
                  </Dropdown.Menu>
              </Dropdown>
              <Dropdown 
                icon="sort"
                className={this.state.selectedSortOption.PropertyName ? " has-selection": ""}
                labeled
                text={LocalizedStrings.Sort} 
                multiple 
                onOpen={(e) => {this.syncState({sortOpen: true})}} 
                onClose={(e) => {this.syncState({sortOpen: false})}}
              >
                  <Dropdown.Menu 
                    open={this.state.sortOpen}
                    onClick={e => e.stopPropagation()} 
                  >
                      <h5 >{LocalizedStrings.SortHeader}</h5>                  
                      {
                        this.sortOptions.map((option, index) => (
                          <Dropdown.Item 
                            key={index}
                            onClick={(e)=>{this.setSortOption(option); e.stopPropagation(); }}
                          >
                            <Radio 
                              checked={this.state.selectedSortOption.PropertyName === option.PropertyName } 
                              style={{marginTop: 0}} 
                              label={option.LocalizedName} 
                            />
                          </Dropdown.Item>
                        ))
                      }
                      <Divider/>
                      <SingleFieldSearch 
                        className="property-search-field"
                        onItemChange={(prop)=>this.setSortOption(prop)}
                        propertyList={this.state.properties || []}  
                        chosenItem={this.state.selectedSortOption}
                      />
                      <Divider/>
                      <Checkbox 
                        label={LocalizedStrings.ReverseOrder} 
                        checked={this.state.orderReversed} 
                        onClick={e => e.stopPropagation()} 
                        onChange={() => this.reverseSortOrder()}
                      />  
                   
                        </Dropdown.Menu>
                    </Dropdown> 
                
            </div>
            <LRFilterInput
                        class="search-field"
                        noLabel
                        onChange={this.setSearchFilter.bind(this)}
                    />

            { this.state.selectedSortOption.PropertyName &&
                <div class="tree-item-note ui label sortedBy-label">
                  <Button compact size="small" onClick={()=> this.reverseSortOrder()} > 
                    <Icon title={(this.state.orderReversed? "Descending" : "Ascending")} name={this.state.orderReversed? "angle up":"angle down"}/>
                  </Button>
                  <Button compact size="small" onClick={()=>this.syncState({sortOpen: !this.state.sortOpen})}>
                    {LocalizedStrings.SortedBy + " "+ LocalizedStrings_ObjectProperties[this.state.selectedSortOption.PropertyName] }
                  </Button>
                  <Button compact size="tiny" onClick={()=> this.setSortOption()} > 
                    <Icon title={(this.state.orderReversed? LocalizedStrings.Ascending: LocalizedStrings.Descending)} name="window close" />
                  </Button>
                </div>
            }
            <div 
              class="tree-container"
              style={{height:"calc(100% - 118px)", overflow:"auto"}}
              ref={this.scrollRef} 
              onScroll={(e) => {this.syncState({scrollOffset: e.target.scrollTop},0)}} 
              onClick={() => {if(!this.state.filterOptions.showFilterSelected){ window.LR_SelectAll({Value: false})}}}
            >
              <div class="tree-render-items-container" 
              style={window.IsVectorworksContext && !window.IsVectorworks2025 ?{position:"absolute"} : undefined}
              >
                {renderedItems}
              </div>
              <div 
                className="tree-inside-container"
                style = {{height: this.state.countDisplay*ELEMENT_HEIGHT}}
                data-lrhandle={EMPTY_UUID} 
              />
            </div>         
        </div>    
    )
  }

  debounceTimer = undefined
  setSearchFilter(value) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(async () => {
      const searchQuery = value.trim();
      const uuidPattern = /^\{[A-F0-9\-]{1,36}/i;
      const isUUIDSearch = uuidPattern.test(searchQuery);
  
      if (isUUIDSearch) { 
        this.setState(
          (prevState) => ({
            searchText: searchQuery,
            isUUIDSearch: true,
          }),
          () => {
            this.recalculateObjectsToDisplay();
          }
        );
      } else {
        this.syncState({ searchText: searchQuery, isUUIDSearch: false });

        await window.LR_SetSceneTreeFilterState({
          SceneTreeFilterState: {
            SearchFilter: searchQuery
          }
        });
      }
    }, SEARCH_DELAY);
  }

  //option eg: {onlyVisible: true}
  setFilterOption(option, clear){
    this.syncState({filterOptions: {...(clear ? filterOptions_NoSelected : this.state.filterOptions), ...option }}, ()=> {
      let options = this.state.filterOptions
      window.LR_SetSceneTreeFilterState(
      {
        SceneTreeFilterState : {
          ShowFilterAssemblyGroup: options.onlyAssemblyGroup,
          ShowFilterConsumer     : options.onlyConsumer,
          ShowFilterDistributer  : options.onlyDistributer,
          ShowFilterPlugBox      : options.onlyPlugBox,
          ShowFilterGenerator    : options.onlyGenerator,
          ShowFilterFixture      : options.onlyFixtures,
          ShowFilterStructures   : options.onlyStructures,
          ShowFilterCablePath    : options.onlyCablePath,
          ShowFilterSupport      : options.onlySupport,
          ShowFilterAudio        : options.onlyAudio,
          ShowFilterMeshes       : options.onlyMeshes,
          ShowFilterVisible      : options.onlyVisible
        }
      })
    })
  }

  onShowChildrenFilterChecked(checked) {
    this.setState(
      (prevState) => ({
        filterOptions: {
          ...prevState.filterOptions,
          showChildren: checked.showChildren,
        },
      }),
      () => {
        this.recalculateObjectsToDisplay(); 
      }
    );
  }

  showFilterSelectedChecked(checked) {
    this.setState(
      (prevState) => ({
        filterOptions: {
          ...prevState.filterOptions,
          showFilterSelected: checked.showFilterSelected,
        },
      }),
      () => {
        this.recalculateObjectsToDisplay(); 
      }
    );
  }
  
  shouldItemDisplay(item)
  {
    return item.VisibleInSceneTree
  }

  getTree = async () =>
  {
    let tree = await window.LR_GetObjectTree({
      CompleteTree: false, 
      Async: true, 
      IncludeProperties: ["Name", "Selected", "Expanded", "ResourceType", "ObjectId", "FixtureId", "Existing", "LoadConnectedMeasured", "LoadConnectedCalculated", "Visible", "VisibleInSceneTree", "SceneTreePosition"],
      UseSorting: true,
      OrderReversed: this.state.orderReversed
    });

    tree.forEach((element, i) =>
    {

      this.treeMap.set(element.UUID, element);
      element.children = [];

      if(element.ParentUUID !== undefined)
      {
        let obj = this.treeMap.get(element.ParentUUID)
        if(obj)
        {
          obj.children.push(element);
        }
        else
        {
          console.error("let obj = this.treeMap.get(element.ParentUUID) FAILED for ", element.ParentUUID)
        }
      }
    });
    return tree;
  }

  updateTreeSelection(object) 
  {
    let obj = this.treeMap.get(object.UUID);

    if(obj === undefined || obj.UUID === EMPTY_UUID)  { return; }

    if(object.Selected !== undefined)          
    { 
      if (object.Selected)
      {
        this.selectionList.push(object.UUID);
      }
      else 
      {
        this.selectionList = this.selectionList.filter(item => item !== object.UUID);
      }
    }
    Object.assign(obj, object)
  }

  updateDuplicatedFixtureIds = () => 
  {
    window.LR_GetDuplicatedFixtureIDs().then(res => {
      this.syncState({DuplicatedFixtureIds: res})
    })

    window.LR_GetDuplicatedObjectIds().then(res => {

      this.syncState({DuplicatedObjectIds: res})
    })
  }

  onSelectObject =(uuid) =>
  {
      this.LastSelectedUuid = uuid
  }

  setUpCallbacks()
  {
    globalCallbacks.updateDuplicatedFixtureIds = () =>
    {
      this.updateDuplicatedFixtureIds()
    }

    let mutex = new Mutex()

    globalCallbacks.getSceneTree = async () => 
    {
      await mutex.acquire()
      this.treeMap.clear();
      this.syncState({loading : true});
      this.tree = await this.getTree();
      this.updateDuplicatedFixtureIds()

      let state = (await window.LR_GetSceneTreeFilterState()).SceneTreeFilterState
      let filterOptions = {
        onlyAssemblyGroup: state.ShowFilterAssemblyGroup,
        onlyConsumer     : state.ShowFilterConsumer     ,
        onlyDistributer  : state.ShowFilterDistributer  ,
        onlyPlugBox      : state.ShowFilterPlugBox      ,
        onlyGenerator    : state.ShowFilterGenerator    ,
        onlyFixtures     : state.ShowFilterFixture      ,
        onlyStructures   : state.ShowFilterStructures   ,
        onlyCablePath   : state.ShowFilterCablePath   ,
        onlySupport      : state.ShowFilterSupport      ,
        onlyAudio        : state.ShowFilterAudio        ,
        onlyMeshes       : state.ShowFilterMeshes       ,
        onlyVisible      : state.ShowFilterVisible      ,
        showChildren     : false  , 
        showFilterSelected: false
      }
  
      mutex.release()
      
      this.syncState({
        filterOptions, 
        tree : this.tree, 
        loading: false,
        searchText: state.SearchFilter
      });

      await this.recalculateObjectsToDisplay()
    }

    globalCallbacks.refreshSceneTree = async (modifiedObjects, properties) => 
    {
      if(properties.Name || 
        properties.Selected || 
        properties.Expanded || 
        properties.FixtureId || 
        properties.ElectricalConnections || 
        properties.LoadConnectedCalculated || 
        properties.LoadConnectedCalculated || 
        properties.Visible || 
        properties.VisibleInSceneTree || 
        properties.HasCableSelected || 
        properties.FullyConnected || 
        properties.LoadConnectedMeasured || 
        properties.LoadConnectedCalculated || 
        properties.Existing || 
        properties.ObjectId)
      {
        await mutex.acquire()

        for(let completeObject of modifiedObjects){
          this.updateTreeSelection(completeObject);
        }

        mutex.release()

        let currentScroll = this.state.scrollOffset

        if (this.selectionList.length === 1 && this.selectionList[0] !== this.LastSelectedUuid)
        {
          // Move tree to selection
          let treeOffset = this.tree.findIndex(item => item.UUID === this.selectionList[0])
          let finalOffset = treeOffset
          for (let i = 0; i < treeOffset; i++)
          {
            let hiddenCount = this.getHiddentNodeCount(this.tree[i])
            i += hiddenCount;
            finalOffset -= (hiddenCount);
          }
          finalOffset -= 10
          currentScroll = finalOffset * ELEMENT_HEIGHT
          if (this.scrollRef?.current && !properties.ObjectId)
          {
            this.scrollRef.current.scrollTo(0, currentScroll)
          }
        }
        this.LastSelectedUuid  =undefined
    
        this.syncState({tree : this.tree, change : !this.state.change});
      }
    }

    globalCallbacks.displayDrawingMenu = async () =>
    {
      let drawingList = await window.LR_GetDrawings();

      let activeDrawing = "";
      let drawings = drawingList.Drawings.map(drawing =>
      {
        if(drawing.UUID === drawingList.ActiveDrawing) { activeDrawing = drawing.Path; }

        return(
        {
          key   : drawing.UUID,
          value : drawing.Path,
          text  : drawing.Name,
        });
      });

      this.syncState({drawings, activeDrawing});


      let drawingSettings = await window.LR_GetDrawingSettings()
      let obj = {}
      drawingSettings.StructuralCalculationObjects.forEach(e=>{obj[e] = true})

      this.syncState({StructuralCalculationObjects: obj, UseStructuralCalculationObjects: drawingSettings.StructuralCalculationObjects.length > 0})
    }
  }

  // ---------------------------------------------------------------------------------
  // Drag and Drop functions

  onDragStart = (UUID) =>
  {
    this.syncState({dragged : UUID});
  }

  onDragEnd = () =>
  {
    this.syncState({dragged : undefined});
  }

  onDrop = (e) =>
  {
    let request = 
    {
      ParentUUID      : 0,
      ChildUUID       : this.state.dragged,
      GlobalCoordinates : !e.altKey,
    }
    window.LR_SetChild(request);
  }

  // ---------------------------------------------------------------------------------
  // Open drawing

  openDrawing = async (e, {value}) =>
  {
    this.syncState({activeDrawing: value});
    await window.LR_OpenLRWFile({Path: value});
  }

}

export default SceneTreeHierarchy;
