
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Input, Table, Label, Popup} from 'semantic-ui-react'
import TimePhase from "./TimePhase";
import LocalizedStrings from "../../localization/SceneTimeLineComponent";
import UnitInput from '../Basics/BasicUnitInput';
import { BASE_UNIT_DATE } from '../../util/defines';
import { globalCallbacks }  from "../../util/callback";

class TimePhaseGroup extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      name  : undefined,
    };

  }

  render() 
  {
    let phase = this.props.phase
    return(
      <React.Fragment>
        
      <>
      { window.IsIOS ? this.renderTableRowInIos() 
      : <Table.Row  draggable       = {true}
                    onDragStart     = {(e) => {if (this.props.onDragStart) {this.props.onDragStart()}e.stopPropagation();}}
                    onDragEnter     = {(e) => {if (this.props.onDragEnter) {this.props.onDragEnter()}e.stopPropagation();}}
                    onDragEnd       = {(e) => {if (this.props.onDragEnd) {this.props.onDragEnd()} e.stopPropagation();}}>
        <Table.Cell collapsing >
        <Popup trigger={<Icon link name="play circle outline" color={this.state.coloredPlayButton ? "orange" : null} onClick = {() => {
          if(globalCallbacks.PlayTimeLine){globalCallbacks.PlayTimeLine(phase)}
        }}/>}>
                  <p>{LocalizedStrings.PlayBackTimeLine}</p>
        </Popup>
        </Table.Cell>
        <Table.Cell collapsing>
          <Popup content={LocalizedStrings.ExpandTimePhase}
                trigger={
                  <Icon name={phase.Expanded ? "caret down" : "caret right"} onClick={this.onCollapse}/>
                }/>
        </Table.Cell>
        <Table.Cell colSpan="2">
          <Input  size      = "large"
                  name      = "Name" 
                  value     = {this.getInputEntry()}
                  transparent
                  onChange  = {(e, {value}) => this.onValueChanged(value)} 
                  onBlur    = {this.onBlur}
                  onKeyDown = {(e) => {if(e.keyCode === 13) {this.onBlur(); e.target.blur()}}}
                  style     = {{width: "calc(100% - 20px)"}}
                  />    
          </Table.Cell>
          <Table.Cell textAlign="center" colSpan="2"  collapsing>
                <UnitInput label={false} readOnly value={phase.CurrentDate} baseUnit={BASE_UNIT_DATE}/>                  
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
                  <Popup content={LocalizedStrings.TimeLineStepEdit}
                  trigger={                      
                      <Icon name='edit' onClick={()=>{this.openEditTimeLineChange(phase.UUID)}}/>      
                    }/>    
                </Table.Cell>
            <Table.Cell textAlign="center" collapsing>
              <Popup content={LocalizedStrings.DeleteTimePhase}
              trigger={
            <Icon   name='delete'  
                    color = "red" 
                    onClick  = {(e) => {this.onDeleteTimePhase(); e.stopPropagation();}}/> 
              }/>
            </Table.Cell>
      </Table.Row>
      }</>
      {phase.Expanded ? 
        <>{
          phase.TimePhaseChangeObjects.map((change, i) =>
          {
            return(
                <TimePhase  openEditTimeLineStepChange = {()=>{this.openEditTimeLineStepChange(change.UUID)}}
                            key={change.UUID}
                            change={change}
                            onDragStart     = {() => {this.draggedIndex = i;}}
                            onDragEnter     = {() => {this.reorderTimePhaseChange(i);}}
                            onDragEnd       = {() => {this.draggedIndex = -1; this.setTimePhaseChangeOrder();}}/>)
          }
        )
        }
        <Table.Row>
          <Table.Cell colSpan="6">
            <Label as="a" basic onClick={() => this.onAddNewTimePhaseChange()}>
              <Icon name="plus"/>{LocalizedStrings.AddNewTimePhaseChange}
            </Label>
          </Table.Cell>
          <Table.Cell/>
          <Table.Cell/>
        </Table.Row>
        </> : null}
      </React.Fragment>
    );
  }

  renderTableRowInIos = () => {
    let phase = this.props.phase
    return (
    <Table.Row >
      <div style={{ display: "flex", marginLeft: "1rem", alignItems: "center"}}>
        <Popup content={LocalizedStrings.ExpandTimePhase}
               trigger={
                <Icon name={phase.Expanded ? "caret down" : "caret right"} onClick={this.onCollapse}/>
         }/>
          <Input  size      = "large"
                  name      = "Name" 
                  value     = {this.getInputEntry()}
                  transparent
                  onChange  = {(e, {value}) => this.onValueChanged(value)} 
                  onBlur    = {this.onBlur}
                  onKeyDown = {(e) => {if(e.keyCode === 13) {this.onBlur(); e.target.blur()}}}
                  style     = {{width: "calc(100% - 20px)"}}/>  
           <UnitInput label={false} readOnly value={phase.CurrentDate} baseUnit={BASE_UNIT_DATE}/> 
           <Popup content={LocalizedStrings.TimeLineStepEdit}
                  trigger={                      
                      <Icon name='edit' onClick={()=>{this.openEditTimeLineChange(phase.UUID)}}/>      
                    }/> 
           <Popup content={LocalizedStrings.DeleteTimePhase}
              trigger={
            <Icon   name='delete'  
                    color = "red" 
                    onClick  = {(e) => {this.onDeleteTimePhase(); e.stopPropagation();}}/> 
              }/> 
      </div>
    </Table.Row>
    )
  }

  openEditTimeLineStepChange(uuid){
    if(this.props.openEditTimeLineStepChange){
      this.props.openEditTimeLineStepChange(uuid)
    }
  }

  openEditTimeLineChange(uuid)
  {
    if(this.props.openEditTimeLineChange){
      this.props.openEditTimeLineChange(uuid)
    }
    //TODO: window.LR_OpenEditTimeLineChange({UUID: this.props.phase.UUID})
  }
  

  onCollapse = () =>
  {
    window.LR_SetTimePhase({ UUID : this.props.phase.UUID, Expanded: ! this.props.phase.Expanded });
  }

  onAddNewTimePhaseChange = () =>
  {
    window.LR_AddNewTimePhaseChange({ UUID : this.props.phase.UUID });
  }

  onValueChanged = (value) =>
  {
    this.setState({name:value})
  }

  onBlur = () =>
  {
    window.LR_SetTimePhase({UUID: this.props.phase.UUID, Name: this.state.name});
    this.setState({name:undefined})
  }

  getInputEntry = () =>
  {
    return this.state.name ? this.state.name : this.props.phase.Name
  }

  onDeleteTimePhase = () =>
  {
    window.LR_DeleteTimePhase({UUID: this.props.phase.UUID});
  }

  // Drag and Drop the PhaseChanges
  reorderTimePhaseChange = (currentPlace) =>
  {
    if (this.draggedIndex < 0) { return; }
    let timePhaseChanges = [...this.props.phase.TimePhaseChangeObjects];
    let draggedIndex = this.draggedIndex;
    let j = 0;
    let temp = 0;

    if(currentPlace > draggedIndex)
    {
      for(j = currentPlace; j > draggedIndex; j--)
      {
        temp = timePhaseChanges[j].Order;
        timePhaseChanges[j].Order   = timePhaseChanges[j-1].Order;
        timePhaseChanges[j-1].Order = temp;
      }
    } else if(currentPlace < draggedIndex)
    {
      for(j = currentPlace; j < draggedIndex; j++)
      {
        temp = timePhaseChanges[j].Order;
        timePhaseChanges[j].Order   = timePhaseChanges[j+1].Order;
        timePhaseChanges[j+1].Order = temp;
      }
    }

    timePhaseChanges.sort(function(a, b) {return a.Order - b.Order});
    this.draggedIndex = currentPlace;

    this.props.phase.TimePhaseChangeObjects = timePhaseChanges;
    this.forceUpdate();
  }

  setTimePhaseChangeOrder = () =>
  {
    window.LR_SetTimePhaseChangeOrder(this.props.phase.TimePhaseChangeObjects);
  }

}

export default TimePhaseGroup;

