//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
const Editor = React.lazy(()=>import('jsoneditor-react').then(m => ({default: m.JsonEditor})))
import 'jsoneditor-react/es/editor.min.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Icon, Input, Label, List, Menu, Message, Segment, Table } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/UserOverview";
import { FetchActiveUser, FetchCollaborators, FetchCustomProjectData, FetchGroups, FetchMembers, FetchProject, FetchProjectReviewTemplate, FetchShareLink, FetchSlackChannels, FetchUserReviewTemplates, hasFetched } from '../../redux/actions/fetch_actions';
import { lrServerConnection } from '../../redux/light_right_server_connection';
import { ACTIVE_PROJECT, ACTIVE_USER, COLLABORATORS, CUSTOM_PROJECT_DATA, GROUPS, MEMBERS, PROJECT_REVIEW_TEMPLATE, SHARE_LINKS, SLACK_CHANNELS, USER_REVIEW_TEMPLATES } from "../../redux/redux_defines";
import { ValidateEmail } from '../../util/defines';
import { withRouter } from '../../webApp/WebRouter';
import LRModal from '../Basics/BasicModal';
import CollapsableDevider from '../Basics/CollapsableDevider';
import { addNotification } from '../NotificationDisplay/NotificationDisplay';
import General from './General';

import { globalCallbacks } from "../../util/callback";
import "./ProjectSettings.css"

class ProjectSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSelection: null,
      deleteModal: false,

      searchQuery: "",
      openGroup: false,
      editedRights: null,
      changedRights: false,
      shareLinkModalOpen: false,
      shareLinkName: "Share link",
      copied: "",

      newCustomData: undefined
    };
    FetchCollaborators(true);
    FetchMembers(true);
    FetchGroups(true);
    this.jsonEditor = new React.createRef()
  }

  genMenuProps(name) {
    return {
      name,
      active: name === this.props.activeMenu,
      as: Link,
      to: `/${this.props.match.params.username}/${this.props.match.params.projectname}/settings/${name}`,
    };
  }

  render() {
    if(!lrServerConnection.__LOGGEDINUSER) {
      return null
    }
    FetchCustomProjectData();
    return (
      <div>
        <Grid stackable>
          <Grid.Column width={4}>
            <Menu secondary vertical>
              <Menu.Item {...this.genMenuProps("general")}>
                <Icon name="settings" />
                {LocalizedStrings.General}
              </Menu.Item>
              <Menu.Item {...this.genMenuProps("access")}>
                <Icon name="user" />
                {LocalizedStrings.Access}
              </Menu.Item>
              <Menu.Item {...this.genMenuProps("offline")}>
                <Icon name="wifi" />
                {LocalizedStrings.OfflineServer}{" "}
              </Menu.Item>
              <Menu.Item {...this.genMenuProps("review")}>
                <Icon name="eye" />
                {LocalizedStrings.Review}{" "}
              </Menu.Item>
              <Menu.Item {...this.genMenuProps("slack")}>
                <Icon name="slack" />
                {LocalizedStrings.Slack}
              </Menu.Item>
              <Menu.Item {...this.genMenuProps("customData")}>
                <Icon name="database" />
                {LocalizedStrings.CustomData}
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={12}>
            {this.props.activeMenu === "general" && this.props.project.hasFetched ? (
              <General drawingSettings={this.props.project.data.DrawingSettings} description={this.props.project.data.description}/>
            ) : null}
            {this.props.activeMenu === "access" ? this.renderAccess() : null}
            {this.props.activeMenu === "review" ? this.renderReviewTemplateSelect() : null}
            {this.props.activeMenu === "slack" ? this.renderSlack() : null}
            {this.props.activeMenu === "customData" ? this.renderCustomData() : null}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  //-------------------------------------------------
  // Custom data tab

  renderCustomData = () => {
    if (!this.props.customData || !this.props.customData.data)
      return <Segment placeholder><Header icon>{LocalizedStrings.NoCustomDataFetched}</Header></Segment>

    const initialData = this.props.customData.data.customData // editor is an uncontrolled component
    return (
        <Grid>
          <Grid.Row>
            <Grid.Column width="16">
              <Editor
                ref = {this.jsonEditor}
                value={initialData}
                onChange={this.customDataChange}
                onValidate={this.onValidate}
                mode = "code"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16">
              <Button
                disabled={!this.state.newCustomData || !(this.state.newCustomData instanceof Object)}
                positive
                floated="right"
                onClick={this.updateCustomData}
              >
                {LocalizedStrings.Apply}
              </Button>
              <Button
                disabled={!this.state.newCustomData}
                negative
                floated="right"
                onClick={this.cancelCustomDataChanges}
              >
                {LocalizedStrings.Cancel}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
    );
  }

  onValidate = (json) => {
    let errors = [];

    if (!(json instanceof Object)) {
      errors.push({
        path: [],
        message: 'The data should be an instance of object (so an array or an object)'
      });
    }

    return errors;
  }

  customDataChange = (newCustomData) => {
    this.setState({newCustomData})
  }

  updateCustomData = () => {
    if (this.state.newCustomData instanceof Object) {
      lrServerConnection.setCustomDataOnProject(this.state.newCustomData).then(() => {
        FetchCustomProjectData(true);
        this.setState({newCustomData: undefined})
      })
    }
  }

  cancelCustomDataChanges = () => {
    if (this.jsonEditor.current) 
    {
      const jsonData = this.props.customData.data.customData
      this.jsonEditor.current.jsonEditor.set(jsonData)
    }
    this.setState({newCustomData: undefined})
  }

  //-------------------------------------------------

  renderReviewTemplateSelect = () => {
    FetchUserReviewTemplates();
    FetchProjectReviewTemplate();

    let currTemplate = hasFetched(this.props.projectReviewTemplate)
      ? this.props.projectReviewTemplate.data
      : {};
    let defaultTemplateOption = currTemplate ? currTemplate._id : undefined;

    let templates = hasFetched(this.props.userReviewTemplates)
      ? this.props.userReviewTemplates.data
      : [];
    let templateOptions = templates.map((template) => {
      return { key: template._id, value: template._id, text: template.name };
    });

    return (
      <Form.Select
        size="mini"
        label={LocalizedStrings.SelectReviewTemplate}
        options={templateOptions}
        value={this.state.templateSelection ?? defaultTemplateOption}
        fluid
        selection
        onChange={this.setProjectTemplate}
      ></Form.Select>
    );
  };

  setProjectTemplate = (e, { value }) => {
    this.setState({ templateSelection: value });
    lrServerConnection.setProjectReviewTemplate({ reviewId: value });
  };

  

  onActiveSelectionChanged = (_, { value }) => {
    this.setState({
      activeSelection: value,
    });
  };

  renderAccess() {
    FetchMembers();
    let members = hasFetched(this.props.members)
      ? this.props.members.data.members
      : [];
    let groups = hasFetched(this.props.members)
      ? this.props.members.data.groups
      : [];
    FetchProject();
    FetchShareLink();
    let sharelinks = this.props.sharelinks.data

    if (hasFetched(this.props.members) && this.state.editedRights === null) {
      this.setState({
        editedRights: this.props.members.data,
      });
    }

    if (this.state.editedRights !== null) {
      members = this.state.editedRights.members;
      groups = this.state.editedRights.groups;
    }

    FetchActiveUser()

    const loggedIn = 
      hasFetched(this.props.loggedIn) 
      ? this.props.loggedIn.data.username 
      : ""
    
    let hasAdmin = false
    let masterLocked = false
    let runChecksOnServer = false
    if (hasFetched(this.props.project)) {
      let project = this.props.project.data
      hasAdmin = 
        project.owner.username === loggedIn
        || project.members.some(m => m.admin && m?.user?.username === loggedIn)
        || project.groups.some(g => g.admin && g.group.members.some(m => m.username))
      masterLocked = project.masterLocked
      runChecksOnServer = project.runChecksOnServer
    }

    return (
      <div style={{ width: "60%"}}>
        {this.addMemberModal()}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{LocalizedStrings.MemberOrGroup}</Table.HeaderCell>
              <Table.HeaderCell>{LocalizedStrings.Write}</Table.HeaderCell>
              <Table.HeaderCell>{LocalizedStrings.Admin}</Table.HeaderCell>
              <Table.HeaderCell>{LocalizedStrings.Role}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {members.map((member, i) => {
              return this.renderMemberRow(member, i, members, hasAdmin)
            })}
            {groups.map((group, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <Label style={{ padding: 5, marginRight: "0.5em" }}>G</Label>
                  {group.group.name}
                  { hasAdmin ?
                    <div style={{ float: "right" }}>
                      <Icon
                        name="delete"
                        color="red"
                        onClick={this.removeGroup(i)}
                      />
                    </div> : null
                  }
                </Table.Cell>
                <Table.Cell
                  style={{ textAlign: "center" }}
                  negative={!group.write}
                  positive={group.write}
                  onClick={this.toggleGroupPerm(i, "write", hasAdmin)}
                >
                  {group.write ? (
                    <Icon name="check" color="green" />
                  ) : (
                    <Icon name="times" color="red" />
                  )}
                </Table.Cell>
                <Table.Cell
                  style={{ textAlign: "center" }}
                  negative={!group.admin}
                  positive={group.admin}
                  onClick={this.toggleGroupPerm(i, "admin", hasAdmin)}
                >
                  {group.admin ? (
                    <Icon name="check" color="green" />
                  ) : (
                    <Icon name="times" color="red" />
                  )}
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colspan="4">
                {hasAdmin ? 
                <><Button
                    icon="plus"
                    primary
                    content={LocalizedStrings.AddMember}
                    onClick={this.openAddMember}
                  />
                  <Button
                    icon="plus"
                    primary
                    content={LocalizedStrings.AddGroup}
                    onClick={this.openAddGroup}
                  />
                  <Button
                    positive={this.state.changedRights}
                    disabled={!this.state.changedRights}
                    content={LocalizedStrings.Apply}
                    floated="right"
                    onClick={this.onApply}
                  />
                </> : null}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        {this.addGroupModal()}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{LocalizedStrings.Sharelinks}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{
            sharelinks.map((link, i) => <Table.Row key={i}><Table.Cell>
                <Label style={{padding: 5, marginRight: "0.5em"}} onClick={this.onCopy(link)}>
                  <Icon name="copy" style={{margin:0}}/>
                </Label>
                {link.name}
                <Icon name="delete" color="red" style={{float: "right"}} onClick={this.deleteLink(link)}/>
              </Table.Cell></Table.Row>)
          } </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>
                {hasAdmin ? <Button
                  icon="plus"
                  primary
                  content={LocalizedStrings.AddSharelink}
                  onClick={()=>this.setState({shareLinkModalOpen: true})}
                /> : null}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        {this.addShareLinkModal()}
        <Form>
          <Form.Group>
            <Form.Checkbox label={LocalizedStrings.LockMaster} checked={masterLocked} onChange={this.toggleMasterLock}/>
          </Form.Group>
          <Form.Group>
            <Form.Checkbox label={LocalizedStrings.RunChecksOnServer} checked={runChecksOnServer} onChange={this.togglerunChecksOnServer}/>
          </Form.Group>
        </Form>
      </div>
    );
  }

  renderMemberRow = (member, i, members, hasAdmin) => {
    const isInvite = !member.user && member.invite.pending
    const nameOrMail = isInvite ? member.invite.issuedForMail : member.user.name

    let memberRole = member.role 

    return (
      <Table.Row style={{ width: "100vw" }}>
        <Table.Cell>
          <Label style={{ padding: 5, marginRight: "0.5em" }}>{isInvite ? LocalizedStrings.Pending : "M"}</Label>
          {nameOrMail}
          {(isInvite || !this.indexIsOwner(i, members)) && hasAdmin ? (
            <div style={{ float: "right" }}>
              <Icon
                name="delete"
                color="red"
                onClick={() => {
                  this.onRemoveUserClicked(nameOrMail);
                }}
              />
            </div>
          ) : null}
        </Table.Cell>
        <Table.Cell
          style={{ textAlign: "left"}}
          negative={!member.write}
          positive={member.write}
          onClick={this.toggleMemberPerm(i, "write", hasAdmin, isInvite)}
        >
          {member.write ? (
            <Icon name="check" color="green" style={{ marginLeft:"0.5rem" }} />
          ) : (
            <Icon name="times" color="red"  style={{ marginLeft:"0.5rem" }}  />
          )}
        </Table.Cell>
        <Table.Cell
          style={{ textAlign: "left" }}
          negative={!member.admin}
          positive={member.admin}
          onClick={this.toggleMemberPerm(i, "admin", hasAdmin, isInvite)}
        >
          {member.admin ? (
            <Icon name="check" color="green"  style={{ marginLeft:"0.5rem" }}  />
          ) : (
            <Icon name="times" color="red"  style={{ marginLeft:"0.5rem" }}  />
          )}
        </Table.Cell>
        <Table.Cell 
          style={{ textAlign: "left" }}
          negative={!member.admin}
          positive={member.admin}
          >
            <Form.Input
              value={memberRole}
              onChange={(e, { value }) => {this.handleRoleChange(i, hasAdmin, value)}}>
            </Form.Input>
        </Table.Cell>
      </Table.Row>
    )
  }

  handleRoleChange = (i, admin, value) => {
    if(!admin) return
    this.setState({
      changedRights: true,
      editedRights: {
        ...this.state.editedRights,
        members: this.state.editedRights.members.map((mem, j) => ({
          ...mem,
          role:
            j === i  ? value : mem.role
        })),
      },
    });
  }
  addShareLinkModal() {
    let invalidName = this.state.shareLinkName === "" || (this.props.sharelinks.data.find(link => link.name === this.state.shareLinkName) ? "Name already taken" : false)
    return (
      <LRModal
        open={this.state.shareLinkModalOpen}
        size="mini"
        title={LocalizedStrings.AddSharelink}
        onCancelClick={this.onCancelShareLink}
        onOkClick={this.addLink}
        okDisabled={invalidName}>
        <Form>
          <Form.Input
            label={LocalizedStrings.Name}
            value={this.state.shareLinkName}
            error={invalidName}
            onChange={(_, {value}) => this.setState({shareLinkName: value})}
          />
        </Form>
      </LRModal>
    )
  }

  onCancelShareLink = () => this.setState({
    shareLinkModalOpen: false,
    shareLinkName: "Share link"
  })

  onCopy = (linkObj) => () => {
    navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + "/" + lrServerConnection.__USERNAME + "/" + lrServerConnection.__PROJECT + "?sharetoken=" + linkObj.token)
    addNotification(LocalizedStrings.CopySuccessful)
    //console.log(window.location.protocol + "//" + window.location.host + "/" + lrServerConnection.__USERNAME + "/" + lrServerConnection.__PROJECT + "/token/" + linkObj.token)
  }

  deleteLink = (linkObj) => () => {
    lrServerConnection.deleteShareLink(linkObj.name)
    .then(() => FetchShareLink(true))
  }

  addLink = () => {
    lrServerConnection.createShareLink(this.state.shareLinkName)
    .then(() => FetchShareLink(true))
    this.onCancelShareLink()
  }

  toggleMasterLock = (_, {checked}) => {
    lrServerConnection.setMasterLock(checked)
    .then(() => FetchProject(true))
  }

  togglerunChecksOnServer = (_, {checked}) => {
    lrServerConnection.setrunChecksOnServer(checked)
    .then(() => FetchProject(true))
  }

  toggleMemberPerm = (i, perm, admin, isInvite=false) => () => {
    if(!admin) return
    this.setState({
      changedRights: true,
      editedRights: {
        ...this.state.editedRights,

        members: this.state.editedRights.members.map((val, j) => ({
          ...val,
          [perm]:
            j === i && (isInvite || !this.indexIsOwner(i, this.state.editedRights.members)) 
              ? !val[perm]
              : val[perm],
        })),
      },
    });
  };

  toggleGroupPerm = (i, perm, admin) => () => {
    if(!admin) return
    this.setState({
      changedRights: true,
      editedRights: {
        ...this.state.editedRights,
        groups: this.state.editedRights.groups.map((val, j) => ({
          ...val,
          [perm]: j === i ? !val[perm] : val[perm],
        })),
      },
    });
  };

  indexIsOwner = (i, members) =>
    hasFetched(this.props.project)
      ? members[i].user.username === this.props.project.data.owner.username
      : true;

  openAddMember = () => this.setState({ open: true });
  closeAddMember = () => this.setState({ open: false });

  openAddGroup = () => this.setState({ openGroup: true });
  closeAddGroup = () => this.setState({ openGroup: false });

  addGroupModal = () => {
    FetchGroups();
    let groups = hasFetched(this.props.groups) ? this.props.groups.data : [];

    let usedGroups = [];
    if (this.state.editedRights !== null)
      usedGroups = this.state.editedRights.groups;

    let unusedGroups = groups.filter(
      (group) => !usedGroups.find((ug) => ug.group._id === group._id)
    );

    return (
      <LRModal
        open={this.state.openGroup}
        onCancelClick={this.closeAddGroup}
        title={LocalizedStrings.AddGroup}
      >
        <List celled>
          {unusedGroups.map((group) => (
            <List.Item key={group.name} onClick={this.addGroup(group._id)}>
              <List.Content>{group.name}</List.Content>
            </List.Item>
          ))}
        </List>
      </LRModal>
    );
  };

  addGroup = (groupid) => () => {
    let foundGroup = this.props.groups.data.find(
      (group) => group._id === groupid
    );
    if (foundGroup) {
      this.setState({
        changedRights: true,
        editedRights: {
          ...this.state.editedRights,
          groups: [
            ...this.state.editedRights.groups,
            { group: foundGroup, write: false, admin: false },
          ],
        },
        openGroup: false,
      });
    }
  };

  removeGroup = (i) => () => {
    this.setState({
      changedRights: true,
      editedRights: {
        ...this.state.editedRights,
        groups: this.state.editedRights.groups.filter((_, j) => i !== j),
      },
    });
  };

  addMemberModal = () => {
    FetchCollaborators();
    let collaborators = hasFetched(this.props.collaborators)
      ? this.props.collaborators.data
      : [];

    let members = hasFetched(this.props.members)
      ? this.props.members.data.members
      : [];

    if (this.state.editedRights !== null)
      members = this.state.editedRights.members;

    let showMembers = collaborators.filter((coll) => {
      let isMember = members.find((m) => m.user?.username === coll.username);
      if (isMember) {
        return false;
      }

      let name = coll.name.toLowerCase();
      return name.includes(this.state.searchQuery.toLowerCase());
    });

    const mailNotValid = !ValidateEmail(this.state.inviteMail)
    return (
      <LRModal
        open={this.state.open}
        size="mini"
        title={LocalizedStrings.AddMember}
        noCancel
        onCancelClick={this.closeAddMember}
        onOkClick={this.closeAddMember}
      >
        <Input
          value={this.state.searchQuery}
          onChange={(e, { value }) => {
            this.setState({ searchQuery: value });
          }}
        />
        <List className='project_settings_listContainer' celled style={{ maxHeight: "300px", overflowY: "auto" }}>
          {showMembers.map((user) => (
            <List.Item
              key={user.username}
            >
              <div className='project_settings_listItem'>
                {user.name}
                <Button
                  className='project_settings_buttonList'
                  floated="right"
                  onClick={() => this.onAddUserToProjectClicked(user.username)}
                >{LocalizedStrings.AddUser}</Button>
              </div>
            </List.Item>
          ))}
        </List>
        <CollapsableDevider header={LocalizedStrings.InviteHeader} icon="mail" open={this.state.openInvite} update={()=> this.setState({openInvite: !this.state.openInvite})}>
          <Form>
            <Form.Group>
              <Form.Input width={12} fluid placeholder={LocalizedStrings.Mail} onChange={(e, {value}) => { this.setState({inviteMail: value}) }}/>
              <Form.Button width={4} disabled={mailNotValid} fluid positive onClick={this.sendInvite}>{LocalizedStrings.Send}</Form.Button>
            </Form.Group>
          </Form>
          { (mailNotValid || this.state.mailError) && <Message error>{this.state.mailError ?? LocalizedStrings.InvalidMail}</Message>}
        </CollapsableDevider>
      </LRModal>
    );
  };

  sendInvite = () => {
    lrServerConnection.inviteUser(this.state.inviteMail).then(async(res) => {
      if (res.status >= 300) {
        res = await res.json()
        this.setState({mailError: res.message})
      }
      else {
        FetchCollaborators(true)
        FetchMembers(true)
        this.setState({mailError: undefined, editedRights: { ...this.state.editedRights, members: [ ...this.state.editedRights.members, res.memberInvite]}});
      }
    })
  }

  renderSlack() {
    FetchProject();
    FetchSlackChannels();

    let project = this.props.project;
    let slackChannels = this.props.slackChannels;

    let select = "";
    if (project.hasFetched) {
      select = project.data.slackChannel;
    }

    return (
      <div>
        <Form.Select
          loading={project.isFetching || slackChannels.isFetching}
          value={select}
          options={(slackChannels.data.length ? slackChannels.data : []).map((channel) => ({
            text: channel.name,
            value: channel.name,
          }))}
          onChange={(e, { value }) => {
            globalCallbacks
              .setSlackChannel(value)
              .then(() => FetchProject(true));
          }}
        />
      </div>
    );
  }


  onAddUserToProjectClicked = (username) => {
    let foundUser = null;

    if (hasFetched(this.props.collaborators)) {
      foundUser = this.props.collaborators.data.find(
        (coll) => coll.username === username
      );
    }

    if (foundUser) {
      this.setState({
        changedRights: true,
        open: false,
        editedRights: {
          ...this.state.editedRights,
          members: [
            ...this.state.editedRights.members,
            { user: foundUser, write: false, admin: false },
          ],
        },
      });
    }
  };

  // removes a user by updating the state var editedRights
  // user can be given by username or mail (regular users vs pending invites)
  onRemoveUserClicked = (nameOrMail) => {
    this.setState({
      changedRights: true,
      editedRights: {
        ...this.state.editedRights,
        members: this.state.editedRights.members.filter(
          (memb) => {
            const compareByName = memb?.user?.username
            const compareTo = compareByName ? memb.user.username : memb.invite.issuedForMail
            return compareTo !== nameOrMail}
        ),
      },
    });
  };

  onApply = () => {
    lrServerConnection.updateMembers(
      this.state.editedRights.members,
      this.state.editedRights.groups
    );
    this.setState({ changedRights: false });
  };
}

const mapStateToProps = (state) => {
  return {
    collaborators: state[COLLABORATORS],
    members: state[MEMBERS],
    project: state[ACTIVE_PROJECT],
    slackChannels: state[SLACK_CHANNELS],
    userReviewTemplates: state[USER_REVIEW_TEMPLATES],
    projectReviewTemplate: state[PROJECT_REVIEW_TEMPLATE],
    groups: state[GROUPS],
    loggedIn: state[ACTIVE_USER],
    sharelinks: state[SHARE_LINKS],
    customData: state[CUSTOM_PROJECT_DATA]
  };
};

export default connect(mapStateToProps)(withRouter(ProjectSettings));

