import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Checkbox, Divider, DropdownItemProps, Form, Grid, Header, Icon, Label, Tab, TabPane, Table } from "semantic-ui-react";
import LocalizedStrings from "../../localization/GlobalSettingsModal";
import LocalizedStrings_OFields from "../../localization/LightRightObjectsFields";
import { globalCallbacks } from '../../util/callback';
import { LRVector3D, globalWindowInterface } from '../../util/callbackTypes';
import { BASE_UNIT_NUMBER, BASE_UNIT_PAGE_LENGTH, BASE_UNIT_STRING, CompareObjects, POLYGON_LINE_TYPE } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import ColorInputField from '../ColorPicker/ColorInputField';
import { cie2hex } from '../ColorPicker/utilities';

declare const window: globalWindowInterface

const headerStyle = {
    cursor: 'pointer',
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
};

interface IPdfFormatDef {
    FontName : string
    FontSize: number
    Bold: boolean
    Italic: boolean
    Underlined: boolean
    SpaceBefore: number
    SpaceAfter: number
    Color: LRVector3D
}

enum CELL_SIDES {
    Left,
    Right,
    Top,
    Bottom
}

interface PdfTableCellBorderDef {
    LineWidth: number
    Padding: number
    PolygonLineType: POLYGON_LINE_TYPE
    LineColor: LRVector3D
}

interface PdfTableCellDef {
    BackgroundColor: LRVector3D
    Left: PdfTableCellBorderDef
    Right: PdfTableCellBorderDef
    Top: PdfTableCellBorderDef
    Bottom: PdfTableCellBorderDef
}

interface PdfTableFormatDef {
    Striped: boolean
    UseOuterShellSettings: boolean
    Header: PdfTableCellDef
    Cells: PdfTableCellDef
    AltCells: PdfTableCellDef
    OuterShell: PdfTableCellDef
    GroupHeader: PdfTableCellDef
}

export interface IMarginInterface {
    MarginTop: number,
    MarginLeft: number,
    MarginRight: number,
    MarginBottom: number,
    Height: number,
    Width: number,
}
export interface IPdfFormat {
    H1: IPdfFormatDef
    H2: IPdfFormatDef
    H3: IPdfFormatDef
    H4: IPdfFormatDef
    Text: IPdfFormatDef
    CellHeader: IPdfFormatDef
    FooterText: IPdfFormatDef
    Toc1: IPdfFormatDef
    Toc2: IPdfFormatDef
    Toc3: IPdfFormatDef
    Toc4: IPdfFormatDef
    TableFormat: PdfTableFormatDef
    PageMargins: IMarginInterface
}

function OneEntry(this: unknown, {
    fonts, 
    data,
    onFormatChange
}: {
    fonts : Array<string>,
    data: IPdfFormatDef,
    onFormatChange: (name: string, val: any) => void
}) {
    let {
        Bold: bold,
        Italic: italic,
        Underlined: underlined
    } = data    

    const [fontName, setFontName] = useState(data.FontName);      

    let fontOptions = fonts ? Object.keys(fonts).map( key=>
        {
            return{
                text: fonts[key],
                key: key,
                value: key}
        }) : [];        

    return <div>
        <Grid columns='two' divided>
            <Grid.Row>
                <Grid.Column>
                    <Form.Dropdown
                        search
                        button                        
                        value={data.FontName}
                        size="mini"
                        name={"FontName"}
                        label={LocalizedStrings.FontName}
                        options={fontOptions}
                        onChange={(name, {value}) => {      
                            let currentFont = value.toString(); 
                            setFontName(currentFont)
                            onFormatChange("FontName", value)
                        }}
                    />
                    <UnitInput label={LocalizedStrings.FontSize} baseUnit={BASE_UNIT_NUMBER}
                        onStateUpdate={(name, val) => onFormatChange("FontSize", val)}
                        value={data.FontSize}
                        customLabel={
                            <Button.Group compact>
                                <Button onClick={() => onFormatChange("Underlined", !underlined)} color={underlined ? "grey" : undefined} type="button"> <Icon name="underline" color={underlined ? undefined : "black"} /> </Button>
                                <Button onClick={() => onFormatChange("Bold", !bold)} color={bold ? "grey" : undefined} type="button"> <Icon name="bold" color={bold ? undefined : "black"} /> </Button>
                                <Button onClick={() => onFormatChange("Italic", !italic)} color={italic ? "grey" : undefined} type="button"> <Icon name="italic" color={italic ? undefined : "black"} /> </Button>
                            </Button.Group>
                        }
                    />
                    <ColorInputField
                        label={LocalizedStrings.TextColor}
                        labelPosition='right'
                        colorX={data.Color.X}
                        colorY={data.Color.Y}
                        colorL={data.Color.Z}
                        onColorChanged={cie => onFormatChange("Color", { X: cie.fx, Y: cie.fy, Z: cie.f_Y })}
                    />

                </Grid.Column>
                <Grid.Column>
                    <UnitInput label={LocalizedStrings.SpaceBefore}
                        onStateUpdate={(name, val) => onFormatChange("SpaceBefore", val)}
                        baseUnit={BASE_UNIT_PAGE_LENGTH} value={data.SpaceBefore} />
                    <UnitInput label={LocalizedStrings.SpaceAfter}
                        onStateUpdate={(name, val) => onFormatChange("SpaceAfter", val)}
                        baseUnit={BASE_UNIT_PAGE_LENGTH} value={data.SpaceAfter} />
                </Grid.Column>
                <Grid.Column>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
}

function RenderColorItem({
    value,
    onChange,
    label,
    style,
    disabled
}: {
    value: LRVector3D,
    onChange: (v: LRVector3D) => void,
    label: string,
    style: React.CSSProperties,
    disabled?: boolean
}) {
    return <div style={style}>
        <ColorInputField
            disabled={disabled}
            colorX={value.X}
            colorY={value.Y}
            colorL={value.Z}
            label={label}
            onColorChanged={(cie) => onChange({
                X: cie.fx,
                Y: cie.fy,
                Z: cie.f_Y
            })}
        />
    </div>
}

const LINE_TYPE_OPTIONS: DropdownItemProps[] = [
    { value: POLYGON_LINE_TYPE.Line, text: LocalizedStrings_OFields.LineType_Line },
    { value: POLYGON_LINE_TYPE.Dash, text: LocalizedStrings_OFields.LineType_Dash },
    { value: POLYGON_LINE_TYPE.Point, text: LocalizedStrings_OFields.LineType_Point },
    { value: POLYGON_LINE_TYPE.PointDash, text: LocalizedStrings_OFields.LineType_PointDash },
    { value: POLYGON_LINE_TYPE.None, text: LocalizedStrings_OFields.LineType_None },
]

function CellSelector({
    selected,
    disabled,
    onSelectionChanged,
    cellFormat
}: {
    selected: CELL_SIDES,
    disabled?: boolean,
    onSelectionChanged: (side: CELL_SIDES) => void,
    cellFormat: PdfTableCellDef
}) {
    let wh = { width: 260, height: 130 }

    let CButton = ({ children, ...a }) => <Button disabled={disabled} style={{ width: 90, margin: 0, pointerEvents: "auto" }} {...a}>{children}</Button>

    let convertBorderStyle = (i: POLYGON_LINE_TYPE) => {
        if (i === POLYGON_LINE_TYPE.Line) { return "0" }
        else if (i === POLYGON_LINE_TYPE.Dash) { return "3, 2" }
        else if (i === POLYGON_LINE_TYPE.Point) { return "2, 2" }
        else if (i === POLYGON_LINE_TYPE.PointDash) { return "3, 2, 1, 2" }
        else if (i === POLYGON_LINE_TYPE.None) { return "0" }
    }

    let convertLineWidth = (i: PdfTableCellBorderDef) => i.PolygonLineType === POLYGON_LINE_TYPE.None ? 0 : i.LineWidth
    return <div style={{ ...wh, position: "relative" }}>
        <div style={{ ...wh, position: "absolute", left: 0, top: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <svg width="65" height="30">
                <line x1="0"  y1="0"  x2="65" y2="0"  style={{ stroke: cie2hex(cellFormat.Top.LineColor),    strokeWidth: convertLineWidth(cellFormat.Top),    strokeDasharray: convertBorderStyle(cellFormat.Top.PolygonLineType)    }} />
                <line x1="65" y1="0"  x2="65" y2="30" style={{ stroke: cie2hex(cellFormat.Right.LineColor),  strokeWidth: convertLineWidth(cellFormat.Right),  strokeDasharray: convertBorderStyle(cellFormat.Right.PolygonLineType)  }} />
                <line x1="65" y1="30" x2="0"  y2="30" style={{ stroke: cie2hex(cellFormat.Bottom.LineColor), strokeWidth: convertLineWidth(cellFormat.Bottom), strokeDasharray: convertBorderStyle(cellFormat.Bottom.PolygonLineType) }} />
                <line x1="0"  y1="30" x2="0"  y2="0"  style={{ stroke: cie2hex(cellFormat.Left.LineColor),   strokeWidth: convertLineWidth(cellFormat.Left),   strokeDasharray: convertBorderStyle(cellFormat.Left.PolygonLineType)   }} />
                <text x="32.5" y="15" textAnchor="middle" dominantBaseline="central">Content</text>
            </svg>
        </div>
        <div style={{ ...wh, position: "absolute", left: 0, top: 0, display: "flex", justifyContent: "space-between", alignItems: "center", pointerEvents: "none" }}>
            <CButton primary={selected === CELL_SIDES.Left} onClick={() => onSelectionChanged(CELL_SIDES.Left)}>{LocalizedStrings.Left}</CButton>
            <CButton primary={selected === CELL_SIDES.Right} onClick={() => onSelectionChanged(CELL_SIDES.Right)}>{LocalizedStrings.Right}</CButton>
        </div>
        <div style={{ ...wh, position: "absolute", left: 0, top: 0, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", pointerEvents: "none" }}>
            <CButton primary={selected === CELL_SIDES.Top} onClick={() => onSelectionChanged(CELL_SIDES.Top)}>{LocalizedStrings.Top}</CButton>
            <CButton primary={selected === CELL_SIDES.Bottom} onClick={() => onSelectionChanged(CELL_SIDES.Bottom)}>{LocalizedStrings.Bottom}</CButton>
        </div>

    </div>
}

function BasicTableCellSettings({
    value: data,
    disabled,
    onChange,
    noMargin
}: {
    value: PdfTableCellDef,
    disabled?: boolean,
    noMargin?: boolean,
    onChange: (v: PdfTableCellDef) => void
}) {
    let [tab, setTab] = useState(CELL_SIDES.Left)

    let [lastData, setLastData] = useState<PdfTableCellBorderDef>(undefined)

    let rData: PdfTableCellBorderDef
    switch (tab) {
        case CELL_SIDES.Left: rData = data.Left; break;
        case CELL_SIDES.Right: rData = data.Right; break;
        case CELL_SIDES.Top: rData = data.Top; break;
        case CELL_SIDES.Bottom: rData = data.Bottom; break;
    }

    let update = (d: PdfTableCellDef) => {
        setLastData(structuredClone(rData))
        
        onChange(structuredClone(d))
    }

    let copy = () => {
        let d = structuredClone(lastData)
        switch (tab) {
            case CELL_SIDES.Left: data.Left = d; break;
            case CELL_SIDES.Right: data.Right = d; break;
            case CELL_SIDES.Top: data.Top = d; break;
            case CELL_SIDES.Bottom: data.Bottom = d; break;
        }
        onChange(structuredClone(data))
    }

    let applyAll = () => {
        data.Left = structuredClone(lastData)
        data.Right = structuredClone(lastData)
        data.Top = structuredClone(lastData)
        data.Bottom = structuredClone(lastData)
        onChange(structuredClone(data))
    }

    return <>
        <div style={{ display: "flex", alignItems: "stretch" }}>
            <Form.Group style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <div style={{ flex: "1", margin: 5 }}>
                    <label>{LocalizedStrings.LineStyle}</label>
                    <Form.Select
                        disabled={disabled}
                        options={LINE_TYPE_OPTIONS}
                        value={rData.PolygonLineType}
                        onChange={(e, { value }) => {
                            rData.PolygonLineType = value as POLYGON_LINE_TYPE
                            update(data)
                        }}
                        style={{ width: "100%" }}
                    />
                </div>
                <RenderColorItem
                    disabled={disabled || rData.PolygonLineType === POLYGON_LINE_TYPE.None}
                    style={{ margin: 5 }}
                    label={LocalizedStrings.LineColor}
                    value={rData.LineColor}
                    onChange={val => {
                        rData.LineColor = val
                        update(data)
                    }}
                />
                <UnitInput
                    disabled={disabled || rData.PolygonLineType === POLYGON_LINE_TYPE.None}
                    label={LocalizedStrings.LineWidth}
                    name={"LineWidth"}
                    value={rData.LineWidth}
                    baseUnit={BASE_UNIT_PAGE_LENGTH}
                    onStateUpdate={(n, value) => {
                        rData.LineWidth = value
                        update(data)
                    }}
                    min={0}
                />
                {
                    noMargin ? null : <UnitInput
                        disabled={disabled}
                        label={LocalizedStrings.Padding}
                        value={rData.Padding}
                        baseUnit={BASE_UNIT_PAGE_LENGTH}
                        onStateUpdate={(name, v) => {
                            rData.Padding = v
                            update(data)
                        }}
                        min={0}
                    />
                }
            </Form.Group>
            <div style={{ margin: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Button.Group style={{ marginBottom: 10 }} vertical>
                    <Button disabled={!lastData} onClick={copy} style={{ marginBottom: 5 }}>{LocalizedStrings.CopyFromPreviousSide}</Button>
                    <Button disabled={!lastData} onClick={applyAll}>{LocalizedStrings.ApplyToAllSides}</Button>
                </Button.Group>
                <CellSelector disabled={disabled} selected={tab} cellFormat={data} onSelectionChanged={setTab} />
            </div>
        </div>
    </>
}

function TableCellSettings({
    value: data,
    onChange,
    isOuterShell
}: {
    value: PdfTableCellDef,
    onChange: (v: PdfTableCellDef) => void,
    isOuterShell?: boolean
}) {
    return <>
        {
            isOuterShell ?? <RenderColorItem
                style={{ margin: 5 }}
                label={LocalizedStrings.CellColor}
                value={data.BackgroundColor}
                onChange={val => {
                    data.BackgroundColor = val
                    onChange(data)
                }}
            />
        }
        {isOuterShell ?? <Divider />}
        <BasicTableCellSettings noMargin={isOuterShell} value={data} onChange={onChange} />
    </>
}

function CopyMarginModal({
    open,
    onClose,
    onSelected
}: {
    open: boolean,
    onClose: () => void,
    onSelected: (name: IMarginInterface) => void
}) {
    let [isLoading, setIsLoading] = useState(false)
    let [templateMap, setTemplateMap] = useState<{ text: string, value: string, margins: IMarginInterface }[]>([])
    let [selected, setSelected] = useState("")

    const fromCoreFormat = (obj) => {
        return {
            value: obj.Name,
            text: obj.Name,
            margins: {
                Width: obj.PaperSize.X,
                Height: obj.PaperSize.Y,
                MarginLeft: obj.Margin.X,
                MarginTop: obj.Margin.Y,
                MarginRight: obj.Margin.X,
                MarginBottom: obj.Margin.Y,
            }
        } as { value: string, text: string, margins: IMarginInterface }
    }

    useEffect(() => {
        if (open && !isLoading) {
            setIsLoading(true)
            window.LR_GetPaperFormatTemplateMap().then(res => {
                setTemplateMap(res.ResourceMap.paperFormatTemplate.map(fromCoreFormat))
                setIsLoading(false)
            })
        } else {
            setSelected("")
        }
    }, [open])

    return <>
        <LRModal
            open={open}
            onCancelClick={onClose}
            onOkClick={() => {
                onSelected(templateMap.find(i => i.value === selected).margins)
                onClose()
            }}
            title={LocalizedStrings.CopyMarginsFromPaperTemplate}
        >
            <div style={{ minHeight: "30vh"}}>
                <Form.Select
                    search
                    options={templateMap}
                    value={selected}
                    onChange={(e, { value }) => setSelected(value as string)}
                    style={{ width: "100%", marginBottom: 10 }}
                    placeholder={LocalizedStrings.SelectPaperTemplate}
                />
                <Form>
                    {
                        selected && ["MarginLeft", "MarginRight", "MarginTop", "MarginBottom", "Height", "Width"].map(i => <UnitInput
                            label={LocalizedStrings[i]}
                            name={i}
                            key={i}
                            value={templateMap.find(i => i.value === selected).margins[i]}
                            baseUnit={BASE_UNIT_PAGE_LENGTH}
                            readOnly
                        />)
                    }
                </Form>
            </div>
        </LRModal>
    </>
}

interface PrintFormatTemplateMapItemDef {
    Const: boolean,
    Name: string,
    format: IPdfFormat
}

export function PrintSettingsPresetSelector(this: unknown, {
    disabled,
    format,
    withoutButtons,
    selectedPreset,
    defaultPreset,
    presets,
    onChangeSelected,
    onAdd,
    onDelete,
    onUpdate,
    onSetToDefault,
    style
}: {
    disabled?: boolean,
    format?: IPdfFormat,
    withoutButtons?: boolean,
    selectedPreset: string,
    defaultPreset: string,
    presets: PrintFormatTemplateMapItemDef[],
    onChangeSelected: (preset: string) => void
    onAdd?: (preset: string) => void
    onDelete?: (preset: string) => void
    onUpdate?: (preset: string) => void
    onSetToDefault?: (preset: string) => void
    style?: React.CSSProperties
}
) {
    const [createOpen, setCreateOpen] = useState(false)
    const [createName, setCreateName] = useState("New Preset")


    let currFormat = presets.find(i => i.Name === selectedPreset)

    let hasChanged = useMemo(() => {
        if (!format) { return false }

        if (!currFormat) { return true }

        return !CompareObjects(currFormat.format, format)
    }, [presets, format, selectedPreset])

    return <div style={style}>
        <Form.Dropdown disabled={disabled}
            selection
            compact
            search
            options={presets.map(i => ({
                text: <>
                    <Label color={i.Const ? "grey" : "green"}>
                        {i.Const ? LocalizedStrings.ProvidedGenerated : LocalizedStrings.UserGenerated}
                    </Label>
                    {
                        (i.Name !== defaultPreset) ? null :
                            <Label color="blue">
                                {LocalizedStrings.IsDefault}
                            </Label>
                    }
                    {i.Name}
                    {
                        !i.Const && !withoutButtons ?
                            <Icon name="delete"
                                color="red"
                                onClick={(e) => { onDelete?.(i.Name) }} /> : null
                    }
                </>
                , value: i.Name
            }))}
            fluid
            value={selectedPreset}
            onChange={(_, { value }) => onChangeSelected(String(value))} />
        {
            withoutButtons ? null :
                <Button.Group style={{ width: "100%", marginTop: "1rem" }}>
                    <Button disabled={disabled || !hasChanged || currFormat?.Const} compact size="mini" color="blue" content={LocalizedStrings.UpdatePreset} onClick={() => onUpdate?.(selectedPreset)} />
                    <Button.Or />
                    <Button disabled={disabled || !hasChanged} compact size="mini" positive content={LocalizedStrings.CreatePreset} onClick={() => setCreateOpen(true)} />
                    <Button.Or />
                    <Button disabled={disabled || (defaultPreset === selectedPreset)} compact size="mini" color="blue" content={LocalizedStrings.SetDefault} onClick={() => onSetToDefault?.(selectedPreset)} />
                </Button.Group>
        }
        <LRModal open={createOpen}
            title={LocalizedStrings.CreatePreset}
            onOkClick={() => {
                onAdd?.(createName)
                setCreateOpen(false)
                setCreateName("New Preset")
            }}
            onCancelClick={() => {
                setCreateOpen(false)
                setCreateName("New Preset")
            }}>
            <Form>
                <Form.Input value={createName} onChange={(e, { value }) => setCreateName(value)} />
            </Form>
        </LRModal>
    </div>
}

function PresetSelector(this: unknown, {
    disabled,
    format,
    onFormatUpdate,
    withoutButtons
}: {
    disabled?: boolean,
    format?: IPdfFormat,
    onFormatUpdate?: (format: IPdfFormat, currentPreset: string) => void,
    withoutButtons?: boolean
}
) {
    let [printFormatTemplate, setPrintFormatTemplate] = useState<PrintFormatTemplateMapItemDef[]>([])
    let [selectedPrintFormatTemplate, setSelectedPrintFormatTemplate] = useState("Default")


    let loadPrintFormats = () => {
        window.LR_GetPrintFormatTemplateMap().then(res => res.ResourceMap.printFormats).then(setPrintFormatTemplate)
    }

    useEffect(() => {
        globalCallbacks.updatePresetSelectorSettings = loadPrintFormats
        loadPrintFormats()

        window.LR_GetSetSelectedPrintFormatTemplate().then(res => { setSelectedPrintFormatTemplate(res.PrintFormatTemplateName) })

        return () => {
            globalCallbacks.updatePresetSelectorSettings = undefined
        }
    }, [])

    let createPrintFormat = async (name: string) => {
        await window.LR_AddPrintFormatTemplate({
            Name: name,
            Const: false,
            format
        })
        onFormatUpdate(format, name)
        setSelectedPrintFormatTemplate(name)
        loadPrintFormats()
    }

    let updatePrintFormat = async (name: string) => {
        await window.LR_SetPrintFormatTemplate({
            Name: name,
            Const: false,
            format
        })
        loadPrintFormats()
    }

    let deletePrintFormat = (name: string) => {
        window.LR_DeletePrintFormatTemplate({ Name: name })
        setSelectedPrintFormatTemplate("Default")
    }

    let setAsDefault = (name: string) => window.LR_GetSetSelectedPrintFormatTemplate({ Name: name })

    let setSelected = (name: string) => {
        onFormatUpdate(printFormatTemplate.find(i => i.Name === name).format, name)
        setSelectedPrintFormatTemplate(name)
    }


    let [defaultFormatName, setDefaultFormatName] = useState("Default")
    useEffect(() => {
        globalCallbacks.updatePrintSettingsPresetSelectorDefault = () => {
            window.LR_GetSetSelectedPrintFormatTemplate().then(res => setDefaultFormatName(res.PrintFormatTemplateName))
        }

        return () => {
            globalCallbacks.updatePrintSettingsPresetSelectorDefault = undefined
        }
    }, [])


    return <>
        <PrintSettingsPresetSelector
            onAdd={createPrintFormat}
            onChangeSelected={setSelected}
            onSetToDefault={setAsDefault}
            onDelete={deletePrintFormat}
            onUpdate={updatePrintFormat}
            presets={printFormatTemplate}
            selectedPreset={selectedPrintFormatTemplate}
            defaultPreset={defaultFormatName}
            format={format}
            disabled={disabled}
            withoutButtons={withoutButtons}
        />
    </>
}

export default function PrintSettings(
    this: unknown,
    {
        format,
        onFormatChange,
        onAllFormatChange        
    }: {
        format: IPdfFormat,
        onFormatChange: (format: string, name: string, val: any) => void
        onAllFormatChange: (format: IPdfFormat, currentPreset?: string) => void        
    }) {
    const [fontList, setFontList] = useState([]);

    useEffect(() => {
        async function fetchFonts() {
        try {
            const fonts = await globalCallbacks.GetFontMap();
            setFontList(fonts);
        } catch (error) {
            console.error("Error fetching fonts:", error);
        }
        }
    
        fetchFonts();
    }, []);         

    let [activeIndex, setActiveIndex] = useState(0)

    const tableDef = format.TableFormat
    const onTableChange = (name: string, val: any) => onFormatChange("TableFormat", name, val)
    const panes = [
        tableDef.UseOuterShellSettings ? { menuItem: LocalizedStrings.TableOutline, render: () => <TabPane><TableCellSettings value={tableDef.OuterShell} onChange={v => onTableChange("OuterShell", v)} isOuterShell /></TabPane> } : undefined,
        { menuItem: LocalizedStrings.Headers, render: () => <TabPane><TableCellSettings value={tableDef.Header} onChange={v => onTableChange("Header", v)} /></TabPane> },
        { menuItem: LocalizedStrings.GroupHeaders, render: () => <TabPane><TableCellSettings value={tableDef.GroupHeader} onChange={v => onTableChange("GroupHeader", v)} /></TabPane> },
        { menuItem: LocalizedStrings.Cells, render: () => <TabPane><TableCellSettings value={tableDef.Cells} onChange={v => onTableChange("Cells", v)} /></TabPane> },
        tableDef.Striped ? { menuItem: LocalizedStrings.InterleavingCells, render: () => <TabPane><TableCellSettings value={tableDef.AltCells} onChange={v => onTableChange("AltCells", v)} /></TabPane> } : undefined
    ].filter(i => i)

    let [marginModalOpen, setMarginModalOpen] = useState(false)

    const rotate = ()=>{
        let m = format.PageMargins;
        [m.Height, m.Width] = [m.Width, m.Height];
        let mR = m.MarginRight;
        m.MarginRight = m.MarginTop;
        m.MarginTop = m.MarginLeft;
        m.MarginLeft = m.MarginBottom;
        m.MarginBottom = mR;
        onAllFormatChange({
            ...format,
            PageMargins: m
        })
    }

    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ marginBottom: 20, minWidth: 400 }}>
                    <PresetSelector
                        onFormatUpdate={onAllFormatChange}
                        format={format}
                    />
                </div>
            </div>

            <Accordion fluid styled>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={() => setActiveIndex(0)}
                    style={headerStyle}
                >
                    <Icon name='dropdown' />
                    <Header style={{ margin: 0 }} as="h2">{LocalizedStrings.MarginSettings}</Header>
                    {activeIndex === 0 ? <Button style={{ marginLeft: 15 }} onClick={() => setMarginModalOpen(true)}>{LocalizedStrings.CopyMarginsFromPaperTemplate}</Button> : null}
                    {activeIndex === 0 ? <Button style={{ marginLeft: 15 }} onClick={rotate}><Icon name="redo"/>Rotate</Button> : null}
                </Accordion.Title>

                <Accordion.Content active={activeIndex === 0}>
                    <CopyMarginModal
                        open={marginModalOpen}
                        onClose={() => setMarginModalOpen(false)}
                        onSelected={(v) => {
                            onAllFormatChange({
                                ...format,
                                PageMargins: v
                            })
                        }}
                    />
                    <Form>
                        {
                            ["MarginLeft", "MarginRight", "MarginTop", "MarginBottom", "Height", "Width"].map(i => <UnitInput
                                label={LocalizedStrings[i]}
                                name={i}
                                key={i}
                                value={format.PageMargins[i]}
                                baseUnit={BASE_UNIT_PAGE_LENGTH}
                                onStateUpdate={(name, value) => onFormatChange("PageMargins", name, value)}
                            />)
                        }
                    </Form>
                </Accordion.Content>
                <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={() => setActiveIndex(1)}
                    style={headerStyle}
                >
                    <Icon name='dropdown' />
                    <Header style={{ margin: 0 }} as="h2">{LocalizedStrings.FormatDefinition}</Header>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                    <Tab panes={["H1", "H2", "H3", "H4", "Text", "FooterText", "CellHeader", "Toc1", "Toc2", "Toc3", "Toc4"].map(i => ({
                        menuItem: LocalizedStrings[i],
                        render: () =>                            
                            <TabPane>                                
                                <OneEntry
                                    fonts={fontList}
                                    data={format[i]}
                                    key={i}
                                    onFormatChange={(n, v) => onFormatChange(i, n, v)}
                                />
                            </TabPane>
                    }))} />

                </Accordion.Content>
                <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={() => setActiveIndex(2)}
                    style={headerStyle}
                >
                    <Icon name='dropdown' />
                    <Header style={{ margin: 0 }} as="h2">{LocalizedStrings.TableDefinition}</Header>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                    <Table>
                        <Table.Row>
                            <Table.Cell>
                                <p style={{ lineHeight: "0.4rem" }}>
                                    {LocalizedStrings.UseTableOutline}
                                </p>
                                <p
                                    style={{
                                        fontSize: "11px",
                                        fontStyle: "italic",
                                        letterSpacing: 0.25,
                                    }}
                                >
                                    {LocalizedStrings.UseTableOutlineDescription}
                                </p>
                            </Table.Cell>
                            <Table.Cell style={{ width: "225px" }}>
                                <Checkbox
                                    toggle
                                    checked={tableDef.UseOuterShellSettings}
                                    onChange={(e, { checked }) => {
                                        onTableChange("UseOuterShellSettings", checked)
                                    }}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <p style={{ lineHeight: "0.4rem" }}>
                                    {LocalizedStrings.UseInterleavingCells}
                                </p>
                                <p
                                    style={{
                                        fontSize: "11px",
                                        fontStyle: "italic",
                                        letterSpacing: 0.25,
                                    }}
                                >
                                    {LocalizedStrings.UseInterleavingCellsDescription}
                                </p>
                            </Table.Cell>
                            <Table.Cell style={{ width: "225px" }}>
                                <Checkbox
                                    toggle
                                    checked={tableDef.Striped}
                                    onChange={(e, { checked }) => {
                                        onTableChange("Striped", checked)
                                    }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table>
                    <Divider />
                    <Tab panes={panes} />
                </Accordion.Content>
            </Accordion>
        </>
    )
}