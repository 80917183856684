
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Label, Form, Checkbox, Popup } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/NavigationContainer";
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, BASE_UNIT_WEIGHT } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';

class RackNode extends Component 
{
    constructor(props) 
    {
        super(props)
        this.state={
            openModal: false,
            Name: this.props.rack.Name,
            rack : this.props.rack
        };
    }

    render() 
    {
        let rack = this.props.rack;
        return (
            <React.Fragment>
             { window.IsIOS ? this.renderTableRowInIos(rack) 
             : <Table.Row>
                    <Table.Cell collapsing>
                        {!this.props.small ?
                        <Popup content={LocalizedStrings.RackLinkedTemplate}
                            trigger={   
                            <Label size="mini">{rack.TemplateName}</Label>
                        }/> : null}
                    </Table.Cell>
                    <Table.Cell>
                        <Input  transparent
                                name      = "Name" 
                                value     = {this.state.Name} 
                                onChange  = {(e, {name, value}) => { this.onValueChanged(name, value, e) }} 
                                onBlur    = {() => this.updateData("Name", this.state.Name)}
                                onKeyDown = {this.onKeyDown}
                                fluid/>
                    </Table.Cell>
                    <Table.Cell textAlign="center" collapsing>
                        <Popup content={LocalizedStrings.EditRackPopup}
                            trigger={ 
                            <Icon   name="edit"
                                    onClick={() => this.openModal(rack)} />
                        }/>
                        <Popup content={LocalizedStrings.DeleteRack}
                            trigger={ 
                            <Icon   name    = "delete"
                                    color   = "red"
                                    onClick = {() => this.onDelete(rack.UUID)}
                                    />
                        }/>
                    </Table.Cell>
                </Table.Row>}
                {this.renderModal()}
            </React.Fragment>
        );
    }

    renderTableRowInIos = (rack) => {
        return (
            <Table.Row >
                <div style={{ display: "flex", marginLeft: "1rem"}}>
                <Input  transparent
                                name      = "Name" 
                                value     = {this.state.Name} 
                                onChange  = {(e, {name, value}) => { this.onValueChanged(name, value, e) }} 
                                onBlur    = {() => this.updateData("Name", this.state.Name)}
                                onKeyDown = {this.onKeyDown}
                                />
                <Popup content={LocalizedStrings.EditRackPopup}
                        trigger={ 
                        <Icon   name="edit"
                                onClick={() => this.openModal(rack)} />
                    }/>
                        <Popup content={LocalizedStrings.DeleteRack}
                            trigger={ 
                            <Icon   name    = "delete"
                                    color   = "red"
                                    onClick = {() => this.onDelete(rack.UUID)}
                                    />
                }/>
                </div>
            </Table.Row>
        )
    }

    closeModal = () =>
    {
        this.setState({ openModal     : false });
    }

    openModal = (rack) =>
    {
        this.setState({ openModal     : true,
                        tempSizeX     : rack.CaseSize.X,
                        tempSizeY     : rack.CaseSize.Y,
                        tempSizeZ     : rack.CaseSize.Z,
                        tempWeight    : rack.Weight,
                        tempHECount   : rack.HECount,
                        tempHasWheels : rack.HasWheels,
                        tempAllowTip  : rack.AllowTip,
                        rack          : rack,
                        });
    }

    renderModal = () => {
        return (<LRModal    open={this.state.openModal}
                            title={LocalizedStrings.EditInventoryContainer}
                            onOkClick={this.editRack}
                            onCancelClick={this.closeModal}>
                    <Form>
                        <Form.Group widths="equal">
                            <UnitInput  name           = "tempSizeX"
                                        baseUnit       = {BASE_UNIT_LENGTH}
                                        label          = {LocalizedStrings.NewContainerSizeX}
                                        fluid     
                                        value          = {this.state.tempSizeX}
                                        onStateUpdate  = {this.onValueChanged}/>
                            <UnitInput  name           = "tempSizeY"
                                        baseUnit       = {BASE_UNIT_LENGTH}
                                        label          = {LocalizedStrings.NewContainerSizeY}
                                        fluid     
                                        value          = {this.state.tempSizeY}
                                        onStateUpdate  = {this.onValueChanged}/>
                            <UnitInput  name           = "tempSizeZ"
                                        baseUnit       = {BASE_UNIT_LENGTH}
                                        label          = {LocalizedStrings.NewContainerSizeZ}
                                        fluid     
                                        value          = {this.state.tempSizeZ}
                                        onStateUpdate  = {this.onValueChanged}/>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <UnitInput  name           = "tempHECount"
                                        baseUnit       = {BASE_UNIT_NUMBER}
                                        label          = {LocalizedStrings.HECount}
                                        inline     
                                        fluid     
                                        value          = {this.state.tempHECount}
                                        onStateUpdate  = {this.onValueChanged}/>
                            <UnitInput  name           = "tempWeight"
                                        baseUnit       = {BASE_UNIT_WEIGHT}
                                        label          = {LocalizedStrings.Weight}
                                        inline     
                                        fluid     
                                        value          = {this.state.tempWeight}
                                        onStateUpdate  = {this.onValueChanged}/>
                        </Form.Group>
                        <Form.Group>
                            <Checkbox   label    = {LocalizedStrings.HasWheels}
                                        fluid
                                        toggle
                                        name     = "tempHasWheels"
                                        checked  = {this.state.tempHasWheels}
                                        onChange = {(e, {name, checked}) => { this.onValueChanged(name, checked, e) }}/>
                            <Checkbox   label    = {LocalizedStrings.AllowTip}
                                        fluid
                                        toggle
                                        name     = "tempAllowTip"
                                        checked  = {this.state.tempAllowTip}
                                        onChange = {(e, {name, checked}) => { this.onValueChanged(name, checked, e) }}/>
                        </Form.Group>
                    </Form>
                </LRModal>)
    }

    editRack = () => 
    {
        let newRack = {}
        
        newRack.CaseSize = { X: this.state.tempSizeX,
                             Y: this.state.tempSizeY,
                             Z: this.state.tempSizeZ, }
        
        newRack.Weight  = this.state.tempWeight
        newRack.HECount = this.state.tempHECount
        newRack.HasWheels = this.state.tempHasWheels
        newRack.AllowTip = this.state.tempAllowTip

        window.LR_SetRack({ UUID: this.state.rack.UUID, ...newRack })
        this.closeModal()
    }

    onValueChanged = (name, value, e) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.props.rack.UUID,
            [name] : value
        };
        window.LR_SetRack(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        window.LR_DeleteRack(request);
    }

}

export default RackNode;

