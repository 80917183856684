//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';

import MenuContainer from './MenuContainer'
import { Menu, Icon } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/MenuBar";
import LocalizedStrings_MenuBarItem from "../../localization/MenuBarItem";

import QrCodeReader from '../QrCodeScanner/QrCodeScanner';

import { 
    RENDERER_VIEW_FRONT,
    RENDERER_VIEW_BACK,
    RENDERER_VIEW_LEFT,
    RENDERER_VIEW_RIGHT,
    RENDERER_VIEW_TOP,
    RENDERER_VIEW_BOTTOM,
    RENDERER_VIEW_FRONT_LEFT,
    RENDERER_VIEW_FRONT_RIGHT,
    RENDERER_VIEW_REAR_LEFT,
    RENDERER_VIEW_REAR_RIGHT,
    TOOL_SELECT_INDEX,
    OBJECT_PROPERTIES_INDEX,
    SCENE_TREE_INDEX,
    NAVIGATION_CONTAINER_INDEX,
    RENDERER_INDEX,
    IsVectorworksContext,
    IsElectronContext,
    IsWebsocketMode,
    IsRunningAsBrowser} from "../../util/defines";
import { HandleScanInApp } from '../QrCodeScanner/defaultScanning';
    
import { globalCallbacks } from "../../util/callback";

const ICON_MAPPING = {
    'File': 'file',
    'Edit': 'edit',
    'Selection': 'hand pointer',
    'View': 'eye',
    'PA Cloud': 'cloud',
    'Help': 'help circle'
};

function GetMenuBarEntries(recentFiles, AllowHiddenFunctions = false)
{
    let isBrowser = IsRunningAsBrowser()
    let files=recentFiles
    if (!files) { files = [] }

    let copyPasteItems = IsElectronContext() ? [
        {role: "copy", label: "Copy"},
        {role: "paste", label: "Paste"},
        {role: "cut", label: "Cut"},
    ] : [
    {
        label: 'Copy',
        cmd:"LR_CopyObject",
        arg:{},
        sendToCore:true,
    },
    {
        label: 'Cut',
        cmd:"LR_CutObject",
        arg:{},
        sendToCore:true,
    },
    {
        label: 'Paste',
        cmd:"LR_PasteObject",
        arg:{},
        sendToCore:true,
    },]

    let menuItems = [
        {
            label: "File",
            submenu: 
            [
                {
                    label: 'New',
                    cmd:"LR_CreateNewDrawing",
                    arg:{},
                    show: !isBrowser && !IsVectorworksContext()
                },
                {
                    label: 'Open...',
                    cmd:"LR_OpenLRWFile",
                    arg:{},
                    show: !isBrowser
                },
                {
                    label: 'Checkout...',
                    cmd:"LR_CheckoutProject",
                    arg:{},
                    show: !isBrowser
                },
                {
                    label: 'Open Recent',
                    submenu: files.map(path => (
                    { 
                        NoLoc: true,
                        label: path.substring(path.lastIndexOf('/') + 1),
                        cmd:"LR_OpenRecentChecked",
                        arg:{Path: path},
                    })),
                    show: !isBrowser
                },
                {type: 'separator', show: !isBrowser},
                {
                    label: 'Save...',
                    cmd:"LR_SaveFile",
                    arg:{SaveAs: false},
                    show: !isBrowser
                },
                {
                    label: 'Save As...',
                    cmd:"LR_SaveFile",
                    extra: "SaveAs",
                    arg:{SaveAs: true},
                },
                {
                    label: 'Close',
                    cmd:"LR_CloseLRWFile",
                    arg:{},
                    show: !isBrowser && !IsVectorworksContext()
                },
                
                { type: 'separator' },
                {
                    label: 'Show Connected load cells...',
                    cmd:"LR_ShowLoadCellMonitor",
                },
                {
                    label: 'Request Checked Structural Calculation...',
                    cmd:"LR_ShowRequestCalculationCheck",
                },
                { type: 'separator' },
                {
                    label: 'Create Paperwork PDF...',
                    cmd:"LR_ExportPaperworkReport",
                    arg:{Path:""},
                },
                {
                    label: 'Create Calculation Report...',
                    cmd:"LR_ExportStructuralReport",
                    arg:{Path:""},                
                },
                {
                    label: 'Export Current View...',
                    cmd:"LR_ExportView",
                    arg:{Path:""},                
                },
                {
                    label: 'Create Print Labels...',
                    cmd:"LR_PrintLabels",
                    arg:{Path:""},
                },
                { type: 'separator' },
                {
                    label: "Export",
                    submenu: 
                    [
                        {
                            label: 'Send Changes to MVRxchange...',
                            cmd:"LR_CreateNewMVRxchangeRevision",
                            arg:{},
                            show: !isBrowser
                        },
                        {
                            label: 'Export MVR...',
                            cmd:"LR_WriteMVR",
                            arg:{},
                            show: !isBrowser
                        },
                        {
                            label: 'Export DSTV...',
                            cmd:"LR_ExportDSTV",
                            arg:{},
                            show: !isBrowser
                        },
                        {
                            label: 'Export GLTF/GLB...',
                            cmd:"LR_ExportMeshScene",
                            arg:{Type:"GLB"},
                            show: !isBrowser
                        },
                        {
                            label: 'Export 3DS...',
                            cmd:"LR_ExportMeshScene",
                            arg:{Type:"3DS"},
                            show: !isBrowser
                        },
                        {
                            label: 'Export GDTF...',
                            cmd:"LR_ExportAsGdtf",
                            arg:{},
                            show: !isBrowser
                        },
                        {
                            label: 'Export IFC 2x3...',
                            cmd:"LR_ExportIFC",
                            arg:{Type:"2x2"},
                        },
                        {
                            label: 'Export IFC 4...',
                            cmd:"LR_ExportIFC",
                            arg:{Type:"4"},
                        },
                        {
                            label: 'Export Symbols...',
                            cmd:"LR_ExportSymbols",
                            arg:{ResourceType: 3}
                        },
                        {
                            label: 'Export Print Labels...',
                            cmd:"LR_ExportSymbols",
                            arg:{ResourceType: 12}
                        },
                        {
                            label: "Export JSON",
                            submenu: 
                            [
                                { label: 'Export SYMBOLMAP', cmd:"LR_Export", arg:{Type:"SYMBOLMAP"},},
                                { label: 'Export FIELDMAP', cmd:"LR_Export", arg:{Type:"FIELDMAP"},},
                                { label: 'Export TEMPLATETRUCK',cmd:"LR_Export", arg:{Type:"TEMPLATETRUCK"},},
                                { label: 'Export TEMPLATECASE', cmd:"LR_Export", arg:{Type:"TEMPLATECASE"},},
                                { label: 'Export TEMPLATERACK', cmd:"LR_Export", arg:{Type:"TEMPLATERACK"},},
                                { label: 'Export TEMPLATEPAPERFORMAT', cmd:"LR_Export", arg:{Type:"TEMPLATEPAPERFORMAT"},},
                                { label: 'Export PRINTFORMAT', cmd:"LR_Export", arg:{Type:"PRINTFORMAT"},},
                                { label: 'Export CROSSSECTION',  cmd:"LR_Export", arg:{Type:"CROSSSECTION"},},
                                { label: 'Export MATERIAL',  cmd:"LR_Export", arg:{Type:"MATERIAL"},},
                                { label: 'Export CONNECTOR',  cmd:"LR_Export", arg:{Type:"CONNECTOR"},},
                                { label: 'Export STRUCTURALREPORT',  cmd:"LR_Export", arg:{Type:"STRUCTURALREPORT"},},
                                { label: 'Export PAPERWORKREPORT',  cmd:"LR_Export", arg:{Type:"PAPERWORKREPORT"},},
                                { label: 'Export PROPERTYTABELES',  cmd:"LR_Export", arg:{Type:"PROPERTYTABELES"},},
                                { label: 'Export INVENTORYTABELES',  cmd:"LR_Export", arg:{Type:"INVENTORYTABELES"},},
                                { label: 'Export SETTINGS',  cmd:"LR_Export", arg:{Type:"SETTINGS"},},
                            ],
                            show: !isBrowser
                        },
                    ]
                },
                {
                    label: "Vectorworks Integration",
                    submenu: 
                    [
                        {
                            label: 'Read Vectorworks Drawing...',
                            cmd:"LR_ReadVectorworksDrawing",
                            arg:{},
                        },
                        {
                            label: 'Read and Calculate...',
                            cmd:"LR_ReReadAndRunStructuralCalculation",
                            arg:{},
                        },
                        {
                            label: 'Update References...',
                            cmd:"LR_ReadVectorworksDrawing",
                            arg:{UpdateReference:true},
                        },
                        
                        {
                            label: 'Import 2D Plan...',
                            cmd:"LR_Import2DPlanFromHostApplication",
                            arg:{},
                        },
                        {
                            label: 'Import 3D Plan...',
                            cmd:"LR_Import3DPlanFromHostApplication",
                            arg:{},
                        },
                        {
                            label: 'Import Structural Members...',
                            cmd:"LR_ImportStructuralMemberFromHoistApplication",
                            arg:{},
                        },
                        {
                            label: 'Prepare Symbol for Production Assist...',
                            cmd:"LR_PrepareSymbolsFromHostApplication",
                            arg:{},
                        },
                        {
                            label: 'Refresh Symbol for Production Assist...',
                            cmd:"LR_RefreshSymbolsFromHostApplication",
                            arg:{},
                        },
                        {
                            label: 'Write Symbols from Production Assist into Drawings...',
                            cmd:"LR_WriteSymbolsToHostApplication",
                            arg:{},
                        },
                    ]
                },
                {
                    label: "AutoCAD Integration",
                    submenu: 
                    [
                        {
                            label: 'Prepare Symbol for Production Assist...',
                            cmd:"LR_PrepareSymbolsFromHostApplication",
                            arg:{},
                        }
                    ]
                },
                {
                    label: "Import",
                    submenu: 
                    [
                    {
                        label: 'Request Changes from MVRxchange...',
                        cmd:"LR_GetMVRxchangeFile",
                        arg:{},
                        show: !isBrowser
                    },
                    {
                        label: 'Import Production Assist Drawing...',
                        cmd:"LR_ImportLRWX",
                        arg:{Async: true},
                        show: !isBrowser
                    },
                    {
                        label: 'Import Production Assist Drawing from Cloud...',
                        cmd:"LR_ImportLRWX",
                        arg:{Async: true, Checkout: true},
                        show: !isBrowser
                    },
                    {
                        label: 'Import MVR...',
                        cmd:"LR_ReadMVR",
                        arg:{Async: true},
                        show: !isBrowser
                    },
                    {
                        label: 'Import DSTV...',
                        cmd:"LR_ImportDSTV",
                        arg:{},
                        show: !isBrowser
                    },
                    {
                        label: 'Import PDF...',
                        cmd:"LR_ReadPDF",
                        arg:{},
                    },
                    {
                        label: 'Import DWG...',
                        cmd:"LR_ReadDWG",
                        arg:{},
                        show: IsElectronContext()
                    },
                    // Import pdf
                    {
                        label: 'Import GDTF...',
                        cmd:"LR_ImportGdtf",
                        arg:{},
                        show: !isBrowser
                    },
                    {
                        label: 'Import Mesh...',
                        cmd:"LR_ImportMesh",
                        arg:{},
                        show: !isBrowser
                    },
                    {
                        label: 'Import Mesh with Structure...',
                        cmd:"LR_ImportMeshScene",
                        arg:{},
                        show: !isBrowser
                    },                
                    {
                        label: 'Import IFC 2x3...',
                        cmd:"LR_ReadIFC",
                        arg:{},
                    },
                    {
                        label: 'Import SketchUp...',
                        cmd:"LR_ReadSketchUp",
                        arg:{},
                    },
                    {
                        label: 'Import SVG...',
                        cmd:"LR_ImportSVG",
                        arg:{},
                    },
                    {
                        label: 'Import Image...',
                        cmd:"LR_ImportTexture",
                        arg:{},
                    },
                    {
                        label: 'Import Document...',
                        cmd:"LR_ImportExternalDocument",
                        arg:{},
                    },
                    {
                        label: 'Import MA3 Cue...',
                        cmd:"LR_ReadMa3Cue",
                        arg:{},
                    },
                    {
                        label: "Import JSON",
                        submenu: 
                        [
                            { label: 'Import SYMBOLMAP', cmd:"LR_Import", arg:{Type:"SYMBOLMAP"},},
                            { label: 'Import FIELDMAP', cmd:"LR_Import", arg:{Type:"FIELDMAP"},},
                            { label: 'Import TEMPLATETRUCK',cmd:"LR_Import", arg:{Type:"TEMPLATETRUCK"},},
                            { label: 'Import TEMPLATECASE', cmd:"LR_Import", arg:{Type:"TEMPLATECASE"},},
                            { label: 'Import TEMPLATERACK', cmd:"LR_Import", arg:{Type:"TEMPLATERACK"},},
                            { label: 'Import TEMPLATEPAPERFORMAT', cmd:"LR_Import", arg:{Type:"TEMPLATEPAPERFORMAT"},},
                            { label: 'Import PRINTFORMAT', cmd:"LR_Import", arg:{Type:"PRINTFORMAT"},},
                            { label: 'Import CROSSSECTION',  cmd:"LR_Import", arg:{Type:"CROSSSECTION"},},
                            { label: 'Import MATERIAL',  cmd:"LR_Import", arg:{Type:"MATERIAL"},},
                            { label: 'Import CONNECTOR',  cmd:"LR_Import", arg:{Type:"CONNECTOR"},},
                            { label: 'Import STRUCTURALREPORT',  cmd:"LR_Import", arg:{Type:"STRUCTURALREPORT"},},
                            { label: 'Import PAPERWORKREPORT',  cmd:"LR_Import", arg:{Type:"PAPERWORKREPORT"},},
                            { label: 'Import PROPERTYTABELES',  cmd:"LR_Import", arg:{Type:"PROPERTYTABELES"},},
                            { label: 'Import INVENTORYTABELES',  cmd:"LR_Import", arg:{Type:"INVENTORYTABELES"},},
                            { label: 'Import SETTINGS',  cmd:"LR_Import", arg:{Type:"SETTINGS"},},
                        ],
                        show: !isBrowser
                    },
                ]
            },
            { type: 'separator', show: !isBrowser},
            {
                visible: ! IsVectorworksContext(),
                label: "Purge",
                cmd:"LR_DeleteUnusedObjects",
                arg:{},
            },
            {
                label: "Delete empty Assembly Groups",
                cmd: "LR_DeleteEmptyAssemblyGroups",
                arg: {}
            },
            { type: 'separator', show: !isBrowser},
            {
                label: 'Settings...',
                cmd:"LR_RunOpenSettings",
                arg:{},
            }
            ] 
        },   
        {
            label: "Edit",
            submenu: 
            [
                {
                    label: 'Undo',
                    cmd:"LR_DoUndo",
                    arg:{},
                },
                {
                    label: 'Redo',
                    cmd:"LR_DoRedo",
                    arg:{},
                },
                { type: 'separator' },
                ...copyPasteItems,
                {
                    label: 'Duplicate',
                    cmd:"LR_DuplicateSelectedObject",
                    arg:{},
                },
                {
                    label: 'Delete',
                    cmd:"LR_DeleteSelectedObjects",
                    arg:{},
                },
                { type: 'separator' },
                {
                    label: 'RenameObject',
                    cmd:"LR_ActivateInputField",
                    extra:"Name",
                    arg:{HTMLInputId: "_OIP_Name"},
                },
                {
                    label: 'RenameObjectId',
                    cmd:"LR_ActivateInputField",
                    extra:"ObjectId",
                    arg:{HTMLInputId: "_OIP_ObjectId"},
                },
                { type: 'separator' },
                {
                    label: "Replace defining resource...",
                    cmd:"LR_ShowChangeFixtureType",
                    arg:{Selected: true},
                },
                {
                    label: "Edit Defining Resource",
                    cmd:"LR_OpenGeometryEditMode",
                    arg:{Selected: true},
                },
                {
                    label: "Create Symbol Definition from Object Definition",
                    cmd:"LR_AddNewSymbolDefinition",
                    arg:{CopySelected: true},
                },
                {
                    label: "Edit Object Definition",
                    extra: "Object",
                    cmd:"LR_OpenGeometryEditMode",
                    arg:{Selected: true, Object: true},
                },
                { type: 'separator' },
                {
                    label: "Run Structural Calculation",
                    cmd:"LR_ReRunStructuralCalculation",
                    arg:{},
                },
                {
                    label: "Show Calculation Overview",
                    cmd:"LR_ShowCalculationOverview",
                    arg:{},
                },
                {
                    label: "Show Calculation Settings...",
                    cmd:"LR_ShowCalculationSettings",
                    arg:{},
                },
                {
                    label: "Simulate Windload",
                    cmd:"LR_SimulateWindLoad",
                    arg:{},
                },
                {
                    label: "Store Structural Results as Maximum",
                    cmd:"LR_StoreResultsAsStructuralBenchmark",
                    arg:{},
                },
                {
                    label: "Auto Adjust Chain Shortens",
                    cmd: "LR_AdjustChainShortens",
                    arg: {},
                },
                {
                    label: "Calculate Stiffness values for loads",
                    cmd: "LR_CalculateStiffnessValuesForLoad",
                    arg: {},
                },
                {
                    label: "Calculate Loads for selected Hoists",
                    cmd: "LR_CalculateLoadForHoist",
                    arg: {},
                },
                {
                    label: "Calculate Ballast",
                    cmd: "LR_CalculateBallastForGroundSupports",
                    arg: {},
                },
                {
                    label: "Calculate Effect from Chain Shorten",
                    cmd: "LR_CalculateChainShortenEffect",
                    arg: {},
                },
                {
                    label: "Calculate Effect from Chain Shorten by Custom Value...",
                    cmd: "LR_CalculateChainShortenEffect",
                    arg: {AskForDelta: true},
                },
                {
                    label: "Randomize Chain Shorten Values...",
                    cmd: "LR_RandomizeChainShorten",
                    arg: {},
                },
                {
                    label: "Set Objects to Calculate",
                    cmd:"LR_SetCalculationObject",
                    arg:{},
                },
                {
                    label: "Remove Objects to Calculate",
                    cmd:"LR_SetCalculationObject",
                    extra:"Clear",
                    arg:{Clear: true},
                },
                { type: 'separator' },
                {
                    label: "Run Electrical Calculation",
                    cmd:"LR_ReRunElectricalCalculation",
                    arg:{Clear: true},
                },
                { type: 'separator' },
                {
                    label: "Group by",
                    submenu: [
                        {
                            label: 'Group by Layer',
                            cmd:"LR_CreateGroupsByProperty",
                            arg:{Property: "Layer"},
                        },
                        {
                            label: 'Group by Class',
                            cmd:"LR_CreateGroupsByProperty",
                            arg:{Property: "Class"},
                        }
                    ]
                },
                {
                    label: "Create Selection Group for",
                    submenu: [
                        {
                            label: 'Fixtures in Drawing',
                            cmd:   "LR_AddSelectionGroupForFixturesInLine",
                            arg:   {},
                        },
                        {
                            label: 'Fixtures on Selected Structures',
                            cmd:   "LR_AddSelectionGroupForFixturesInLine",
                            arg:   { Selected: true},
                        }
                    ]
                },
                {
                    label: "Create Assembly Sheets",
                    submenu: [
                        {
                            label: "Empty for all Groups",
                            cmd:"LR_CreateAssemblySheetsForGroups",
                            arg:{Selected: true},
                        },
                        {
                            label: "With Template for all Groups",
                            cmd:"LR_ShowCreateWorksheetDialog",
                            arg:{AllAssemblyGroups: true},
                        },
                        {
                            label: "With Template for selected objects",
                            cmd:"LR_ShowCreateWorksheetDialog",
                            arg:{},
                        },
                    ]
                },
            ]
        }, 
        {
            label: "Selection",
            submenu: 
            [
                {
                    label: 'Select All',
                    cmd:"LR_SelectAll",
                    arg:{Value:true},
                },
                {
                    label: 'Deselect All',
                    cmd:"LR_SelectAll",
                    extra: "False",
                    arg:{Value: false},
                },
                {
                    label: 'Expand All',
                    cmd:"LR_ExpandAll",
                    arg:{Value:true},
                },
    
                {
                    label: 'Collapse All',
                    cmd:"LR_ExpandAll",
                    extra: "False",
                    arg:{Value:false},
                },
                { type: 'separator' },
                {
                    label: 'Previous',
                    cmd:"LR_SelectPrev",
                    arg:{},
                },
                {
                    label: 'Next',
                    cmd:"LR_SelectNext",
                    arg:{},
                },
                {
                    label: 'Up',
                    cmd:"LR_SelectUp",
                    arg:{},
                },
                {
                    label: 'Down',
                    cmd:"LR_SelectDown",
                    arg:{},
                },
                
                {
                    label: 'Select Previous',
                    cmd:"LR_SelectPrev",
                    extra: "SelectAddition",
                    arg:{SelectAddition:true},
                },
                {
                    label: 'Select Next',
                    cmd:"LR_SelectNext",
                    extra: "SelectAddition",
                    arg:{SelectAddition:true},
                },
                {
                    label: 'Select Up',
                    cmd:"LR_SelectUp",
                    extra: "SelectAddition",
                    arg:{SelectAddition:true},
                },
                {
                    label: 'Select Down',
                    cmd:"LR_SelectDown",
                    extra: "SelectAddition",
                    arg:{SelectAddition:true},
                },
                { type: 'separator' },
                {
                    label: 'Create Assembly Sheet',
                    cmd:"LR_AddNewWorksheet",
                    arg:{},
                },
                {
                    label: 'Create Dimensions',
                    cmd:"LR_AddDimensions",
                    arg:{},
                },
                {
                    label: 'Create Drops',
                    cmd:"LR_InsertDropForSelectedObjects",
                    arg:{},
                },
                {
                    label: 'Create Bridles',
                    cmd:"LR_InsertBridlesForSelectedObjects",
                    arg:{},
                },
                {
                    label: 'Grappel Selected Structure',
                    cmd:"LR_InsertGrappelsForSelectedObjects",
                    arg:{},
                },
                {
                    label: 'Curve Selected Structure',
                    cmd:"LR_CurveTrussForSelectedObjects",
                    arg:{},
                },
                
                {
                    label: 'Create Truss Cross',
                    cmd:"LR_InsertTrussCrossForSelectedObjects",
                    arg:{},
                },
                {
                    label: 'Convert to Structure',
                    cmd:"LR_ConvertToStructure",
                    arg:{},
                },
                {
                    label: 'Add New Windload',
                    cmd:"LR_AddNewWindLoad",
                    arg:{},
                },
                {
                    label: 'Add New LineLoad',
                    cmd:"LR_AddNewLineLoad",
                    arg:{},
                },
                {
                    label: 'Add New PointLoad',
                    cmd:"LR_AddNewPointLoad",
                    arg:{},
                },
                { type: 'separator' },
                {
                    label: "Select System Objects",
                    cmd: "LR_SelectSystemObjectS",
                    arg: {},
                },
                {
                    label: "Merge Selected Objects",
                    cmd:"LR_MergeSelectedObjects",
                    arg:{},
                },
                
                {
                    label: 'Group Selected Objects',
                    cmd:"LR_GroupSelected",
                    arg:{},
                },
                
                
                {
                    label: 'Arrange Selected Objects',
                    cmd:"LR_ArrangeSelectedObjects",
                    arg:{},
                },
                {
                    label: "Move",
                    submenu: [
                        {
                            label: 'Move Selected Object Right',
                            cmd:"LR_CommandLine",
                            extra:"OffsetX+",
                            arg:{Command: "Set (OffsetX)  + '+100'"},
                        },
                        {
                            label: 'Move Selected Object Left',
                            cmd:"LR_CommandLine",
                            extra:"OffsetX-",
                            arg:{Command: "Set (OffsetX)  + '-100'"},
                        },
                        {
                            label: 'Move Selected Object Front',
                            cmd:"LR_CommandLine",
                            extra:"OffsetY+",
                            arg:{Command: "Set (OffsetY)  + '+100'"},
                        },
                        {
                            label: 'Move Selected Object Back',
                            cmd:"LR_CommandLine",
                            extra:"OffsetY-",
                            arg:{Command: "Set (OffsetY)  + '-100'"},
                        },
                        {
                            label: 'Move Selected Object Up',
                            cmd:"LR_CommandLine",
                            extra:"OffsetZ+",
                            arg:{Command: "Set (OffsetZ)  + '+100'"},
                        },
                        {
                            label: 'Move Selected Object Down',
                            cmd:"LR_CommandLine",
                            extra:"OffsetZ-",
                            arg:{Command: "Set (OffsetZ)  + '-100'"},
                        },

                    ]
                },
                { type: 'separator' },
                {
                    label: 'Power Patch 1:1',
                    cmd:"LR_PatchSelectedObjects",
                    arg:{Type: 0},
                },
                {
                    label: 'Power Patch Fill Current',
                    extra:"Fill",
                    cmd:"LR_PatchSelectedObjects",
                    arg:{Type: 1},
                },
                {
                    label: 'Hotpatch Selected Objects...',
                    cmd:"LR_ShowHotpatchForSelectedObjects",
                    arg:{},
                },
                {
                    label: 'Data Patch',
                    cmd:"LR_DataPatchSelectedObjects",
                    arg:{},
                },
                {
                    label: 'Delete Power Patch',
                    cmd:"LR_RemoveElectricalObjectConnection",
                    extra:"Power",
                    arg:{Selected:true, Data:false},
                },
                {
                    label: 'Delete Hot Patch',
                    cmd:"LR_RemoveElectricalObjectConnection",
                    extra:"Hot",
                    arg:{Selected:true, Power:false, Data:false, Hotpatch:true},
                },
                {
                    label: 'Delete Data Patch',
                    cmd:"LR_RemoveElectricalObjectConnection",
                    extra:"Data",
                    arg:{Selected:true, Power:false},
                },
                {
                    label: 'Resolve Multiple Output Usage',
                    cmd:"LR_ResolveMultipleOutputUsage",
                    arg:{Selected:true},
                },
                { type: 'separator' },
                {
                    label: 'Place Object to Selected Structures...',
                    cmd:"LR_RunPlaceToStructure",
                    arg:{UseSelected: true},
                },
                { type: 'separator' },
                {
                    label: 'Show Results for Selection...',
                    cmd:"LR_ShowResultsForSelection",
                    arg:{},
                }
            ]
        }, 
        {
            label: "View",
            submenu: 
            [
                { role: "resetzoom", label: "resetzoom"  },
                { role: "zoomin", label: "zoomin"  },
                { role: "zoomout", label: "zoomout"  },
                {
                    label: 'Zoom to Selection',
                    cmd:"LR_ZoomToSelection",
                    arg:{},
                },
                { type: 'separator' },
                { role: "togglefullscreen", label: "togglefullscreen"  },
                { type: "separator" },
                {
                    label: "Toolbar",
                    renderCmd: "toggleContainer",
                    arg: TOOL_SELECT_INDEX,
                    checked: true
                },
                {
                    label: "Objects Properties",
                    renderCmd: "toggleContainer",
                    arg: OBJECT_PROPERTIES_INDEX,
                    checked: true
                },
                {
                    label: "Scene Tree",
                    renderCmd: "toggleContainer",
                    arg: SCENE_TREE_INDEX,
                    checked: true
                },
                {
                    label: "Navigation",
                    renderCmd: "toggleContainer",
                    arg: NAVIGATION_CONTAINER_INDEX,
                    checked: true
                },
                {
                    label: "Renderer",
                    renderCmd: "toggleContainer",
                    arg: RENDERER_INDEX,
                    checked: true
                },
                { type: "separator" },
                {
                    label: 'Front View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_FRONT",
                    arg:{View: RENDERER_VIEW_FRONT},
                },
                {
                    label: 'Back View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_BACK",
                    arg:{View: RENDERER_VIEW_BACK},
                },
                {
                    label: 'Left View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_LEFT",
                    arg:{View: RENDERER_VIEW_LEFT},
                },
                {
                    label: 'Right View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_RIGHT",
                    arg:{View: RENDERER_VIEW_RIGHT},
                },
                {
                    label: 'Top View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_TOP",
                    arg:{View: RENDERER_VIEW_TOP},
                },
                {
                    label: 'Bottom View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_BOTTOM",
                    arg:{View: RENDERER_VIEW_BOTTOM},
                },
                {
                    label: 'Front Left View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_FRONT_LEFT",
                    arg:{View: RENDERER_VIEW_FRONT_LEFT},
                },
                {
                    label: 'Front Right View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_FRONT_RIGHT",
                    arg:{View: RENDERER_VIEW_FRONT_RIGHT},
                },
                {
                    label: 'Rear Left View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_REAR_LEFT",
                    arg:{View: RENDERER_VIEW_REAR_LEFT},
                },
                {
                    label: 'Rear Right View',
                    cmd:"LR_SetRendererView",
                    extra:"RENDERER_VIEW_REAR_RIGHT",
                    arg:{View: RENDERER_VIEW_REAR_RIGHT},
                },
                { type: 'separator' },
                {
                    label: 'Isometric or Ortho View',
                    cmd:"LR_ToggleRenderingMode",
                    arg:{},
                },
                { type: "separator" },
                { role: "reload", label: "reload" },
                { role: "toggledevtools", label: "toggledevtools"  },
                
            ]
        }, 
        {
            role: "window",
            label: "window",
            submenu: [
                {
                    label: "Worksheets",
                    electronCmd: "openElectronWindowById",
                    arg: 2,
                },
                {
                    label: "Lighting Paperwork",
                    electronCmd: "openElectronWindowById",
                    arg: 7,
                },
                {
                    label: "Resource Manager",
                    electronCmd: "openElectronWindowById",
                    arg: 3,
                },
                {
                    label: "Electrical Network",
                    electronCmd: "openElectronWindowById",
                    arg: 4,
                },
                {
                    label: "Timeline",
                    electronCmd: "openElectronWindowById",
                    arg: 5,
                },
                {
                    label: "Data Network",
                    electronCmd: "openElectronWindowById",
                    arg: 6,
                },
                { type: "separator" },
                { role: "minimize", label:"minimize" },
            ]
        },
        {
            label: "PA Cloud",
            submenu: 
            [
                {
                    label: 'Commit to Project...',
                    cmd:"LR_CommitToServer",
                    arg:{},
                },
                {
                    label: 'Update from current Branch...',
                    cmd:"LR_UpdateFromServer",
                    arg:{},
                },
                {
                    label: "Update Current Branch from Master...",
                    cmd:"LR_UpdateFromBranch",
                    arg:{Branch: "master"},
                },
                {
                    label: "Update from Template...",
                    cmd:"LR_UpdateFromTemplate",
                    arg:{},
                },
                {
                    label: 'Create Branch...',
                    cmd:"LR_CreateBranchAsync",
                    arg:{},
                },
                {
                    label: "Unlink Project",
                    cmd:"LR_SetLinkedProject",
                    arg:{Project: "", Owner: "", Branch: ""},
                    show: !isBrowser
                },
                {
                    label: 'Open Linked Project online',
                    cmd:"LR_OpenCurrentOnline",
                    arg:{},
                    show: !isBrowser
                },
                {
                    label: 'Show Project Calendar',
                    cmd:"LR_OpenCalendar",
                    arg:{},
                },
                { type: 'separator' },
                {
                    label: 'Commit Changes for Resource',
                    cmd:"LR_CommitRessourceDrawing",
                    arg:{},
                },
                {
                    label: 'Download all Online Resources',
                    cmd:"LR_DownloadAllResource",
                    arg:{},
                    show: !isBrowser
                },
                { type: 'separator' },
                {
                    label: 'Add User to Project',
                    cmd:"LR_ShowAddUserToProject",
                    arg:{},
                },
                {
                    label: 'Share Project Link...',
                    cmd:"LR_ShowShareDialog",
                    arg:{},
                },
                { type: 'separator', show: !isBrowser},
                {
                    label: 'Connect to Remote...',
                    cmd:"LR_ShowConnectToRemote",
                    arg:{},
                    show: !isBrowser
                },
                { type: 'separator' },
                {
                    label: 'Sign out',
                    cmd:"LR_LogOutFromServer",
                    arg:{},
                    show: !isBrowser
                },
            ]
        },
        {
            label: "Help",
            submenu: [
                { 
                    label: "About", 
                    cmd:"LR_ShowAboutDialog",
                    arg:{}, 
                },
                {
                    label: "Check For Update",
                    cmd:"LR_CheckForUpdate",
                    arg:{Force: true},
                    show: !isBrowser
                },
                {
                    label: "First Steps Tour",
                    cmd:"LR_SetGlobalSettings",
                    arg:{ShowTour: true},
                },
                { 
                    label: "Open Online Help...", 
                    cmd:"LR_OpenHelp",
                    arg:{}, 
                },
                { 
                    label: "Open Online Glossar...", 
                    cmd:"LR_OpenHelp",
                    arg:{Page:"gloassar"},
                    extra:"glossar" 
                },
                { 
                    label: "Show Shortcuts...", 
                    cmd:"LR_ShowEditShortCutsModal",
                    arg:{}, 
                },
                { 
                    label: "Give Feedback...", 
                    cmd:"LR_MakeFeedbackInApp",
                    arg:{ID: "5928220087025664"}
                },
                { 
                    label: "Report Symbol...", 
                    cmd:"LR_MakeFeedbackInApp",
                    arg:{ID: "4568668913532928"}
                },
                { 
                    label: "Register Custom Server...", 
                    cmd:"LR_SetOnlineConfig",
                    arg:{},
                    show: !isBrowser
                },
                { 
                    label: "Install Vectorworks Plugin...", 
                    cmd:"LR_InstallLightRightPlugin",
                    show: IsElectronContext()
                },
                { 
                    label: "Install AutoCAD Plugin...", 
                    cmd:"LR_InstallLightRightPlugin",
                    show: IsElectronContext()
                },
                { 
                    label: "Install BricsCAD Plugin...", 
                    cmd:"LR_InstallLightRightPlugin",
                    show: IsElectronContext()
                },
                { 
                    label: "Show Truss Calculator...", 
                    cmd:"LR_ShowTrussDataGenerator",
                    arg:{},
                },
            ]
        }
    ]

    if(IsElectronContext() && navigator.platform === "MacIntel")
    {
        menuItems = [
            {
                role: IsWebsocketMode() ? "help" : undefined,
                label: "Production Assist",
                submenu: [
                  { label: "About", cmd:"LR_ShowAboutDialog", arg:{},},
                  { type: "separator" },
                  { role: "services", label: "services" },
                  { type: "separator" },
                  { role: "hide", label: "hide"  },
                  { role: "hideothers", label: "hideothers"  },
                  { role: "unhide", label: "unhide"  },
                  { type: "separator" },
                  { role: "quit", label: "quit"  }
                ]
            },
            ...menuItems
        ]

        // Edit menu
        menuItems[2].submenu.push(
        { type: "separator" },
        {
          label: "Speech",
          submenu: [{ role: "startspeaking", label: "startspeaking" }, { role: "stopspeaking", label: "stopspeaking"  }]
        })
        menuItems[4].submenu.push({ type: "separator" }, { role: "front", label: "front"  });
    }

    return menuItems
}


export function GetPossibleMenuBarCommands()
{
    let menuItems = GetMenuBarEntries()

    const cmds = Array.from(CycleThruMenu(menuItems,(item)=> item.show !== false ))
    return cmds.map((command)=>({
        ...command,
        label: LocalizedStrings_MenuBarItem[command.label] || command.label
    }))
}

window.SHORT_CUTS_JUMPER = {}

export async function GetMenuBarItems(recentFiles, includeArrows = true)
{
    let shortCuts = await window.LR_GetShortCutsSettings()
    
    let menuItems = GetMenuBarEntries(recentFiles, shortCuts.AllowHiddenFunctions)
    menuItems = filterMenu(menuItems, (item)=>item.show || item.show === undefined)

    window.SHORT_CUTS_JUMPER = {}

    for(let item of CycleThruMenu(menuItems)){
        let entry = item.cmd
        if(item.extra)
        {
            entry += item.extra
        }

        if(shortCuts[entry] && (includeArrows ? true : (!["Next", "Previous", "Up", "Down"].includes(item.label))))
        {
            item.accelerator = shortCuts[entry].Key
            window.SHORT_CUTS_JUMPER[item.accelerator] = item
        }

        if(!item.NoLoc)
        {
            if(!LocalizedStrings_MenuBarItem[item.label] && item.label)
            {
                console.error(`Failed to load Loc for ${item.label}`)
            }

            item.label = LocalizedStrings_MenuBarItem[item.label] ? LocalizedStrings_MenuBarItem[item.label] : item.label
        }
    }

    return menuItems
}

function filterMenu(menu, filterFun = (item)=>true){
    return menu?.filter(i => filterFun(i)).map(i => {return {...i, submenu: filterMenu(i.submenu, filterFun)}})
}

function *CycleThruMenu(menu, filterFun = (item)=>true){
    for(let i of menu){
        if(filterFun(i)){
            yield i
            if(Array.isArray(i.submenu)){
                yield* CycleThruMenu(i.submenu, filterFun)
            }
        }
    }
}


export function isModifierKey(key)
{
    return key === "Command" ||
            key === "Control" ||
            key === "CommandOrControl" ||
            key === "Alt" ||
            key === "Option" ||
            key === "Shift";
}

export function getAcceloratorName(acc) 
{
    if (typeof acc !== "string") { return; }
    let splits = acc.split("+")

    let getModifierName = (modifier) =>
    {
        if (modifier === "Command")
        {
            if (navigator.platform === "MacIntel")
            {
                return "Cmd"
            }
            else if (navigator.platform === "Windows")
            {
                return "Win";
            }else{
                return "Super"
            }
        }
        else if (modifier === "Control")
        {
            return "Ctrl"
        }
        else if (modifier === "CommandOrControl")
        {
            if (navigator.platform === "MacIntel")
            {
                return "Cmd";
            }
            else
            {
                return "Ctrl";
            }
        }
        else if (modifier === "Alt")
        {
            if (navigator.platform === "MacIntel")
            {
                return "Option";
            }
            else
            {
                return "Alt";
            }
        }
        else if (modifier === "Option")
        {
            return "Option";
        }
        else if (modifier === "Shift")
        {
            return "Shift";
        }
        console.error(modifier , "not machted")
        return modifier
    }
    
    let result = "";
    for (let i = 0; i < splits.length; i++)
    {
        let currentKey = splits[i]
        if (isModifierKey(currentKey))
        {
            result += getModifierName(currentKey)
        }
        else
        {
            result += currentKey
        }
        if (i < splits.length - 1)
        {
            result += "+"
        }
    }
    return result;
}

class MenuBar extends Component 
{
    constructor(props)
    {
        super(props)

        this.state = {
            MainMenu: [],
            qropen: false
        }
    }
    
    componentDidMount()
    {
        this.SetUpCallbacks()
        
        window.LR_GetRecentFiles().then(recentFiles => {
            globalCallbacks.updateMenuBar(recentFiles.RecentFiles);
        })

    }

    componentWillUnmount()
    {
    }

    openCamera =() =>
    {
        this.setState({qropen:true})
    }

    

    render()
    {
        const mainMenu = this.state.MainMenu || [];
        const filteredMenu = this.props.ios ? mainMenu.filter(menuItem => menuItem.label !== "Production Assist").map(menuItem => ({...menuItem,label: <Icon name={ICON_MAPPING[menuItem.label] || 'bars'} /> })) : mainMenu;

        return(
            <Menu size={this.props.ios ? "mini": "large"} fluid style={{position: this.props.ios ? "static" : (this.props.position ?? "fixed"), zIndex:"101"}} compact>
                {this.props.noCollapse ? null : <Menu.Item onClick={this.props.showproperties}><Icon name="angle left"/></Menu.Item>}
                {this.props.saveExitRoute ? <Menu.Item onClick={this.props.saveExitRoute}><Icon name="angle left"/>{LocalizedStrings.ExitEditMode}</Menu.Item> : null}
                {filteredMenu.map((category,i) => { if (category.role) { return null; } return <MenuContainer menuIndex={i} key={category.label + i} label={category.label} submenu={category.submenu}/>})}
                { this.props.ios ? null : 
                <Menu.Menu position='right' style={{overflow: "auto"}}>
                    <Menu.Item name={LocalizedStrings.Plan} active={"renderNormalView"=== this.props.activeView} onClick={this.props.onChangeView.bind(this,"renderNormalView")} />
                    <Menu.Item name={LocalizedStrings.Worksheet} active={"renderTableView"=== this.props.activeView} onClick={this.props.onChangeView.bind(this,"renderTableView")} />
                    <Menu.Item name={LocalizedStrings.Resource} active={"renderResourceManager"=== this.props.activeView} onClick={this.props.onChangeView.bind(this,"renderResourceManager")} />
                    <Menu.Item name={LocalizedStrings.Electrical} active={"renderElectricalGraph"=== this.props.activeView} onClick={this.props.onChangeView.bind(this,"renderElectricalGraph")} />
                    <Menu.Item name={LocalizedStrings.DMX} active={"renderDMXView"=== this.props.activeView} onClick={this.props.onChangeView.bind(this,"renderDMXView")} />
                    <Menu.Item name={LocalizedStrings.LightPlot} active={"renderLightPaperwork"=== this.props.activeView} onClick={this.props.onChangeView.bind(this,"renderLightPaperwork")} />
                    <Menu.Item name={LocalizedStrings.Timeline} active={"renderTimeline"=== this.props.activeView} onClick={this.props.onChangeView.bind(this,"renderTimeline")} />
                    <Menu.Item onClick={this.openCamera} ><Icon name="camera"/>{LocalizedStrings.Camera}</Menu.Item>
                    {this.props.noCollapse ? null :<Menu.Item onClick={this.props.shownavigation}><Icon name="angle right"/></Menu.Item>}
                    <QrCodeReader 
                    open={this.state.qropen}
                    onScan={HandleScanInApp}
                    onCancelClick={()=>{this.setState({qropen:false})}}
                    onOkClick={()=>{this.setState({qropen:false})}}
                    title={"Scan QR Code"}
                    size="small"
                    noCancel={true}/>
                </Menu.Menu>}
            </Menu>
        )
    }

    SetUpCallbacks = () =>
    {
        globalCallbacks.updateMenuBar = async (recentFiles) =>
        {
            let menu = await GetMenuBarItems(recentFiles)
            this.setState({MainMenu: menu})
        }

        globalCallbacks.showWorksheetView = (arg) =>
        {
            if (this.props.onChangeView)
            {
                this.props.onChangeView(arg)
            }
        }

        
    }

}

export default MenuBar;