
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Button, Table, Form, Message, Loader, Label} from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ResourceManager";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from '../../util/callback';
import LRTableHeaderCell from '../Basics/BasicTableView';
import LocalizedStrings_ObjectProperties from "../../localization/LightRightObjectsFields";
import { IsRunningAsBrowser } from '../../util/defines';



class VectorworksFieldMapping extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      map: [],
      isLoading: false,
      lrValue: false,
      lrFactor: 1.0,
      pioValue: false,
      vwValue: false,
      lrOptions:[],
      pioOptions:[],
      customFields: [],
      open: false,

    };

  }

  componentDidMount = async () =>
  {
    this.setUpCallbacks();
    if (globalCallbacks.updateFieldMap) { globalCallbacks.updateFieldMap(); }

    let lr_fields = await window.LR_GetPossibleFields()

    let lrOptions = lr_fields.map((entry, i) => 
      {
        return{
          text: entry.ArrayName ?(LocalizedStrings_ObjectProperties[entry.ArrayName + "_" + entry.PropertyName] || LocalizedStrings_ObjectProperties[entry.PropertyName]) + " ( " + LocalizedStrings_ObjectProperties[entry.ArrayName] + " ) " : LocalizedStrings_ObjectProperties[entry.PropertyName],
          value:i,
          key:  entry.ArrayName+entry.PropertyName,
          label: { children: entry.PropertyList }
        }
      })

    this.updateFields()
    this.setState({lrOptions: lrOptions, fields: lr_fields})

  }

  updateFields = async() =>
  {
    let pio_fields = await window.LR_GetLocalPlugInFields()
    let pioOptions = pio_fields.map(element => 
    {
      return {
        text: element.PIOName,
        value:element.PIOName,
        key: element.PIOName,
        fields: element.Fields.map(field => 
          {
            return {
              text: field,
              value: field,
              key: field,
            }
          })
      }
    });

    this.setState({pioOptions: pioOptions})
  }

  render() 
  {
    let vwOption = []

    let selectedPio = this.state.pioValue;

    this.state.pioOptions.forEach(entry => 
      {
        if(selectedPio === entry.value)
        {
          vwOption = entry.fields
        }
      })

      this.state.customFields.forEach(entry => 
        {
          vwOption.push(entry)
        })


    return(
      <React.Fragment>
      <Table selectable striped celled>
        <Loader inverted content='Loading' />
        <Table.Header>
          <Table.Row>
            <LRTableHeaderCell>{LocalizedStrings.VWPIOName}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.VWPIOField}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.LRProperty}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.LRArray}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.LRPropertyFactor}</LRTableHeaderCell>
          </Table.Row>
        </Table.Header>
        {this.state.map.length !== 0   ?
        <Table.Body>
          {this.state.map.map( entry => {return this.render_Row(entry)})}
        </Table.Body> 
        :
        this.state.isLoading ? <Message positive style={{width:'100%'}}><Loader active content={LocalizedStrings.Loading}/></Message> :  
                              <Message style={{width:'100%'}} warning><Message.Header>{LocalizedStrings.NoFieldMapHeader}</Message.Header><p>{LocalizedStrings.NoFieldMapText}</p></Message>}
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='5'>
               <Button
              floated='right'
              icon
              labelPosition='left'
              primary
              onClick={this.openEditModal}
              size='small'><Icon name='truck' />{LocalizedStrings.AddMapping}</Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>     
      <LRModal  title={LocalizedStrings.AddMapping}
                open={this.state.open}
                onCancelClick={this.onDiscardModal}
                onOkClick={this.onOkClick}>
          <Form>
            <Form.Group widths="equal">
              <Form.Select search label={LocalizedStrings.VWPIOName} options={this.state.pioOptions} value={this.state.pioValue} onChange={(e, {value})=>{this.setState({ pioValue: value})}}/>
              <Form.Select onAddItem={this.handleAddition} search selection allowAdditions label={LocalizedStrings.VWPIOField} options={vwOption} value={this.state.vwValue} onChange={(e, {value})=>{this.setState({ vwValue: value})}}/>
            </Form.Group>
            <Form.Select search label={LocalizedStrings.LRProperty} options={this.state.lrOptions} value={this.state.lrValue} onChange={(e, {value})=>{this.setState({ lrValue: value})}}/>
            <Form.Input label={LocalizedStrings.LRFactor} type="number"value={this.state.lrFactor} onChange={(e, {value})=>{this.setState({ lrFactor: value})}}/>
          </Form>
      </LRModal>
      </React.Fragment>
    );
  }

  handleAddition = (e, { value }) => {
    this.setState({
      customFields: [{ text: value, value, key:value }, ...this.state.customFields],
    })
  }

  onOkClick = () =>
  {
    if(this.state.lrValue !== undefined && this.state.vwValue && this.state.pioValue)
    {
      window.LR_AddVWFieldMap({
        Entry: {
          ParametricName:this.state.pioValue,
          VWField: this.state.vwValue,
          LRField: this.state.fields[this.state.lrValue].PropertyName ,
          LRArray: this.state.fields[this.state.lrValue].ArrayName,
          Factor: this.state.lrFactor,
        }
      }).then(() => {
        if (globalCallbacks.updateFieldMap && IsRunningAsBrowser()) { globalCallbacks.updateFieldMap(); }
      })

      this.setState({open: false})
    }
    
  }

  onDiscardModal = () =>
  {
    this.setState({open: false})
  }

  openEditModal = () =>
  {
    this.updateFields()
    this.setState({open: true, lrValue: undefined, vwValue: undefined, pioValue: undefined,})
  }

  onDeleteFieldMap = (entry) => {
    window.LR_DeleteVWFieldMap({ Entry: {ParametricName: entry.ParametricName,VWField: entry.VWField, LRField: entry.LRField} }).then(() => {
      if (globalCallbacks.updateFieldMap && IsRunningAsBrowser()) { globalCallbacks.updateFieldMap(); }
    })
  }

  render_Row(entry) 
  {
    return(<React.Fragment>
          <Table.Row>
            <Table.Cell>
              <Icon name="delete" color="red" link textAlign="right" onClick={() => this.onDeleteFieldMap(entry)}></Icon>
              {entry.ParametricName}
            </Table.Cell>
            <Table.Cell>
              {entry.VWField}
            </Table.Cell>
            <Table.Cell>
              {entry.LRField}
            </Table.Cell>
            <Table.Cell>
              {entry.LRArray}
            </Table.Cell>
            <Table.Cell>
              {entry.Factor !== 1.0 ? <Label>{entry.Factor}</Label> : undefined}
            </Table.Cell>
          </Table.Row>
          </React.Fragment>)
  }

  setUpCallbacks()
  {
    globalCallbacks.updateFieldMap = async () =>
    {
      this.setState({isLoading: true})
      let res = await window.LR_GetVWFieldMap() 
      this.setState({map: res.ResourceMap.vectorWorksfieldMapping, isLoading: false})
    }
  }
}

export default VectorworksFieldMapping;

