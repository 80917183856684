
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Form } from 'semantic-ui-react'
import CaseNode from "./CaseNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import { BASE_UNIT_LENGTH, BASE_UNIT_ONE_BASED, BASE_UNIT_WEIGHT, IsElectronContext } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"

const SORT_BY = "Name"

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class CaseTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            cases : [],
            truckOptions : [],
            selectedTruck: undefined,
            sorting: null,
            indexMap: [],

            caseEditOpen : false,
            editedCaseObject : undefined,

            openCreateCase: false,
            template:"",
            optionTemplate:[],

            newSizeX : 0,
            newSizeY : 0,
            newSizeZ : 0,
            newWeight: 0,

            // search
            isLoading: false,
            results  : [], 
            value    : "",

            amountToAdd : 0,
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.updateCaseObjects();
        globalCallbacks.loadTruckOptionForCases();
    }

    render() 
    {
      const mobileStyle = {position: "relative", marginBottom: "8rem"}
        return (
          <div style={{width:"100%", height:"100%"}}>
            <Table style={{borderBottom: "none", margin:0}}>
                <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan={this.props.small ? '4' : '5'} style={{zIndex: 0}} onClick={this.onHeaderClick}>
                        {<Icon link style={{floated: 'left', position: 'absolute',zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                        <Search
                          open={false}
                          loading={this.state.isLoading}
                          value={this.state.value}
                          onClick = {(e)=>{e.stopPropagation()}}
                          onSearchChange={(e, { value }) =>
                            this.onSearchChange(value, this.state.cases)
                          }
                          aligned="right"
                          size = {window.IsIOS ? "mini" : "large"}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                </Table.Header> 
            </Table>

            <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}> 
              <Table striped structured compact="very" size="small">
                <Table.Body>
                  {this.renderRows()}
                </Table.Body>
              </Table>
            </div>

            <Segment vertical textAlign="center" style={window.IsIOS ? mobileStyle : {position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width: "100%", border:"none"}}>
              <Label as="a" color="green" onClick={this.openAddNewClass}>
                <Icon name="plus" />
                {LocalizedStrings.AddNewCase}
              </Label>
            </Segment>
            {this.returnEditModal()}

            <LRModal
              open={this.state.openCreateCase}
              scrolling={false}
              title={LocalizedStrings.AddNewCase}
              onOkClick={() => {
                this.onAddNewClass(true);
              }}
              onCancelClick={() => {
                this.onAddNewClass(false);
              }}
            >
              <Form>
                <Form.Select
                  name="Template"
                  options={this.state.optionTemplate}
                  value={this.state.template}
                  onChange={(e, { name, value }) => {
                    this.setState({ template: value });
                  }}
                ></Form.Select>
                <UnitInput
                  step={"1"}
                  min={1}
                  baseUnit={BASE_UNIT_ONE_BASED}
                  label="Amount to add"
                  value={this.state.amountToAdd}
                  onStateUpdate={(name, value, e) => {
                    this.setState({ amountToAdd: value });
                  }}
                />
              </Form>
            </LRModal>
          </div>

        );
    }

    onHeaderClick = () => {
      let newVal = null
      switch (this.state.sorting) {
          case null:          newVal = "ascending";   break;
          case "ascending":   newVal = "descending";  break;
          default:            newVal = null;          break;
      }
      this.setState({
          sorting: newVal
      })
    }

    renderRows = () => {
      let showData = !this.state.value ? this.state.cases : this.state.isLoading ? this.state.cases : this.state.results
      if(this.state.sorting) {
        return this.state.indexMap.map((_, i) => {
            let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
            return showData[this.state.indexMap[calcIndex]]
        }).map((caseEntry) => caseEntry.UUID ? <CaseNode
          truckOptions={this.state.truckOptions}
          key={caseEntry.UUID}
          case={caseEntry}
          small={this.props.small}
        /> : null)
      }

      return showData.map((caseEntry) => 
        <CaseNode
          truckOptions={this.state.truckOptions}
          key={caseEntry.UUID}
          case={caseEntry}
          small={this.props.small}
        />
      )
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

    returnEditModal =() =>
    {
        return(
            <LRModal    
                open={this.state.caseEditOpen} 
                title={LocalizedStrings.EditCase}
                onOkClick = {this.confirmEditModal}
                onCancelClick = {() => {this.setState({caseEditOpen: false})}}>
                <Form>
                    <Form.Input     name      = "newName"
                                    label     = {LocalizedStrings.NewContainerName}
                                    inline
                                    fluid
                                    value     = {this.state.newName}
                                    onChange  = {(e) => this.onValueChanged("newName", e.target.value)}/>                        
                    <Form.Select    label={LocalizedStrings.LinkedTruck} 
                                    value={this.state.selectedTruck} 
                                    options={this.state.truckOptions} 
                                    onChange={this.onCaseTruckChange}/>
                
                    <Form.Group widths="equal">
                        <UnitInput  name           = "newSizeX"
                                    baseUnit       = {BASE_UNIT_LENGTH}
                                    label          = {LocalizedStrings.NewContainerSizeX}
                                    inline
                                    fluid
                                    value          = {this.state.newSizeX}
                                    onStateUpdate  = {(name, val) => this.onValueChanged(name, val)}/>

                        <UnitInput  name           = "newSizeY"
                                    baseUnit       = {BASE_UNIT_LENGTH}
                                    label          = {LocalizedStrings.NewContainerSizeY}
                                    inline
                                    fluid
                                    value          = {this.state.newSizeY}
                                    onStateUpdate  = {(name, val) => this.onValueChanged(name, val)}/>

                        <UnitInput  name           = "newSizeZ"
                                    baseUnit       = {BASE_UNIT_LENGTH}
                                    label          = {LocalizedStrings.NewContainerSizeZ}
                                    inline
                                    fluid
                                    value          = {this.state.newSizeZ}
                                    onStateUpdate  = {(name, val) => this.onValueChanged(name, val)}/>

                    </Form.Group>
                    <UnitInput  name           = "newWeight"
                                baseUnit       = {BASE_UNIT_WEIGHT}
                                label          = {LocalizedStrings.Weight}
                                inline
                                fluid
                                value          = {this.state.newWeight}
                                onStateUpdate  = {(name, val) => this.onValueChanged(name, val)}/>    
                </Form>
            </LRModal>)
    }

    confirmEditModal = () =>
    {
        window.LR_SetCase({UUID: this.state.editedCaseObject.UUID, Name: this.state.newName, CaseSize: {X: this.state.newSizeX, Y: this.state.newSizeY, Z: this.state.newSizeZ}, Weight: this.state.newWeight})
        this.setState({caseEditOpen: false})
    }

    onValueChanged = (name, val) =>
    {
        this.setState({[name]: val})
    }

    openAddNewClass = async () =>
    {
        let caseTemplates = await window.LR_GetCaseTemplateMap();

        let caseOptions = []

        caseOptions.push({
            text: "None",
            value:"",
            key: "None",
        })

        caseTemplates.ResourceMap.caseTemplate.forEach(element => 
        {
            caseOptions.push({
                text: element.Name,
                value: element.Name,
                key: element.Name,
            })
            
        });

        this.setState({openCreateCase:true, optionTemplate: caseOptions})
    }

    onAddNewClass = (create) =>
    {
        this.setState({openCreateCase: false});
        if(create)
        {
            window.LR_AddNewCase({Template: this.state.template, amountToAdd: this.state.amountToAdd})
        }
        
    }

    onCaseTruckChange = (e, {value}) =>
    {  
        window.LR_SetCase({UUID: this.state.editedCaseObject.UUID, LinkedContainer: value})

        this.setState({selectedTruck: value})
    }

    setUpCallbacks() 
    {
        globalCallbacks.updateCaseObjects = () =>
        {
            window.LR_GetCases().then(res => {
              let indexMap = []
              let objects = res.Cases
              objects.forEach((_, i) => indexMap.push(i))
              indexMap.sort((a,b) => objects[a][SORT_BY] === objects[b][SORT_BY]  ? 0 : (objects[a][SORT_BY]  < objects[b][SORT_BY]  ? -1 : 1))
              this.setState({cases: res.Cases, indexMap})
            })
        }


        globalCallbacks.loadTruckOptionForCases = async () =>
        {
            let trucks = await window.LR_GetTrucks()
            let truckOptions = trucks.InventoryContainers.map(truck => ({key: truck.UUID, value: truck.UUID, text: truck.Name}));
            this.setState({truckOptions})

            return truckOptions
        }


        globalCallbacks.openCaseEdit = async (caseObject) =>
        {
            let truckOptions = await globalCallbacks.loadTruckOptionForCases()
            this.setState({caseEditOpen: true, editedCaseObject: caseObject, newName: caseObject.Name, newSizeX: caseObject.CaseSize.X, newSizeY: caseObject.CaseSize.Y, newSizeZ: caseObject.CaseSize.Z, newWeight: caseObject.Weight, truckOptions: truckOptions, selectedTruck: caseObject.LinkedContainer ? caseObject.LinkedContainer : undefined})
            
        }
    }
}

export default CaseTable;

