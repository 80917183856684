import { IsRunningAsBrowser } from '../../util/defines';
import { globalWindowInterface } from "../../util/callbackTypes";
import "izitoast/dist/css/iziToast.min.css"
import izitoast, { IziToastSettings } from "izitoast"

declare const window: globalWindowInterface;

const notifications: Set<SingleNotification> = new Set()

/**
 * Legacy Function to maintain compatibility, please use the static functions of the SingleNotification class
 * @param {*} title 
 * @param {*} content 
 * @param {*} Positive 
 * @param {*} Error 
 * @param {*} Warning 
 * @param {*} link 
 */
export async function addNotification(title: string, content: string, Positive = false, Error = false, Warning = false, link: string = undefined) {
  if (IsRunningAsBrowser()) {
    await window.WEB_CORE.isLoaded
  }

  let type = undefined
  if (Error) {
    type = "error"
  } else if (Warning) {
    type = "warning"
  } else if (Positive) {
    type = "positive"
  }
  let not = await SingleNotification.newNotification(title, content, type)
  not.link = link
  not.show()
}

type avTypes = "positive" | "error" | "warning" | undefined


export class SingleNotification {
  title: string
  content: string
  duration: number
  link: string
  type: avTypes
  not: HTMLDivElement
  settings: IziToastSettings
  private initFun: ()=>void
  private initPromise = new Promise<void>(cb => this.initFun = cb)
  buttons: IziToastSettings["buttons"] = []

  //Add new notification. If notification already exists, duration gets reset to given duration
  static async newNotification(title: string, content: string, type: avTypes = undefined, duration = 5000) {
    let ext = new SingleNotification()
    ext.title = title || ""
    ext.content = content || ""
    ext.type = type
    let alt: SingleNotification = undefined
    for (let i of notifications) {
      if (i.equals(ext)) {
        alt = i
      }
    }

    if(alt){
      alt.duration = duration
      await alt.refreshDuration(duration)
      return alt
    }else{
      ext.duration = duration
      notifications.add(ext)
      return ext
    }

  }

  async updateContent(content: string){
    await this.initPromise
    this.content = content || ""
    izitoast.show({
      ...(this.settings || {}),
      message: this.content,
      displayMode: 2,
      onOpening: (settings, toast)=>{
        this.not = toast
      }
    })
  }

  async updateProgressBar(ms: number) {
    await this.initPromise
    if(!this.not){ return; }
    izitoast.progress({ timeout: ms }, this.not).start()
  }

  addButton(title: string, onClick: Function) {
    if (!Array.isArray(this.buttons)) { this.buttons = []; }
    this.buttons.push([
      `<button>${title}</button>`,
      (inst, toast)=>{
        onClick()
      },
      false
    ])
  }

  async show() {
    let t = {
      "positive": "green",
      "error": "red",
      "warning": "yellow"
    }

    if(this.not){
      return
    }
    [this.not, this.settings] = await new Promise(cb => izitoast.show({
      color: t[this.type],
      timeout: this.duration || false,
      title: this.title,
      layout: 2,
      buttons: Array.isArray(this.buttons) ? this.buttons : [],
      message: this.content || "",
      position: "bottomCenter",
      titleSize: "11px",
      messageSize: "10px",
      class: "notificationStyle",
      onOpening(settings, toast) {
        cb([toast, settings])
      }
    }))

    this.settings.onClosed = () => {
      notifications.delete(this)
    }

    this.initFun()
  }

  async delete() {
    await this.initPromise
    izitoast.hide({}, this.not)
    notifications.delete(this)
  }

  async refreshDuration(duration = undefined) {
    await this.initPromise
    if(this.duration){
      izitoast.progress({timeout: duration}, this.not, ()=>{
        izitoast.progress({}, this.not).start()
      }).reset()
    }
  }

  equals(notification: SingleNotification) {
    return this.title === notification.title && this.content === notification.content && this.type === notification.type
  }
}
