//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Popup, Checkbox } from 'semantic-ui-react'
import LocalizedStrings from '../../localization/NavigationContainer';
import { EMPTY_UUID } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB
class ClassNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
            class: this.props.clss,
            expand: false
        }

        this.deleted = false;
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.clss))
        if(oldProps.clss[name] !== value) 
        {
            this.setState({...this.state, class:{...this.state.class, [name]: value}});
        }
    }

    render() 
    {
        return (this.props.small || window.IsIOS) ? this.renderSmall() : this.renderBig()
    }

    renderSmall = () =>
    {
        let clss = this.props.clss;

        return  <Table.Row>
                    <Table.Cell>
                        <div>
                            <Input  transparent
                                    name      = "Name" 
                                    value     = {this.state.class.Name} 
                                    onChange  = {this.onValueChanged} 
                                    onBlur    = {() => this.updateData("Name", this.state.class.Name)}
                                    onKeyDown = {this.onKeyDown}
                                    style     = {{width:"90%"}}
                                    fluid/>
                         
                            <Icon 
                                style = {{ float : "right", marginTop:"-1rem"}}
                                float = "right"
                                 
                                name='dropdown' 
                                rotated={this.state.expand ? undefined : "counterclockwise"}   
                                onClick={() => {this.setState({expand:!this.state.expand})}}
                            /> 
                        </div>
                        {this.state.expand 
                         ? 
                        <p style={{fontSize:"0.7rem", display:"flex", justifyContent:"space-between", margin:"0.5rem 0"}}>
                        <Icon 
                            name     = {clss.Visible || clss.UUID === this.props.activeUuid ? "eye" : "eye slash"}
                            link
                            size     = 'large'
                            color    = {clss.UUID === this.props.activeUuid ? "blue" : clss.OnlyVisibleUsed ? "grey" : "black"}
                            disabled = {clss.OnlyVisibleUsed || clss.UUID === this.props.activeUuid}
                            onClick  = {(event) => {this.updateData("Visible", !clss.Visible, event)}}/>
                        <Icon 
                            name    = {clss.OnlyVisible ? "eye" : "eye slash outline"}
                            size     = 'large'
                            link
                            color   = {clss.OnlyVisible ? "green" : clss.OnlyVisibleUsed ? "black" : "grey"}
                            onClick = {() => {this.updateData("OnlyVisible", !clss.OnlyVisible); this.forceUpdate()}}/>
                        <Icon  
                            name    = "check circle"
                            size     = 'large'
                            link
                            color   = {clss.UUID === this.props.activeUuid ? "green" : "black"}
                            onClick = {() => {clss.UUID === this.props.activeUuid ? window.LR_SetActiveClass({Class: EMPTY_UUID}) : window.LR_SetActiveClass({Class: clss.UUID})}}/>
                        <Icon 
                            name    = "file outline"
                            size     = 'large'
                            link
                            onClick = {this.createWorksheet}/>
                        <Icon 
                            name    = "delete"
                            size     = 'large'
                            link
                            color   =  "red"
                            onClick = {() => {this.onDelete(clss.UUID)}}/>
                    </p>
                    : ""}       
                    </Table.Cell>
                </Table.Row>
    }

    renderBig = () => 
    {
        let clss = this.props.clss;


        return <Table.Row>
                <Table.Cell collapsing>
                    <Checkbox checked={this.props.checked} onChange= {() => {this.props.onSelectClassNode(clss.UUID, !this.props.checked)}}/>
                </Table.Cell>
                <Table.Cell>
                    <Input  transparent
                            name      = "Name" 
                            value     = {this.state.class.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", this.state.class.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                    <Popup content={LocalizedStrings.VisibilityClass}
                            trigger={
                                <Icon 
                                name     = {clss.Visible || clss.UUID === this.props.activeUuid ? "eye" : "eye slash"}
                                link     = {!clss.OnlyVisibleUsed}
                                color    = {clss.UUID === this.props.activeUuid ? "blue" : clss.OnlyVisibleUsed ? "grey" : "black"}
                                disabled = {clss.OnlyVisibleUsed || clss.UUID === this.props.activeUuid}
                                onClick  = {(event) => {this.updateData("Visible", !clss.Visible, event)}}
                                />
                            } />
                    <Popup content={LocalizedStrings.OnlyVisibilityClass}
                            trigger={
                                <Icon   name    = {clss.OnlyVisible ? "eye" : "eye slash outline"}
                                        link
                                        color   = {clss.OnlyVisible ? "green" : clss.OnlyVisibleUsed ? "black" : "grey"}
                                        onClick = {() => this.updateData("OnlyVisible", !clss.OnlyVisible)}
                                        />
                            }/>
                    <Popup content={LocalizedStrings.ActivateClass} 
                            trigger={
                                <Icon   name    = "check circle"
                                        link
                                        color   = {clss.UUID === this.props.activeUuid ? "green" : "black"}
                                        onClick = {() => {clss.UUID === this.props.activeUuid ? window.LR_SetActiveClass({Class: EMPTY_UUID}) : window.LR_SetActiveClass({Class: clss.UUID})}}/>
                            }/>
                    <Popup content={LocalizedStrings.CreateWorksheetClass}
                            trigger={
                                <Icon   name    = "file outline"
                                        link
                                        onClick = {this.createWorksheet}
                                        />
                            } />
                    <Popup content={LocalizedStrings.DeleteClass} 
                            trigger={
                                <Icon   name    = "delete"
                                        color   = "red"
                                        link
                                        onClick = {() => this.onDelete(clss.UUID)}
                                        />
                            }/>
                </Table.Cell>
            </Table.Row>
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({...this.state, class:{...this.state.class, [name]: value}});
    }

    createWorksheet = () =>
    {
        if (globalCallbacks.createWorksheetFromClass)
        {
            globalCallbacks.createWorksheetFromClass(this.state.class.UUID)
        }
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.state.class.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.class.Name; }

        if(event)
        {
            if(event.altKey)
            {
                request.ModifierKey = true;
                this.setState({...this.state, class:{...this.state.class, Visible: true}});
            }
            else
            {
                request.ModifierKey = false;
                this.setState({...this.state, class:{...this.state.class, Visible: value}});
            }
            
        }
        
        window.LR_SetClass(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur()}
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        //We use this test in case the UI didn't update fast enough (happened with big scenes)
        if(!this.deleted)
        {
            window.LR_DeleteClass(request);
        }
        
        this.deleted = true;
    }

}

export default ClassNode;

