
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/NavigationContainer";
import ContainerNode from './ContainerNode';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import { BASE_UNIT_DATE, IsElectronContext } from '../../util/defines'
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"

const SORT_BY = "Name"

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class ContainerTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            containers  : [],

            openModal   : false,
            newName     : "New Truck",
            newSizeX    : 0,
            newSizeY    : 0,
            newSizeZ    : 0,
            loadingLocation    : "",
            driver    : "",
            licence    : "",
            LoadingDateStart : new Date().toISOString(),
            LoadingDateEnd   : new Date().toISOString(),
            DeLoadingDate    : new Date().toISOString(),

            templateOptions: [],
            selectedTemplate: "",

            // search
            isLoading: false,
            results  : [], 
            value    : "",
            sorting: null,
            indexMap: [],
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.refreshInventoryContainers();
    }

    componentWillUnmount = () => 
    {
    }

    render() 
    {
        const mobileStyle = {position: "relative", marginBottom: "8rem"}
        return (
            <div style={{width:"100%", height:"100%"}}>
                <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3' style={{zIndex: 0}} onClick={this.onHeaderClick}>
                                {<Icon link style={{floated: 'left', position: 'absolute',zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                                <Search open    = {false}
                                        loading = {this.state.isLoading}
                                        value   = {this.state.value}
                                        onClick = {(e)=>{e.stopPropagation()}}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value, this.state.containers)}
                                        size = {window.IsIOS ? "mini" : "large"}
                                        aligned = "right"/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                <div style={{width:"100%",maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}>
                    <Table striped structured compact='very' size="small">  
                        <Table.Body>
                            {this.renderRows()}
                        </Table.Body>
                    </Table>
                </div>
                <Segment vertical textAlign="center" style={window.IsIOS ? mobileStyle : {position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width: "100%", border:"none"}}>
                    <Label as="a" color="green" onClick={this.openModal}>
                        <Icon name="plus"/>{LocalizedStrings.AddNewInventoryContainer}
                    </Label>
                </Segment>

                {this.renderModal()}

               
            </div>

        );
    }

    renderRows = () => {
        let showData = !this.state.value ? this.state.containers : this.state.isLoading ? this.state.containers : this.state.results

        if(showData.length === 0) return false

        if(this.state.sorting) {
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return showData[this.state.indexMap[calcIndex]]
            }).map((container) => <ContainerNode small={this.props.small} key={container.UUID} container = {container} />)
        }

        return showData.map(container => {
            return (
                <ContainerNode small={this.props.small} key={container.UUID} container = {container} />
            )
        })
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
      }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

    renderModal = () =>
    {
        return <LRModal     open={this.state.openModal}
                            title={LocalizedStrings.AddNewInventoryContainer}
                            onCancelClick={this.closeModal}
                            onOkClick={this.addNewInventoryContainer}
                            scrolling={false}
                            >
                        <Form>
                            <Form.Select label={LocalizedStrings.Template} options={this.state.templateOptions} value={this.state.selectedTemplate} onChange={(e, {value}) => {this.setState({selectedTemplate: value})}}/>
                            <Form.Group widths="equal">
                                <Form.Input name = "loadingLocation"
                                label     = {LocalizedStrings.LoadingLocation}
                                inline
                                fluid
                                value     = {this.state.loadingLocation}
                                onChange  = {this.onValueChanged}/>
                                <Form.Input name = "driver"
                                label     = {LocalizedStrings.ShippingCompany}
                                inline
                                fluid
                                value     = {this.state.driver}
                                onChange  = {this.onValueChanged}/>
                                <Form.Input name = "licence"
                                label     = {LocalizedStrings.Licence}
                                inline
                                fluid
                                value     = {this.state.licence}
                                onChange  = {this.onValueChanged}/>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <UnitInput label={LocalizedStrings.LoadingDateStart} name="LoadingDateStart" isClearable baseUnit={BASE_UNIT_DATE} value={this.state.LoadingDateStart} onStateUpdate={(name, date) => this.onDateChanged(name, date)} />
                                <UnitInput label={LocalizedStrings.LoadingDateEnd} name="LoadingDateEnd" isClearable baseUnit={BASE_UNIT_DATE} value={this.state.LoadingDateEnd} onStateUpdate={(name, date) => this.onDateChanged(name, date)} />
                                <UnitInput label={LocalizedStrings.DeLoadingDate} name="DeLoadingDate" isClearable baseUnit={BASE_UNIT_DATE} value={this.state.DeLoadingDate} onStateUpdate={(name, date) => this.onDateChanged(name, date)} />
                            </Form.Group>
                        </Form>
            </LRModal>
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]: value});
    }
    
    onDateChanged = async (name, date) => {
        this.setState({[name]: date});
    }

    openModal = async (e) => 
    {
        let truckTemplates = await window.LR_GetTruckTemplateMap()

        let options = truckTemplates.ResourceMap.truckTemplate.map(truckTempl => {
            return {
                key: truckTempl.Name,
                value: truckTempl.Name,
                text: truckTempl.Name,
            }
        })

        options.unshift({
            key: "None",
            value: "",
            text: "None",
        })

        this.setState({openModal: true, templateOptions: options});
    }

    addNewInventoryContainer = (e) => 
    {
        let request = 
        {
            Name        : "Truck",
            Template    : this.state.selectedTemplate,

            LoadingLocation  :this.state.loadingLocation,
            Driver  : this.state.driver,
            Licence  : this.state.licence,

            LoadingDateStart  : this.state.LoadingDateStart,
            LoadingDateEnd  : this.state.LoadingDateEnd,
            DeLoadingDate  : this.state.DeLoadingDate,
        }
        window.LR_AddNewTruck(request);

        this.closeModal();
    }

    closeModal = (e) => 
    {
        this.setState({openModal: false});
    }

    setUpCallbacks()
    {
        globalCallbacks.refreshInventoryContainers = async () =>
        {
            let ret = await window.LR_GetTrucks();
            let containers = ret.InventoryContainers;
            // Remove the first one which is the default container
            containers.shift();

            let indexMap = []
            let objects = containers
            objects.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => objects[a][SORT_BY] === objects[b][SORT_BY]  ? 0 : (objects[a][SORT_BY]  < objects[b][SORT_BY]  ? -1 : 1))

            this.setState({containers, indexMap});
        }
    }
}

export default ContainerTable;

