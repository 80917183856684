//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react'
import LocalizedStrings from "../localization/MobileFooter";
import { RESPONSIVE_PHONE } from '../util/defines';
import MediaQuery from 'react-responsive'
import Footer from '../electronApp/Footer';

class MobileFooter extends Component 
{
    constructor(props)
    {
      super(props);
      this.state = {
      }
    }

    componentDidMount = async () =>
    {        
    }

    render()
    {
        return(
            <MediaQuery maxWidth={RESPONSIVE_PHONE}>
            {(isPhone) => isPhone? 
            <>
            <div style={{display: "flex", flexDirection: "column", position: "fixed", bottom: "0", width: "100%", backgroundColor:"rgb(202, 203, 205)"}}>
                <Footer ios/>
                <div style={{display: 'flex', flexWrap: "wrap",   justifyContent: "center", textAlign: "center"}}>
                    {this.renderMenuItem("file", LocalizedStrings.Project, `projectCheckout`)}
                    {this.renderMenuItem("eye", LocalizedStrings.GraphicView, `renderNormalView`, true)}
                    {this.renderMenuItem("table", LocalizedStrings.TableView, `renderTableView`, true)}
                    {this.renderMenuItem("plug", LocalizedStrings.ElectricalView, `wirings`)}
                    {this.renderMenuItem("tasks", LocalizedStrings.Task, `tasks`)}
                    {this.renderMenuItem("camera", LocalizedStrings.Camera,`camera`)}
                </div>
            </div> 
            </>: null}
            </MediaQuery>
        )
    }


    renderMenuItem = (icon, name, path, rotate = false) =>
    {
        let style = { top: "50%",fontSize:"0.9em", minHeight:"2em", flexGrow:"1", borderStyle:"solid", borderWidth: ".3px", paddingLeft: "5px", paddingTop: "4px",paddingRight: "4px", wordBreak: "normal", wordWrap: "normal", textAlign: "center"}
        
        if(this.props.activeView === path)
        {
            style.backgroundColor ="rgb(190, 190, 190)"
        }
        
        return(
            <div onClick={()=>{this.props.changeComponent(path); window.LR_IOS_EnableRotation(rotate)}} style={style}>
                <Icon name={icon} size="big"/>
                <br/>
                {name}
            </div>
        )
    }
   
}


export default MobileFooter