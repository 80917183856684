import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import LocalizedStrings from "../../localization/TableViewComponent";
import LRModal from "../Basics/BasicModal";
import PrintSettings, { IPdfFormat, PrintSettingsPresetSelector } from "../GlobalSettings/PrintSettings";
import { globalWindowInterface } from "../../util/callbackTypes";
import { globalCallbacks } from "../../util/callback";

declare const window: globalWindowInterface;

export default function CustomPDFFormatPicker({
    PDFFormatName, 
    onChangePdfFormat, 
    
}: {    
    PDFFormatName?: string,    
    onChangePdfFormat: (formatName : string, formatDefini: IPdfFormat) => void,    
}) {
    const [paperFormatOpen, setPaperFormatOpen] = useState(false)

    let [PDFFormats_List, setPDFFormats_List] = useState([])    

    let loadPrintFormats = () => {
        window.LR_GetPrintFormatTemplateMap().then(res => res.ResourceMap.printFormats).then(setPDFFormats_List)
    }

    let updateCustomFormat = (formatDefini: IPdfFormat, formatNam?: string)=>{
        onChangePdfFormat(formatNam, formatDefini)
    }

    let changeFormatProperty = (formatName: string, formatType: string, prop: string, newVal : any)=>
    {
        let PDFFormats_List_To_edit = PDFFormats_List
        PDFFormats_List_To_edit.forEach(item => {
            if (item.Name == formatName) {
                item.format[formatType][prop] = newVal
            }
        })

        setPDFFormats_List(PDFFormats_List_To_edit);

        let formatDefini_Changed = getFormatDefiniByName(formatName);
        onChangePdfFormat(PDFFormatName, formatDefini_Changed)
    }

    let getFormatDefiniByName = (formatNam: string)=>{
        let foundFormat = PDFFormats_List.find(i => i.Name === formatNam)        

        if (foundFormat) {
            return foundFormat.format
        }
        else
        {
            console.error("Pdf format defini not found for: ", formatNam);
        }

        return undefined
    }

    let selectNewPreset = (Name: string)=>{
        let foundFormat = getFormatDefiniByName(Name)
        
        onChangePdfFormat(Name, foundFormat);
    }
    
    if (!PDFFormatName) {
        PDFFormatName = "Default"
    }

    let [defaultFormatName, setDefaultFormatName] = useState("Default")

    useEffect(() => {
        globalCallbacks.updateSettingsViewPresets = loadPrintFormats
        loadPrintFormats()

        globalCallbacks.updatePrintSettingsPresetSelectorDefault2 = () => {
            window.LR_GetSetSelectedPrintFormatTemplate().then(res => setDefaultFormatName(res.PrintFormatTemplateName))
        }

        window.LR_GetSetSelectedPrintFormatTemplate().then(res => {             
            if(! PDFFormatName)
            {
                onChangePdfFormat(res.PrintFormatTemplateName, res.PrintFormatTemplate)
            } 
        }) 

        return () => {
            globalCallbacks.updateSettingsViewPresets = undefined
            globalCallbacks.updatePrintSettingsPresetSelectorDefault2 = undefined
        }
    }, [])
    
    
    let pdfFormatDefini = getFormatDefiniByName(PDFFormatName)    

    return <>
        <h5 style={{marginTop: 14, marginBottom: 4}}>{LocalizedStrings.PaperFormatPreset}</h5>
        <Form.Group style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 14, marginLeft: 0}} >
            <PrintSettingsPresetSelector
                withoutButtons
                selectedPreset={PDFFormatName}
                defaultPreset={defaultFormatName}
                presets={PDFFormats_List}
                onChangeSelected={selectNewPreset}
                style={{width: 320}}
            />
            <Button style={{marginLeft: 10}} onClick={() => setPaperFormatOpen(true)}>{LocalizedStrings.OpenFormatSettings}</Button>
        </Form.Group>
        <LRModal
            open={paperFormatOpen}
            title={LocalizedStrings.PDFFormatSettings}
            onOkClick={() => setPaperFormatOpen(false)}
            style={{width: 1200}}
            noCancel
        >
            {
                pdfFormatDefini ?
                <PrintSettings
                    format={pdfFormatDefini}
                    onFormatChange={(format, name, val) => 
                        {     
                            changeFormatProperty(PDFFormatName, format, name, val);                            
                        }} 
                    onAllFormatChange={updateCustomFormat}
                />
                :
                null
            }

        </LRModal>
    </>
}