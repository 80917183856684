//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import { Icon, Form, Dropdown, Flag } from 'semantic-ui-react'

import { globalCallbacks } from '../util/callback';
import LocalizedStrings from "../localization/Footer";
import CommandLine from '../util/commandline';
import LRModal from "../component/Basics/BasicModal";
import { IsDarkTheme, LightWorkspace, AllWorkspace, RiggingWorkspace, AppLanguageToString, StringToAppLanguage, StringToAppTheme } from '../util/defines';
import BasicSelect from '../component/Basics/BasicSelect';


class Footer extends Component 
{
    constructor(props)
    {
      super(props);
      this.state = {
          user: "User",
          Organizations: [],
          DefaultResourceUser: "User",
          LinkedTemplate: "",
          file: "",
          branch:"",
          SigningJobId: "",
          branchwa:[],
          connected:false,
          message:false,
          errorDisplayOpen: false,
          UseLiveCalculation:true,
          MVRxchangeRunning:false,
          PosiStageNetRunning:false,
          PosiStageNetClientRunning:false,
          MVRxchangeServiceName:"",
          UseAutoPatch:true,
          CurrentlyRunningCalculation: 0,
          theme: !IsDarkTheme(),
          CalculateVisibleStructureOnly: false,
          CalculateSelectedStructureOnly: false,
          Renderer_DisableSnapping: false,

          Renderer_ShowLabels3D: true
      }

      this.CurrentlyRunningCalculation_mtx = 0


    }

    componentDidMount = async () =>
    {
        this.setUpCallbacks();
        
        let res1 = await window.LR_GetLoggedInUser()
        let res2 = await window.LR_GetBaseURL()
        let res3 = await window.LR_GetOrganizationsAsync()

        this.setState({user: res1.User, URL: res2.URL, Licence: res1.Licence, Port: res2.Port, Organizations: res3})
        
       if (globalCallbacks.updateActiveProject)
       {
           globalCallbacks.updateActiveProject();
       }
    }


    render()
    {
        let color = this.state.connected ? "green": "#5e5e5e"
        if(this.state.message)
        {
            color = "blue"
        }

        let marginLeft  = this.props.ios ? "1.5em" : "5px"
        let marginRight = this.props.ios ? "1.0em" : "3px"

        let gb = this.state.URL !=="https://www.production-assist.com" ? (this.state.theme ? 'yellow' : "#CCCCCC") : "#CCCCCC"

        if(this.state.SigningJobId !== "")
        {
            gb = "#FF4500"
        }

        let languageOptions = [
            { key: 'en', text: <Flag name='gb'/> , value: 'en'},
            { key: 'fr', text: <Flag name='fr'/> , value: 'fr'},
            { key: 'de', text: <Flag name='de'/> , value: 'de'},
            { key: 'it', text: <Flag name='it'/> , value: 'it'},
            { key: 'ru', text: <Flag name='ru'/> , value: 'ru'},
            { key: 'es', text: <Flag name='es'/> , value: 'es'},
            { key: 'cz', text: <Flag name='cz'/> , value: 'cz'},
        ]

        let style = {height: this.props.size, position: "fixed", bottom: "0", width: "100%", backgroundColor:gb}
        if(this.props.ios)
        {
            style = { backgroundColor:gb, flexWrap: "no-wrap"}
        }

        return(
            <div style={(this.props.webApp) ? {height: this.props.size, width: "100%", backgroundColor:gb}: style}>
                {this.props.ios ? null: 
                <div style={{marginLeft:"3px",color:"#5e5e5e"}}>
                    <CommandLine/>
                </div>}
                <div style={this.props.ios ? {display: "flex", flexDirection: "row", justifyContent: "space-evenly"} : {float: "left", marginLeft:marginLeft,marginRight:marginLeft,color:"#5e5e5e"}}>
                {this.props.ios ? null:
                <Form>
                        <Form.Group >
                            
                            <div style={{cursor: "pointer", marginLeft: "0.3em", marginRight:"0.3em"}} onClick={(e) => { window.LR_OpenLink({URL: this.state.URL + "/" + this.state.user}) }} data-tooltip={LocalizedStrings["User_Tooltip"]} data-position="top left">
                            <Icon name="user"/>
                            {this.state.user}
                            </div>
                            {" "}
                            <div style={{cursor: "pointer", marginLeft: "0.3em", marginRight:"0.3em" }} onClick={(e) => { window.LR_OpenLink({URL: this.state.URL + "/settings/account"}) }} data-tooltip={LocalizedStrings["Plan_Tooltip"]}>
                            <Icon name="payment"/>
                            {LocalizedStrings[this.state.Licence?.ProductName] }

                            </div>
                            {
                                this.state.ResourceIdent ? 
                                <div style={{cursor: "pointer", marginLeft: "0.3em", marginRight:"0.3em" }} onClick={(e) => { window.LR_CommitRessourceDrawing() }} >
                                    <Icon name="globe"/>
                                    {this.state.ResourceIdent}
                                </div> : null
                            }
                            {this.renderDefaultRessourceUser()}
                            {" "}
                            {this.props.ios ? null: <>
                            {
                            this.state.file ? 
                            <div style={{cursor: "pointer",  marginLeft: "0.3em", marginRight:"0.3em", color: this.state.IsDefaultBranch ? "green" : "orange"}} onClick={(e) => { if (this.state.file) { window.LR_OpenLink({URL: this.state.URL + "/" + this.state.owner + "/" + this.state.file}) } }}>
                                <Icon name="file" />
                                <span data-tooltip={LocalizedStrings["Project_Tooltip"]} >
                                    {this.state.owner}
                                    /
                                    {this.state.file}
                                    /
                                </span>
                                <BasicSelect normalDropdown onStateUpdate={this.ChangeBranch} options={this.state.branches} value={this.state.branch}/>
                            <Icon style={{cursor: "pointer"}} onClick={(event)=>{window.alert(this.state.userRight > 0 ? LocalizedStrings.ProjectHasWrite : LocalizedStrings.ProjectNoWrite); event.stopPropagation()}} name={this.state.userRight > 0 ? "lock open" : "lock"}/>
                            </div> 
                            :
                            <div>
                                <Icon style={{cursor: "pointer"}} color={"red"} name="file" />
                                {LocalizedStrings.NoProject}
                            </div> 
                            }
                            </>}
                            {
                                this.state.LinkedTemplate ? <>
                                    <Icon name="theme"/>
                                    {this.state.LinkedTemplate }
                                    <div data-tooltip={LocalizedStrings["LinkedTemplate"]} data-position="top right" style={{color:"black"}}>{this.state.LinkedTemplate}</div>
                                </> : null
                            }


                        </Form.Group>
                    </Form>}
                </div>
                <div style={this.props.ios ? {display: "flex", flexDirection: "row", justifyContent: "space-around"} : {float: "right", marginRight:marginRight, display: "flex", flexDirection: "row"}}>
                    {this.EnablePosiStageNet()}
                    {this.EnablePosiStageNetServer()}
                    {this.EnableMvrXchange()}
                    <div data-tooltip={LocalizedStrings["Renderer_DisableSnapping"]}>{this.disableSnappingIcon()}</div>
                    <div data-tooltip={LocalizedStrings["Calculation_LoadCellsMonitor"]}>{this.loadCellIcon()}</div>
                    <div data-tooltip={LocalizedStrings["CalculationSelected_Tooltip"]}>{this.calculatorSelectedIcon()}</div>
                    <div data-tooltip={LocalizedStrings["CalculationVisible_Tooltip"]}>{this.calculatorVisibleIcon()}</div>
                    <div data-tooltip={LocalizedStrings["Calculation_Tooltip"]}>{this.calculatorIcon()}</div>
                    <div data-tooltip={LocalizedStrings["AutoPatch_Tooltip"]}>{this.AutoPatchIcon()}</div>
                    {this.props.ios ? null : <Icon name="" />}
                    <div data-tooltip={LocalizedStrings["Error_Tooltip"]}><Icon color="black" style={{cursor: "pointer"}} name="tag" onClick={() => {this.onErrorDisplay()}}/></div>
                    <div data-tooltip={LocalizedStrings["Feedback_Tooltip"]}><Icon color="black" style={{cursor: "pointer"}} name="talk" onClick={() => {this.onShowFeedback()}}/></div>
                    {this.props.ios ? null : <Icon name="" />}
                    {this.props.ios ? null :<>
                    <div data-tooltip={LocalizedStrings["Remote_Tooltip"]} data-position="top right" ><Icon style={{cursor: "pointer", color:color}} name="sitemap" onClick={() => {this.openLRNet()}}/></div>
                    <div data-tooltip={LocalizedStrings["Port_Tooltip"]} data-position="top right" style={{color:"black"}}>{this.state.Port > 0 ? this.state.Port : null}</div>
                    {this.props.ios ? null : <Icon name="" />}
                     </>}
                     <div data-tooltip={LocalizedStrings["Reload_Tooltip"]} data-position="top right" style={{color:"black"}}><Icon name="redo" onClick={()=>{ window.location.reload()}}/></div>
                     {this.props.ios ? null : <Icon name="" />}

                    {this.props.ios ? null:
                    <>
                    <Dropdown style = {{background:"transparent", lineHeight:"20px"}}
                        button
                        floating
                        labeled
                        value       =  {this.state.lang}
                        options      = {languageOptions}
                        onChange     = {(e, {value}) => window.LR_SetGlobalSettings({Language: {Value: StringToAppLanguage(value)}})}
                    />
                 
                    <Dropdown text={LocalizedStrings.SelectTheme} item style = {{color:"black"}} >
                        <Dropdown.Menu color="black">
                            <Dropdown.Item
                                content={LocalizedStrings.LightTheme}
                                onClick={() => this.updateTheme("light")}
                            />
                            <Dropdown.Item
                                content={LocalizedStrings.DarkTheme}
                                onClick={() => this.updateTheme("dark")}
                            />
                        </Dropdown.Menu>
                     </Dropdown>
                     <Dropdown text={LocalizedStrings.SelectWorkspace} item style = {{color:"black"}} >
                        <Dropdown.Menu color="black">
                            <Dropdown.Item
                                content={LocalizedStrings.SelectWorkspace_Rigging}
                                onClick={() => this.set_Workspace(RiggingWorkspace)}
                            />
                            <Dropdown.Item
                                content={LocalizedStrings.SelectWorkspace_Light}
                                onClick={() => this.set_Workspace(LightWorkspace)}
                            />
                            <Dropdown.Item
                                content={LocalizedStrings.SelectWorkspace_All}
                                onClick={() => this.set_Workspace(AllWorkspace)}
                            />
                        </Dropdown.Menu>
                     </Dropdown>
                     <Dropdown text={window.ElectronWebsocketMode ? LocalizedStrings.ConnectionMode_Other : LocalizedStrings.ConnectionMode_App} item style = {{color:"black"}} >
                        <Dropdown.Menu color="black">
                            <Dropdown.Item
                                content={LocalizedStrings.ConnectionMode_App}
                                onClick={() =>{window.LR_E_SetAppMode({ElectronWebsocketMode:false})}}
                            />
                            <Dropdown.Item
                                content={LocalizedStrings.ConnectionMode_Other}
                                onClick={() =>{window.LR_E_SetAppMode({ElectronWebsocketMode:true})}}
                            />
                        </Dropdown.Menu>
                     </Dropdown>
                     </>}
                </div>
                            
                <LRModal    open={this.state.errorDisplayOpen}
                            onOkClick={this.okErrorDisplay}
                            onCancelClick={this.closeErrorDisplay}
                            title={LocalizedStrings.Header}>
                            <Form>
                                <Form.Checkbox  label = {LocalizedStrings.ShowStructuralErrors} checked = {this.state.Renderer_ShowStructuralErrors}  onChange = {(e) => {this.setState({Renderer_ShowStructuralErrors: !this.state.Renderer_ShowStructuralErrors}) }}/>
                                <Form.Checkbox  label = {LocalizedStrings.Show3DLabels} checked = {this.state.Renderer_ShowLabels3D} onChange = {(e, {checked}) => {this.setState({Renderer_ShowLabels3D: checked})}}/>
                            </Form>
                </LRModal>

            </div>
        )
    }

    renderDefaultRessourceUser()
    {
        if(!Array.isArray(this.state.Organizations))
        {
            return null
        }
        let options = this.state.Organizations.map(org => { return {text: org.name, value: org.username,key: org.username }})
        return(
            <>
            <div data-tooltip={LocalizedStrings["ForcedResourceUser"]}>{<Icon name="book"/>}</div>

        
            <Dropdown style = {{background:"transparent", lineHeight:"20px"}}
            button
            floating
            labeled
            value       =  {this.state.DefaultResourceUser}
            options      = {options}
            onChange     = {(e, {value}) => window.LR_SetDrawingWorksheetState({ForcedUserResources: value})}
            />
            </>
        )
    }

    updateTheme = (theme) =>
    {
        this.setState({theme:theme});
        window.LR_SetGlobalSettings({Theme: {Value: StringToAppTheme(theme)}});
    }

    set_Workspace = (theme) =>
    {
        localStorage.setItem("workspace", JSON.stringify(theme))
        window.location.reload(false)
    }

    calculatorIcon = () =>
    {
        return <Icon loading={this.state.CurrentlyRunningCalculation > 0} style={{cursor: "pointer"}} name={"calculator"} color={this.state.UseLiveCalculation ? "green" : "grey"} onClick={() => {this.onSwitchLiveCalculation()}}/>
    }

    loadCellIcon = () =>
    {
        return <Icon style={{cursor: "pointer"}} name={"wifi"} onClick={() => {window.LR_ShowLoadCellMonitor()}}/>
    }

    disableSnappingIcon = ()=>
    {
        return <Icon 
        style={{cursor: "pointer"}} 
        name={"magnet"} 
        color={this.state.Renderer_DisableSnapping ? "red" : "green"}
        onClick={async () => {
            let curr = (await window.LR_GetGlobalSettings()).GlobalSettings
            curr.Renderer_DisableSnapping = !curr.Renderer_DisableSnapping
            window.LR_SetGlobalSettings(curr)
        }}/>
    }

    EnableMvrXchange = ()=>
    {
        return( 
        <div
            style={{cursor: "pointer", marginRight: 7}} 
            data-tooltip={LocalizedStrings["EnableMvrXchange"]}
            onClick={async () => {
                window.LR_OpenMVRxchangeSettings({})
            }}
        >
            <Icon 
            name={"compass outline"} 
            color={this.state.MVRxchangeRunning ? "green" : "grey"}
            />
            {this.state.MVRxchangeServiceName}
        </div>
        )
    }

    EnablePosiStageNet = ()=>
        {
            return( 
            <div
                style={{cursor: "pointer", marginRight: 7}} 
                data-tooltip={LocalizedStrings["PSNRunning"]}
                onClick={async () => {
                    window.LR_SetProjectSettings({PosiStageNetRunning: !this.state.PosiStageNetRunning})
                }}
            >
                <Icon 
                name={"rss square"} 
                color={this.state.PosiStageNetRunning ? "green" : "grey"}
                />
            </div>
            )
        }

        EnablePosiStageNetServer = ()=>
        {
            return( 
            <div
                style={{cursor: "pointer", marginRight: 7}} 
                data-tooltip={LocalizedStrings["PSNRunningClient"]}
                onClick={async () => {
                    window.LR_SetProjectSettings({PosiStageNetClientRunning: !this.state.PosiStageNetClientRunning})
                }}
            >
                <Icon 
                name={"rss"} 
                color={this.state.PosiStageNetClientRunning ? "green" : "grey"}
                />
            </div>
            )
        }

    calculatorVisibleIcon = () =>
    {
        return <Icon style={{cursor: "pointer"}} name={"low vision"} color={this.state.CalculateVisibleStructureOnly ? "green" : "grey"} onClick={() => {this.onSwitchVisibleCalculation()}}/>
    }

    calculatorSelectedIcon = () =>
    {
        return <Icon style={{cursor: "pointer"}} name={"th"} color={this.state.CalculateSelectedStructureOnly ? "green" : "grey"} onClick={() => {this.onSwitchSelectedCalculation()}}/>
    }

    AutoPatchIcon = () =>
    {
        return <Icon style={{cursor: "pointer"}} name="lightbulb" color={this.state.UseAutoPatch ? "green" : "grey"} onClick={() => {this.OnSwitchAutoPatch()}}/>
    }


    onSwitchLiveCalculation = () =>
    {
        window.LR_SetGlobalSettings({App_UseLiveCalculation: !this.state.UseLiveCalculation})
        this.setState({UseLiveCalculation: !this.state.UseLiveCalculation})
    }

    onSwitchVisibleCalculation = () =>
    {
        window.LR_SetProjectSettings({CalculateVisibleStructureOnly: !this.state.CalculateVisibleStructureOnly})
        this.setState({CalculateVisibleStructureOnly: !this.state.CalculateVisibleStructureOnly})
    }

    onSwitchSelectedCalculation = () =>
    {
        window.LR_SetProjectSettings({CalculateSelectedStructureOnly: !this.state.CalculateSelectedStructureOnly})
        this.setState({CalculateSelectedStructureOnly: !this.state.CalculateSelectedStructureOnly})
    }

    OnSwitchAutoPatch = () =>
    {
        window.LR_SetGlobalSettings({Renderer_AutomaticclyResolvePatchingConflicts: !this.state.UseAutoPatch})
        this.setState({UseAutoPatch: !this.state.UseAutoPatch})
    }

    closeErrorDisplay = () =>
    {
        window.LR_SetGlobalSettings({
            Renderer_ShowStructuralErrors: this.state.Renderer_ShowStructuralErrors,
            Renderer_ShowLabels3D: this.state.Renderer_ShowLabels3D
        })
        this.setState({errorDisplayOpen: false})
    }

    okErrorDisplay = () =>
    {
        this.closeErrorDisplay()
    }

    ChangeBranch = (name, value, e) =>
    {
        window.LR_CheckoutProject(
        {
            Project:this.state.file,
            User:this.state.owner,
            Branch:value,
            BranchName: this.state.branches.find(opt => opt.value === value).text,
            CloseCurrent: true,
        })
    }

    openLRNet()
    {
        window.LR_ShowConnectToRemote()
    }

    onShowFeedback()
    {
        window.LR_MakeFeedbackInApp({ID: "5928220087025664"})
    }
    onErrorDisplay = async () =>
    {
        
        let settings = await window.LR_GetGlobalSettings()
        this.setState({errorDisplayOpen: true, ...settings.GlobalSettings})
    }

    setUpCallbacks() 
    {
        globalCallbacks.footerSettingsChanged = async (settings) =>{
            if(settings.Renderer_DisableSnapping !== undefined){
                this.setState({Renderer_DisableSnapping: settings.Renderer_DisableSnapping})
            }

            
            if(settings.App_UseLiveCalculation !== undefined){
                this.setState({UseLiveCalculation: settings.App_UseLiveCalculation})
            }
        }

        globalCallbacks.updateActiveProject = async () =>
        {
            let settings            = await window.LR_GetGlobalSettings()
            let drawingSettings     = await window.LR_GetDrawingSettings()

            console.log(drawingSettings)
            this.setState(
                {
                    CalculateVisibleStructureOnly: drawingSettings.CalculateVisibleStructureOnly, 
                    CalculateSelectedStructureOnly: drawingSettings.CalculateSelectedStructureOnly, 
                    UseLiveCalculation: settings.GlobalSettings.App_UseLiveCalculation, 
                    lang: AppLanguageToString(settings.GlobalSettings.Language.Value), 
                    UseAutoPatch: settings.GlobalSettings.Renderer_AutomaticclyResolvePatchingConflicts,
                    MVRxchangeRunning: drawingSettings.MVRxchangeRunning,
                    PosiStageNetRunning: drawingSettings.PosiStageNetRunning,
                    PosiStageNetClientRunning: drawingSettings.PosiStageNetClientRunning,
                    MVRxchangeServiceName: drawingSettings.MVRxchangeServiceName,
                    SigningJobId: drawingSettings.SigningJobId
                })


            let result = await window.LR_GetLinkedProject()

            this.setState({
                owner: result.Owner,
                file: result.Project,
                branch: result.Branch,
                userRight: result.UserRight,
                ResourceIdent: result.ResourceIdent,
                DefaultResourceUser: result.DefaultResourceUser,
                LinkedTemplate: result.LinkedTemplate
            })

            if(result.Owner)
            {
                let res = await window.LR_GetProjectDataAsync(result)

                if(res?.branches)
                {
                    let branches = res.branches ? res.branches.filter(branch => !branch.closed).map((value) => 
                    {
                        return ({
                            key: value._id,
                            text: value.name,
                            value: value._id,
                        });
                    }) : [];
    
                    if(res.defaultBranch)
                    {
                        branches.push({
                            key: res.defaultBranch._id,
                            text: res.defaultBranch.name,
                            value: res.defaultBranch._id,
                        })
                    }
        
    
        
                    this.setState({
                        branches: branches,
                        IsDefaultBranch: res.defaultBranch._id===result.Branch
                    })
                }


            }
            
            
        }

        globalCallbacks.onLRNET_Connect = async () =>
        {
            this.setState({connected: true})
        }

        globalCallbacks.onBackgroundWorkerStatus = (status, name) =>
        {
            if(status==="bwe" && name==="LRStructure")
            {
                
                this.CurrentlyRunningCalculation_mtx += 1
                this.setState({CurrentlyRunningCalculation: this.CurrentlyRunningCalculation_mtx})
            }
            else if(status==="bws" && name==="LRStructure")
            {
                this.CurrentlyRunningCalculation_mtx -= 1
                this.setState({CurrentlyRunningCalculation: this.CurrentlyRunningCalculation_mtx})
            }
        }
        

        globalCallbacks.onLRNET_Disconnect = async () =>
        {
            this.setState({connected: false})
        }

        globalCallbacks.onLRNET_Message = async () =>
        {
            this.setState({message: true})
            setTimeout(function(){ this.setState({message: false}) }.bind(this), 500);
        }
    }
}

export default Footer;