//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
// @ts-nocheck
import * as THREE from "three";
import { SPOT_LIGHT_HEIGHT, SPOT_LIGHT_RADIUS, VolumetricSpotLight } from "./VolumetricSpotLight";
import { LeftBoxBufferGeometry } from "./LeftBoxBufferGeometry";
import { CSS2DObject } from "./CSS2DRenderer";
import { getUnitValueFromCore, getUnitName } from "../../Basics/BasicUnitInput";
import { get, set } from 'idb-keyval';
import {
    OBJECT_TYPE_Fixture,
    GEOMETRY_OBJECT_TYPE_Electrical,
    GEOMETRY_OBJECT_TYPE_Support,
    GEOMETRY_OBJECT_TYPE_Structure,
    GEOMETRY_FEM_GEOMETRY_TYPE_Frame,
    GEOMETRY_OBJECT_TYPE_Audio,
    GEOMETRY_OBJECT_TYPE_Dimension,
    GEOMETRY_OBJECT_TYPE_InfluenceLine,
    GEOMETRY_OBJECT_TYPE_FemGeometry,
    GEOMETRY_FEM_GEOMETRY_TYPE_Support,
    GEOMETRY_OBJECT_TYPE_Curtain,
    RESOURCE_TYPE_AssemblyGroup,
    RENDERER_VIEW_FRONT,
    RESOURCE_TYPE_TextObject,
    RENDERER_VIEW_BACK,
    RENDERER_VIEW_LEFT,
    RENDERER_VIEW_RIGHT,
    RENDERER_VIEW_TOP,
    RENDERER_VIEW_BOTTOM,
    RENDERER_VIEW_FRONT_LEFT,
    RENDERER_VIEW_FRONT_RIGHT,
    RENDERER_VIEW_REAR_LEFT,
    RENDERER_VIEW_REAR_RIGHT,
    GEOMETRY_OBJECT_TYPE_Origin,
    UNIT_Milimeter,
    UNIT_Centimeter,
    UNIT_Meter,
    GEOMETRY_SUPPORT_TYPE_Rope,
    GEOMETRY_StructureType_CenterLineBased,
    SELECT_COLOR,
    GEOMETRY_FEM_GEOMETRY_TYPE_Node,
    GEOMETRY_OBJECT_TYPE_Polygon,
    EWorkloadColoringMode_Threshold,
    GEOMETRY_OBJECT_TYPE_Load,
    GEOMETRY_OBJECT_TYPE_CableDock,
    EMPTY_UUID,
    BASE_UNIT_LENGTH,
    BASE_UNIT_BOOLEAN,
    BASE_UNIT_AMPERE,
    BASE_UNIT_ANGLE,
    BASE_UNIT_NUMBER,
    BASE_UNIT_WEIGHT,
    BASE_UNIT_VOLUME,
    BASE_UNIT_AREA,
    BASE_UNIT_ONE_BASED,
    BASE_UNIT_VOLTAGE,
    BASE_UNIT_POWER,
    BASE_UNIT_FORCE,
    BASE_UNIT_PERCENT,
    IsDarkTheme,
    EWorkloadColoringMode_Gradient,
    BASE_UNIT_PAGE_LENGTH,
    BASE_UNIT_TORQUE,
    GEOMETRY_SUPPORT_TYPE_Ground,
    PRINT_LABEL_FIELD_TYPE,
    GEOMETRY_FEM_GEOMETRY_TYPE_Load} from "../../../util/defines" ;
import { lrServerConnection } from "../../../redux/light_right_server_connection";
import LRInstanceMesh from "./LightRightInstanceMesh";
import { cie2RGB, cie2hex, rgb2hex } from "../../ColorPicker/utilities";
import { GetTexture, GetTextureByUUID } from "../../ResourceLoadContainer/TextureLoader";
import { store } from "../../../redux/store";
import { GLOBAL_SETTINGS } from "../../../redux/redux_defines";

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import InfiniteGridHelper from "./Grid";
import {mergeBufferGeometries} from 'three/examples/jsm/utils/BufferGeometryUtils';
import { renderTextureToImage } from "../Renderer2d/renderTypes/texture";
import { IAppSettings } from "@type/lr-types";

let xAxisColor = 0xff0201; // Red
let yAxisColor = 0x00ff03; // Green
let zAxisColor = 0x0700ff; // Blue
let windColor = 0XFF6347; // Blue


let supportColor = 0x0700ff; // Blue


export const DEFAULT_INSTANCE_COUNT = 0

// Magnet Meshes
let magnet_material          = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    //magnet_material.transparent = true;
    //magnet_material.opacity = MAGNET_Transparency;
let magnet_material_RED      = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
    //magnet_material_RED.transparent = true;
    //magnet_material_RED.opacity = MAGNET_Transparency;
let magnet_material_BLUE     = new THREE.MeshBasicMaterial( { color: 0x0000ff } );
    //magnet_material_BLUE.transparent = true;
    //magnet_material_BLUE.opacity = MAGNET_Transparency;

let diameter = 10
let torusBuffer = new THREE.TorusGeometry( 75, diameter, 16, 5 , Math.PI);
torusBuffer.computeBoundingBox()
let torusMeshInstance = new LRInstanceMesh(new THREE.InstancedMesh(torusBuffer, magnet_material, DEFAULT_INSTANCE_COUNT), "MAGNET_TORUS")

let cylinerBuffer1 = new THREE.CylinderGeometry( diameter, diameter, 5 );
let cylinder1MeshInstance = new LRInstanceMesh(new THREE.InstancedMesh(cylinerBuffer1, magnet_material_RED, DEFAULT_INSTANCE_COUNT), "MAGNET_CYLINDER_1");
let cylinder2MeshInstance = new LRInstanceMesh(new THREE.InstancedMesh(cylinerBuffer1, magnet_material_BLUE, DEFAULT_INSTANCE_COUNT), "MAGNET_CYLINDER_2");


//let electricalBuffer        = new THREE.BoxGeometry(100, 100, 100);
let diameterLoad = 10
let heightLoad = 500
let cylinderloadBuffer= new THREE.CylinderGeometry( diameterLoad, diameterLoad, diameterLoad );
cylinderloadBuffer.computeBoundingBox()
let loadMesh = new LRInstanceMesh(new THREE.InstancedMesh(cylinderloadBuffer, new THREE.MeshPhongMaterial({color: 0xffffff}), DEFAULT_INSTANCE_COUNT), "LOAD_CYLINDER");

let diametercone = 8 * diameterLoad
let heightCone = 200
let coneLoadBuffer= new THREE.ConeGeometry( diametercone, heightCone );
coneLoadBuffer.computeBoundingBox()
let coneLoadMesh = new LRInstanceMesh(new THREE.InstancedMesh(coneLoadBuffer, new THREE.MeshPhongMaterial({color: 0xffffff}), DEFAULT_INSTANCE_COUNT), "LOAD_CONE");

let load_cubeBuffer  = new LeftBoxBufferGeometry(diameterLoad, 2*diameterLoad, 2*diameterLoad);
load_cubeBuffer.computeBoundingBox()
let load_material    = new THREE.MeshPhongMaterial( {color: 0xffffff} );
let load_node_geometry = new LRInstanceMesh(new THREE.InstancedMesh(load_cubeBuffer, load_material, DEFAULT_INSTANCE_COUNT), "LOAD_NODE");

// Point Load

let pointcylinderloadBuffer= new THREE.CylinderGeometry( diameterLoad, diameterLoad, heightLoad );
pointcylinderloadBuffer.computeBoundingBox()
let pointloadMesh = new LRInstanceMesh(new THREE.InstancedMesh(pointcylinderloadBuffer, new THREE.MeshPhongMaterial({color: 0xffffff}), DEFAULT_INSTANCE_COUNT), "LOAD_POINT");

let pointconeLoadBuffer= new THREE.ConeGeometry( diametercone, heightCone );
pointconeLoadBuffer.computeBoundingBox()
let pointconeLoadMesh = new LRInstanceMesh(new THREE.InstancedMesh(pointconeLoadBuffer, new THREE.MeshPhongMaterial({color: 0xffffff}), DEFAULT_INSTANCE_COUNT), "LOAD_POINT_CONE");

// Structures
let structureColor =  0xcc00cc
let beamColor = 0xffff00
let hingeColor = 0x000000
let diameterStructures = 1
let diameterLength = 1
let structureMeshBuffer = new THREE.BoxGeometry(diameterStructures, diameterStructures, diameterStructures);
structureMeshBuffer.computeBoundingBox()
let structureMesh = new LRInstanceMesh(new THREE.InstancedMesh(structureMeshBuffer, new THREE.MeshPhongMaterial( {color: structureColor}) , DEFAULT_INSTANCE_COUNT), "STRUCTURE");

let structureMeshBuffer2 = new THREE.BoxGeometry(diameterLength, diameterStructures, diameterStructures);
structureMeshBuffer2.computeBoundingBox()
let structureMesh2 = new LRInstanceMesh(new THREE.InstancedMesh(structureMeshBuffer2, new THREE.MeshPhongMaterial( {color: beamColor}) , DEFAULT_INSTANCE_COUNT), "STRUCTURE_BEAM");

let structureMeshBuffer3 = new THREE.BoxGeometry(diameterStructures * 1.5, diameterStructures * 1.5, diameterStructures * 1.5);
structureMeshBuffer3.computeBoundingBox()
let structureMesh3 = new LRInstanceMesh(new THREE.InstancedMesh(structureMeshBuffer3, new THREE.MeshPhongMaterial( {color: hingeColor}) , DEFAULT_INSTANCE_COUNT), "STRUCTURE_HINGE");

let structure_cubeBuffer= new THREE.ConeGeometry( diameterStructures, 3*diameterStructures );
structure_cubeBuffer.computeBoundingBox()
let structure_material    = new THREE.MeshPhongMaterial( {color: beamColor} );
let structure_node_geometry = new LRInstanceMesh(new THREE.InstancedMesh(structure_cubeBuffer, structure_material, DEFAULT_INSTANCE_COUNT), "STRUCTURE_DIR");

// Supports
let supportMeshBuffer = new THREE.BoxGeometry(300, 300, 300);
supportMeshBuffer.computeBoundingBox()
let supportMesh = new LRInstanceMesh(new THREE.InstancedMesh(supportMeshBuffer, new THREE.MeshPhongMaterial(), DEFAULT_INSTANCE_COUNT), "SUPPORT");

let ropeMeshBuffer= new THREE.CylinderGeometry( 16, 16, 1 );
ropeMeshBuffer.computeBoundingBox()
let ropeMesh = new LRInstanceMesh(new THREE.InstancedMesh(ropeMeshBuffer, new THREE.MeshPhongMaterial(), DEFAULT_INSTANCE_COUNT), "SUPPORT");


// Assembly Group
let assemblyMeshBuffer = new THREE.BoxGeometry(40, 40, 40);
assemblyMeshBuffer.computeBoundingBox()
let assemblyMesh = new LRInstanceMesh(new THREE.InstancedMesh(assemblyMeshBuffer, new THREE.MeshBasicMaterial(), DEFAULT_INSTANCE_COUNT), "ASSEMBLY_GROUP");

// Audio Geometry
let audioGeometryMeshBuffer = new THREE.BoxGeometry(40, 40, 40);
audioGeometryMeshBuffer.computeBoundingBox()
let audioGeometryMesh = new LRInstanceMesh(new THREE.InstancedMesh(audioGeometryMeshBuffer, new THREE.MeshBasicMaterial(), DEFAULT_INSTANCE_COUNT), "AUDIO");

// Influence Workload
let influenceWorkloadMeshBuffer = new THREE.BoxGeometry(1, 1, 1);
influenceWorkloadMeshBuffer.computeBoundingBox()
let influenceWorkloadMesh = new LRInstanceMesh(new THREE.InstancedMesh(influenceWorkloadMeshBuffer, new THREE.MeshPhongMaterial({
  shininess: 100,
  specular: 0xffffff
}), DEFAULT_INSTANCE_COUNT), "WORKLOAD");

// FEM
let fem_cubeBuffer  = new THREE.BoxGeometry(12, 12, 12);
fem_cubeBuffer.computeBoundingBox()
let fem_material    = new THREE.MeshBasicMaterial( {color: 0x000000} );
let fem_node_geometry = new LRInstanceMesh(new THREE.InstancedMesh(fem_cubeBuffer, fem_material, DEFAULT_INSTANCE_COUNT), "FEM_NODE");

let fem_frame_Buffer2 = new THREE.BoxGeometry(diameterLength, diameterStructures, diameterStructures);
fem_frame_Buffer2.computeBoundingBox()
let fem_frame_2 = new LRInstanceMesh(new THREE.InstancedMesh(fem_frame_Buffer2, new THREE.MeshPhongMaterial( {color: beamColor}) , DEFAULT_INSTANCE_COUNT), "FEM_FRAME");
    

// VolumetrixSpotLight
let volumetricSpotLightBuffer = new THREE.CylinderGeometry(0, SPOT_LIGHT_RADIUS, SPOT_LIGHT_HEIGHT, 32*2, 20, true).applyMatrix4(new THREE.Matrix4().makeTranslation(0, -SPOT_LIGHT_HEIGHT/2, 0)).applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
let volumetrixSpotLightMesh = new LRInstanceMesh(new THREE.InstancedMesh(volumetricSpotLightBuffer, new THREE.MeshBasicMaterial({transparent: true, opacity: 0.8}), DEFAULT_INSTANCE_COUNT), "VOLUMETRIC_SPOTLIGHT");


// Polygon line
let polygonLineBuffer = new THREE.BoxGeometry(1, 10, 10);
polygonLineBuffer.computeBoundingBox()
let polygonInstanceMesh = new THREE.InstancedMesh(polygonLineBuffer, new THREE.MeshPhongMaterial({color: 0xffffff}), DEFAULT_INSTANCE_COUNT);
let polygonLineMesh = new LRInstanceMesh(polygonInstanceMesh, "POLYGON_LINE");


let polygonGeometryInstanceMeshes = new Map()

//check if value is primitive
function isPrimitive(obj)
{
  return (obj !== Object(obj));
}

//deep equality test for objects
export function deepEqual(obj1, obj2) {

  if(obj1 === obj2) // it's just the same object. No need to compare.
      return true;

  if(isPrimitive(obj1) || isPrimitive(obj2)) // compare primitives
      return obj1 === obj2;

  if(Object.keys(obj1).length !== Object.keys(obj2).length)
      return false;

  // compare objects with same number of keys
  for(let key in obj1)
  {
    if(!(key in obj2)) return false; //other object doesn't have this prop
    if(!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export let Equalish = (a,b, delta = 0.1) =>
{
  return (Math.abs(a-b) <= delta)
}

export let UpdateMatrix = (object) =>
{
  if (!object) { return;}
  object.updateMatrix()
  object.updateMatrixWorld(true)
  object.traverse(child=>
  {
    child.updateMatrix()
  })

}

export let InitScene = (inScene, inCamera, inRenderer, inLabelRenderer, width, height, useColor = undefined) =>
{
    if(inScene.LR_INIT)
    {
      return
    }
    inScene.LR_INIT = true
    //--------------------------------------------------------------------------------
    // Geometry
    inScene.background = useColor ? new THREE.Color().setHex( IsDarkTheme() ? 0x242424 : 0xAAAAAA) : null;

    let xAxis = new THREE.Vector3(1, 0, 0);
    let yAxis = new THREE.Vector3(0, 1, 0);
    let zAxis = new THREE.Vector3(0, 0, 1);
    let origin = new THREE.Vector3(0, 0, 0.1);

    

    let length = 1000;


    let xAxisArrow = new THREE.ArrowHelper(xAxis, origin, length, xAxisColor);
    xAxisArrow.updateMatrix()
    inScene.add(xAxisArrow);

    let yAxisArrow = new THREE.ArrowHelper(yAxis, origin, length, yAxisColor);
    yAxisArrow.updateMatrix()
    inScene.add(yAxisArrow);

    let zAxisArrow = new THREE.ArrowHelper(zAxis, origin, length, zAxisColor);
    zAxisArrow.updateMatrix()
    inScene.add(zAxisArrow); 

    let planeDistance = 100000

    let planeMaterial = new THREE.MeshPhongMaterial( { color: 0x808080, dithering: true, side: THREE.DoubleSide} );
    let planeGeometry = new THREE.PlaneGeometry(planeDistance, planeDistance);
    let plane = new THREE.Mesh( planeGeometry, planeMaterial );
    plane.name = "MAIN_PLANE";
    plane.visible = false
    plane.position.set(0, 0, 0);
    plane.receiveShadow = true;
    plane.updateMatrix()
    inScene.add( plane );

    let bigPlaneGeo  = new THREE.PlaneGeometry(1000000, 1000000);
    let bigPlane = new THREE.Mesh(bigPlaneGeo, undefined);
    bigPlane.name = "BIG_PLANE";
    bigPlane.visible = false; //This should not be changed. We only want this plane for mouse tracing
    bigPlane.updateMatrix()
    inScene.add(bigPlane)

    window.LR_GetGlobalSettings().then(({GlobalSettings})=>{
      let distM =  GlobalSettings.GridSize_M || 1000
      let dist10M = GlobalSettings.GridSize_10M || 10000


      let grid  = new InfiniteGridHelper(distM, dist10M)
      grid.rotateX(Math.PI/2)
      grid.name = "MAIN_GRIDHELPER_M"
      grid.material.uniforms.uDistance.value = planeDistance * 10

      grid.updateMatrix()
      inScene.add(grid)
    })

   



    // Add all instanced meshes to the scene
    torusMeshInstance.SetMeshScene(inScene);
    cylinder1MeshInstance.SetMeshScene(inScene);
    cylinder2MeshInstance.SetMeshScene(inScene);
    structureMesh.SetMeshScene(inScene)
    loadMesh.SetMeshScene(inScene)
    fem_node_geometry.SetMeshScene(inScene)
    assemblyMesh.SetMeshScene(inScene)
    supportMesh.SetMeshScene(inScene)
    ropeMesh.SetMeshScene(inScene)
    audioGeometryMesh.SetMeshScene(inScene)
    coneLoadMesh.SetMeshScene(inScene)
    load_node_geometry.SetMeshScene(inScene)
    structureMesh2.SetMeshScene(inScene)
    structureMesh3.SetMeshScene(inScene)
    fem_frame_2.SetMeshScene(inScene)
    pointconeLoadMesh.SetMeshScene(inScene)
    pointloadMesh.SetMeshScene(inScene)
    structure_node_geometry.SetMeshScene(inScene)
    influenceWorkloadMesh.SetMeshScene(inScene)
    volumetrixSpotLightMesh.SetMeshScene(inScene)
    polygonLineMesh.SetMeshScene(inScene)

    // Add all polygon instances to the scene
    // They disappear when switching from 2D back to 3D
    polygonGeometryInstanceMeshes.forEach(value => value.SetMeshScene(inScene))




    //--------------------------------------------------------------------------------
    // Light
    let d = 50;
    let dirLight = new THREE.DirectionalLight(0xffffff, 1);
    dirLight.color.setHSL(0.1, 1, 0.95);
    dirLight.position.set(-1000, 1750, 1000);
    dirLight.position.multiplyScalar(30);
    dirLight.updateMatrix()
    inScene.add(dirLight);
    dirLight.castShadow = false;
    dirLight.shadow.mapSize.width = 2048;
    dirLight.shadow.mapSize.height = 2048;
    
    dirLight.shadow.camera.left = -d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = -d;
    dirLight.shadow.camera.far = 3500;
    dirLight.shadow.bias = -0.0001;

    let dirLight2 = new THREE.DirectionalLight(0xffffff, 1);
    dirLight2.color.setHSL(0.1, 1, 0.95);
    dirLight2.position.set(1000, -1750, 1000);
    dirLight2.position.multiplyScalar(30);
    dirLight2.updateMatrix()
    inScene.add(dirLight2);
    dirLight2.castShadow = false;
    dirLight2.shadow.mapSize.width = 2048;
    dirLight2.shadow.mapSize.height = 2048;
    dirLight2.shadow.camera.left = -d;
    dirLight2.shadow.camera.right = d;
    dirLight2.shadow.camera.top = d;
    dirLight2.shadow.camera.bottom = -d;
    dirLight2.shadow.camera.far = 3500;
    dirLight2.shadow.bias = -0.0001;

    let camPos = new THREE.Vector3(4500, -2000, 3000)

    inCamera.name = 'Camera';
    inCamera.position.set(camPos.x * 0.7, camPos.y * 0.7, camPos.z * 0.7);
    inCamera.up.set(0, 0, 1);
    inCamera.lookAt(inScene.position);

    inCamera.aspect = width / (height);
    inCamera.updateProjectionMatrix();


    //--------------------------------------------------------------------------------
    // Renderer

     // inRenderer.setClearColor(0xffffff, 0);
     inRenderer.setPixelRatio(window.devicePixelRatio);
     inRenderer.gammaInput = true;
     inRenderer.gammaOutput = true;
     inRenderer.antialias = true;
     inRenderer.setSize(width, height);
 
     inRenderer.shadowMap.enabled = true;
     inRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
 
    if(inLabelRenderer)
    {
      inLabelRenderer.domElement.style.position = 'absolute';
      inLabelRenderer.domElement.style.top = 0;
      inLabelRenderer.setSize(width, height);
    }

 
};

export async function AsyncForEach (array, callback)
{
  for (let index = 0; index < array.length; index++) { await callback(array[index], index, array); }
}

export async function RefeshDefaultInstance (inScene)
{
  torusMeshInstance.SetMeshScene(inScene);
  cylinder1MeshInstance.SetMeshScene(inScene);
  cylinder2MeshInstance.SetMeshScene(inScene);
  structureMesh.SetMeshScene(inScene)
  loadMesh.SetMeshScene(inScene)
  fem_node_geometry.SetMeshScene(inScene)
  assemblyMesh.SetMeshScene(inScene)
  supportMesh.SetMeshScene(inScene)
  ropeMesh.SetMeshScene(inScene)
  audioGeometryMesh.SetMeshScene(inScene)
  coneLoadMesh.SetMeshScene(inScene)
  load_node_geometry.SetMeshScene(inScene)
  structureMesh2.SetMeshScene(inScene)
  structureMesh3.SetMeshScene(inScene)
  fem_frame_2.SetMeshScene(inScene)
  pointconeLoadMesh.SetMeshScene(inScene)
  pointloadMesh.SetMeshScene(inScene)
  structure_node_geometry.SetMeshScene(inScene)
  influenceWorkloadMesh.SetMeshScene(inScene)
  volumetrixSpotLightMesh.SetMeshScene(inScene)
  polygonLineMesh.SetMeshScene(inScene)
}

export async function LoadTextures(textures, textureContainer, uuidLoadContainer) {
  if (!textures || !textures.length) {
    return;
  }

  let aw = textures.map(async tex => {
    let img = await renderTextureToImage(tex)
    let thr_tex = new THREE.Texture(img);

    thr_tex.mapping = THREE.LinearToneMapping;
    thr_tex.format = THREE.RGBAFormat;
    thr_tex.wrapS = THREE.RepeatWrapping;
    thr_tex.wrapT = THREE.RepeatWrapping;

    thr_tex.magFilter = THREE.LinearFilter;
    thr_tex.minFilter = THREE.LinearFilter;

    // no clue why this is indexed by name
    textureContainer.load(tex.Name, { Texture: thr_tex });
    if(uuidLoadContainer){
      uuidLoadContainer.load(tex.UUID, {Texture: thr_tex, width: img.width, height: img.height});
    }

    thr_tex.needsUpdate = true;
  })

  return await Promise.all(aw)
}

export function LoadMeshes (meshes, inMeshContainer, textureContainer, scene)
{
  let prom = new Promise((resolve, reject) => 
  {
    if(!meshes)              { resolve(); }
    if(meshes.length === 0)  { resolve(); }


    try
    {
      let importedMeshes = 0;

      meshes.forEach( async (meshObject) =>
      {
        if (!inMeshContainer.has(meshObject.UUID))
        {
          let lrInstanceMesh = await createThreeJsMesh(meshObject)
          lrInstanceMesh.SetMeshScene(scene);
          inMeshContainer.load(meshObject.UUID, lrInstanceMesh)
 
        }
        importedMeshes++; 
        if(importedMeshes === meshes.length)  { resolve();  } 
      })
    } 
    catch(e)
    {
      console.error(e)
      resolve();
    }
  });

  return prom;
}

export async function LoadResourceContainer (meshes, resourceContainer)
{
  if(!meshes)              { return }
  if(meshes.length === 0)  { return }

  try
  {
    for(let meshObject of meshes)
    {
      if (!resourceContainer.has(meshObject.UUID))
      {
        let tree = window.LR_GetGeometryTree({Async: true, ObjectUUID: meshObject.UUID}).then( tree => 
        {
          resourceContainer.load(meshObject.UUID, tree)
        }
        )

      }
    }
  } 
  catch(e)
  {
    console.error(e)
  }
}

let  createThreeJsMesh = async (meshObject) =>
{
  let meshFromLocalStorage
  try
  {
    
    meshFromLocalStorage = JSON.parse(await get(lrServerConnection.__USERNAME+lrServerConnection.__PROJECT+meshObject.UUID))
  } 
  catch(_)
  {
    meshFromLocalStorage = undefined
  }

  if( ! meshFromLocalStorage)
  {
    meshObject = await window.LR_GetMesh({UUID: meshObject.UUID})
    let storageFunction = async () =>  
    { 
      try
      {
        set(lrServerConnection.__USERNAME+lrServerConnection.__PROJECT+meshObject.UUID, JSON.stringify(meshObject)) 
      }
      catch (error) 
      {
        console.error(error)
      }
      
    }
    storageFunction();


    
    
  }
  else
  {
    meshObject = meshFromLocalStorage
  }
    
  
  if (!meshObject.VertexData.Positions) { return; }


  let positionAttribute = new THREE.Float32BufferAttribute(meshObject.VertexData.Positions, 3)
  let uvAttributes = new THREE.Float32BufferAttribute(meshObject.VertexData.TexCoords, 2, true)
  //let indexBuffer = new Uint32Array(meshObject.VertexData.Indices)

  let meshGeo = new THREE.BufferGeometry();

  meshGeo.setIndex(meshObject.VertexData.Indices);
  meshGeo.setAttribute('position', positionAttribute);
  meshGeo.setAttribute('uv', uvAttributes);
  //meshGeo.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(new Float32Array(meshObject.VertexData.Positions.length).fill(1), 3));
 
  meshGeo.computeVertexNormals();
  meshGeo.BoundingMin = meshObject.BoundingMin
  meshGeo.BoundingMax = meshObject.BoundingMax
  meshGeo.computeBoundingBox()
  
  let material = new THREE.MeshPhongMaterial();
  material.side = THREE.DoubleSide

  let instancedMesh = new THREE.InstancedMesh(meshGeo, material, DEFAULT_INSTANCE_COUNT)

  if (meshObject.Material)
  {
    GetTexture(meshObject.Material).then(tex => {
      
      instancedMesh.material.map = tex.Texture.clone();
      instancedMesh.material.needsUpdate = true;
    })
  }
  //instancedMesh.count = 10
  instancedMesh.setColorAt(0, new THREE.Color(1,1,1))

  return new LRInstanceMesh(instancedMesh, meshObject.Name)
}

export async function LoadObjectsReq  (objectTree, index, container, options)
{
    for(let current_object_pos = 0; current_object_pos < objectTree.length; current_object_pos++)
    {    
      let current       = objectTree[current_object_pos];

      let parentObject = options.ThreeJSObjects.get(current.ParentUUID);
      if(!parentObject)
      {
        parentObject = container
      }
      //Test if it already has been imported (for the DiffRenderer)
      let currentObject = options.ThreeJSObjects.get(current.UUID);
      if(! currentObject) { currentObject = await RenderObject(current, parentObject, options); }
      if(! currentObject) { return; }

      // Set the uniforms for the spotLight beams correctly
      currentObject.updateMatrixWorld(true, true);
      currentObject.traverse(child => 
      { 
        if (child instanceof VolumetricSpotLight) 
        {
          child.updateBeamPosition();
          child.setLight(options.ShowLights); 
          child.setBeam(options.ShowBeams);
          child.setHelper(options.ShowLightHelpers); 
        }     
      })
      
      options.meshInstancesNeedUpdate = true
  }

}


export async function RenderObject(current, container, options, placeAtOrigin = false, dontAddToMap = false)
{
  //--------------------------------------------------------------------------------------------------------------------------
  // When there is no object, dont do anything
  if(!current) { return; }

  //--------------------------------------------------------------------------------------------------------------------------
  // Sometimes you don't want to render to
  let object       = current.EXISTING_OBJECT ? current.EXISTING_OBJECT : new THREE.Object3D();
  if(! current.EXISTING_OBJECT)
  {
    container.add(object);
  }
  
  //--------------------------------------------------------------------------------------------------------------------------
  // Store the object in the amp
  if (dontAddToMap === false)
  {
    if (options.addGeometriesToMap === false)
    {
      options.ThreeJSObjects.set(current.UUID, object);
    }
  }
  
  object.LROBJECT = current

  if(current.ResourceType === RESOURCE_TYPE_TextObject)
  {
        let text = new TextGeometry(current.Name,
        {
          font: options.DefaultFont,
          size: 600,
          height: 5,
          curveSegments: 1,
        });
  
        text.computeBoundingBox();
        text.center();
  
        let textMesh = new THREE.Mesh( text, new THREE.MeshBasicMaterial( {} )); 
        object.add(textMesh);
  }


  //--------------------------------------------------------------------------------------------------------------------------
  // Render Geometry
  else if(current.LinkedFixtureType)
  {
    options.FixtureTypeLoadContainer.get(current.LinkedFixtureType,(tree)=>
    {
      LoadGeometryReq(tree, {}, object, options, current, dontAddToMap)
    })
  }
  else
  {
    let geometryTree = current.Geometries !== undefined ? current.Geometries : await window.LR_GetGeometryTree({Async: true, ObjectUUID: current.UUID})
    LoadGeometryReq(geometryTree, {value: 0}, object, options, current, dontAddToMap);
  }


    
  
  //--------------------------------------------------------------------------------------------------------------------------
  // Set Data
  if(current.OffsetX !== undefined)
  {
    if( ! placeAtOrigin)
    {
      object.position.x = current.OffsetX;
      object.position.y = current.OffsetY;
      object.position.z = current.OffsetZ;      
    }
    object.scale.x    = current.ScaleX;
    object.scale.y    = current.ScaleY;
    object.scale.z    = current.ScaleZ;
    object.rotation.x = current.RotationX;
    object.rotation.y = current.RotationY;
    object.rotation.z = current.RotationZ;
  }

  object.lrUUID           = current.UUID;
  object.name             = current.Name;
  object.HasStructures    = current.HasStructures;
  object.objectType       = current.ObjectType;
  object.resourceType     = current.ResourceType
  object.UUID             = current.UUID;
  object.DefiningResource = current.DefiningResource
  object.lockScale        = current.ScaleIsConst ? true : false


  //Maybe we'll find an other way to do the selection
  object.selected   = current.Selected;

  object.layerLocked  = current.LayerObject?.Locked ?? false;

  current.Visible = current.Visible ?? true
  object.visible = current.Visible

  object.LRLayer = current.Layer;
  object.LRClass = current.Class;

  //Add Label
  let label = CreateLabel(current, options)
  if (label)
  {
    object.add(label);
  }

  //Add spotlight for fixtures
  if(current.ObjectType === OBJECT_TYPE_Fixture)
  {
    object.fixture = true;
    object.linkedFixtureType = current.LinkedFixtureType;

    AttachSpotLightsToObject(object, options);
  }
  else if (current.ResourceType === RESOURCE_TYPE_AssemblyGroup)
  {
    let assemblyGroupMesh = new THREE.Object3D()
    assemblyGroupMesh.MeshInstance = assemblyMesh;
    assemblyGroupMesh.OriginalColor = new THREE.Color(0x02ef21)
    assemblyGroupMesh.CurrentColor = new THREE.Color(0x02ef21)
    assemblyMesh.IncreaseCount()
    object.add(assemblyGroupMesh)
  }
  
  
  

  UpdateMatrix(object)

  //--------------------------------------------------------------------------------------------------------------------------
  // Electrical Connection
  if(current.ElectricalConnections)
  {
    for(let  connection of current.ElectricalConnections)
    {
        createPolygonLinesGeometry(object, 
        {
          FillColor: {},
          LineColor: {X: 0.19491, Y: 0.16726, Z: 9.796},
          ObjectPath: connection.ConnectionPath.map(e=>
          {
            let pt = new THREE.Vector3(e.Point.X,e.Point.Y,e.Point.Z)

            let m = object.matrixWorld.clone()
            m.setPosition(0,0,0)
            m.transpose()
            pt.applyMatrix4(m)

            return {
              ...e,
              Point: {X: pt.x, Y: pt.y, Z: pt.z, }
            }
          }),
          ClosedLine: false,
          ExtrusionHeight: 0,
          LineWidth: 10
        },
        options)
      }

  }

  options.meshInstancesNeedUpdate = true;
  return object;
}


export function AttachSpotLightsToObject (object, options)
{
  object.traverse(geometry =>
  {
    if(geometry.HasBeam)
    {
      /*let spotLightContainer = new THREE.Object3D();
      spotLightContainer.scale.divide(geometry.scale);
      spotLightContainer.name = "spotLight Container";
      spotLightContainer.MeshInstance = volumetrixSpotLightMesh
      volumetrixSpotLightMesh.IncreaseCount()
      spotLightContainer.OriginalColor = new THREE.Color(0xffffff)
      spotLightContainer.CurrentColor = new THREE.Color(0xffffff)
      geometry.add(spotLightContainer);*/

      let spotLight = new VolumetricSpotLight(volumetrixSpotLightMesh, new THREE.Color(0xffffff), 1, 50000, Math.PI / 32, 0.05, 2);
      spotLight.scale.divide(geometry.scale);
      geometry.add(spotLight);
      /*
      spotLightContainer.add(spotLight);
      geometry.add(spotLightContainer);
      options.Scene.add(spotLight.spotLightHelper);*/

      spotLight.setLight(options.ShowLights);
      spotLight.setBeam(options.ShowBeams);
      spotLight.setHelper(options.ShowLightHelpers);
      spotLight.setAngle(0);
    }
  });
}

export function LoadGeometryReq (geometryTree, index, container, options, lr_object, dontAddToMap=false)
{
  let map = new Map()
  for(let i_geo = 0; i_geo < geometryTree.length; i_geo++)
  {
    let current = geometryTree[i_geo];
    let parent = undefined
    if(current.ParentUUID) { parent = map[current.ParentUUID] }
    
    let geo = RenderGeometry(current, parent ? parent : container, options, lr_object, dontAddToMap);
    
    if(geo) { map[current.UUID] = geo }
    
   }

}

export function RenderGeometry (current, container, options, lr_object, dontAddToMap=false)
{
  let geometry = new THREE.Object3D();
  if (!dontAddToMap)
  {
    if (options.addGeometriesToMap === true)
    {
      options.ThreeJSObjects.set(current.UUID, geometry);
    }
  }

  if(current.LinkedSymbolDef !== EMPTY_UUID)
  {
    if(!options.SymbolLoadContainer){
      console.warn("tried to render geometry with a LinkedSymbolDef, without defining a SymbolLoadContainer. Trying to retrieve it manually")
      window.LR_GetGeometryTree({Async: true, ObjectUUID: current.LinkedSymbolDef}).then(tree => {
        if(tree){
          LoadGeometryReq(tree, {}, geometry, options, lr_object, dontAddToMap)
        }
      })
    }else{
      options.SymbolLoadContainer.get(current.LinkedSymbolDef,(tree)=>
      {
        LoadGeometryReq(tree, {}, geometry, options, lr_object, dontAddToMap)
      })
    }

  }


  geometry.visible = !lr_object?.VisibilityProperties?.[current.UUID]


  if(current.MagnetInfo?.IsMagnet)
  {
    // Magnet
    let magnetgeometry = new THREE.Object3D();
    
        
    let torus     = new THREE.Object3D();
    let cylinder1 = new THREE.Object3D();
    let cylinder2 = new THREE.Object3D();
    
    torus.MeshInstance = torusMeshInstance
    torus.OriginalColor = new THREE.Color(0xffffffff)
    cylinder1.MeshInstance = cylinder1MeshInstance
    cylinder2.MeshInstance = cylinder2MeshInstance
    torusMeshInstance.IncreaseCount()
    cylinder1MeshInstance.IncreaseCount()
    cylinder2MeshInstance.IncreaseCount()

    cylinder1.translateX(+75);
    cylinder2.translateX(-75);


    magnetgeometry.add( torus );
    magnetgeometry.add( cylinder1 );
    magnetgeometry.add( cylinder2 );

    
    magnetgeometry.rotateZ(Math.PI / 2)

    // Test U
    magnetgeometry.name = "Magnet"
    magnetgeometry.visible = options.ShowMagnets
    

    //------------------------------------------------------------

    /*let magnetContainer = container.getObjectByName("Magnets");
    if(!magnetContainer)
    {
      magnetContainer = new THREE.Group();
      magnetContainer.name = "Magnets";
      container.add(magnetContainer);
    }*/
    
    //magnetContainer.add(geometry);
    geometry.add(magnetgeometry)
  } 
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Electrical)
  {
    /*
    let electricalGeometry = new THREE.Object3D();
    let mat = new THREE.MeshPhongMaterial({ color: 0xffffff});
    let matBlue = new THREE.MeshPhongMaterial({ color: 0x0000ff});

    
    let mesh = new THREE.Mesh(electricalBuffer, mat);


    let insideMesh = new THREE.Mesh(insideElectricalBuffer, matBlue)
    insideMesh.position.set(0, -10, 0);

    electricalGeometry.add(mesh);
    electricalGeometry.add(insideMesh)

    geometry.add(electricalGeometry);

      */
  }
  else
  {
    options.meshLoadContainer.get(current.MeshUuid, (mesh) => {
      //mesh.setMatrixAt(index, new THREE.Matrix4())
      geometry.MeshInstance = mesh
      geometry.CurrentColor = (lr_object && lr_object.Selected) ? new THREE.Color(SELECT_COLOR) : new THREE.Color(1,1,1)
      mesh.IncreaseCount();
      geometry.MeshUUID = current.MeshUuid
    })
    
  }

  if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Structure) 
  {
    createStructureGeometry(geometry, current, lr_object, options)
    geometry.LinkedStructure = current.LinkedStructure
  }
  if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Load) 
  {
    createLoadGeometry(geometry, current, lr_object, options)
  }
  if (current.ObjectType === GEOMETRY_OBJECT_TYPE_CableDock) 
  {
    createOriginGeometry(geometry, current, lr_object, options)
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Support) 
  {
    if (lr_object && Array.isArray(lr_object.SupportProperties))
    {
      let foundSupportProperties = lr_object.SupportProperties.find(elem => elem.UUID === current.UUID)
      if (foundSupportProperties)
      {
        createSupportGeometry(geometry, foundSupportProperties, lr_object, options);
      }
    }
    else
    {
      createSupportGeometry(geometry, current, lr_object, options)
    }
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Audio) 
  {
    let audioGeometry = new THREE.Object3D();

    audioGeometry.MeshInstance = assemblyMesh;
    audioGeometry.OriginalColor = new THREE.Color(0xffffffff)
    assemblyMesh.IncreaseCount()


    geometry.add(audioGeometry);
    
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Dimension) 
  {
    createDimensionGeometry(geometry, options, current.To, current.DimensionHeight);
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_InfluenceLine) 
  {
    createInfluenceLineGeometry(geometry, options, current, lr_object);
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_FemGeometry) 
  {
    create_FEM_Geometry(geometry, current, options);
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Origin) 
  {
    createOriginGeometry(geometry);
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Curtain) 
  {
    createCurtainGeometry(geometry, current, lr_object);
  }
  else if (current.ObjectType === GEOMETRY_OBJECT_TYPE_Polygon && 
    !(lr_object && lr_object.HasMesh === true && current.PolygonTexture === EMPTY_UUID))
  {
    createPolygonGeometry(geometry, current, options)
  }else if(current.TextureUuid && geometry.TextureUuid !== EMPTY_UUID){
    createTextureGeometry(current).then(i => {
      geometry.add(i)
    })
  }
  
  geometry.name       = current.Name; 
  geometry.ObjectType = current.ObjectType;
  geometry.resourceType = current.ResourceType





  geometry.position.x = current.OffsetX;
  geometry.position.y = current.OffsetY;
  geometry.position.z = current.OffsetZ;
  geometry.scale.x    = current.ScaleX;
  geometry.scale.y    = current.ScaleY;
  geometry.scale.z    = current.ScaleZ;

  if (current.ObjectType !== GEOMETRY_OBJECT_TYPE_Structure) // Structure geometries should not have any rotation. They just represent a position
  {
    geometry.rotation.x = current.RotationX;
    geometry.rotation.y = current.RotationY;
    geometry.rotation.z = current.RotationZ;
  }

  //---------------------------------
  // Fixture Rotation
  if(lr_object && lr_object.GeometryTransformPropertyList)
  {
    let transformZ = lr_object.GeometryTransformPropertyList[current.UUID+"RZ"]
  
    if(transformZ) { geometry.rotateZ(transformZ.Value) }
  
    let transformY = lr_object.GeometryTransformPropertyList[current.UUID+"RY"]
    if(transformY) { geometry.rotateY(transformY.Value) }
  
    let transformX = lr_object.GeometryTransformPropertyList[current.UUID+"RX"]
    if(transformX) { geometry.rotateX(transformX.Value) }
  
    let translateZ = lr_object.GeometryTransformPropertyList[current.UUID+"TZ"]
    if(translateZ) { geometry.translateZ(translateZ.Value) }
  
    let translateY = lr_object.GeometryTransformPropertyList[current.UUID+"TY"]
    if(translateY) { geometry.translateY(translateY.Value) }
  
    let translateX = lr_object.GeometryTransformPropertyList[current.UUID+"TX"]
    if(translateX) { geometry.translateY(translateX.Value) }
  
  }


  geometry.HasBeam    = current.HasBeam;
  geometry.IsMagnet   = current.MagnetInfo? current.MagnetInfo.IsMagnet : false
  geometry.lockScale  = current.ScaleIsConst ? true : false

  container.add(geometry);

  UpdateMatrix(geometry)


  geometry.lrGeometryUUID = current.UUID;
  geometry.UUID = current.UUID;

  return geometry; 
}

export function CreateLabel (lrobj, options, properties)
{

  if (!lrobj.ShowLabel3D) { return undefined; }
  if (lrobj.Label3D === EMPTY_UUID) { return undefined; }
  let HTMLLabel                   = document.createElement('div');
  HTMLLabel.id                    = lrobj.UUID + "ELEMENT" + lrobj.Name;
  HTMLLabel.className             = 'label';
  HTMLLabel.style.display         = "table"    
  HTMLLabel.style.backgroundColor = 'rgba(127, 127, 127, 0.4)';
  HTMLLabel.style.color           = 'rgba(255, 255, 255, 1)';
  HTMLLabel.style.fontSize        = '10px';
  HTMLLabel.style.transformOrigin = 'top left';
  HTMLLabel.style.pointerEvents   = 'none';

  if (Array.isArray(lrobj.Label3DFields))
  {
    for (let property of lrobj.Label3DFields)
    {
      if (property.PrintLabelFieldType !== PRINT_LABEL_FIELD_TYPE.DynamicText 
        && property.PrintLabelFieldType !== PRINT_LABEL_FIELD_TYPE.StaticText 
        && property.PrintLabelFieldType !== PRINT_LABEL_FIELD_TYPE.SymbolGeometry)
      {
        continue
      }

      HTMLLabel.appendChild(CreateLabelInput(lrobj, property))
    }
  }
  

  let label = new CSS2DObject(HTMLLabel);
  label.position.set(lrobj.LabelOffsetX, lrobj.LabelOffsetY, 100);
  //label.visible = options.ShowLabels;
  return label;
}

function getInputType(property)
{
  if (!property.IsUnitBased) { return "text" }
  switch (property.BaseUnit)
  {
    case BASE_UNIT_BOOLEAN: return "bool"
    case BASE_UNIT_AMPERE:
    case BASE_UNIT_ANGLE:
    case BASE_UNIT_NUMBER:
    case BASE_UNIT_WEIGHT:
    case BASE_UNIT_VOLUME:
    case BASE_UNIT_AREA:
    case BASE_UNIT_ONE_BASED:
    case BASE_UNIT_VOLTAGE:
    case BASE_UNIT_POWER:
    case BASE_UNIT_FORCE:
    case BASE_UNIT_PERCENT:
    case BASE_UNIT_LENGTH: return "number"
    default: return "text"
  }
}

function CreateSingleValueLabel(lrobj, value, property)
{
  let valueType = getInputType(property)
  let propertyName = property.PropertyName

  let div                   = document.createElement('div');
  div.style.display         = "table-row";
  
  let inputLabel            = document.createElement('label');
  if(property.IncludePropertyName)
  {
    inputLabel.textContent    = propertyName + ": ";
    inputLabel.style.display  = "table-cell";
    div.appendChild(inputLabel);
  }

  let pre = property.PrefixText
  let post = property.PostfixText

  if (typeof value === "object")
  {
    value = value[propertyName]
  }

  if(!isNaN(value))
  {
    value = Number(value)
    value = Math.round(value * 1000) / 1000 
  }

  value = pre + value + post

  let input                 = document.createElement('input');
  input.type                = valueType;
  input.value               = value;
  input.style.color         = 'rgba(255, 255, 255, 1)';
  input.style.display       = "table-cell";
  input.onclick             = function(e) {console.log("onclick");e.stopPropagation(); e.preventDefault(); e.srcElement.focus()};
  input.oninput             = function(e) {console.log("oninput");input.value = e.srcElement.value;};
  input.onchange            = function(e) {console.log("onchange");e.stopPropagation(); e.preventDefault(); window.LR_SetObject({UUID: lrobj.UUID, [propertyName]: e.srcElement.value}); e.srcElement.blur()};

  
  div.appendChild(input);
  div.style.color           = 'rgba(255, 255, 255, 1)';
  div.id                    = lrobj.UUID+"."+propertyName;
  div.className             = "ui transparent input";
  

  return div;
}

export function CreateLabelInput (lrobj, property) 
{
  let value = property.Value

  if (Array.isArray(value))
  {
    let HTMLLabel                   = document.createElement('div');
    
    for(let v of value){
      if(v){
        HTMLLabel.appendChild(CreateSingleValueLabel(lrobj, v, property))
      }
    }
    return HTMLLabel
  }
  else
  {
    return CreateSingleValueLabel(lrobj, value, property)
  }
}

export function FitCameraToSelectedObjects(scene, camera, orbitControls, isInEditMode)
{
  let selectedObjects = [];

  //Fit bounding box to the selected Objects
  scene.traverse(object =>
  {
    
    if( object.selected && object.visible ) 
    { 
      if(isInEditMode === false || isInEditMode === 0)
      {
        if(object.lrGeometryUUID === undefined)
        {
          selectedObjects.push(object); 
        }
      }
      else
      {
        selectedObjects.push(object); 
      }
    }
  });

  //If there are no selected Objects, fit bounding box to all the LR objects in the scene
  if(selectedObjects.length === 0)
  {
    scene.traverse(object =>
    {
      if(object.lrGeometryUUID && object.visible) { selectedObjects.push(object); }
    });
  }

  //We temporarily remove the spotlight to do the calculations
  //otherwise the beams are taken into account.
  let quarantineZone = [];
  selectedObjects.forEach(object =>
  {
    object.traverse(child =>
    {
      if(child instanceof VolumetricSpotLight)
      {
        quarantineZone.push({Parent: child.parent, SpotLight: child});
        
      }
    });
  });

  quarantineZone.forEach(object => {
    object.Parent.remove(object.SpotLight);
  })

  const boundingBox = new THREE.Box3();

  //If there aren't any LR objects in the scene, don't change anything

  if(selectedObjects.length === 0)
  {
    return;
  }
  else
  {
    for(const object of selectedObjects)
    {
      if (object.MeshInstance)
      {
        HandleInstanceMeshForBoudingBox(boundingBox, object)
      }
      else
      {
        object.traverse(c2 => { if (c2.MeshInstance) 
        { 
          HandleInstanceMeshForBoudingBox(boundingBox, c2)
        } }) 

        boundingBox.expandByObject(object);
      }
    }
  }

  FitCameraToBoundingBox(boundingBox, camera, orbitControls)

  //We put the spotLights back
  quarantineZone.forEach(element =>
  {
    element.Parent.add(element.SpotLight);
  });
}
export function HandleInstanceMeshForBoudingBox(boundingBox, object)
{
  let meshGeometry = object.MeshInstance.GetMesh().geometry
  // using a custom mesh
  if (meshGeometry && meshGeometry.BoundingMin)
  {
    let min = new THREE.Vector3(meshGeometry.BoundingMin.X, meshGeometry.BoundingMin.Y, meshGeometry.BoundingMin.Z)
    let max = new THREE.Vector3(meshGeometry.BoundingMax.X, meshGeometry.BoundingMax.Y, meshGeometry.BoundingMax.Z)
    let meshBounding = new THREE.Box3(min, max);
    meshBounding.applyMatrix4(object.matrixWorld)

    boundingBox.expandByPoint(meshBounding.min)
    boundingBox.expandByPoint(meshBounding.max)
  }
  // using a default mesh
  else if(meshGeometry && meshGeometry.boundingBox)
  {
    let meshBounding = meshGeometry.boundingBox.clone()
    meshBounding.applyMatrix4(object.matrixWorld)

    boundingBox.expandByPoint(meshBounding.min)
    boundingBox.expandByPoint(meshBounding.max)
  }
}

export function FitCameraToBoundingBox(boundingBox, camera, orbitControls)
{
  if (camera instanceof THREE.PerspectiveCamera)
  {
    const fitOffset   = 1.5;
  

    const size    = boundingBox.getSize(new THREE.Vector3());
    const center  = boundingBox.getCenter(new THREE.Vector3());
    
    const maxSize           = Math.max(size.x, size.y, size.z);
    const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
    const fitWidthDistance  = fitHeightDistance / camera.aspect;
    const distance          = fitOffset * Math.max( fitHeightDistance, fitWidthDistance );
    
    const direction = orbitControls.target.clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance);
  
    if(center.length() === 0 && distance === 0)
    {
      console.error(`FitCameraToSelectedObjects failed: center.length() to small (${center.length()})`)
      return
    }
  
    if(center.length() > 10e8)
    {
      console.error(`FitCameraToSelectedObjects failed: center.length() to big (${center.length()})`)
      return
    }
  
    orbitControls.target.copy(center);
  

    camera.near  = Math.min(10, distance / 100);
    camera.far   = Math.max(300000, distance * 100);
    camera.up.set(0, 0, 1)
    
    camera.updateProjectionMatrix();
  
    camera.position.copy(orbitControls.target).sub(direction);
    
    orbitControls.update();
  }
  else
  {
    let distance = 3000
    const direction = orbitControls.target.clone()
    .sub(camera.position)
    .normalize()
    .multiplyScalar(distance);

    const center  = boundingBox.getCenter(new THREE.Vector3());

    if(center.length() > 10e8 || center.length() === 0)
    {
      console.error(`FitCameraToSelectedObjects failed: center.length() to big (${center.length()})`)
      return
    }
    

    orbitControls.target.copy(center);

    camera.position.copy(orbitControls.target).sub(direction);

   //For fitting the object to the screen when using orthographic camera
   let width = camera.top - camera.bottom
   let height = camera.right- camera.left
    camera.zoom = Math.min(width / (boundingBox.max.x - boundingBox.min.x), height / (boundingBox.max.y - boundingBox.min.y)) * 0.4;
    camera.updateProjectionMatrix();
    orbitControls.update();
  }



}

export function SetRendererView(view, scene, camera, orbitControls)
{
  let distance = camera.position.length();
  orbitControls.reset();

  switch(view)
  {
    case RENDERER_VIEW_FRONT:
    {
      camera.position.x = distance;
      camera.position.y = 0;
      camera.position.z = 0;
      break;
    }
    case RENDERER_VIEW_BACK:
    {
      camera.position.x = -distance;
      camera.position.y = 0;
      camera.position.z = 0;
      break;
    }
    case RENDERER_VIEW_LEFT:
    {
      camera.position.x = 0;
      camera.position.y = -distance;
      camera.position.z = 0;
      break;
    }
    case RENDERER_VIEW_RIGHT:
    {
      camera.position.x = 0;
      camera.position.y = distance;
      camera.position.z = 0;
      break;
    }
    case RENDERER_VIEW_TOP:
    {
      //Little trick so we get the x axis towards the bottom of the screen
      camera.position.x = 0;
      camera.position.y = -0.000001;
      camera.position.z = distance;
      break;
    }
    case RENDERER_VIEW_BOTTOM:
    {
      //Little trick so we get the x axis towards the top of the screen
      camera.position.x = 0;
      camera.position.y = -0.000001;
      camera.position.z = -distance;
      break;
    }
    case RENDERER_VIEW_FRONT_LEFT:
    {
      let sideLength = distance / Math.sqrt(3);
      camera.position.x = sideLength;
      camera.position.y = -sideLength;
      camera.position.z = sideLength;
      break;
    }
    case RENDERER_VIEW_FRONT_RIGHT:
    {
      let sideLength = distance / Math.sqrt(3);
      camera.position.x = sideLength;
      camera.position.y = sideLength;
      camera.position.z = sideLength;
      break;
    }
    case RENDERER_VIEW_REAR_LEFT:
    {
      let sideLength = distance / Math.sqrt(3);
      camera.position.x = -sideLength;
      camera.position.y = -sideLength;
      camera.position.z = sideLength;
      break;
    }
    case RENDERER_VIEW_REAR_RIGHT:
    {
      let sideLength = distance / Math.sqrt(3);
      camera.position.x = -sideLength;
      camera.position.y = sideLength;
      camera.position.z = sideLength;
      break;
    }
    default: break;
  }

  camera.lookAt(scene.position);
  camera.updateProjectionMatrix();
  orbitControls.update();
}

export function SetRendererViewBySavedView(savedView, scene, camera, orbitControls)
{
  orbitControls.reset();

  camera.position.x = savedView.CameraPosition.X;
  camera.position.y = savedView.CameraPosition.Y;
  camera.position.z = savedView.CameraPosition.Z;

  camera.quaternion.x = savedView.CameraRotation.X;
  camera.quaternion.y = savedView.CameraRotation.Y;
  camera.quaternion.z = savedView.CameraRotation.Z;
  camera.quaternion.w = savedView.CameraRotation.W;
  camera.updateProjectionMatrix();
  orbitControls.update();
}

export function createLineLoadGeometry(threeObject, current, color, dirEuler, isFemType=false)
{
  let euler  = new THREE.Euler()
  
  if(current.GlobalCoordinates || isFemType)
  {
    euler.setFromQuaternion (new THREE.Quaternion().setFromEuler(new THREE.Euler(current.GlobalRotationX, current.GlobalRotationY, current.GlobalRotationZ)).invert())
  }


  //----------------------------------------------------------------------------
  // Add Left Load
  let c = new THREE.Object3D();
  c.setRotationFromEuler(euler)
  createPointLoadGeometry(c, dirEuler, color)
  threeObject.add(c)


  
  //----------------------------------------------------------------------------
  // Add Right Load
  let c2 = new THREE.Object3D();
  let c3 = new THREE.Object3D();
  c2.setRotationFromEuler(euler)
  c3.translateX(current.LoadLength)

  c3.add(c2)
  threeObject.add(c3)

  createPointLoadGeometry(c2, dirEuler, color)

  //----------------------------------------------------------------------------
  // Add Connecting Bar
  let connectingBar = new THREE.Object3D();
  
  connectingBar.OriginalColor = new THREE.Color(color)
  connectingBar.CurrentColor = new THREE.Color(color)
  connectingBar.MeshInstance = load_node_geometry;
  load_node_geometry.IncreaseCount()
  connectingBar.scale.x = current.LoadLength / diameterLoad
  
  threeObject.add(connectingBar);
  
}

export function createLoadGeometry(threeObject, current, lr_object, renderOptions)
{
  if(current.LoadLength > 0)
  {
    if(current.LoadX !== 0.0) createLineLoadGeometry(threeObject, current, xAxisColor, new THREE.Euler(0,Math.PI/2 * (current.LoadX > 0 ? -1 : 1),0))
    if(current.LoadY !== 0.0) createLineLoadGeometry(threeObject, current, yAxisColor, new THREE.Euler(Math.PI/2 * (current.LoadY > 0 ? 1 : -1),0,0))
    if(current.LoadZ !== 0.0) createLineLoadGeometry(threeObject, current, zAxisColor, new THREE.Euler(Math.PI * (current.LoadZ > 0 ? 1 : 0),0,0)) 
  }
  else
  {
    let euler  = new THREE.Euler()
    if(current.GlobalCoordinates)
    {
      euler.setFromQuaternion( new THREE.Quaternion().setFromEuler(new THREE.Euler(current.GlobalRotationX, current.GlobalRotationY, current.GlobalRotationZ)).invert() )
    }
    let c = new THREE.Object3D();

    if(current.LoadX !== 0.0)createPointLoadGeometry(c, new THREE.Euler(0,Math.PI/2 * (current.LoadX > 0 ? -1 : 1),0), xAxisColor) // X
    if(current.LoadY !== 0.0)createPointLoadGeometry(c, new THREE.Euler(Math.PI/2 * (current.LoadY > 0 ? 1 : -1),0,0), yAxisColor) // X
    if(current.LoadZ !== 0.0)createPointLoadGeometry(c, new THREE.Euler(Math.PI * (current.LoadZ > 0 ? 1 : 0),0,0), zAxisColor) // Z

    c.setRotationFromEuler(euler)

    threeObject.add(c)


  }
}

export function createPointLoadGeometry(threeObject, euler, pointLoadColor)
{
  let c = new THREE.Object3D();
  let loadObject = new THREE.Object3D();
  loadObject.CurrentColor = new THREE.Color(pointLoadColor)
  loadObject.OriginalColor = new THREE.Color(pointLoadColor)
  loadObject.MeshInstance = pointloadMesh;
  pointloadMesh.IncreaseCount()

  loadObject.translateZ(heightLoad / 2 + heightCone / 2)
  loadObject.rotateX(Math.PI/2)
  
  let loadObjectCone = new THREE.Object3D();
  
  loadObjectCone.CurrentColor = new THREE.Color(pointLoadColor)
  loadObjectCone.OriginalColor = new THREE.Color(pointLoadColor)
  loadObjectCone.MeshInstance = pointconeLoadMesh;
  pointconeLoadMesh.IncreaseCount()
  loadObjectCone.translateZ(heightCone / 2)
  loadObjectCone.rotateX(-Math.PI/2)

  c.add(loadObject);
  c.add(loadObjectCone);
  threeObject.add(c);

  c.setRotationFromEuler(euler)
}

export function createStructureGeometry(threeObject, current, lr_object, renderOptions)
{
  let visible = renderOptions.ShowStructure
  if(current.StructureType === GEOMETRY_StructureType_CenterLineBased) 
  { 
    structureColor = 0xffff00 
  }
  else
  {
    visible = renderOptions.ShowInternalStructure
  }

  let StructureSetter = (inObj) => 
  {
    if(current.StructureType === GEOMETRY_StructureType_CenterLineBased)
    {
      inObj.IsStructure = true
      inObj.IsInternalStructure = false
    }
    else
    {
      console.log("IsInternal", visible)

      inObj.IsInternalStructure = true
      inObj.IsStructure = false
    }
    inObj.visible = visible
  }


  let structureGeometry = new THREE.Object3D();
  StructureSetter(structureGeometry);

  
  structureGeometry.OriginalColor = new THREE.Color(structureColor)
  structureGeometry.MeshInstance = structureMesh;
  structureMesh.IncreaseCount()

  structureGeometry.scale.x = 1.5
  structureGeometry.scale.y = 1.5
  structureGeometry.scale.z = 1.5 

  
  
  if(current.HingeNx)
  {
    let hingeGeometry = new THREE.Object3D();
    hingeGeometry.OriginalColor = new THREE.Color(structureColor)
    hingeGeometry.MeshInstance = structureMesh3;
    structureMesh3.IncreaseCount()
    threeObject.add(hingeGeometry);

    hingeGeometry.position.y = 2 * diameterStructures
    hingeGeometry.position.x = -2 * diameterStructures

    StructureSetter(hingeGeometry);
  }
  if(current.HingeVy)
  {
    let hingeGeometry = new THREE.Object3D();
    hingeGeometry.OriginalColor = new THREE.Color(structureColor)
    hingeGeometry.MeshInstance = structureMesh3;
    structureMesh3.IncreaseCount()
    threeObject.add(hingeGeometry);

    hingeGeometry.position.y =  4 * diameterStructures
    hingeGeometry.position.x = -2 * diameterStructures

    StructureSetter(hingeGeometry);
  }
  if(current.HingeVz)
  {
    let hingeGeometry = new THREE.Object3D();
    hingeGeometry.OriginalColor = new THREE.Color(structureColor)
    hingeGeometry.MeshInstance = structureMesh3;
    structureMesh3.IncreaseCount()
    threeObject.add(hingeGeometry);

    hingeGeometry.position.y =  6 * diameterStructures
    hingeGeometry.position.x = -2 * diameterStructures

    StructureSetter(hingeGeometry);
  }
  if(current.HingeMt)
  {
    let hingeGeometry = new THREE.Object3D();
    hingeGeometry.OriginalColor = new THREE.Color(structureColor)
    hingeGeometry.MeshInstance = structureMesh3;
    structureMesh3.IncreaseCount()
    threeObject.add(hingeGeometry);

    hingeGeometry.position.y = -2 * diameterStructures
    hingeGeometry.position.x = -2 * diameterStructures

    StructureSetter(hingeGeometry);
  }
  if(current.HingeMby)
  {
    let hingeGeometry = new THREE.Object3D();
    hingeGeometry.OriginalColor = new THREE.Color(structureColor)
    hingeGeometry.MeshInstance = structureMesh3;
    structureMesh3.IncreaseCount()
    threeObject.add(hingeGeometry);

    hingeGeometry.position.y = -4 * diameterStructures
    hingeGeometry.position.x = -2 * diameterStructures

    StructureSetter(hingeGeometry);
  }
  if(current.HingeMbz)
  {
    let hingeGeometry = new THREE.Object3D();
    hingeGeometry.OriginalColor = new THREE.Color(structureColor)
    hingeGeometry.MeshInstance = structureMesh3;
    structureMesh3.IncreaseCount()
    threeObject.add(hingeGeometry);

    hingeGeometry.position.y = -6 * diameterStructures
    hingeGeometry.position.x = -2 * diameterStructures

    StructureSetter(hingeGeometry);
  }
  

  
  if(current.StructureLength)
  {
    let obj = new THREE.Object3D()

    let target = new THREE.Vector3 (  current.OffsetX + current.StructureOffsetX,
      current.OffsetY + current.StructureOffsetY,
      current.OffsetZ + current.StructureOffsetZ);

    let source = new THREE.Vector3 (  current.OffsetX,
          current.OffsetY,
          current.OffsetZ);

    obj.name ="Structure"



    let width  = 100
    let height = 100

    

    if(current.CrossSectionType === 1)
    {
      width  = current.CrossSectionHeight
      height = current.CrossSectionHeight
    }
    else if(current.CrossSectionType === 2)
    {
      width  = current.CrossSectionWidth
      height = current.CrossSectionHeight
    }
    else if (current.StructureCrossSection)
    {
      let addVectorX = new THREE.Vector3(0, current.StructureCrossSection.Width/2,0)
      let subVectorX = new THREE.Vector3(0, -current.StructureCrossSection.Width/2,0)
      let addVectorY = new THREE.Vector3(0,0,current.StructureCrossSection.Hight/2)
      let subVectorY = new THREE.Vector3(0,0,-current.StructureCrossSection.Hight/2)
      let euler = new THREE.Euler(current.StructureRotationX, current.StructureRotationY, current.StructureRotationZ)

      width  = current.StructureCrossSection.Width
      height = current.StructureCrossSection.Hight

      if(current.StructureType !== GEOMETRY_StructureType_CenterLineBased) 
      {
        width  = current.StructureCrossSection.ChordDiameter
        height = current.StructureCrossSection.ChordDiameter
      }

      addVectorX.applyEuler(euler)
      subVectorX.applyEuler(euler)
      addVectorY.applyEuler(euler)
      subVectorY.applyEuler(euler)

      let startPositions = [
        addVectorX.clone().add(addVectorY), // TOP RIGHT
        subVectorX.clone().add(addVectorY), // TOP LEFT
        subVectorX.clone().add(subVectorY), // BOTTOM LEFT
        addVectorX.clone().add(subVectorY)  // BOTTOM RIGHT
      ]

      let structureLines = {
        TR: new THREE.Line3(source.clone().add(startPositions[0]), target.clone().add(startPositions[0])), // Top Right
        TL: new THREE.Line3(source.clone().add(startPositions[1]), target.clone().add(startPositions[1])), // Top Left
        BL: new THREE.Line3(source.clone().add(startPositions[2]), target.clone().add(startPositions[2])), // Bottom Left
        BR: new THREE.Line3(source.clone().add(startPositions[3]), target.clone().add(startPositions[3])), // Bottom Right
      }

      obj.StructureOutlines = structureLines
    }
    

    obj.StructureLine = new THREE.Line3(source, target)

    obj.OriginalColor = new THREE.Color(beamColor)
    obj.MeshInstance = structureMesh2;
    structureMesh2.IncreaseCount()

    obj.rotation.x = current.StructureRotationX
    obj.rotation.y = current.StructureRotationY
    obj.rotation.z = current.StructureRotationZ
    
    obj.scale.x = current.StructureLength
    obj.scale.z = height
    obj.scale.y = width

    let length = current.StructureLength

    let move = new THREE.Vector3 (  length/2,0,0)
    move.applyEuler(obj.rotation)


    obj.position.copy(move)

    let dir_obj = new THREE.Object3D()
    dir_obj.OriginalColor = new THREE.Color(structureColor)
    dir_obj.MeshInstance = structure_node_geometry;
    structure_node_geometry.IncreaseCount()
    dir_obj.rotation.z = -Math.PI /2

    threeObject.add(obj)
    //obj.add(dir_obj)

    
    StructureSetter(dir_obj);
    StructureSetter(obj);

  }
  

  threeObject.add(structureGeometry);
}



export function createSupportGeometry(threeObject, supportInfo, lr_object, renderOptions)
{
  let supportGeometry = new THREE.Object3D();


  let color = supportColor

  if(supportInfo.SupportType === GEOMETRY_SUPPORT_TYPE_Rope)
  {
    let chain = new THREE.Vector3(supportInfo.RopeOffsetX ? supportInfo.RopeOffsetX : supportInfo.RopeOffset.X, supportInfo.RopeOffsetY ? supportInfo.RopeOffsetY : supportInfo.RopeOffset.Y, supportInfo.RopeOffsetZ ? supportInfo.RopeOffsetZ : supportInfo.RopeOffset.Z)

    let geometry = new THREE.Object3D()
    geometry.name = "Support"
    geometry.MeshInstance = ropeMesh
    geometry.OriginalColor = new THREE.Color(color)
    geometry.CurrentColor = new THREE.Color(color)

    let chn_len = chain.length()
    geometry.rotateX(Math.PI/2);
    geometry.scale.x = 1
    geometry.scale.z = 1
    geometry.scale.y = chn_len

    chain.normalize()

    let mx = new THREE.Matrix4().lookAt(chain,new THREE.Vector3(0,0,0),new THREE.Vector3(0,1,0));
    let qt = new THREE.Quaternion().setFromRotationMatrix(mx);
    
    let pos =  new THREE.Vector3(0,0,chn_len/2)
    pos.applyQuaternion(qt)

    geometry.applyQuaternion(qt)

    geometry.position.x = pos.x
    geometry.position.y = pos.y
    geometry.position.z = pos.z

    //geometry.quaternion.set()
    ropeMesh.IncreaseCount()
    supportGeometry.add( geometry );

    if(lr_object && supportInfo && supportInfo.Calculated)
    {
      let reactionForce = supportInfo.ReactionForce  // kN
      let capacity      = supportInfo.Capacity * 9.81  / 1000 /  1000                 // g
      if(supportInfo.OnValidSystem)
      {
        let gradient
        if(store.getState()[GLOBAL_SETTINGS]?.data?.GlobalSettings?.Renderer_Workload_Coloring.Value === EWorkloadColoringMode_Threshold)  { gradient = ThresholdColor(reactionForce / capacity * 100, supportInfo.WorkloadHigh, supportInfo.WorkloadOverload) }
        else                                                                                                                      { gradient = GradientColor(reactionForce / capacity ) }
    
        color = rgb2hex([gradient.R,gradient.G,gradient.B])
      }
      else
      {
        color = "#cccccc"
      }


      if(supportInfo.ShowResult)
      {

        let unit = store.getState()[GLOBAL_SETTINGS]?.data?.GlobalSettings?.App_DisplayUnit_Force.Value
        
        let v = getUnitValueFromCore(reactionForce,  unit, BASE_UNIT_FORCE);

        let text = new TextGeometry( v.toFixed(store.getState()[GLOBAL_SETTINGS]?.data?.GlobalSettings?.App_DisplayUnit_Force.Accuracy) + getUnitName(unit, BASE_UNIT_FORCE),
        {
          font: renderOptions.DefaultFont,
          size: 600,
          height: 5,
          curveSegments: 1,
        });
  
        text.computeBoundingBox();
        text.center();
  
        let textMesh = new THREE.Mesh( text, new THREE.MeshBasicMaterial( {color: color} )); 
        threeObject.add(textMesh);
        textMesh.position.x = supportInfo.RopeOffset.X
        textMesh.position.y = supportInfo.RopeOffset.Y
        textMesh.position.z = supportInfo.RopeOffset.Z
        textMesh.OriginalColor = new THREE.Color(color)
      }
    }

    geometry.OriginalColor = new THREE.Color(color)
    geometry.CurrentColor  = new THREE.Color(color)
    
    let worldQuat = new THREE.Quaternion()
    threeObject.getWorldQuaternion(worldQuat)    
    supportGeometry.setRotationFromQuaternion(supportGeometry.quaternion.multiply(worldQuat.invert()));
    
  }
  else if(supportInfo.SupportType === GEOMETRY_SUPPORT_TYPE_Ground)
  {
    let geometry = new THREE.Object3D()
    geometry.name = "GroundSupport"
    geometry.MeshInstance = supportMesh
    geometry.OriginalColor = new THREE.Color(color)
    geometry.CurrentColor = new THREE.Color(color)

    supportMesh.IncreaseCount()
    supportGeometry.add( geometry );

    geometry.visible = renderOptions.ShowGroundSupports
    geometry.isGroundSupport = true
    if(lr_object && supportInfo && supportInfo.Calculated)
    {
      let reactionForce = supportInfo.ReactionForceZ // kN
      let capacity      = supportInfo.CapacityForceZ                  // kN
      
      let gradient
      if(store.getState()[GLOBAL_SETTINGS]?.data?.GlobalSettings?.Renderer_Workload_Coloring.Value === EWorkloadColoringMode_Threshold)  { gradient = ThresholdColor(reactionForce / capacity * 100, supportInfo.WorkloadHigh / 100 /* to percent */, supportInfo.WorkloadOverload) }
      else                                                                                                                      { gradient = GradientColor(reactionForce / capacity ) }
      

      color = rgb2hex([gradient.R,gradient.G,gradient.B])

      if(supportInfo.ShowResult)
      {
        let text = new TextGeometry((reactionForce).toFixed(2) +" kN",
        {
          font: renderOptions.DefaultFont,
          size: 600,
          height: 5,
          curveSegments: 1,
        });
  
        text.computeBoundingBox();
        text.center();
  
        let textMesh = new THREE.Mesh( text, new THREE.MeshBasicMaterial( {color: color} )); 
        threeObject.add(textMesh);
        textMesh.OriginalColor = new THREE.Color(color)
      }
    }

  }
  supportGeometry.name = "ir_Support";
  
  threeObject.add(supportGeometry);
}

export function createDimensionGeometry(threeObject, renderOptions, dimensionToVector, height)
{
  let material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
  
  let ToVector = new THREE.Vector3( dimensionToVector.X, dimensionToVector.Y, dimensionToVector.Z )

  let offset     = new THREE.Vector3( 0, 0, height )
  let offsetText = new THREE.Vector3( 0, 0, height + 120 )
  if(Equalish(dimensionToVector.X,0, 100) &&  Equalish(dimensionToVector.Y,0, 100) )
  {
    offset = new THREE.Vector3( 0, height, 0 )
    offsetText = new THREE.Vector3( 0, height + 120, 0 )
  }

  let points = [];
  points.push( new THREE.Vector3( 0, 0, 0 ) );
  points.push( offset );
  points.push( ToVector.clone().add(offset));
  points.push( ToVector );

  let lineBuffer = new THREE.BufferGeometry().setFromPoints( points );

  let line = new THREE.Line( lineBuffer, material );
  line.OriginalColor = new THREE.Color(0x0000ff)

  const unitType = store.getState()[GLOBAL_SETTINGS]?.data?.GlobalSettings?.App_DisplayUnit_Length?.Value;
  let unitString = getUnitName(unitType, BASE_UNIT_LENGTH);

  let length = getUnitValueFromCore(ToVector.length(), unitType, BASE_UNIT_LENGTH);

  let commaFix = 0
  switch (unitType)
  {
    case UNIT_Milimeter:  commaFix = 0; break;
    case UNIT_Centimeter: commaFix = 1; break;
    case UNIT_Meter:      commaFix = 1; break;
    default:              commaFix = 0;
  }

  let textGeo = new TextGeometry(length.toFixed(commaFix) + unitString,
    {
      font: renderOptions.DefaultFont,
      size: 200,
      height: 5,
      curveSegments: 1,
    });


  textGeo.computeBoundingBox();
  textGeo.center();

  let textMesh = new THREE.Mesh( textGeo, material );
  
  textMesh.OriginalColor = new THREE.Color(0x0000ff)

  //--------------------------------------------------------------
  // Text orientation
  textMesh.rotateX(Math.PI/2);

  //Math.atan2() returns the angle in the plane (in radians) between the positive x-axis and the ray from (0,0) to the point (x,y), for Math.atan2(y,x).
  let yAngle = Math.atan2(dimensionToVector.Y, dimensionToVector.X);
  textMesh.rotateY(yAngle);

  // If v1 = [x1,y1] and v2 = [x2,y2] are the components of two vectors, then: a = atan2(x1*y2 - y1*x2, x1*x2 + y1*y2);
  // and since we want the angle between (0, 0, 1) and dimensionToVector in the YZ plane:
  let zAngle = Math.atan2(-dimensionToVector.Z, dimensionToVector.Y);
  
  // I'm not sure why this works...
  if(dimensionToVector.Y < 0) {textMesh.rotateZ(Math.PI+zAngle);}
  else                        {textMesh.rotateZ(-zAngle);}

  //--------------------------------------------------------------
  // Text position

  textMesh.position.x = dimensionToVector.X / 2.0 + offsetText.x
  textMesh.position.y = dimensionToVector.Y / 2.0 + offsetText.y;
  textMesh.position.z = dimensionToVector.Z / 2.0 + offsetText.z; // Since we center the mesh, we also have to compensate the vertical offset
  
  //--------------------------------------------------------------

  threeObject.ToVector = dimensionToVector;
  threeObject.add(line);
  threeObject.add(textMesh);

}


export function createInfluenceLineGeometry(threeObject, renderOptions, InfluenceLine, lr_object)
{  
  let material_Deflection = new THREE.LineBasicMaterial( { color: 0X000080 } );
  let material_MaxDeflection = new THREE.LineBasicMaterial( { color: 0x00FFFF } );
  let material_Rotation = new THREE.LineBasicMaterial( { color: 0xFFA500 } );
  let material_Moment     = new THREE.LineBasicMaterial( { color: 0XFF00FF } );
  let material_Force      = new THREE.LineBasicMaterial( { color: 0X00FF00 } );
  let material_X      = new THREE.LineBasicMaterial( { color: 0X00FFFF } );
  let material_Workload      = new THREE.LineBasicMaterial( { color: 0XFFFFFF } );
  let material_MaxWorkload      = new THREE.LineBasicMaterial( { color: 0XFF0000 } );
  
  let startAdd = 0

  let bufferGeometries = new Map()

  let addBufferGeometry = (material) => (geo) =>
  {
    // If geo is not undefined push to bufferGeometries
    if(geo !== undefined)
    {
      // If bufferGeometries does not contain material.uuid, create new array
      if(!bufferGeometries.has(material.uuid))
      {
        bufferGeometries.set(material.uuid, {Material: material, Geometries: []})
      }
      // Add geo.Geometry to bufferGeometries[material.uuid]
      bufferGeometries.get(material.uuid).Geometries.push(geo)
    }
  } 

  InfluenceLine.Length.forEach((count, i_line) => 
  {
    let end = startAdd + count

    let show = InfluenceLine.ShowEntry[i_line]
  
    if(show && lr_object.ShowDu)  { addBufferGeometry(material_MaxDeflection)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleDeflection,  InfluenceLine.Distance, InfluenceLine.MaxDeflection,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "XYZ", undefined,renderOptions, lr_object.ShowText, BASE_UNIT_PAGE_LENGTH)); }
    if(show && lr_object.ShowDu)  { addBufferGeometry(material_Deflection)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleDeflection,  InfluenceLine.Distance, InfluenceLine.Deflection,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "XYZ", undefined,renderOptions, lr_object.ShowText, BASE_UNIT_PAGE_LENGTH)); }
    if(show && lr_object.ShowDx)  { addBufferGeometry(material_Deflection)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleDeflection,  InfluenceLine.Distance, InfluenceLine.Deflection,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","X", renderOptions, lr_object.ShowText, BASE_UNIT_PAGE_LENGTH)); }
    if(show && lr_object.ShowDy)  { addBufferGeometry(material_Deflection)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleDeflection,  InfluenceLine.Distance, InfluenceLine.Deflection,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Y","Y", renderOptions, lr_object.ShowText, BASE_UNIT_PAGE_LENGTH)); }
    if(show && lr_object.ShowDz)  { addBufferGeometry(material_Deflection)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleDeflection,  InfluenceLine.Distance, InfluenceLine.Deflection,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","Z", renderOptions, lr_object.ShowText, BASE_UNIT_PAGE_LENGTH)); }
    if(show && lr_object.ShowPhix)  { addBufferGeometry(material_Rotation)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleRotation,  InfluenceLine.Distance, InfluenceLine.Rotations,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","X", renderOptions, lr_object.ShowText, BASE_UNIT_NUMBER)); }
    if(show && lr_object.ShowPhiy)  { addBufferGeometry(material_Rotation)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleRotation,  InfluenceLine.Distance, InfluenceLine.Rotations,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Y","Y", renderOptions, lr_object.ShowText, BASE_UNIT_NUMBER)); }
    if(show && lr_object.ShowPhiz)  { addBufferGeometry(material_Rotation)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleRotation,  InfluenceLine.Distance, InfluenceLine.Rotations,  InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","Z", renderOptions, lr_object.ShowText, BASE_UNIT_NUMBER)); }
    if(show && lr_object.ShowNx)  { addBufferGeometry(material_X)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleForce,       InfluenceLine.Distance, InfluenceLine.Forces,               InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","X", renderOptions, lr_object.ShowText, BASE_UNIT_FORCE)); }
    if(show && lr_object.ShowVy)  { addBufferGeometry(material_Force)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleForce,       InfluenceLine.Distance, InfluenceLine.Forces,           InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Y","Y", renderOptions, lr_object.ShowText, BASE_UNIT_FORCE)); }
    if(show && lr_object.ShowVz)  { addBufferGeometry(material_Force)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleForce,       InfluenceLine.Distance, InfluenceLine.Forces,           InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","Z", renderOptions, lr_object.ShowText, BASE_UNIT_FORCE)); }
    if(show && lr_object.ShowMt)  { addBufferGeometry(material_X)(create_InfluenceLine_Part(threeObject, startAdd, end, InfluenceLine.ScaleMoment,      InfluenceLine.Distance, InfluenceLine.Moments,              InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","X", renderOptions, lr_object.ShowText, BASE_UNIT_TORQUE)); }
    if(show && lr_object.ShowMby) { addBufferGeometry(material_Moment)(create_InfluenceLine_Part(threeObject, startAdd, end, -1 *InfluenceLine.ScaleMoment,      InfluenceLine.Distance, InfluenceLine.Moments,     InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Z","Y", renderOptions, lr_object.ShowText, BASE_UNIT_TORQUE)); }
    if(show && lr_object.ShowMbz) { addBufferGeometry(material_Moment)(create_InfluenceLine_Part(threeObject, startAdd, end, -1 * InfluenceLine.ScaleMoment,      InfluenceLine.Distance, InfluenceLine.Moments,    InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "Y","Z", renderOptions, lr_object.ShowText, BASE_UNIT_TORQUE)); }
    if(show && lr_object.ShowWorkload) { create_InfluenceLine_Workload(threeObject, InfluenceLine, startAdd, end, InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], Workload, InfluenceLine.Dimensions[i_line])}
    if(show && lr_object.ShowWorkloadDeflection) { create_InfluenceLine_Workload(threeObject, InfluenceLine, startAdd, end, InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], WorkloadDeflection, InfluenceLine.Dimensions[i_line])}
    if(show && lr_object.ShowWorkloadLine) { addBufferGeometry(material_Workload)(create_InfluenceLine_Part(threeObject, startAdd, end, -1 * 1000,      InfluenceLine.Distance, InfluenceLine.Workload,    InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "V","Z", renderOptions, lr_object.ShowText, BASE_UNIT_NUMBER)); }
    if(show && lr_object.ShowWorkloadLine) { addBufferGeometry(material_MaxWorkload)(create_InfluenceLine_Part(threeObject, startAdd, end, -1 * 1000,      InfluenceLine.Distance, 1,    InfluenceLine.Position[i_line], InfluenceLine.Quat[i_line], "VS",undefined, renderOptions, lr_object.ShowText, BASE_UNIT_NUMBER)); }


    startAdd += count
  });

  let materials = []
  let bufferGeometriesArray = []
  if (bufferGeometries.size > 0)
  {

    bufferGeometries.forEach((value, key)=>{
      let geo = mergeBufferGeometries(value.Geometries)
      if (geo)
      {
        bufferGeometriesArray.push(geo)
        materials.push(value.Material)
      }
    })

    let finalBuffer = mergeBufferGeometries(bufferGeometriesArray, true)

    if (!finalBuffer)
    {
      console.error("Could not merge influence line buffer")
      return;
    }
    let lineSegment = new THREE.LineSegments(finalBuffer, materials);

    threeObject.add(lineSegment)
  }

  InfluenceLine.ResultHighlighting.forEach((e) => 
  {
    if(e.Type === 0 && !lr_object.ShowDu)       {return}
    if(e.Type === 1 && !lr_object.ShowNx)       {return}
    if(e.Type === 2 && !lr_object.ShowVy)       {return}
    if(e.Type === 3 && !lr_object.ShowVz)       {return}
    if(e.Type === 4 && !lr_object.ShowMt)       {return}
    if(e.Type === 5 && !lr_object.ShowMby)      {return}
    if(e.Type === 6 && !lr_object.ShowMbz)      {return}
    if(e.Type === 7 && !lr_object.ShowWorkload) {return}
    if(e.Type === 8 && !lr_object.ShowDx)       {return}
    if(e.Type === 9 && !lr_object.ShowDy)       {return}
    if(e.Type === 10 && !lr_object.ShowDz)    {return}

    let gradient

    if(e.Value === 0){ 
      gradient = COLOR_GREY
    }
    else if(store.getState()[GLOBAL_SETTINGS]?.data?.GlobalSettings?.Renderer_Workload_Coloring.Value === EWorkloadColoringMode_Gradient) { 
      gradient = GradientColor(e.Value, false /* 0 Is OK */) 
    }
    else { 
      gradient = ThresholdColor(e.Value,  InfluenceLine.WorkloadHigh / 100 /* to percent */, InfluenceLine.WorkloadOverLoad / 100 /* to percent */) 
    }
    
    
    let color = rgb2hex([gradient.R,gradient.G,gradient.B])
    let material_Workload      = new THREE.LineBasicMaterial( { color: color } );
    
    let material;
    let unit;
    let unitSubType = 0;
    let unitBaseType =0
    let value = 0;
    let heightFactor

    const settings = store.getState()[GLOBAL_SETTINGS].data.GlobalSettings
    switch(e.Type){
      case 8:
      case 9:
      case 10:
      case 0: 
        material = material_Deflection;
        heightFactor = InfluenceLine.ScaleDeflection;
        unitBaseType = BASE_UNIT_LENGTH
        unitSubType = settings.App_DisplayUnit_Length.Value
        break;
      case 1: 
        material = material_X;
      case 2: 
      case 3: 
        material = material || material_Force;
        heightFactor = InfluenceLine.ScaleForce;
        unitBaseType = BASE_UNIT_FORCE
        unitSubType = settings.App_DisplayUnit_Force.Value
        break;
      case 4:
        material = material_X;
      case 5: 
      case 6: 
        material = material || material_Moment;
        heightFactor = InfluenceLine.ScaleMoment;
        unitBaseType = BASE_UNIT_TORQUE
        unitSubType = settings.App_DisplayUnit_Moment.Value
        break;
      case 7:
        material = material_Workload;
        heightFactor = 1;
        unitBaseType = BASE_UNIT_PERCENT
        e.Value = e.Value * 100 
        unitSubType =0 
        break;
    }

    unit = getUnitName(unitSubType, unitBaseType);
    value = getUnitValueFromCore(e.Value, unitSubType, unitBaseType);

    let text = new TextGeometry(
      [value.toFixed(2), unit].join(" "),
      {
        font: renderOptions.DefaultFont,
        size: 300,
        height: 5,
        curveSegments: 1,
      }
    );

    text.computeBoundingBox();
    text.center();

    let textMesh = new THREE.Mesh( text, material );
    textMesh.OriginalColor = material.color.clone()

    textMesh.position.x = e.Point.X
    textMesh.position.y = e.Point.Y
    textMesh.position.z = e.Point.Z + (e.Value * heightFactor)

    threeObject.add(textMesh);

    textMesh.rotateX(Math.PI/2);

  });

  if(lr_object.ShowWindLoadDirection)
  {
    let angle = new THREE.Vector3(1,0,0)
    angle.applyEuler(new THREE.Euler(0,0, InfluenceLine.WindloadDirection))

    
    threeObject.add( new THREE.ArrowHelper(angle, new THREE.Vector3(), 10000, windColor))
  }

}

function Workload(InfluenceLine, i_line)
{
  return InfluenceLine.Workload[i_line]
}

function WorkloadDeflection(InfluenceLine, i_line)
{
  return (new THREE.Vector3(InfluenceLine.Deflection[i_line].X,InfluenceLine.Deflection[i_line].Y,InfluenceLine.Deflection[i_line].Z)).length()  / InfluenceLine.ResultHighlighting[0].Value
}

export function create_InfluenceLine_Workload(threeObject, InfluenceLine, startAdd, end, pos, quat, workloadFunction, dim)
{

  let allBeams = new THREE.Object3D();

  allBeams.position.x = pos.X
  allBeams.position.y = pos.Y
  allBeams.position.z = pos.Z

  allBeams.quaternion.x = quat.X
  allBeams.quaternion.y = quat.Y
  allBeams.quaternion.z = quat.Z
  allBeams.quaternion.w = quat.W

  for(let i_line = startAdd; i_line < end -1 ; i_line++)
  {
    let value = workloadFunction(InfluenceLine, i_line)
    let gradient

    if(value === 0)                                                                                                                { gradient = COLOR_GREY}
    else if(store.getState()[GLOBAL_SETTINGS]?.data?.GlobalSettings?.Renderer_Workload_Coloring.Value === EWorkloadColoringMode_Gradient)   { gradient = GradientColor(value, false /* 0 Is OK */) }
    else                                                                                                                           { gradient = ThresholdColor(value,  InfluenceLine.WorkloadHigh / 100 /* to percent */, InfluenceLine.WorkloadOverLoad / 100 /* to percent */) }
    

    let color = rgb2hex([gradient.R,gradient.G,gradient.B])

    let workloadObject = new THREE.Object3D();
    workloadObject.MeshInstance = influenceWorkloadMesh
    influenceWorkloadMesh.IncreaseCount()
    workloadObject.OriginalColor = new THREE.Color(color);
    workloadObject.CurrentColor = workloadObject.OriginalColor;

    let distanceFromStart = InfluenceLine.Distance[i_line] 
    let lengthOfBeam      = InfluenceLine.Distance[i_line+1] - InfluenceLine.Distance[i_line]
    workloadObject.scale.x = lengthOfBeam
    workloadObject.scale.y = dim.Y
    workloadObject.scale.z = dim.X

    let move = new THREE.Vector3(distanceFromStart + lengthOfBeam/2, 0, 0)
    
    workloadObject.position.copy(move)
    
    allBeams.add( workloadObject );

  }

  threeObject.add(allBeams);
}


export function createBox(e)
{
  let allBeams = new THREE.Object3D();

  allBeams.position.x = e.Position.X
  allBeams.position.y = e.Position.Y
  allBeams.position.z = e.Position.Z

  allBeams.quaternion.x = e.Quat.X
  allBeams.quaternion.y = e.Quat.Y
  allBeams.quaternion.z = e.Quat.Z
  allBeams.quaternion.w = e.Quat.W

  let color = cie2hex(e.Color)

  let workloadObject = new THREE.Object3D();
  workloadObject.MeshInstance = influenceWorkloadMesh
  influenceWorkloadMesh.IncreaseCount()
  workloadObject.OriginalColor = new THREE.Color(color);
  workloadObject.CurrentColor = workloadObject.OriginalColor;

  workloadObject.scale.x = e.Length
  workloadObject.scale.y = 100
  workloadObject.scale.z = 100

  let move = new THREE.Vector3(e.Length/2, 0, 0)
  
  workloadObject.position.copy(move)
  
  allBeams.add( workloadObject );

  allBeams.updateMatrix()

  return allBeams;

}

export function create_InfluenceLine_Part(threeObject, startAdd, end, scale, distanceArr, valueArr, pos, quat, align, prop, renderOptions, showText, unit)
{
  let threeQuat = new THREE.Quaternion(quat.X, quat.Y, quat.Z, quat.W);
  let threePos = new THREE.Vector3(pos.X, pos.Y, pos.Z);
  let threeScale = new THREE.Vector3(1, 1, 1)

  let transformMatrix = new THREE.Matrix4();
  // Compose matrix from quaternion and position
  transformMatrix.compose(threePos, threeQuat, threeScale);
  let points = [];

  let pushPoint = (point) =>
  {
    point.applyMatrix4(transformMatrix);
    points.push(point);
  }
  
  let bufferGeometries = [];
  let maxCoords = []
  for(let i = startAdd; i< end; i++ ) 
  { 
    if     (align === "XYZ") { pushPoint( new THREE.Vector3(  scale * valueArr[i].X + distanceArr[i],  scale * valueArr[i].Y,     scale * valueArr[i].Z     )); }
    else if(align === "Z")   { pushPoint( new THREE.Vector3(                          distanceArr[i],  0,                         scale * valueArr[i][prop] )); }
    else if(align === "Y")   { pushPoint( new THREE.Vector3(                          distanceArr[i],  scale * valueArr[i][prop], 0                         )); }
    else if(align === "V")   { pushPoint( new THREE.Vector3(                          distanceArr[i],  0,                         scale * valueArr[i] )); }
    else if(align === "VS")   { pushPoint( new THREE.Vector3(                         distanceArr[i],  0,                         scale * valueArr )); }

    if(prop)
    {
      let bufferGeo = new THREE.BufferGeometry().setFromPoints( [points[points.length - 1], new THREE.Vector3(distanceArr[i],  0, 0 ).applyMatrix4(transformMatrix)] )
      bufferGeometries.push(bufferGeo)
    }

    if(align !== "VS" &&showText && i !== startAdd && i+1 !== end)
    {
      let z0 = Math.abs(scale*valueArr[i - 1][prop])
      let z1 = Math.abs(scale*valueArr[i][prop])
      let z2 = Math.abs(scale*valueArr[i + 1][prop])
      
      if((z1 > z0 && z1 > z2) || align === "V")
      {
        if     (align === "XYZ") { maxCoords.push({Value: new THREE.Vector3(  valueArr[i].X + distanceArr[i],  valueArr[i].Y,      valueArr[i].Z     ).length(), Point: new THREE.Vector3(  scale * valueArr[i].X + distanceArr[i],  scale * valueArr[i].Y,     scale * valueArr[i].Z     ).applyMatrix4(transformMatrix)}) }
        else if(align === "Z")   { maxCoords.push({Value: valueArr[i][prop], Point: new THREE.Vector3(                          distanceArr[i],  0,                         scale * valueArr[i][prop] ).applyMatrix4(transformMatrix)}) }
        else if(align === "Y")   { maxCoords.push({Value: valueArr[i][prop], Point: new THREE.Vector3(                          distanceArr[i],  scale * valueArr[i][prop], 0                         ).applyMatrix4(transformMatrix)}) }
        else if(align === "V")   { maxCoords.push({Value: valueArr[i], Point: new THREE.Vector3(                          distanceArr[i],  scale * valueArr[i][prop], 0                         ).applyMatrix4(transformMatrix)}) }
      }
    }
    if(showText && i === startAdd)
    {
      if     (align === "XYZ") { maxCoords.push({Value: new THREE.Vector3(  valueArr[i].X + distanceArr[i],  valueArr[i].Y,      valueArr[i].Z     ).length(), Point: new THREE.Vector3(  scale * valueArr[i].X + distanceArr[i],  scale * valueArr[i].Y,     scale * valueArr[i].Z     ).applyMatrix4(transformMatrix)}) }
      else if(align === "Z")   { maxCoords.push({Value: valueArr[i][prop], Point: new THREE.Vector3(                          distanceArr[i],  0,                         scale * valueArr[i][prop] ).applyMatrix4(transformMatrix)}) }
      else if(align === "Y")   { maxCoords.push({Value: valueArr[i][prop], Point: new THREE.Vector3(                          distanceArr[i],  scale * valueArr[i][prop], 0                         ).applyMatrix4(transformMatrix)}) }
      else if(align === "V")   { maxCoords.push({Value: valueArr[i], Point: new THREE.Vector3(                          distanceArr[i],  scale * valueArr[i][prop], 0                         ).applyMatrix4(transformMatrix)}) }

    }


  }

  let unitString = ""
  let factor = 1

  if     (unit === BASE_UNIT_PAGE_LENGTH )  { unitString = "mm";                   }
  else if(unit === BASE_UNIT_NUMBER )       { unitString = "°";                   }
  else if(unit === BASE_UNIT_FORCE )        { unitString = "kN";                   }
  else if(unit === BASE_UNIT_TORQUE)        { unitString = "kNm"; factor = 0.001;  }

 
  
  for(let e of maxCoords)
  {
    threeObject.add((AddTextAtPoint((e.Value*factor).toFixed(2) + unitString, {X: e.Point.x, Y: e.Point.y, Z: e.Point.z}, supportColor, renderOptions)))
  }
  
  let segmentedPoints = []
  for (let i = 0; i < points.length - 1; i++)
  {
    segmentedPoints.push(points[i])
    segmentedPoints.push(points[i + 1])
  }
  

  
  bufferGeometries.push(new THREE.BufferGeometry().setFromPoints( segmentedPoints ))



  if(align !== "XYZ")
  {
    bufferGeometries.push(new THREE.BufferGeometry().setFromPoints( [new THREE.Vector3().applyMatrix4(transformMatrix), new THREE.Vector3(distanceArr[end-1],  0, 0 ).applyMatrix4(transformMatrix)] ))
  }

  return mergeBufferGeometries(bufferGeometries)
}

function AddTextAtPoint(str, position ,color, renderOptions)
{
  let text = new TextGeometry(String(str),
  {
    font: renderOptions.DefaultFont,
    size: 50,
    height: 5,
    curveSegments: 1,
  });

  text.computeBoundingBox();
  text.center();

  let textMesh = new THREE.Mesh( text, new THREE.MeshBasicMaterial( {color: color} )); 
  textMesh.position.x = position.X
  textMesh.position.y = position.Y
  textMesh.position.z = position.Z
  textMesh.OriginalColor = new THREE.Color(color)

  return textMesh
}

export function create_FEM_Geometry(threeObject, lr_geo, options)
{
  let fem_geo = new THREE.Object3D();

  if(lr_geo.FemType === GEOMETRY_FEM_GEOMETRY_TYPE_Support)
  {
  
    if(lr_geo.SupportX > 0)  { fem_geo.add(new THREE.ArrowHelper(new THREE.Vector3(1,0,0), new THREE.Vector3(), 75, zAxisColor)); }
    if(lr_geo.SupportY > 0)  { fem_geo.add(new THREE.ArrowHelper(new THREE.Vector3(0,1,0), new THREE.Vector3(), 75, xAxisColor)); }
    if(lr_geo.SupportZ > 0)  { fem_geo.add(new THREE.ArrowHelper(new THREE.Vector3(0,0,1), new THREE.Vector3(), 75, yAxisColor)); }
    if(lr_geo.SupportXX > 0) { fem_geo.add(new THREE.ArrowHelper(new THREE.Vector3(1,0,0), new THREE.Vector3(), 150, xAxisColor)); }
    if(lr_geo.SupportYY > 0) { fem_geo.add(new THREE.ArrowHelper(new THREE.Vector3(0,1,0), new THREE.Vector3(), 150, yAxisColor)); }
    if(lr_geo.SupportZZ > 0) { fem_geo.add(new THREE.ArrowHelper(new THREE.Vector3(0,0,1), new THREE.Vector3(), 150, zAxisColor)); }

    //threeObject.add(AddTextAtPoint("X "+ lr_geo.SupportValueX, {X: 0, Y: 0, Z: 0},zAxisColor,  options))
    //threeObject.add(AddTextAtPoint("Y "+ lr_geo.SupportValueY, {X: 0, Y: 0 + 60, Z: 0},zAxisColor,  options))
    //threeObject.add(AddTextAtPoint("Z "+ lr_geo.SupportValueZ, {X: 0, Y: 0 + 120, Z: 0},zAxisColor,  options))
    //threeObject.add(AddTextAtPoint("XX "+ lr_geo.SupportValueXX, {X: 0, Y: 0 + 180, Z: 0},zAxisColor,  options))
    //threeObject.add(AddTextAtPoint("YY "+ lr_geo.SupportValueYY, {X: 0, Y: 0 + 240, Z: 0},zAxisColor,  options))
    //threeObject.add(AddTextAtPoint("ZZ "+ lr_geo.SupportValueZZ, {X: 0, Y: 0 + 300, Z: 0},zAxisColor,  options))

  
    fem_geo.name = "fem_object";


  }
  else if(lr_geo.FemType === GEOMETRY_FEM_GEOMETRY_TYPE_Frame)
  {
    let obj = new THREE.Object3D()
    let color = new THREE.Color(Math.random() * 0xffffff)

    obj.MeshInstance = structureMesh2;
    structureMesh2.IncreaseCount()
    obj.position.x =  lr_geo.FrameLength / 2
    obj.scale.x =  lr_geo.FrameLength / diameterLength
    obj.scale.y = 10
    obj.scale.z = 10
    obj.OriginalColor = new THREE.Color(color)
    fem_geo.add(obj);
  }
  else if(lr_geo.FemType === GEOMETRY_FEM_GEOMETRY_TYPE_Node)
  {
    fem_geo.OriginalColor = new THREE.Color(0x000000)
    fem_geo.MeshInstance = fem_node_geometry;
    fem_node_geometry.IncreaseCount()
  }
  else if (lr_geo.FemType === GEOMETRY_FEM_GEOMETRY_TYPE_Load){
    const loadColor = 0xffff00;
    if(lr_geo.LoadLength > 0) {
      createLineLoadGeometry(fem_geo, lr_geo, loadColor, new THREE.Euler(0, 0, 0), true)
    }
    else 
    {
      createPointLoadGeometry(fem_geo, new THREE.Euler(0, 0, 0), loadColor )
    }
    
    fem_geo.position.set(lr_geo.GlobalOffsetX - lr_geo.OffsetX, lr_geo.GlobalOffsetY - lr_geo.OffsetY, lr_geo.GlobalOffsetZ - lr_geo.OffsetZ - (heightLoad + heightCone/2));
  }


  threeObject.add(fem_geo);

}

export function createOriginGeometry(threeObject)
{
  let originGeometry = new THREE.Object3D();

  let cubeBuffer  = new THREE.BoxGeometry(100, 100, 100);
  let material    = new THREE.MeshBasicMaterial( {color: 0x000000} );
  let cube        = new THREE.Mesh(cubeBuffer, material);
  cube.OriginalColor = new THREE.Color(0x000000)

  


  originGeometry.name = "origin";

  originGeometry.add(cube);
  threeObject.add(originGeometry);

}

export function createCurtainGeometry(threeObject, geometry, lr_object)
{
  let curtain = new THREE.Object3D();
  curtain.name = "curtain";

  if(!lr_object.ObjectPath)
  {
    const plane     = new THREE.PlaneGeometry( geometry.CurtainHeight, 1000, 2 );
    const material  = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
    const planeMesh = new THREE.Mesh( plane, material )
    planeMesh.OriginalColor = new THREE.Color(0xffff00);
    planeMesh.translateZ(-geometry.CurtainHeight/2);
    planeMesh.translateX(500);
    planeMesh.rotateX(Math.PI/2);
    curtain.add(planeMesh);

  }
  else
  {
    
    for(let i = 0; i < lr_object.ObjectPath.length-1; i++)
    {
      let curve = new THREE.LineCurve3(new THREE.Vector3(lr_object.ObjectPath[ i ].Point.X, lr_object.ObjectPath[ i ].Point.Y, lr_object.ObjectPath[ i ].Point.Z),
                                       new THREE.Vector3(lr_object.ObjectPath[i+1].Point.X, lr_object.ObjectPath[i+1].Point.Y, lr_object.ObjectPath[i+1].Point.Z))

      const length = geometry.CurtainHeight, width = 8;

      const shape = new THREE.Shape();
      shape.moveTo( 0,0 );
      shape.lineTo( 0, width );
      shape.lineTo( length, width );
      shape.lineTo( length, 0 );
      shape.lineTo( 0, 0 );
  
      const extrudeSettings = {
        steps: 2,
        depth: 16,
        bevelEnabled: true,
        bevelThickness: 1,
        bevelSize: 1,
        bevelOffset: 0,
        bevelSegments: 1,
        extrudePath: curve
      };
  
      const extrude = new THREE.ExtrudeGeometry( shape, extrudeSettings );
      const material = new THREE.MeshPhongMaterial()
      const mesh = new THREE.Mesh( extrude, material ) ;
      mesh.OriginalColor = material.color.clone()
      curtain.add( mesh );

                                   

    }

  }

  threeObject.add(curtain);
}

export function createPolygonGeometry(threeObject, lr_geometry, options)
{
  if (!Array.isArray(lr_geometry.ObjectPath)) { return; }

  if (lr_geometry.PolygonTextureName && (lr_geometry.ExtrusionHeight > 0 || lr_geometry.FillPolygon))
  {
    createPolygonExtrusionGeometry(threeObject, lr_geometry, options);
  }
  else
  {
    createPolygonLinesGeometry(threeObject, lr_geometry, options);
  }
}

async function createTextureGeometry(lr_geometry){
  let {Texture, width, height} = await GetTextureByUUID(lr_geometry.TextureUuid)
  let material = new THREE.MeshBasicMaterial({
    map: Texture.clone(),
    side: THREE.DoubleSide
  });

  let geometry = new THREE.PlaneGeometry(width*0.1, height*0.1);
  
  return new THREE.Mesh(geometry, material);
}

function createGeometrySpecificInstanceMesh(uuid, geometry, material, options, geometryName = uuid)
{
  let resultInstanceMesh = undefined

  if (polygonGeometryInstanceMeshes.has(uuid))
  {
    resultInstanceMesh = polygonGeometryInstanceMeshes.get(uuid)
    resultInstanceMesh.GetMesh().geometry = geometry.clone()
  }
  else
  {
    let instanceMesh = new THREE.InstancedMesh(geometry.clone(), material, DEFAULT_INSTANCE_COUNT)
    resultInstanceMesh = new LRInstanceMesh(instanceMesh, "Polygon_" + geometryName)
    resultInstanceMesh.SetMeshScene(options.Scene)
    polygonGeometryInstanceMeshes.set(uuid, resultInstanceMesh)
  }

  return resultInstanceMesh
}

function generatePolygonFillShapeGeometry(lr_geometry)
{
  let points2d = lr_geometry.ObjectPath.map(p => new THREE.Vector2(p.Point.X, p.Point.Y))

  let shape = new THREE.Shape(points2d);
  let shapeGeo = new THREE.ShapeGeometry(shape)

  shapeGeo.computeBoundingBox();

  let shapeWidth = shapeGeo.boundingBox.max.x - shapeGeo.boundingBox.min.x
  let shapeHeight = shapeGeo.boundingBox.max.y - shapeGeo.boundingBox.min.y

  for (let uvIndex = 0; uvIndex < shapeGeo.getAttribute("uv").count; uvIndex++)
  {
    shapeGeo.getAttribute("uv").array[uvIndex * 2] /= shapeWidth
    shapeGeo.getAttribute("uv").array[uvIndex * 2] += 0.5
    shapeGeo.getAttribute("uv").array[uvIndex * 2] += lr_geometry.PolygonTextureOffsetX * lr_geometry.PolygonTextureScale
    
    shapeGeo.getAttribute("uv").array[uvIndex * 2 + 1] /= shapeHeight
    shapeGeo.getAttribute("uv").array[uvIndex * 2 + 1] += 0.5
    shapeGeo.getAttribute("uv").array[uvIndex * 2 + 1] += lr_geometry.PolygonTextureOffsetY * lr_geometry.PolygonTextureScale
  }
  return shapeGeo
}

function isClockwise(points) {
  let signedArea = 0
  for (let [idx, i] of points.entries()) {
    let x1 = i.x
    let y1 = i.y
    let x2, y2
    if (idx === points.length - 1) {
      x2 = points[0].x
      y2 = points[0].y
    } else {
      x2 = points[idx + 1].x
      y2 = points[idx + 1].y
    }

    signedArea += (x1 * y2 - x2 * y1)
  }
  return signedArea < 0 ? false : true
}

function createPolygonLinesGeometry(threeObject, lr_geometry, options)
{
  let lineContainer = new THREE.Group();

  let rgbFill = cie2RGB({fx: lr_geometry.FillColor.X, fy: lr_geometry.FillColor.Y, f_Y: lr_geometry.FillColor.Z})
  let rgbLine = cie2RGB({fx: lr_geometry.LineColor.X, fy: lr_geometry.LineColor.Y, f_Y: lr_geometry.LineColor.Z})

  for (let i = 0; i < lr_geometry.ObjectPath.length - (lr_geometry.ClosedLine ? 0 : 1); i++)
  {
    let currentPoint = lr_geometry.ObjectPath[i];
    let nextPoint = lr_geometry.ObjectPath[(i + 1) % lr_geometry.ObjectPath.length];

    let currentVec = new THREE.Vector3(currentPoint.Point.X, currentPoint.Point.Y, currentPoint.Point.Z);
    let direction = new THREE.Vector3(nextPoint.Point.X - currentPoint.Point.X, nextPoint.Point.Y - currentPoint.Point.Y, nextPoint.Point.Z - currentPoint.Point.Z);
    
    var qt = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(1, 0, 0), direction.clone().normalize());
    
    let lineObject = new THREE.Object3D()
    lineObject.position.copy(currentVec.clone().add(direction.clone().multiplyScalar(0.5)))
    lineObject.quaternion.copy(qt);
    lineObject.scale.set(direction.length(), lr_geometry.LineWidth, 1);

    lineObject.MeshInstance = polygonLineMesh
    polygonLineMesh.IncreaseCount()
    lineObject.OriginalColor = new THREE.Color(rgbLine[0] / 255, rgbLine[1] / 255, rgbLine[2] / 255)
    lineObject.CurrentColor = new THREE.Color(rgbLine[0] / 255, rgbLine[1] / 255, rgbLine[2] / 255)

    lineContainer.add(lineObject)
  }
  threeObject.add(lineContainer)

  if (lr_geometry.ExtrusionHeight > 0)
  {
    lineContainer.position.z += lr_geometry.ExtrusionHeight / 2
    lineContainer.scale.set(1, 1, lr_geometry.ExtrusionHeight / 10)
    lineContainer.updateMatrix()
    lineContainer.updateMatrixWorld(true)
  }

  if (lr_geometry.FillPolygon)
  {
    let points3d = lr_geometry.ObjectPath.map(p => new THREE.Vector3(p.Point.X, p.Point.Y, p.Point.Z))

    if (isClockwise(points3d)) {
      // only renders correctly if is counterclockwise
      points3d.reverse()
    }
    let shape = new THREE.Shape(points3d);

    let shapeGeo = new THREE.ShapeGeometry(shape)
    for (let [idx, i] of points3d.entries()) {
      shapeGeo.attributes.position.setZ(idx, i.z)
    }

    let polygonGeometryInstance = createGeometrySpecificInstanceMesh(lr_geometry.UUID, shapeGeo, new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, opacity: lr_geometry.FillAlpha  }), options, "Polygon_" + lr_geometry.Name)
    polygonGeometryInstance.GetMesh().material.map = undefined
    polygonGeometryInstance.GetMesh().material.needsUpdate = true

    let mesh = new THREE.Object3D();
    mesh.MeshInstance = polygonGeometryInstance
    polygonGeometryInstance.IncreaseCount();
    mesh.OriginalColor = new THREE.Color(rgbFill[0] / 255, rgbFill[1] / 255, rgbFill[2] / 255)
    mesh.CurrentColor = new THREE.Color(rgbFill[0] / 255, rgbFill[1] / 255, rgbFill[2] / 255)

    threeObject.add(mesh)

    if (lr_geometry.ExtrusionHeight > 0)
    {
      let topMesh = new THREE.Object3D()
      topMesh.MeshInstance = polygonGeometryInstance
      polygonGeometryInstance.IncreaseCount();
      topMesh.OriginalColor = new THREE.Color(rgbFill[0] / 255, rgbFill[1] / 255, rgbFill[2] / 255)
      topMesh.CurrentColor = new THREE.Color(rgbFill[0] / 255, rgbFill[1] / 255, rgbFill[2] / 255)
  
      topMesh.position.z += lr_geometry.ExtrusionHeight
      threeObject.add(topMesh);
    }
  }
  threeObject.add(lineContainer)

}

function createPolygonExtrusionGeometry(threeObject, lr_geometry, options)
{
  if (lr_geometry.PolygonTextureScale === 0) { lr_geometry.PolygonTextureScale = 1 }

  // Generate outside mesh
  let vertices  = []
  let tcs       = []

  let indices   = []

  let pushVertexToArray = (v) => {
    vertices.push(v.x)
    vertices.push(v.y)
    vertices.push(v.z)
  }

  let pushTcToArray = (tc) => {
    tcs.push(tc.x)
    tcs.push(tc.y)
  }

  let textureOffset = new THREE.Vector2(lr_geometry.PolygonTextureOffsetX, lr_geometry.PolygonTextureOffsetY)

  let textureAspectRatio = lr_geometry.PolygonTextureWidth / lr_geometry.PolygonTextureHeight

  for (let i = 0; i < lr_geometry.ObjectPath.length - (lr_geometry.ClosedLine ? 0 : 1); i++)
  {
    let currentPoint = lr_geometry.ObjectPath[i];
    let nextPoint = lr_geometry.ObjectPath[(i + 1) % lr_geometry.ObjectPath.length];

    let firstPoint = new THREE.Vector3(currentPoint.Point.X, currentPoint.Point.Y, currentPoint.Point.Z);
    let secondPoint = new THREE.Vector3(nextPoint.Point.X, nextPoint.Point.Y, nextPoint.Point.Z);
    let thirdPoint = new THREE.Vector3(nextPoint.Point.X, nextPoint.Point.Y, nextPoint.Point.Z + lr_geometry.ExtrusionHeight);
    let fourthPoint = new THREE.Vector3(currentPoint.Point.X, currentPoint.Point.Y, currentPoint.Point.Z + lr_geometry.ExtrusionHeight);


    let width = secondPoint.clone().sub(firstPoint).length();

    let faceAspectRatio = (width / lr_geometry.ExtrusionHeight) * textureAspectRatio;

    pushVertexToArray(firstPoint)
    pushTcToArray(new THREE.Vector2(0, 0).add(textureOffset).multiplyScalar(1 / lr_geometry.PolygonTextureScale))
    pushVertexToArray(secondPoint)
    pushTcToArray(new THREE.Vector2(faceAspectRatio < 1 ? 1 : faceAspectRatio, 0).add(textureOffset).multiplyScalar(1 / lr_geometry.PolygonTextureScale))
    pushVertexToArray(thirdPoint)
    pushTcToArray(new THREE.Vector2(faceAspectRatio < 1 ? 1 : faceAspectRatio, faceAspectRatio < 1 ? (1 / faceAspectRatio) : 1).add(textureOffset).multiplyScalar(1 / lr_geometry.PolygonTextureScale))
    pushVertexToArray(fourthPoint)
    pushTcToArray(new THREE.Vector2(0, faceAspectRatio < 1 ? (1 / faceAspectRatio) : 1).add(textureOffset).multiplyScalar(1 / lr_geometry.PolygonTextureScale))

    indices.push(i * 4 + 0)
    indices.push(i * 4 + 1)
    indices.push(i * 4 + 2)

    indices.push(i * 4 + 2)
    indices.push(i * 4 + 3)
    indices.push(i * 4 + 0)
  }

  let positionAttribute = new THREE.Float32BufferAttribute(vertices, 3);
  let uvAttribute = new THREE.Float32BufferAttribute(tcs, 2);

  let geometry = new THREE.BufferGeometry();
  geometry.setIndex(indices);
  geometry.setAttribute("position", positionAttribute);
  geometry.setAttribute("uv", uvAttribute);
  geometry.computeVertexNormals();

  let material = new THREE.MeshPhongMaterial({ color: 0xffffff, side: THREE.DoubleSide });

  let finalBuffer = geometry


  if (lr_geometry.FillPolygon)
  {
    let fillBuffer = generatePolygonFillShapeGeometry(lr_geometry)
    
    let bufferArray = [geometry, fillBuffer]

    if (lr_geometry.ExtrusionHeight > 0)
    {
      let topFillBuffer = fillBuffer.clone()

      for (let uvIndex = 0; uvIndex < topFillBuffer.getAttribute("position").count; uvIndex++)
      {
        topFillBuffer.getAttribute("position").array[uvIndex * 3 + 2] += lr_geometry.ExtrusionHeight // Increase z-position
      }
      
      bufferArray.push(topFillBuffer)
    }

    
    finalBuffer = mergeBufferGeometries(bufferArray, true);
  }

  let instanceMesh = createGeometrySpecificInstanceMesh(lr_geometry.UUID, finalBuffer, material, options, "Polygon_" + lr_geometry.Name)

  if (lr_geometry.PolygonTextureName)
  {
    GetTexture(lr_geometry.PolygonTextureName).then((t) => {
      let tex = t.Texture.clone()
      tex.needsUpdate = true
      tex.rotation = lr_geometry.PolygonTextureRotation
      instanceMesh.GetMesh().material.map = tex;
      instanceMesh.GetMesh().material.needsUpdate = true;
    })
  }
  else
  {
    instanceMesh.GetMesh().material.map = undefined
    instanceMesh.GetMesh().material.needsUpdate = true
  }

  let instance = new THREE.Object3D();
  instance.MeshInstance = instanceMesh
  instanceMesh.IncreaseCount()
  instance.OriginalColor = new THREE.Color(1, 1, 1)
  instance.CurrentColor = new THREE.Color(1, 1, 1)

  threeObject.add(instance)
}


let COLOR_GREEN  = { R: 0, G: 255, B: 0}
let COLOR_YELLOW = { R: 255, G: 255, B: 0}
let COLOR_RED    = { R: 255, G: 0, B: 0}
let COLOR_GREY    = { R: 128, G: 128, B: 128}

export function GradientColorFunction(percentage, ColorA, ColorB)
{
  if(Array.isArray(ColorA)){
    ColorA = {
      R: ColorA[0],
      G: ColorA[1],
      B: ColorA[2]
    }
  }

  if(Array.isArray(ColorB)){
    ColorB = {
      R: ColorB[0],
      G: ColorB[1],
      B: ColorB[2]
    }
  }

  return{
    R: ColorA.R + percentage * (ColorB.R - ColorA.R),
    G: ColorA.G + percentage * (ColorB.G - ColorA.G),
    B: ColorA.B + percentage * (ColorB.B - ColorA.B),
  }
}
export function GradientColor(percentage, nullRed = true)
{
  if(percentage <= 0.0 || Number.isNaN(percentage)) {
    if(nullRed) {return COLOR_RED}
    else        {return COLOR_GREEN}
  }
  else if (percentage < 0.5)                { return GradientColorFunction(percentage / 0.5, COLOR_GREEN, COLOR_YELLOW) }
  else if (percentage < 1)                  { return GradientColorFunction((percentage - 0.5) / 0.5, COLOR_YELLOW, COLOR_RED) }
  return COLOR_RED
}


export function ThresholdColor(percentage, highWorkload = 0.8, overloadWorkload = 1.0)
{
  if      (percentage < highWorkload)        { return COLOR_GREEN } 
  else if (percentage < overloadWorkload)    { return COLOR_YELLOW }
  return COLOR_RED
}