//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import ObjectProperties from '../component/ObjectProperties/ObjectPropertiesComponent';
import ToolSelect from '../component/ToolSelect/ToolSelectComponent';
const Renderer = React.lazy(()=> import('../component/Renderer/RendererWrapper'))

import SceneTreeHierarchy from '../component/SceneTreeHierarchy/SceneTreeHierarchyComponent';
const TableView = React.lazy(()=>import('../component/TableViews/TableViewComponent'))
import ResourceManager from '../component/RessourceManager/ResourceManager';
const ElectricalGraph = React.lazy(()=>import("../component/ElectricalGraph/ElectricalGraphComponent"))

import NewSignIn from '../component/SignIn/NewSignin';
import MenuBar from '../component/MenuBar/MenuBar';
import DmxOverview from '../component/DmxOverview/DmxOverview';
import NavigationContainer from '../component/Navigation/NavigationContainer';
import Timeline from '../component/Timeline/Timeline';
import LightingPaperwork from '../component/LightingPaperwork/LightingPaperwork';
import Footer from '../electronApp/Footer';
import * as FlexLayout from "./../util/FlexLayout_Fix/src/index";
import './../electronApp/App.css';
import LocalizedStrings from "../localization/TableViewComponent";


import { EMPTY_UUID, OBJECT_PROPERTIES_TAB_ID, SCENE_TREE_TAB_ID, RENDERER_TAB_ID, NAVIGATION_TAB_ID, TOOL_SELECT_INDEX, OBJECT_PROPERTIES_INDEX, SCENE_TREE_INDEX, NAVIGATION_CONTAINER_INDEX, RENDERER_INDEX, CHILD_TAB_ID, RESPONSIVE_PHONE} from "../util/defines";
import MediaQuery from 'react-responsive';
import MobileFooter from './MobileFooter';
import QrCodeReaderSite from '../component/QrCodeScanner/QrCodeSite';
import Worksheet from '../component/WorkSheet/WorkSheet';
import AppProjectOverview from './ProjectOverviewMobile';
import UserTaskOverview from '../component/UserTaskOverview/UserTaskOverview';
import { HandleScanInApp } from '../component/QrCodeScanner/defaultScanning';
const DefaultModalContainer = React.lazy(()=>import('../component/DefaultModalContainer/DefaultModalContainer'))

import LongPressHandler from '../component/RightClickMenu/LongPressHandler';
import { Icon, Tab, Image, Grid, Dropdown } from 'semantic-ui-react';
import logo from "../content/img/applogo_white.png";
import BackgroundImage from '../content/img/BackgroundGraphicInfoPartMobile.png';
import "./ios.css"
import { globalCallbacks } from "../util/callback";



let objectProperties_json = {
  "type": "tab",
  "id": OBJECT_PROPERTIES_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Object Properties",
  "component": "ObjectProperties"
}

let navigation_json = {
  "type": "tab",
  "id": NAVIGATION_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Navigation Container",
  "component": "NavigationContainer"
}

let sceneTreeHierarchy_json = {
  "type": "tab",
  "id": SCENE_TREE_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Scene Tree Hierarchy",
  "component": "SceneTreeHierarchy"
}

let renderer_json = {
  "type": "tab",
  "id": RENDERER_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Renderer",
  "component": "Renderer"
}



var layout_json = {
  global: {
    tabEnableClose: true,
    tabSetEnableMaximize: false,
  },
  layout: {
      "type": "row",
      "weight": 100,
      "children": [
          {
              "type": "tabset",
              "weight": 10,
              "selected": 0,
              "children": [
                objectProperties_json
              ]
          },
          {
            "type": "tabset",
            "weight": 10,
            "selected": 0,
            "children": [
              sceneTreeHierarchy_json
            ]
        },
          {
            "type": "tabset",
            "weight": 50,
            "selected": 0,
            "children": [
              renderer_json
            ]
          },
          {
            "type": "tabset",
            "weight": 10,
            "selected": 0,
            "children": [
              navigation_json
            ]
          }
      ]
  }
};

//---------------------------------------------------------------------
// LightRightRouter
class IosRouter extends Component 
{ 
  constructor(props)
  {
    super(props);

    this.state = 
    {
      Component: window.innerWidth > RESPONSIVE_PHONE ?  "renderNormalView" : "projectCheckout",
      showObjectProperties: true,
      showNavigation: true,
      layoutDef: FlexLayout.Model.fromJson(layout_json),

      renderToolSelect: true,
      renderObjectProperties: true,
      renderSceneTree: true,
      renderNavigation: true,
      renderRenderer: true,
      Worksheets: []
    };
  }

  componentDidMount = async () => 
  {
    this.setUpCallbacks()
    // add event listener to the tabs
    let oipNode = this.state.layoutDef.getNodeById(OBJECT_PROPERTIES_TAB_ID)
    let navigationNode = this.state.layoutDef.getNodeById(NAVIGATION_TAB_ID)
    let sceneTreeNode = this.state.layoutDef.getNodeById(SCENE_TREE_TAB_ID)
    let rendererNode = this.state.layoutDef.getNodeById(RENDERER_TAB_ID)

    if (oipNode) oipNode.setEventListener("close", () => {this.setState({renderObjectProperties: false})})
    if (navigationNode) navigationNode.setEventListener("close", () => {this.setState({renderNavigation: false})})
    if (sceneTreeNode) sceneTreeNode.setEventListener("close", () => {this.setState({renderSceneTree: false})})
    if (rendererNode) rendererNode.setEventListener("close", () => {this.setState({renderRenderer: false})})
    

    let json = await window.LR_GetAppDisplayState()
    if(json.global) { this.setState({layoutDef: FlexLayout.Model.fromJson(json)}) }    

    globalCallbacks.toggleContainer = (index) =>
    {
      this.toggleContainer(index)
    }

    globalCallbacks.EnableWorksheetView = () =>
    {
      this.setState({Component: "renderTableView", })
    }

    globalCallbacks.Enable3DVView = () =>
    {
      this.setState({Component: "renderNormalView", })
    }

    let res1     = await window.LR_GetLoggedInUser()
    this.setState({User: res1.User})

    globalCallbacks.updateWorksheetSelector()
  }

  setUpCallbacks() 
  {
    globalCallbacks.updateActiveProjectIPhone = async () =>
    {        
        let result = await window.LR_GetLinkedProject()

        this.setState({
            owner: result.Owner,
            file: result.Project,
            branch: result.Branch,
            userRight: result.UserRight
        })
      }

      globalCallbacks.updateWorksheetSelector = async () =>
      {
        let workSheetArray = await window.LR_GetWorksheets();

        this.setState({
          Worksheets: workSheetArray.Worksheets.map(w=>{ return { key: w.UUID, text:w.Name, value: w.UUID }})
        })
      }
    }

  render ()
  {

    let size = "3em"
    let showToolBar = false
    let backgroundStyle = {backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}
    if(this.state.Component === "renderNormalView") {size = "7.5em"; showToolBar = true}
    return <div style={{ MozUserSelect: "none", WebkitUserSelect: 'none', msUserSelect: 'none', WebkitTouchCallout: 'none', UserSelect: 'none'}}>
      <NewSignIn iosApp> 
      
        <DefaultModalContainer/>
        <MediaQuery maxWidth={RESPONSIVE_PHONE}>
        {(isPhone => 
        {
          return(
          <>
          {(!isPhone && showToolBar) ? <div style={{height:"5em", overflowY:"auto", overflowX:"hidden",position: "fixed", top:"3em", width:"100vW", zIndex:"100", marginTop: "0.75rem"}}> <ToolSelect /></div> : null}
          <div className='ios-component' style={{height: "100vh", overflowY:"auto", overflowX:"hidden",position: "fixed", top: isPhone ? "0" : size, width:"100vW",...(this.state.Component === "projectCheckout" && backgroundStyle)}}>
            {this.renderComponent(size,isPhone )}
          </div>
          </>)
        })}
        </MediaQuery>
        
        

        <MediaQuery maxWidth={RESPONSIVE_PHONE}>
        {(isPhone => 
        {
          return isPhone ? 
          <>
            <MobileFooter changeComponent={this.changeComponent} activeView={this.state.Component}/>
          </> 
          : 
          <>
          <Footer size="45px" />
          <MenuBar noCollapse onChangeView={this.changeComponent} activeView={this.state.Component} showproperties={this.showObjectProperties} shownavigation={this.showNavigation}/>
          </>
        })}
        </MediaQuery>
        <LongPressHandler/>
      </NewSignIn>
    </div>
  }
  
  thisModelChange = () =>
  {
    window.LR_SetAppDisplayState(this.state.layoutDef.toJson())
    
  }
  factory = (node) => 
  {
    var component = node.getComponent();
    let retVal = null
    let style = {}

    if      (component === "SceneTreeHierarchy")  { retVal =  <SceneTreeHierarchy node={node} onClose={() => {this.setState({renderSceneTree: false})}}/>; style={height: "100%",}}
    else if (component === "ObjectProperties")    { retVal =  <ObjectProperties node={node} onClose={() => {this.setState({renderObjectProperties: false})}}/>; style={height: "100%", }}
    else if (component === "NavigationContainer") { retVal =  <NavigationContainer isIos={true} node={node} onClose={() => {this.setState({renderNavigation: false})}}/>; style={height: "100%", }}
    else if (component === "Renderer" || component === "worksheet")            
    { 
      if(this.state.Component !== "renderNormalView")
      {
        retVal =  <TableView  node={node} onClose={() => {this.setState({renderRenderer: false})}}/>; style={height: "100%", }
      }
      else
      {
        retVal =  <Renderer rootUuid={0}/>; style={ height: "100%"}
      }
      
    }

    return <div style={{...style, overflowX:"hidden", overflowY: "auto", position: 'relative'} }>{retVal}</div>
}

  titleFactory = (node) => 
  {
    // compute tab width in tabset
    let width = node._rect.width / node._parent._children.length
    let widthStr = String(width) + "px"
    
    // compute tab position in tabset by computing needed margin
    let pre = 0
    let child = node._parent._children[pre]
    while (child.getName() !== node.getName())
    { 
      pre += 1
      child = node._parent._children[pre]
    }
    let marginLeft = width * pre
    let marginLeftStr = String(marginLeft) + "px"

    // focused tab coloring
    let backgroundColor = "#aaaaaa"
    if (!node._visible)
    {
      backgroundColor = "#dddddd"
    }

    return <div style={{overflow: "hidden", marginLeft: marginLeftStr, backgroundColor: backgroundColor, width: widthStr, height: "inherit", paddingLeft: "2px", position: "absolute"}}>
              {!node._visible && node.getName()}
            </div>
  }

  renderComponent(size, mobile)
  {
    if(this.state.Component === "renderNormalView" || mobile)
    {
      return this.renderNormalView(size)
    }
    else if(this.state.Component === "renderResourceManager")
    {
      return this.renderFullSizeComponent(<ResourceManager iPad/>)
    }
    else if(this.state.Component === "renderTableView")
    {
      return this.renderFullSizeComponent(<TableView iPad/>)
    }
    else if(this.state.Component === "renderElectricalGraph")
    {
      return this.renderFullSizeComponent(<ElectricalGraph/>)
    }
    else if(this.state.Component === "renderDMXView")
    {
      return this.renderFullSizeComponent(<DmxOverview/>)
    }
    else if(this.state.Component === "renderLightPaperwork")
    {
      return this.renderFullSizeComponent(<LightingPaperwork/>)
    }
    else if(this.state.Component === "renderTimeline")
    {
      return this.renderFullSizeComponent(<Timeline/>)
    }

    return this.renderNormalView(size)
  }

  changeComponent = (newComponent) =>
  {
    console.log("New component", newComponent)
    this.setState({Component: newComponent})
    globalCallbacks.updateActiveProjectIPhone()
  }

  showObjectProperties = () =>
  {
    this.setState({showObjectProperties: !this.state.showObjectProperties})
  }

  showNavigation = () =>
  {
    this.setState({showNavigation: !this.state.showNavigation})
  }

  renderNormalView(size)
  {
    return(   
      <MediaQuery maxWidth={RESPONSIVE_PHONE}>
        {(isPhone => 
        {
          if(isPhone)
          {
            return this.renderPhoneNormal()
          }
          return(
          <>
          <div style={{width:"100vW", overflow:"hidden",position: "fixed",}}>
          <FlexLayout.Layout 
            model={this.state.layoutDef}
            onModelChange={this.thisModelChange}
            factory={this.factory.bind(this)}
            titleFactory={this.titleFactory.bind(this)}
            height={"calc(100vh - " + size +" - 40px)"}
          />
        </div>
          </>)
        })}
        </MediaQuery>     
        
          )
  }

  
  renderPhoneNormal = () =>
  {
    const panes = [
      {
        menuItem: LocalizedStrings.SceneTree,
        render: () => <Tab.Pane attached={false}><SceneTreeHierarchy/></Tab.Pane>,
      },
      {
        menuItem: LocalizedStrings.ObjectProperties,
        render: () => <Tab.Pane attached={false}><ObjectProperties/></Tab.Pane>,
      },
    ]

    const panes2 = [
      {
        menuItem: LocalizedStrings.UserTask,
        render: () => <Tab.Pane attached={false}><UserTaskOverview MobileView/></Tab.Pane>,
      },   

      {
        menuItem: LocalizedStrings.GantBoard,
        render: () => <Tab.Pane attached={false}><Timeline/></Tab.Pane>,
      },
         
    ]
    if(this.state.Component === "camera")
    {
      return (
      <>
        {this.renderIphoneProjectInfo()}
        <QrCodeReaderSite onScan={HandleScanInApp}/>
        <Tab renderActiveOnly menu={{ pointing: true }} panes={panes} />
      </>)
    }
    if(this.state.Component === "wirings")
    {
      return (
      <>
        {this.renderIphoneProjectInfo()}
        <ElectricalGraph/>
      </>)
    }
    
    if(this.state.Component === "tasks")
    {
      return <>{this.renderIphoneProjectInfo()} 
       <Tab renderActiveOnly menu={{ pointing: true }} panes={panes2} />
      
      </>
    }
    
    if(this.state.Component === "renderNormalView")
    {
      return <>{this.renderIphoneProjectInfo()} <MenuBar ios noCollapse/><ToolSelect/><Worksheet renderOnly/></>
    }
    if(this.state.Component === "renderTableView")
    {
      return <>{this.renderIphoneProjectInfo()} <Worksheet iPad/></>
    }
    if(this.state.Component === "projectCheckout")
    {
      return <AppProjectOverview changeComponent={this.changeComponent}/>
    }
    return <>
    ERROR    2
    
    </>
  }

  renderIphoneProjectInfo()
  {
    let changedView = (e, {value} ) =>
    {
      window.LR_ShowWorkSheet({UUID: value})
    }
    let showDropDown = false
    if(this.state.Component === "renderNormalView" || this.state.Component === "renderTableView")
    {
      showDropDown = true 
    }

    return (
      <Grid verticalAlign="middle">
        <Grid.Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "1rem 1.25rem"}}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Icon
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                window.alert(
                  this.state.userRight > 0
                    ? LocalizedStrings.ProjectHasWrite
                    : LocalizedStrings.ProjectNoWrite
                );
                event.stopPropagation();
              }}
              name={this.state.userRight > 0 ? "lock open" : "lock"}
            />
            {this.state.owner && this.state.file ? <b>{this.state.owner}/{this.state.file}</b> : <p>{LocalizedStrings.NoOnlineProjectConnected}</p>}
          </div>
    
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {showDropDown && (
              <Dropdown
                scrolling
                onChange={changedView}
                options={[...this.state.Worksheets, { key: EMPTY_UUID, value: EMPTY_UUID, text: "<Scene>" }]}
              />
            )}
            <Image floated="right" src={logo} alt="production assistant logo" size="mini" onClick={() => { window.LR_RunOpenSettings()}}/>
          </div>
        </Grid.Row>
      </Grid>
    );
  }

  renderDmxView()
  {
    let heightToolBar    = "4em"

    let sizeObjectProperty = 20;

    if(!this.state.showNavigation) { sizeObjectProperty = 0 }

    let spaceleft = 100 - sizeObjectProperty

    return(
      <React.Fragment>
        <div style={{height:"100vh", overflowY:"auto", overflowX:"hidden",position: "fixed", top:"4em", width:"100vW"}}>
          <div style={{height:"calc(100vh - "+ heightToolBar+")",overflowY:"auto", overflowX:"hidden",position: "fixed", top:heightToolBar, width:spaceleft+"vW"}}>
            <DmxOverview WebApp/>
          </div>
          

          <div style={{height:"calc(100vh - "+ heightToolBar+")", overflowY:"auto", overflowX:"hidden",position: "fixed", left:spaceleft+"vW", top:heightToolBar, width:sizeObjectProperty+"vW"}}>
            {this.state.showObjectProperties ? <ObjectProperties/> : null}
          </div>
        
        </div>
        </React.Fragment>
          )
  }

  renderFullSizeComponent(child)
  {
    return(
      <React.Fragment>
        <div style={{height:"calc(100vh)", overflowY:"auto", overflowX:"hidden",position: "fixed", top:"4em", width:"100vW"}}>
          {child}
        </div>
      </React.Fragment>
          )
  }



  toggleContainer = (index) =>
  {
    console.log("Toggle ios container");
    switch (index)
    {
    case TOOL_SELECT_INDEX:
      break;
    case OBJECT_PROPERTIES_INDEX:
      if (this.state.renderObjectProperties)
      {
        this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(OBJECT_PROPERTIES_TAB_ID))
        this.setState({renderObjectProperties: false})
      }
      else
      {
        let newObjectProperties = new FlexLayout.TabNode(this.state.layoutDef, objectProperties_json)
        this.newTabset(newObjectProperties)
        this.setState({renderObjectProperties: true})
      }
      break;
    case SCENE_TREE_INDEX:
      if (this.state.renderSceneTree)
      {
        this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(SCENE_TREE_TAB_ID))
        this.setState({renderSceneTree: false})
      }
      else
      {
        let newSceneTree = new FlexLayout.TabNode(this.state.layoutDef, sceneTreeHierarchy_json)
        this.newTabset(newSceneTree)
        this.setState({renderSceneTree: true})
      }
      break;
    case NAVIGATION_CONTAINER_INDEX:
      if (this.state.renderNavigation)
      {
        this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(NAVIGATION_TAB_ID))
        this.setState({renderNavigation: false})
      }
      else
      {
        let newNavigation = new FlexLayout.TabNode(this.state.layoutDef, navigation_json)
        this.newTabset(newNavigation)
        this.setState({renderNavigation: true})
      }
      break;
    case RENDERER_INDEX: 
      if (this.state.renderRenderer)
      {
        this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(RENDERER_TAB_ID))
        this.setState({renderRenderer: false})
      }
      else
      {
        let newRenderer = new FlexLayout.TabNode(this.state.layoutDef, renderer_json)
        this.newTabset(newRenderer)
        this.setState({renderRenderer: true})
      }
      break;
    default: break;
    }
  }

  newTabset = (node) => {
    let idChild = CHILD_TAB_ID;
    let rightChild = 0;
    let tempModel = node.getModel();
    let rootNode = tempModel.getRoot();
    let modelChildren = rootNode.getChildren();

    if (node instanceof FlexLayout.TabNode || node instanceof FlexLayout.TabSetNode) {
      if(modelChildren.length <= 1) {
        let tabSet = new FlexLayout.TabSetNode(tempModel, { type: "tabset" });
        rootNode._addChild(tabSet);
        this.state.layoutDef.doAction(FlexLayout.Actions.moveNode(node.getId(), tabSet.getId(), FlexLayout.DockLocation.BOTTOM, 0));
      } 
      else {
        for(var i=0; i <= (modelChildren.length - 1); i++) {
          if(modelChildren[i].getRect().getRight() > rightChild) {
              rightChild = modelChildren[i].getRect().getRight();
              idChild = i;
          }
        }
        let toNode = modelChildren[idChild];
        if (toNode instanceof FlexLayout.TabSetNode || toNode instanceof FlexLayout.BorderNode || toNode instanceof FlexLayout.RowNode) {
            this.state.layoutDef.doAction(FlexLayout.Actions.moveNode(node.getId(), toNode.getId(), FlexLayout.DockLocation.BOTTOM, 0));
        }
      }
    }
  }

}

export default IosRouter

