import React, { Component } from 'react'
import LocalizedStrings from "../../localization/ResourceManager";
import { Icon, Button, Table, Message, Loader, Form} from 'semantic-ui-react'
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_LENGTH, BASE_UNIT_PRESSURE, BASE_UNIT_DENSITY, BASE_UNIT_POISSON_EFFECT, BASE_UNIT_SELECTABLE, IsRunningAsBrowser, IsVectorworksContext, } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import { globalCallbacks } from '../../util/callback';
import DynamicTable from '../Basics/DynamicTableView';
import LRFilterInput from '@component/Basics/FilterField';


let BasicMaterialOptions = [
    {key: "1", value: "1", text: LocalizedStrings.NoneMaterial},
    {key: "2", value: "2", text: LocalizedStrings.Aluminum},
    {key: "3", value: "3", text: LocalizedStrings.Steel},
    {key: "4", value: "4", text: LocalizedStrings.RopeMaterial},
]

let C9BucklingClassOptions = [
    {key: "0", value: "0", text: LocalizedStrings.ClassNone},
    {key: "1", value: "1", text: LocalizedStrings.ClassA},
    {key: "2", value: "2", text: LocalizedStrings.ClassB},
]

class MaterialTemplateDisplay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            materialTemplates : [],
            editModalOpen : false,
            createModalOpen: false, 
            modalValues: {},
            searchFilter: ""
        }
    }
    componentDidMount = async() => {
        this.setUpCallbacks()
        let materialTemplates = await window.LR_GetMaterialTemplates();

        this.setState({materialTemplates: materialTemplates.ResourceMap.material})
    }

  

    render() {
      //----------------------------------------------------
      // define dynamic table view props

      const headerIdent = [
        {editable: false, unit: undefined,                    sortable: true, ident: "MaterialName",         label: LocalizedStrings.MaterialName},
        {editable: false, unit: undefined,                    sortable: true, ident: "Bruchgrenze",          label: LocalizedStrings.Bruchgrenze},
        {editable: true,  unit: undefined,                    sortable: true, ident: "BucklingClass",        label: LocalizedStrings.BucklingClass},
        {editable: false, unit: undefined,                    sortable: true, ident: "MaterialDefault",      label: LocalizedStrings.MaterialDefault},
        {editable: true,  unit: BASE_UNIT_DENSITY,            sortable: true, ident: "Density",              label: LocalizedStrings.Density},
        {editable: true,  unit: BASE_UNIT_PRESSURE,           sortable: true, ident: "E_Modul",              label: LocalizedStrings.E_Modul},
        {editable: true,  unit: BASE_UNIT_PRESSURE,           sortable: true, ident: "G_Modul",              label: LocalizedStrings.G_Modul},
        {editable: true,  unit: undefined,                    sortable: true, ident: "MaterialType",         label: LocalizedStrings.MaterialType},
        {editable: true,  unit: BASE_UNIT_PRESSURE,           sortable: true, ident: "Streckgrenze",         label: LocalizedStrings.Streckgrenze},
        {editable: true,  unit: BASE_UNIT_LENGTH,             sortable: true, ident: "T_Waermeausdehnung",   label: LocalizedStrings.T_Waermeausdehnung},
        {editable: true,  unit: BASE_UNIT_POISSON_EFFECT,     sortable: true, ident: "V_Querkontraktion",    label: LocalizedStrings.V_Querkontraktion},    
      ]

        const cellRender = (entry, rowIndex) => {
        
            return {
                MaterialName:(
                 <>
                 {!entry.Default && (
                     <Icon 
                        name="delete"
                        color="red"
                        link
                        textAlign="right"
                        onClick={() => this.onDeleteMaterialTemplate(entry)}    
                     />
                 )}
                 {entry.Name}
                 </>
                ),
                Bruchgrenze         : entry.Bruchgrenze,
                BucklingClass       : this.renderSelectCell("BucklingClass", entry,C9BucklingClassOptions),
                MaterialDefault     : entry.Default ? "yes" : "no",
                Density             : this.renderUnitCell("Density", entry, BASE_UNIT_DENSITY, 0),
                E_Modul             : this.renderUnitCell("E_Modul", entry, BASE_UNIT_PRESSURE, 0),
                G_Modul             : this.renderUnitCell("G_Modul", entry, BASE_UNIT_PRESSURE, 0),
                MaterialType        : this.renderSelectCell("MaterialType",entry, BasicMaterialOptions),
                Streckgrenze        : this.renderUnitCell("Streckgrenze", entry, BASE_UNIT_PRESSURE, 0),
                T_Waermeausdehnung  : this.renderUnitCell("T_Waermeausdehnung", entry, BASE_UNIT_LENGTH, undefined),
                V_Querkontraktion   : this.renderUnitCell("V_Querkontraktion", entry, BASE_UNIT_POISSON_EFFECT, undefined)
            };
        };

        let filteredData = [...this.state.materialTemplates];

        if(this.state.searchFilter !== "") {
            filteredData = this.state.materialTemplates.filter(entry => entry.Name.toLowerCase().includes(this.state.searchFilter.toLowerCase())); 
        }
        const cellData = filteredData.map(e => ({...e, editable: !e.Default}));
        const footer = (
            <Table.HeaderCell colSpan="28">
                <Button
                floated='="left'
                icon
                labelPosition="left"
                primary
                onClick={this.openCreateModal}
                size="small"
                >
                    <Icon name="" />
                    {LocalizedStrings.AddMaterial}
                </Button>
            </Table.HeaderCell>
        )

    return (
        <>
          <div style={{position: "fixed", top: IsVectorworksContext() ? "8.5rem" : "4.5rem", right: "20px", zIndex: 1000, width: "30rem"}} >
            <LRFilterInput style={{maxWidth: "30%"}} noLabel value={this.state.searchFilter} onChange={(value) => this.setState({searchFilter: value})} />
          </div>

          <div style={{marginTop: "2rem", overflowY: "auto", maxHeight: "100%"}}>
            {cellData.length > 0 ? ( 
                <DynamicTable
                    cellRender={cellRender}
                    headerIdent={headerIdent}
                    cellData={cellData}
                    footerRow={footer}  
                />
            ) : ( this.state.isLoading ? <Message style={{width:'100%'}}><Loader active content={LocalizedStrings.Loading}/></Message> : 
                <>
                <Message style={{width:"100%"}} warning>
                    <Message.Header>
                    {LocalizedStrings.NoMaterialTemplatesHeader}
                    </Message.Header>
                    <p>{LocalizedStrings.NoMaterialTemplatesText}</p>
                </Message>
                <Button
                        floated='="left'
                        icon
                        labelPosition="left"
                        primary
                        onClick={this.openCreateModal}
                        size="small">
                            <Icon name="" />
                            {LocalizedStrings.AddMaterial}
                </Button>
                </>
            )}
            {this.renderEditModal()}
          </div>
        </>   
    )  }


    onDeleteMaterialTemplate = (entry) => {
        window.LR_DeleteMaterialTemplate({Name: entry.Name}).then(() => {
            if (globalCallbacks.updateMaterialTemplates && IsRunningAsBrowser) { globalCallbacks.updateMaterialTemplates() }
        })
    }

    renderUnitCell = (propName, entry, baseUnit, min) => {
        return( <UnitInput  baseUnit      = {baseUnit} 
                            label         = {false}   
                            noStepper 
                            readOnly={!entry.editable}
                            cellChild
                            transparent 
                            name          = {propName}
                            value         = {entry[propName]}
                            onUpdateData  = {(name, value) => {
                                value = value < min ? min : value; 
                                this.updateMaterial(propName, value, entry)
                            }}/> )
    }


    renderSelectCell = (propName, entry, options) => {
        const currVal = String(entry[propName])
        return (
        <UnitInput
            readOnly={!entry.editable}
            baseUnit={BASE_UNIT_SELECTABLE}
            options={options}
            value={currVal}
            onlyShowOnEdit
            updateData={value => this.updateMaterial(propName, value, entry)}
        />
        )
    }

    updateMaterial = (name, value, entry) => {
        entry[name] = value

        window.LR_SetMaterialTemplate(entry).then(() => {
            if (globalCallbacks.updateMaterialTemplates && IsRunningAsBrowser) { globalCallbacks.updateMaterialTemplates() }
        })
    }

    //----------------------------------------
    // EDIT MODAL
    //----------------------------------------

    renderEditModal = () => {
        return (<LRModal    title={LocalizedStrings.EditMaterialHeader}
                            open={this.state.editModalOpen || this.state.createModalOpen}
                            onCancelClick={this.onDiscardModal}
                            onOkClick={this.onOkClick}>
                    <Form>
                        <Form.Input value={this.state.modalValues.Name}   
                                    label = {LocalizedStrings.Name} 
                                    onChange={(e, {value}) => {this.setModalValues("Name", value)}}
                        />   

                        <UnitInput  value={this.state.modalValues.BucklingClass}   
                                    label = {LocalizedStrings.BucklingClass} 
                                    options={C9BucklingClassOptions}
                                    baseUnit={BASE_UNIT_SELECTABLE}
                                    updateData={(value) => {
                                        this.setModalValues("BucklingClass", value);
                                    }}
                        />

                        <UnitInput value={this.state.modalValues.MaterialType}   
                                    label = {LocalizedStrings.MaterialType} 
                                    options={BasicMaterialOptions}
                                    baseUnit={BASE_UNIT_SELECTABLE}
                                    updateData={(value) => {
                                        this.setModalValues("MaterialType", value);
                                    }}
                        />

                        <UnitInput
                            name = "Density"
                            min={0}
                            value={this.state.modalValues.Density}
                            label={LocalizedStrings.Density}
                            baseUnit={BASE_UNIT_DENSITY}
                            onStateUpdate={(name, value, e) => {
                            this.setModalValues(name, value);
                            }}
                        /> 

                        <Form.Group widths="equal">
                            <UnitInput
                                name = "Bruchgrenze"
                                min={0}
                                value={this.state.modalValues.Bruchgrenze}
                                label={LocalizedStrings.Bruchgrenze}
                                baseUnit={BASE_UNIT_PRESSURE}
                                onStateUpdate={(name, value, e) => {
                                this.setModalValues(name, value);
                                }}
                            /> 
                            <UnitInput
                                name = "Streckgrenze"
                                min={0}
                                value={this.state.modalValues.Streckgrenze}
                                label={LocalizedStrings.Streckgrenze}
                                baseUnit={BASE_UNIT_PRESSURE}
                                onStateUpdate={(name, value, e) => {
                                this.setModalValues(name, value);
                                }}
                            /> 
                        </Form.Group>

                        <Form.Group widths="equal">
                            <UnitInput
                                name = "E_Modul"
                                min={0}
                                value={this.state.modalValues.E_Modul}
                                label={LocalizedStrings.E_Modul}
                                baseUnit={BASE_UNIT_PRESSURE}
                                onStateUpdate={(name, value, e) => {
                                this.setModalValues(name, value);
                                }}
                            /> 
                            <UnitInput
                                name = "G_Modul"
                                min={0}
                                value={this.state.modalValues.G_Modul}
                                label={LocalizedStrings.G_Modul}
                                baseUnit={BASE_UNIT_PRESSURE}
                                onStateUpdate={(name, value, e) => {
                                this.setModalValues(name, value);
                                }}
                            />                        
                        </Form.Group>

                        <Form.Group widths="equal">
                            <UnitInput
                                    name = "T_Waermeausdehnung"
                                    value={this.state.modalValues.T_Waermeausdehnung}
                                    label={LocalizedStrings.T_Waermeausdehnung}
                                    baseUnit={BASE_UNIT_LENGTH}
                                    onStateUpdate={(name, value, e) => {
                                    this.setModalValues(name, value);
                                    }}
                            /> 

                            <UnitInput
                                    name = "V_Querkontraktion"
                                    value={this.state.modalValues.V_Querkontraktion}
                                    label={LocalizedStrings.V_Querkontraktion}
                                    baseUnit={BASE_UNIT_POISSON_EFFECT}
                                    onStateUpdate={(name, value, e) => {
                                    this.setModalValues(name, value);
                                    }}
                            /> 
                        </Form.Group>
                    </Form>
                </LRModal>)
    }

    setModalValues = (key, value) => {
        this.setState({ modalValues : { ...this.state.modalValues, [key]: value }})
    }

    openEditModal = () => {
        this.setState({editModalOpen: true})
    }

    openCreateModal = () => {
        this.setState({createModalOpen: true, modalValues: {BucklingClass: "0", MaterialType: "1"}})
    }

    onDiscardModal = () => {
        this.setState({editModalOpen: false, createModalOpen: false, modalValues: {}})
    }

    onOkClick = async() => {
        if (this.state.createModalOpen) {
            window.LR_AddMaterialTemplate(this.state.modalValues).then(() => {
                if (globalCallbacks.updateMaterialTemplates && IsRunningAsBrowser) { globalCallbacks.updateMaterialTemplates() }
            })
        }
        else if (this.state.modalValues) {
            window.LR_SetMaterialTemplate(this.state.modalValues).then(() => {
                if (globalCallbacks.updateMaterialTemplates && IsRunningAsBrowser) { globalCallbacks.updateMaterialTemplates() }
            })
        }

        this.setState({editModalOpen: false, createModalOpen: false, modalValues: {}})
    }

    setUpCallbacks()
    {
        globalCallbacks.updateMaterialTemplates = async () =>
        {
            this.setState({isLoading: true})
            let materialTemplates = await window.LR_GetMaterialTemplates() 
            this.setState({materialTemplates: materialTemplates.ResourceMap.material, isLoading: false})
        }
    }
}

export default MaterialTemplateDisplay