
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Checkbox, Form, Icon, Message, Search, Table } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ObjectPropertyComponent";

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
import { IsElectronContext, SETTING_Default } from '../../util/defines';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB
import { cie2hex, rgb2hex } from '@component/ColorPicker/utilities';

const INFLUENCE_LINE_FIELDS = [
  "ShowDu",
  "ShowDx",
  "ShowDy",
  "ShowDz",
  "ShowPhix",
  "ShowPhiy",
  "ShowPhiz",
  "ShowWorkload",
  "ShowWorkloadDeflection",
  "ShowWorkloadLine",
  "ShowFloorLoad",
  "ShowNx",
  "ShowVy",
  "ShowVz",
  "ShowMt",
  "ShowMby",
  "ShowMbz",
  "ShowWindLoadDirection",
  "ShowText",
  "ShowOnlySelected",
];


class InfluenceLineDisplay extends Component 
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
        AvailableCrossSection: [],
        sorting: null,
        sortedFields: [...INFLUENCE_LINE_FIELDS],
        isLoading: false,
        value: "",
        propertySettingMode : SETTING_Default,
    };
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
    globalCallbacks.getStructuralResultsForNavigation();
  }

  componentWillUnmount = () => 
  {
    if(globalCallbacks.getStructuralResultsForNavigation) {
      globalCallbacks.getStructuralResultsForNavigation = undefined;
    }
  }

  render() 
  {
    return (
      <>
        {this.state.HasInfluenceLine ?
          <div style={{width:"100%", height:"100%"}}>
            <Table style={{borderBottom: "none", margin: 0}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={4} style={{zIndex: 0}} onClick={this.onHeaderClick}>
                    {<Icon link style={{floated: 'left', position: 'absolute', zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                    <Search
                      aligned = "right"
                      size    = {window.IsIOS ? "mini" : "large"}  
                      open    = {false}
                      loading = {this.state.isLoading}
                      value   = {this.state.value}
                      onClick = {(e) => e.stopPropagation()}
                      onSearchChange = {(e, {value}) => this.onSearchChange(value)}>
                    </Search>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>

            <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em", overflowX: "hidden"}}>
              <Table striped structured compact='very' size="small"> 
                {this.renderRows()}    
              </Table> 
            </div>
          </div> 
          :  <Message negative>{LocalizedStrings.NoCalculationResult}</Message> }
      </>
    );
  }

  onHeaderClick = () => {
    const { sorting, filteredFields = INFLUENCE_LINE_FIELDS } = this.state;
  
    let newSorting = null;
    let sortedFields = [...filteredFields];
  
    // Toggle between ascending, descending, and no sorting
    if (sorting === null || sorting === "descending") {
      newSorting = "ascending";
      sortedFields.sort(); 
    } else if (sorting === "ascending") {
      newSorting = "descending";
      sortedFields.sort().reverse(); 
    } else {
      newSorting = null;
      sortedFields = INFLUENCE_LINE_FIELDS; 
    }
  
    this.setState({
      sorting: newSorting,
      filteredFields: sortedFields, 
    });
  };
  
  
  onSearchChange = (value) => {
    this.setState({ isLoading: true, value }); 
  
    if (value.trim() === "") {
      this.setState({
        isLoading: false,
        filteredFields: [...INFLUENCE_LINE_FIELDS],
      });
      return;
    }
  
    const filteredFields = INFLUENCE_LINE_FIELDS.filter((field) =>
      field.toLowerCase().includes(value.toLowerCase()) 
    );
  
    this.setState({
      isLoading: false,
      filteredFields, 
    });
  };
  

  renderRows = () => {
    const { filteredFields = INFLUENCE_LINE_FIELDS } = this.state;
    return (
      <Table.Body>
        {this.state.HadShowOnlyOneCrossSection && (
        <Table.Row>
          <Table.Cell>
            {this.getInputFieldSelect("ShowOnlyOneCrossSection", this.state.AvailableCrossSection)}
          </Table.Cell>
        </Table.Row>)}

        {filteredFields.map((field) => {
          if(!this.state["Had" + field]) return null;
          return <Table.Row key={field}><Table.Cell>{this.getInputFieldBool(field)}</Table.Cell></Table.Row>
        })}
      </Table.Body>
    );
  };

  getInputFieldBool = (name, disabled = false) =>
  {
    if (this.state["Had" + name] === false) { return null; }
    let color = "";
    let preset = this.state["Preset"+name]
    if (preset && preset.Color)
    {
      color = cie2hex(preset.Color)
    }
    
    let value = this.state[name];
    if(typeof(value) !== "boolean") {value = false}

    return (
      <Checkbox 
        name     = {name} 
        fluid    = "true"
        size     = "mini"
        disabled = {disabled}
        label    = {<label style={{color: color}}>{LocalizedStrings[name]}</label>} 
        checked  = {value}
        onChange  = {this.updateDataCheckbox} 
        data-removepreset = {preset && Object.keys(preset).length > 0 ? preset.UUID : 0}
        data-removepropname = {name}>
      </Checkbox>
    ) 
  }

  getInputFieldSelect = (name, option, preset, additions = false) =>
  {
    let color = "";
    if (preset && preset.Color)
    {
      color = rgb2hex([preset.Color.X, preset.Color.Y, preset.Color.Z]);
    }
    let options = option ?  [...option] : []

    let value = this.state[name]
        
    return (
      <Form.Dropdown
        search
        allowAdditions = { additions || {} }
        data-removepreset = {preset && Object.keys(preset).length > 0 ? preset.UUID : 0}
        data-removepropname = {name}
        name      = {name}   
        value     = {value}
        label     = {<b>{LocalizedStrings[name]}:</b>}
        options   = {options}  
        onChange  = {this.updateDataNow}
        style={{backgroundColor: color, marginLeft: "1rem"}}>
      </Form.Dropdown>
    )
  }

  updateDataCheckbox = (e, data) =>
  {
    window.LR_SetObject({UUID: this.state.ActiveUUID, [data.name]: data.checked})
    this.setState({[data.name]: data.checked})
  }

  updateDataNow = (e, {name, value}) =>
  {
    window.LR_SetObject({UUID: this.state.ActiveUUID, [name]: value})
    this.setState({[name]: value})
  }

  setUpCallbacks() 
  {
    globalCallbacks.getStructuralResultsForNavigation = async () => {
      let properties =  await window.LR_GetObjectProperties({Async: true, CalcResults: true});

      let workloadTrussKeyed =  []
      let res     = await window.LR_GetStructuralResult()
      let set = new Set();
      res.forEach(e=>
      {
        if(e.Type ===  4) 
        {
          set.add(e.Key)
        }
      })

      for (const value of set) {
        workloadTrussKeyed.push({
          text: value,
          key: value,
          value: value,
        })
      }

      workloadTrussKeyed.push({
        text: "<None>",
        key: "<None>",
        value: "",
      })

      this.setState({...properties, AvailableCrossSection: workloadTrussKeyed})
    }
  }
}

export default InfluenceLineDisplay;

