//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import React, { Component, useEffect, useRef } from "react";
import { Search, Header, Segment, ButtonGroup, Radio, Icon } from "semantic-ui-react";
let rehypeRaw
const ReactMarkdown = React.lazy(async ()=>{
  rehypeRaw = (await import("rehype-raw")).default
  return await import("react-markdown")
})

import gfm from "remark-gfm";
import { NavLink } from "react-router-dom";
import Strings from "../../localization/Documentation";
import escapeRegExp from "lodash/escapeRegExp"
import { Navigate, Link } from "react-router-dom";
const NewNavBar = React.lazy(()=>import("../ProductionAssistLandingPage/NewNavBar"));
import "./newdocumentation.css";
import { getLocale } from "../../util/defines";
import remarkToc from 'remark-toc'
import {slugify} from '../../util/defines'
import BasicSelect from "../Basics/BasicSelect";
import { withRouter } from "../../webApp/WebRouter";


const CustomLink = ({ href, children }) => {
  return (
    <Link href={href} style={{ textDecoration: 'underline' }}>
      {children}
    </Link>
  );
};

const languageOptions = [
  { key: 'German',  flag:"de", text: "Deutsch",  value: 'de' },
  { key: 'English', flag:"uk", text: "English",  value: 'en' },
  { key: 'Spanish', flag:"es", text: "Español",  value: 'es' },
  { key: 'French',  flag:"fr", text: "Français", value: 'fr' },
  //{ key: 'Italian', flag:"it", text: "Italiano", value: 'it' },
  //{ key: 'Russian', flag:"ru", text: "Русский",  value: 'ru' },
]
import CONST from "../../webApp/CONST";


let content_documentation = [
  {
    header: "Start_header",
    items: [
      {
        header:"Start_header_intry",
        markdown: "productionassist",
      },
      {
        header: "Start_header_register",
        markdown: "register",
      },
      {
        header: "Start_header_install",
        markdown: "installation",
      },
      {
        header: "Start_header_QS_APP",
        markdown: "quickstart",
      },
      {
        header: "Start_header_QS_MVR",
        markdown: "quickstart_mvr",
      },
      {
        header: "Start_header_QS_VW",
        markdown: "quickstart_vectorworks",
      },
      {
        header: "Start_header_SystemRequirements",
        markdown: "systemrequirements",
      },
      {
        header: "Start_header_Shortcuts",
        markdown: "Shortcuts",
      },
    ],
  },
  {
    header: "Basic_Concepts_Header",
    items: [
      {
        header: "Basic_Concepts_Object_Geometry",
        markdown: "object-geometry-relationship"
      }
    ]
  },
  {
    header: "UI_header",
    items: [
      {
        header: "UI_header_UI",
        markdown: "userinterface",
      },
      {
        header: "UI_header_Menubar",
        markdown: "menubar",
      },
      {
        header: "UI_header_Toolbar",
        markdown: "toolbar",
      },
      {
        header: "UI_header_OIP",
        markdown: "objectproperties",
      },
      {
        header: "UI_header_SceneTree",
        markdown: "scenetree",
      },
      {
        header: "UI_header_Render",
        markdown: "drawing",
      },
      
      {
        header: "UI_header_Navigation",
        markdown: "navigation",
      },
      {
        header: "UI_header_CommandLine",
        markdown: "commandline",
      },
      {
        header: "UI_header_Context",
        markdown: "contextmenu",
      },
      {
        header: "UI_header_ResourceManager",
        markdown: "resourcemanager",
      }
    ],
  },
  {
    header: "Workflow_header",
    items: [
      {
        header: "Workflow_headerOnline",
        markdown: "onlineworkflow",
      },
      {
        header: "Workflow_header_PANET",
        markdown: "panet",
      },
      {
        header: "Workflow_header_MVR",
        markdown: "mvrgdtfworkflow",
      },
      {
        header: "Workflow_header_Symbole",
        markdown: "symbolworkflow",
      },
      {
        header: "Workflow_header_content",
        markdown: "online_content",
      },
      
      
      
    ],
  },
  {
    header: "Addon_header",
    items: [
      {
        header: "Addon_header_VW",
        markdown: "pavectorworks",
      },
      {
        header: "Addon_header_MA3",
        markdown: "pama3",
      },
      {
        header: "Addon_header_AutoCAD",
        markdown: "paautocad",
      },
    ],
  },
  {
    header: "Application_header",
    items: [
      {
        header: "Application_header_Inventar",
        markdown: "worksheets",
      },
      {
        header: "Application_header_Electric",
        markdown: "electricalplanning",
      },
      
      {
        header: "Application_header_Network",
        markdown: "networkplanning",
      },
      {
        header: "Application_header_Labels",
        markdown: "labelcreation",
      },
      /*
      {
        header: "Application_header_Work",
        markdown: "workplanning",
      },
      
      {
        header: "Application_header_Documentation",
        markdown: "showdocumantation",
      },
      */
      {
        header: "Application_header_Calculation",
        markdown: "staticcalculation",
      },
      {
        header: "Application_header_LoadCells",
        markdown: "load_cell_connection",
      },
      {
        header: "Application_header_structural_check",
        markdown: "staticcalculation_request_checked",
      },

      
    ],
  },
  {
    header: "Tutorial_header",
    items: [
      {
        header: "Tutorial_header_paperwork",
        markdown: "tutorial_create_patch_table",
      },
      {
        header: "Tutorial_header_calc",
        markdown: "tutorial_ground_support",
      },
      {
        header: "Tutorial_header_print_label",
        markdown: "tutorial_label_printing",
      },
      {
        header: "Tutorial_header_online",
        markdown: "tutorial_online_paperwork",
      },
      {
        header: "Tutorial_header_ProTeams",
        markdown: "pro_team_licence_share",
      },
      {
        header: "Tutorial_header_Animation",
        markdown: "animation",
      },
      {
        header: "Tutorial_header_background",
        markdown: "print-label-as-background-tutorial"
      }
    ],
  },
  {
    header: "Payment_header",
    items: [
      {
        header: "Payment_header_change",
        markdown: "change_payment_plan",
      },
      {
        header: "Payment_header_UpdateProjects",
        markdown: "update_projects",
      },
    ],
  },
  {
    header: "Glossar_header",
    items: [
      {
        header: "Glossar_header_glossar",
        markdown: "gloassar",
      },
    ],
  },
  {
    header: "Api_header",
    items: [
      {
        header: "Api_header_checks",
        markdown: "checks",
      },
    ],
  },
];

let content_knowhow= [
  {
    header: "KnowHow_Static_Header",
    items: [
      {
        header: "KnowHow_Static_Calculation",
        markdown: "knowhow_static_what_is_a_calculation",
      },
      
      {
        header: "KnowHow_Static_Forces",
        markdown: "knowhow_static_forces_moments",
      },
      {
        header: "KnowHow_Static_System",
        markdown: "knowhow_static_static_systems",
      },
      {
        header: "KnowHow_Static_Eurocode",
        markdown: "knowhow_static_eurocode",
      },
      {
        header: "KnowHow_Static_Fem",
        markdown: "knowhow_static_what_is_a_fem_calculation",
      },
    ],
  },
];

let searchDataBase = [];
class NewDocumentation extends Component {
  constructor() {
    super();
    this.state = {
      searchString: "",
      intro: "",
      loading: false,
      results: [],
      value: "",
      lang: getLocale(),
      selectedTopic: "productionassist",
      isTopicOpenMobile: "productionassist",
      isMobile: window.innerWidth <= 1025,  
    };

    this.infoRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount = () => {
    this.reload();
    if(this.props.type === "knowhow") {this.setState({selectedTopic: "knowhow_static_what_is_a_calculation"})}
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate = (prevProps, prevState) =>
  {
    if(prevProps.type !== this.props.type){ this.reload()};

    if(prevProps.type === "documentation" && this.props.type === "knowhow")
    {
      this.setState({ selectedTopic: "knowhow_static_what_is_a_calculation", selected_header: "knowhow_static_what_is_a_calculation"})
    }else if (prevProps.type === "knowhow" && this.props.type === "documentation") {
        this.setState({ selectedTopic: "productionassist", selected_header: "productionassist"})
    }
  }

  handleResize = () => {
    const isMobile = window.innerWidth <= 1025;

    if (isMobile !== this.state.isMobile) {
      this.setState({
        isMobile: isMobile,
        isTopicOpenMobile: isMobile ? "productionassist" : null,
      });
    }
  };

  getDefinition = () =>
  {
    return this.props.type === "knowhow" ? content_knowhow : content_documentation
  }

  getLinkBase = () =>
  {
    return this.props.type === "knowhow" ? "knowhow" : "documentation"
  }

  getHeader = () =>
  {
    return this.props.type === "knowhow" ? Strings.HeaderKnowHow : Strings.HeaderDocumentation
  }

  getLocalize = (key) => 
  {
    return Strings[key]
  }

  reload()
  {
    
    this.getDefinition().forEach((section) => {
      section.items.forEach(async (item) => {
        let url = new URL(
          `/api/markdown/${this.state.lang}/${
            item.markdown
          }.md`,
          CONST.BASE_URL 
        );
        let response = await fetch(url, { method: "GET" });
        let text = await response.text();
        this.setState({ [item.markdown]: text });

        text.split("\n").forEach((e, i) => {
          searchDataBase.push({
            description: e,
            title: this.getLocalize(item.header),
            markdown: item.markdown,
            key: item.header + i,
          });
        });
      });
    });
  }

  getActive() {
    return this.props.match.params.topic
      ? this.props.match.params.topic
      : this.getDefinition()[0].items[0].markdown;
  }

  handleSearchChange = (e, data) => {
    this.setState({ value: data.value });
    const re = new RegExp(escapeRegExp(data.value), "i");

    let results = []
    for(let i of searchDataBase){
      if(results.length > 10){
        break;
      }
      if(re.test(i.description)){
        results.push(i)
      }
    }

    this.setState({ results: results });
  };

  resultRenderer = (object) => {
    return (
      <>
        <Header>{object.title}</Header>
        <Segment>
        <ReactMarkdown
        remarkPlugins={[
          gfm,
          [
            remarkToc,
            {
              tight: true,
              ordered: true,
              heading: 'inhaltsverzeichnis|toc|table[ -]of[ -]contents?',
            },
          ],
        ]}
        components={{
          img: Image,
          a: (props) => <CustomLink {...props} />,
        }}
        transformImageUri={(uri) => {
          return `${CONST.BASE_URL}/api/markdown/${this.state.lang}/${uri}`;
        }}
      >
        {object.description}
      </ReactMarkdown>
        </Segment>
      </>
    );
  };

  render() {
    const { isMobile } = this.state;
    return (
      <div className="new-documentation">
        <header>
          <NewNavBar />
          <div className="main-heading">
            <h1>{this.getHeader()}</h1>
            <Search
              placeholder="Search"
              icon='search'
              loading={this.state.loading}
              results={this.state.results}
              value={this.state.value}
              resultRenderer={this.resultRenderer}
              onResultSelect={(e, data) => {
                this.setState({ selected_header: data.result.markdown, selectedTopic: data.result.markdown});}}
              onSearchChange={this.handleSearchChange}
            />
          </div>
        </header>
        {isMobile ? this.renderMobile() : this.renderBigScreen()}

        
      </div>
    );
  }

  renderDocument = () =>
  {
      return (
        <div className="document-container" ref={this.infoRef} style={{position:"relative"}}>
            <ButtonGroup floated="right">
            <BasicSelect
                normalDropdown
                style={{position:"absolute", top: "15px", right: "15px"}}
                button
                className='icon'
                labeled
                icon='world'
                options={languageOptions}
                onStateUpdate={(name, value, e) => {
                    if (value !== this.state.lang) {
                    Strings.setLanguage(value); 
                    this.setState({ lang: value }, () => {
                        this.reload();
                    });
                    }
                }}
                value={this.state.lang}
            />
            </ButtonGroup>
            <ReactMarkdown
                remarkPlugins={[gfm]}
                rehypePlugins={[rehypeRaw]}
                components={{ 
                    img: Image,
                    h1: h1, 
                    h2: h2, 
                    h3: h3, 
                    h4: h4, 
                    }}
                transformImageUri={(uri) => {
                    return `${
                    CONST.BASE_URL
                    }/api/markdown/${this.state.lang}/${uri}`;
                }}>
                    {this.state[this.getActive()]}
            </ReactMarkdown>
        </div>
      )
  }

  renderBigScreen = () =>
  {
    return (
    <div className="info d-desktop" >
      {this.state.selected_header && this.props.match.location.pathname !== `/${this.props.type}/${this.state.selected_header}` ? (
              <Navigate to={`/${this.props.type}/${this.state.selected_header}`} replace />
            ) : null}

        <div className="topic">
            <div>{this.getDefinition().map((entry) => this.renderGroup(entry, "big"))}</div>
        </div>
        {this.renderDocument()}
    </div>
    )   
  }

  renderMobile = () => 
  {
    return (
      <div className="info d-mobile" >
            {this.state.selected_header && this.props.match.location.pathname !== `/${this.props.type}/${this.state.selected_header}` ? (
              <Navigate to={`/${this.props.type}/${this.state.selected_header}`} replace />
            ) : null}

        <div className="topic">
            <div>{this.getDefinition().map((entry) => this.renderGroup(entry, "small"))}</div>
        </div>
      </div>
    )
  }

  handleRadioChange(markdown) {
    this.setState({ selectedTopic: markdown, selected_header: markdown });
  }

  renderGroup(section, responsive) {
    const { selectedTopic, isTopicOpenMobile } = this.state;

    return (
      <div className="group">
        <h2>{this.getLocalize(section.header)}</h2>
        <ul>
          {section.items.map((entry, i) => (
            <li key={i}>
              {responsive === "big" ? (
                <NavLink
                    to={`/${this.getLinkBase()}/${entry.markdown}`}
                    key={entry.markdown}
                    name={entry.header}
                    onClick={() => {
                    window.scrollTo({
                    behavior: "smooth",
                    top: this.infoRef.current.offsetTop,
                    });
                }}
                >
                    <Radio
                        label={this.getLocalize(entry.header)}
                        name={`radio-group-${section.header}`}
                        value={entry.markdown}
                        checked={selectedTopic === entry.markdown}
                        onChange={() => this.handleRadioChange(entry.markdown)}
                    />
                </NavLink>
              ) : (
                <div>
                    <NavLink
                        to={`/${this.getLinkBase()}/${entry.markdown}`}
                        key={entry.markdown}
                        name={entry.header}
                        onClick={() => {
                          this.setState({
                            isTopicOpenMobile: isTopicOpenMobile === entry.markdown ? null : entry.markdown,
                          });
                      }}
                    >
                      <p className="header-text">{this.getLocalize(entry.header)}</p>
                      <span className="showhire">
                        {isTopicOpenMobile === entry.markdown ? (
                          <Icon name="chevron up" size="small" />
                        ) : (
                          <Icon name="chevron down" size="small" />
                        )}
                      </span>
                      {isTopicOpenMobile !== entry.markdown && <div className="line"></div>}
                    </NavLink>
                {isTopicOpenMobile === entry.markdown && this.renderDocument()}
              </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

function Image(props) {
  const imageRef = useRef(null);

  const openFullscreen = () => {
    const element = imageRef.current;
    if (element) {
      if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // For Safari
      } else if (element.requestFullscreen) {
        element.requestFullscreen(); // For other browsers
      }
    }
  };

  useEffect(() => {
    // Listen for orientation change event
    const handleOrientationChange = () => {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        //closeFullscreen();
      }
    };
    window.addEventListener("orientationchange", handleOrientationChange);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <>
      <img
        ref={imageRef}
        {...props}
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "2rem",
          width: "100%",
          maxWidth: "100%",
          cursor: "pointer",
        }}
        alt=""
        onClick={openFullscreen}
      />
    </>
  );
}


function id(props)
{
  let id = props.children[props.children.length-1].toLowerCase()
  id = slugify(id);
  return id;
}

function h1(props) {

  return<h1 id={id(props)}>{props.children}</h1>
}

function h2(props) {

  return<h2 id={id(props)}>{props.children}</h2>
}

function h3(props) {

  return<h3 id={id(props)}>{props.children}</h3>
}
function h4(props) {

  return<h4 id={id(props)}>{props.children}</h4>
}


export default withRouter(NewDocumentation);