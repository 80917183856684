import React, { Component } from "react";
import { connect } from 'react-redux';
import { Placeholder, Divider, Grid, Header, Icon, Form, Radio, List, Checkbox, Button, Segment, Dimmer, Progress, Loader } from "semantic-ui-react";
import LocalizedStrings from "../../localization/Security"
const Editor = React.lazy(()=>import('jsoneditor-react').then(m => ({default: m.JsonEditor})))

import { PROJECTS } from "./../../redux/redux_defines"
import { FetchProjects, hasFetched } from "../../redux/actions/fetch_actions";
import LRFilterInput from "../Basics/FilterField";
import { lrServerConnection } from '../../redux/light_right_server_connection';
import { addNotification } from "../NotificationDisplay/NotificationDisplay";

class UpdateTemplates extends Component
{
  constructor(props) {
    super(props)
    this.state = {
      ResourceContentChecked: new Set(),
      json :{},
      regextext: "",
      regextextTemplate:"",
      value : "all",
      valueTemplate : "all",
      checkedState: {},
      checkedStateTemplate: {},
      CurrentlyProgressing: false,
      CurrentlyProgressingCount: 0,
      CurrentlyProgressingIndex: 0,
      appendResource: false,
      overwritingResource:false,
      copyResources: false,
      updateObjects: false
    }
  }


  handleChange = (e, { value }) => this.setState({ value })
  handleChangeTemplate = (e, { value }) => this.setState({ valueTemplate: value })

  onResourceContentUpdate(type, value)
  {
    if(value)
    {
      this.state.ResourceContentChecked.add(type)
    }else
    {
      this.state.ResourceContentChecked.delete(type)
    }

    this.setState({ResourceContentChecked: this.state.ResourceContentChecked})
  }

  render()
  {
    let LoadingData = ! hasFetched(this.props.projects)

    console.log(this.props.projects)

    let ValidData = this.state.ResourceContentChecked.size === 0

    return <>
      {this.renderProgressBar()}
    <Segment >
      {this.renderProgress()}
      <Header>
        <Icon name="history"/>{LocalizedStrings.UpdateResources}
      </Header>
      {LocalizedStrings.UpdateResourcesHint}
      <Divider horizontal/>
      <Grid>
          <Grid.Row>
            <Grid.Column width="8">
              <Editor
                 htmlElementProps={{style: {height: "44.5rem"}}}
                value={this.state.json}
                onChange={this.customDataChange}
                onValidate={this.onValidate}
                mode="code"
              />
            </Grid.Column>
            <Grid.Column width="8">
              <Segment>

              
            <Header>{LocalizedStrings.SelectProject}</Header>
            <Form>
              <Form.Field>
                {LocalizedStrings.UpdateProjectUsingTheCriteria}
              </Form.Field>
              <Form.Field>
                <Radio
                  label={LocalizedStrings.UpdateProjectUsingTheCriteria_Selected}
                  name='radioGroup'
                  value='all'
                  checked={this.state.value === 'all'}
                  onChange={this.handleChange}
                />
                
              </Form.Field>
              <Form.Field>
                <Radio
                  label={LocalizedStrings.UpdateProjectUsingTheCriteria_Regex}
                  name='radioGroup'
                  value='regex'
                  checked={this.state.value === 'regex'}
                  onChange={this.handleChange}
                />

              <LRFilterInput  value   = {this.state.regextext}
                              noLabel
                              disabled={this.state.value !== 'regex'}
                              onChange = {(regextext) => this.setState({regextext})}            
              />
              </Form.Field>
              

            </Form>
            <Header>{LocalizedStrings.UpdateType}</Header>
            <Form>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("symbolMap")}               label={LocalizedStrings.SymbolMap}        onChange={(e, {value})=>{this.onResourceContentUpdate("symbolMap", !value)}}/>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("vectorWorksfieldMapping")} label={LocalizedStrings.FieldMap}         onChange={(e, {value})=>{this.onResourceContentUpdate("vectorWorksfieldMapping", !value)}}/>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("crossSection")}            label={LocalizedStrings.CrossSection}     onChange={(e, {value})=>{this.onResourceContentUpdate("crossSection", !value)}}/>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("material")}                label={LocalizedStrings.Material}         onChange={(e, {value})=>{this.onResourceContentUpdate("material", !value)}}/>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("calculationReport")}       label={LocalizedStrings.StructuralReport} onChange={(e, {value})=>{this.onResourceContentUpdate("calculationReport", !value)}}/>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("paperworkReport")}         label={LocalizedStrings.PaperworkReport}  onChange={(e, {value})=>{this.onResourceContentUpdate("paperworkReport", !value)}}/>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("printFormats")}         label={LocalizedStrings.printFormats}  onChange={(e, {value})=>{this.onResourceContentUpdate("printFormats", !value)}}/>
              <Form.Checkbox    value={this.state.ResourceContentChecked.has("paperFormatTemplate")}         label={LocalizedStrings.paperFormatTemplate}  onChange={(e, {value})=>{this.onResourceContentUpdate("paperFormatTemplate", !value)}}/>
            </Form>
            <Header>{LocalizedStrings.UpdateOperation}</Header>
            <Form>
              <Form.Checkbox  checked={this.state.appendResource}      onChange={()=>{this.setState({appendResource: !this.state.appendResource})}}            label={LocalizedStrings.Append}/>
              <Form.Checkbox  checked={this.state.overwritingResource} onChange={()=>{this.setState({overwritingResource: !this.state.overwritingResource})}}  label={LocalizedStrings.OverwriteExistingEntries}/>
            </Form>
            <Divider horizontal/>
            <Button disabled={LoadingData || ValidData} fluid negative onClick={this.ApplyTemplateChangers} >{LocalizedStrings.ChangeProjects}</Button>
            </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column>
            <Segment>
            <Header>{LocalizedStrings.ProjectsToUpdate} {LoadingData ? "" : this.getProjectsToUpdate(true).length}</Header>
            <Divider horizontal/>

            <List style={{overflow:"auto", height:"30em"}}>
              {this.renderListEntry()}
            </List>
            </Segment>
            </Grid.Column>
          </Grid.Row>
          </Grid>

      <Header><Icon name="history"/>{LocalizedStrings.UpdateFromTemplate}</Header>
      {LocalizedStrings.UpdateFromTemplateHint}
      <Divider horizontal/>

              <Segment>

              
            <Header>{LocalizedStrings.SelectProject}</Header>
            <Form>
              <Form.Field>
                {LocalizedStrings.UpdateProjectUsingTheCriteria}
              </Form.Field>
              <Form.Field>
                <Radio
                  label={LocalizedStrings.UpdateProjectUsingTheCriteria_Selected}
                  name='radioGroup'
                  value='all'
                  checked={this.state.valueTemplate === 'all'}
                  onChange={this.handleChangeTemplate}
                />
                
              </Form.Field>
              <Form.Field>
                <Radio
                  label={LocalizedStrings.ProjectUsingTemplate}
                  name='radioGroup'
                  value='template'
                  checked={this.state.valueTemplate === 'template'}
                  onChange={this.handleChangeTemplate}
                />
                  <Form.Select 
                  search   
                  selection   
                  onChange={(e, {value} ) => this.setState({BaseTemplate: value})}
                  options={this.getProjects().map(e=> {return { text:e.owner.username + "/" + e.name, value:e.owner.username + "/" + e.name, key: e.owner.username + "/" + e.name }})}

                  value={this.state.BaseTemplate}         />
                  </Form.Field>
                <Form.Field>
                <Radio
                  label={LocalizedStrings.UpdateProjectUsingTheCriteria_Regex}
                  name='radioGroup'
                  value='regex'
                  checked={this.state.valueTemplate === 'regex'}
                  onChange={this.handleChangeTemplate}
                />
          
                <LRFilterInput  value   = {this.state.regextextTemplate}
                                noLabel
                                disabled={this.state.valueTemplate !== 'regex'}
                                onChange = {(regextextTemplate) => this.setState({regextextTemplate})}            
                />
              </Form.Field>
              

            </Form>
            <Header>{LocalizedStrings.UpdateOperation}</Header>
            <Form>
            <Form.Checkbox checked={this.state.copyResources} onChange={()=>{this.setState({copyResources: !this.state.copyResources})}} label={LocalizedStrings.AlsoCopyRessources}/>
            <Form.Checkbox checked={this.state.updateObjects} onChange={()=>{this.setState({updateObjects: !this.state.updateObjects})}} label={LocalizedStrings.OnlyUpdateObjectsFromTemplate}/>
            </Form>
            <Divider horizontal/>
            <Button disabled={LoadingData} fluid negative onClick={this.ApplyTemplateChangeTemplate} >{LocalizedStrings.ChangeProjects}</Button>
            </Segment>
            <Segment>
            <Header>{LocalizedStrings.ProjectsToUpdate} {LoadingData ? "" : this.getProjectsToUpdateTemplate(true).length}</Header>
            <Divider horizontal/>

            <List style={{overflow:"auto", height:"30em"}}>
              {this.renderListEntryTemplate()}
            </List>
            </Segment>
      
    </Segment> 
    </>
  }

  customDataChange = (newCustomData) => {
    this.setState({json:newCustomData})
  }

  onValidate = (json) => {
    let errors = [];
    if (!(json instanceof Object)) {
      errors.push({
        path: [],
        message: 'The data should be an instance of object (so an array or an object)'
      });
    }

    return errors;
  }
  
  renderProgress()
  {
    if(!this.state.CurrentlyProgressing)
    {
      return null
    }
    return <>
      <Dimmer active>
        <Loader active/>
      </Dimmer>
    </>
  }

  renderProgressBar()
  {
    if(!this.state.CurrentlyProgressing)
    {
      return null
    }

    return <>
        <Progress progress='value' value={this.state.CurrentlyProgressingIndex} total={this.state.CurrentlyProgressingCount} />
    </>
  }

  ApplyTemplateChangers = async () =>
  {
    let data = {
      data: this.state.json,
      appendResource: this.state.appendResource,
      overwritingResource: this.state.overwritingResource
    }

    let projectsToUpdate = this.getProjectsToUpdate(true)

    this.setState({CurrentlyProgressing: true, CurrentlyProgressingCount:  projectsToUpdate.length, CurrentlyProgressingIndex: 0})


    for(let e of projectsToUpdate)
    {
      let projectName = e.name;

      console.log("Updating", projectName)

      for(let resourceType of Array.from(this.state.ResourceContentChecked)){
       let entry = await lrServerConnection.updateProjectResource(projectName, resourceType, data.data, data.appendResource, data.overwritingResource)

       if(entry.id === undefined)
       {
        addNotification(projectName, LocalizedStrings.FailedtoUpdate, false, true)
       }
      }

      console.log("DONE", projectName)
      this.setState({CurrentlyProgressingIndex: this.state.CurrentlyProgressingIndex+1})
    }


    this.setState({CurrentlyProgressing: false})
  }

  ApplyTemplateChangeTemplate = async () =>
  {
    let projectsToUpdate = this.getProjectsToUpdateTemplate(true)
 
    let data = {
      copyResources: this.state.copyResources,
      updateObjects: this.state.updateObjects
    } 

    this.setState({CurrentlyProgressing: true, CurrentlyProgressingCount:  projectsToUpdate.length, CurrentlyProgressingIndex: 0})


    for(let e of projectsToUpdate)
    {
      let projectName = e.projectname;

      await lrServerConnection.updateProjectTemplate(projectName, data.copyResources, data.updateObjects);
      this.setState({CurrentlyProgressingIndex: this.state.CurrentlyProgressingIndex+1})
    }


    this.setState({CurrentlyProgressing: false})
  }

  getProjectsToUpdate(respectedCheckedCheckState = false)
  {
    if( ! hasFetched(this.props.projects))
    {
      return []
    }
    return this.props.projects.data.filter(e =>
    { 
      let name = e.owner.username + "/" + e.name
      if(this.state.value === "regex")
      {
        if(!name.match(this.state.regextext))
        {
          return false
        }
      }
      let v = this.state.checkedState[name] === undefined || this.state.checkedState[name]

      if(!v && respectedCheckedCheckState === true)
      {
        return false
      }

      return true
    })
  }

  renderListEntry()
  {
    FetchProjects()
    if( ! hasFetched(this.props.projects))
    {
      return <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
    }
    return <>
      {this.getProjectsToUpdate().map(e => 
        {
          let name = e.owner.username + "/" + e.name
          
          let v = this.state.checkedState[name] === undefined || this.state.checkedState[name]
          return <>    
          <List.Item>
            <Checkbox checked={v} label={name} onChange={()=>{ this.setState({checkedState: {...this.state.checkedState, [name]: !v}})}}/>
          </List.Item>
          </>}
        
        )}

    </>
  }

  getProjects()
  {
    if( ! hasFetched(this.props.projects))
    {
      return []
    }

    return this.props.projects.data
  }

  getProjectsToUpdateTemplate(respectedCheckedCheckState = false)
  {
    if( ! hasFetched(this.props.projects))
    {
      return []
    }
    let baseTemplateProjectName;

    if(this.state.BaseTemplate)
    {
      baseTemplateProjectName = this.state.BaseTemplate.split("/")[1]
    }

    return this.props.projects.data.filter(e =>
    { 
      let name = e.owner.username + "/" + e.name
      let template = e.template
   
 
      if(this.state.valueTemplate === "regex")
      {
        if(!name.match(this.state.regextextTemplate))
        {
          return false
        }
      }

      if(this.state.valueTemplate === "template")
      {
        if(template !== baseTemplateProjectName)
        {
          return false
        }
      }
      
      let v = this.state.checkedStateTemplate[name] === undefined || this.state.checkedStateTemplate[name]

      if(!v && respectedCheckedCheckState === true)
      {
        return false
      }

      return true
    })
  }

  renderListEntryTemplate()
  {
    FetchProjects()
    if( ! hasFetched(this.props.projects))
    {
      return <Placeholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
    }
    return <>
      {this.getProjectsToUpdateTemplate().map(e => 
        {
          let name = e.owner.username + "/" + e.name
          let v = this.state.checkedStateTemplate[name] === undefined || this.state.checkedStateTemplate[name]
          return <>    
          <List.Item>
            <Checkbox checked={v} label={name} onChange={()=>{ this.setState({checkedStateTemplate: {...this.state.checkedStateTemplate, [name]: !v}})}}/>
          </List.Item>
          </>}
        
        )}

    </>
  }
}



//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(UpdateTemplates)
