
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Input, Table } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/NavigationContainer";
import { BASE_UNIT_NUMBER, BASE_UNIT_SELECTABLE } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';

class CaseNode extends Component {
    constructor(props) {
        super(props);

        this.state = this.props.entry;
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        for (const [name, value] of Object.entries(newProps.entry))
            if (oldProps.entry[name] !== value) {
                this.setState({ [name]: value });
            }
    }

    render() {
        let entry = this.state;
        return (
            <>
            {window.IsIOS ? this.renderTableRowInIos() 
            : <Table.Row
                draggable={this.props.draggable}
                onDragStart={e => this.props.onDragStart?.(e)}
                onDragEnter={e => this.props.onDragEnter?.(e)}
                onDragEnd=  {e => this.props.onDragEnd?.(e)}
            >
                <Table.Cell collapsing>
                    <Icon name="bars" style={{cursor: "pointer"}} color={this.props.draggable ? "black": "grey"}/>
                </Table.Cell>
                <Table.Cell>
                    <Input transparent
                        name="Name"
                        value={entry.Name}
                        onChange={this.onValueChanged}
                        onBlur={() => this.updateData("Name", entry.Name)}
                        onKeyDown={this.onKeyDown} />
                </Table.Cell>
                <Table.Cell data-tooltip={LocalizedStrings.FactorLoadGroup}>
                    <UnitInput
                        fluid
                        transparent
                        inline
                        size="small"
                        baseUnit={BASE_UNIT_NUMBER}
                        value={entry.LoadFactor}
                        onStateUpdate={(name, value) => { this.setState({ LoadFactor: value }) }}
                        onUpdateData={(name, value, e) => { this.updateData("LoadFactor", entry.LoadFactor, e) }} />
                </Table.Cell>
                <Table.Cell collapsing data-tooltip={LocalizedStrings.TypeLoadGroup} data-position="top right">
                    <UnitInput
                        value={entry.LoadGroupType}
                        noFluid
                        style={{
                            minHeight: 0
                        }}
                        options={[
                            {
                                text: LocalizedStrings.LoadGroupType_Static,
                                key: "0",
                                value: 0
                            },
                            {
                                text: LocalizedStrings.LoadGroupType_Variable,
                                key: "1",
                                value: 1
                            }
                        ]}
                        baseUnit={BASE_UNIT_SELECTABLE}
                        updateData={(value) => {
                            this.updateData("LoadGroupType", value)
                        }} />
                </Table.Cell>
                <Table.Cell collapsing data-tooltip={LocalizedStrings.DeleteLoadGroup} data-position="top right">
                    <Icon name="delete"
                        color="red"
                        style={{cursor: "pointer"}}
                        onClick={() => this.onDelete(entry.UUID)}
                    />
                </Table.Cell>
            </Table.Row>}
            </>
        );
    }

    renderTableRowInIos = () => {
        let entry = this.state;
        return (
            <Table.Row style={{padding: "0.25rem"}}>
                <div style={{ display: "flex", marginLeft: "1rem", alignItems: "center", justifyContent: "space-between"}}>
                    <Input transparent
                        style={{width: "5rem"}}
                        name="Name"
                        value={entry.Name}
                        onChange={this.onValueChanged}
                        onBlur={() => this.updateData("Name", entry.Name)}
                            onKeyDown={this.onKeyDown} />
                     <UnitInput
                        value={entry.LoadGroupType}
                        noFluid
                        style={{
                            minHeight: 0
                        }}
                        options={[
                            {
                                text: LocalizedStrings.LoadGroupType_Static,
                                key: "0",
                                value: 0
                            },
                            {
                                text: LocalizedStrings.LoadGroupType_Variable,
                                key: "1",
                                value: 1
                            }
                        ]}
                        baseUnit={BASE_UNIT_SELECTABLE}
                        updateData={(value) => {
                            this.updateData("LoadGroupType", value)
                        }} />
                    
                    <Icon name="delete"
                        color="red"
                        style={{cursor: "pointer"}}
                        onClick={() => this.onDelete(entry.UUID)}
                    />
                </div>
            </Table.Row>
        )
    }

    onValueChanged = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    updateData = (name, value, event) => {
        let request =
        {
            UUID: this.state.UUID
        };

        if (name) { request[name] = value; }
        else { request.Name = this.state.Name; }


        window.LR_SetLoadGroup(request);
    }

    onKeyDown = (event) => {
        if (event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    onDelete = (uuid) => {
        let request =
        {
            UUID: uuid,
        }

        window.LR_DeleteLoadGroup(request);
    }

}

export default CaseNode;

