
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import LocalizedStrings from "../../localization/NavigationContainer";
import React, { Component } from 'react';
import { Table, Icon, Input, Popup } from 'semantic-ui-react'

class PrintLabelNode extends Component 
{
    constructor(props) {
        super(props);
        this.state = this.props.entry;
      }
    
      componentDidUpdate(oldProps) {
        const newProps = this.props;
        for (const [name, value] of Object.entries(newProps.entry)) {
          if (oldProps.entry[name] !== value) {
            this.setState({ [name]: value });
          }
        }
      }
    render() 
    {
        let entry = this.state;
        return (
            <>
           { window.IsIOS ? this.renderTableRowInIos(entry) 
          : <Table.Row>
                <Table.Cell collapsing>
          <Popup
            content={LocalizedStrings.PrintView}
            trigger={
              <Icon
                link
                name="print"
                onClick={() => this.activate(entry.UUID)}
              />
            }
          />
        </Table.Cell>
                <Table.Cell>
                    <Input  transparent
                            name      = "Name" 
                            value     = {entry.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", entry.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                <Popup content={LocalizedStrings.StorePrintView}
                    trigger={
                        <Icon   name    = "edit" 
                            link
                            onClick = {() => {this.edit(entry.UUID)}}/>
                    }/>
                    <Popup content={LocalizedStrings.DeletePrintView}
                            trigger={
                        <Icon   name    = "delete"
                            color   = "red"
                            onClick = {() => this.onDelete(entry.UUID)}
                        />
                    }/>
                </Table.Cell>
            </Table.Row>}
            </>
        );
    }

    renderTableRowInIos = (entry) => {
        return (
            <Table.Row >
                <div style={{ display: "flex", marginLeft: "1rem"}}>
                    <Popup
                        content={LocalizedStrings.PrintView}
                        trigger={
                        <Icon
                            link
                            name="print"
                            onClick={() => this.activate(entry.UUID)}
                        />}
                    />
            
                    <Input  transparent
                            name      = "Name" 
                            value     = {entry.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", entry.Name)}
                            onKeyDown = {this.onKeyDown}
                            />
               
                    <Popup content={LocalizedStrings.StorePrintView}
                        trigger={
                            <Icon   name    = "edit" 
                                link
                                onClick = {() => {this.edit(entry.UUID)}}/>
                        }/>
                    <Popup content={LocalizedStrings.DeletePrintView}
                        trigger={
                        <Icon   name    = "delete"
                            color   = "red"
                            onClick = {() => this.onDelete(entry.UUID)}
                        />
                    }/>
            </div>
            </Table.Row>
        )
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        
        window.LR_SetPrintLabel(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur()}
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        window.LR_DeletePrintLabel(request);
    }

    edit = (uuid) =>
    {
        window.LR_OpenGeometryEditMode({UUID: uuid})
    }

    activate = (uuid) =>
    {
        let request = 
        {
            LabelUUID: uuid,
            PageInfo: {},
            SheetLayerMode: true,
        }

        window.LR_PrintLabels(request);
    }

}

export default PrintLabelNode;