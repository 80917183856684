
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/NavigationContainer";
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import SavedViewNode from './SavedViewNode';
import { IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class SavedViewTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            SavedViews : [],

            sorting: null,
            indexMap: [],
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.updateSavedViews();
    }

    render() 
    {
        const mobileStyle = {position: "relative", marginBottom: "8rem"}

        return (
            <div style={{width:"100%", height:"100%"}}>
                <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={this.onHeaderClick} colSpan='2'style={{zIndex:0}} >
                                {<Icon link style={{floated: 'left', position: 'absolute', zIndex:1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                                <Search open    = {false}
                                        loading = {this.state.isLoading}
                                        onClick = {(e)=>{e.stopPropagation()}}
                                        value   = {this.state.value}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value, this.state.SavedViews)}
                                        size = {window.IsIOS ? "mini" : "large"}
                                        aligned = "right"/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}>
                    <Table striped structured compact='very' size="small">
                        <Table.Body>
                            { this.renderRows()}
                        </Table.Body>
                    </Table>
                </div>

                <Segment vertical textAlign="center" style={window.IsIOS ? mobileStyle : {position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width: "100%", border:"none"}}>
                    <Label as="a" color="green" onClick={ this.addNewSavedView }>
                        <Icon name="plus"/>{LocalizedStrings.AddNewSavedView}
                    </Label>
                </Segment>
             </div>

        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    renderRows = () => {
        if(this.state.sorting) {
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return this.state.SavedViews[this.state.indexMap[calcIndex]]
            }).map(entry => {return <SavedViewNode key={entry.UUID} entry={entry}/>})
        }

        return this.state.SavedViews.map(entry => {return <SavedViewNode key={entry.UUID} entry={entry}/>})
    }
 
    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }


    addNewSavedView = () =>
    {
        let req = {}
        if(globalCallbacks.getRenderView)
        {
            req = {
                ...globalCallbacks.getRenderView(),
                ReadLayerVisibility: true
            }
        }
        window.LR_AddNewSavedView(req)
    }

    setUpCallbacks() 
    {
        globalCallbacks.updateSavedViews = () =>
        {

            window.LR_GetSavedViews().then(res => {
                let indexMap = []
                let objects = res.SavedViews
                objects.forEach((_, i) => indexMap.push(i))
                indexMap.sort((a,b) => objects[a][SORT_BY] === objects[b][SORT_BY]  ? 0 : (objects[a][SORT_BY]  < objects[b][SORT_BY]  ? -1 : 1))

                this.setState({SavedViews: res.SavedViews, indexMap})
            })
        }

    }
}

export default SavedViewTable;

