//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import ObjectProperties from '../component/ObjectProperties/ObjectPropertiesComponent';
import ToolSelect from '../component/ToolSelect/ToolSelectComponent';
const Renderer = React.lazy(()=> import("../component/Renderer/RendererWrapper"))

import SceneTreeHierarchy from '../component/SceneTreeHierarchy/SceneTreeHierarchyComponent';
import TableView from '../component/TableViews/TableViewComponent';
import ResourceManager from '../component/RessourceManager/ResourceManager';
const ElectricalGraph = React.lazy(()=>import("../component/ElectricalGraph/ElectricalGraphComponent"))

import MenuBar from '../component/MenuBar/MenuBar';
import DmxOverview from '../component/DmxOverview/DmxOverview';
import NavigationContainer from '../component/Navigation/NavigationContainer';
import Timeline from '../component/Timeline/Timeline';
import LightingPaperwork from '../component/LightingPaperwork/LightingPaperwork';
import Footer from '../electronApp/Footer';


import * as FlexLayout from "./../util/FlexLayout_Fix/src/index";
import './../electronApp/App.css';
import './../webApp/App.css';

import { OBJECT_PROPERTIES_TAB_ID, SCENE_TREE_TAB_ID, RENDERER_TAB_ID, NAVIGATION_TAB_ID, TOOL_SELECT_INDEX, OBJECT_PROPERTIES_INDEX, SCENE_TREE_INDEX, NAVIGATION_CONTAINER_INDEX, RENDERER_INDEX, CHILD_TAB_ID } from "../util/defines";
import { globalWindowInterface } from '../util/callbackTypes';
import {Navigate} from "react-router-dom"
import { lrServerConnection } from '../redux/light_right_server_connection';
import { Button, Modal } from 'semantic-ui-react';
import LocalizedStrings from "../localization/WebEditMode"
import DefaultModalContainer from '../component/DefaultModalContainer/DefaultModalContainer';
import LongPressHandler from '../component/RightClickMenu/LongPressHandler';
import { globalCallbacks } from '../util/callback';

declare const window: globalWindowInterface;

let objectProperties_json = {
  "type": "tab",
  "id": OBJECT_PROPERTIES_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Object Properties",
  "component": "ObjectProperties"
}

let navigation_json = {
  "type": "tab",
  "id": NAVIGATION_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Navigation Container",
  "component": "NavigationContainer"
}

let sceneTreeHierarchy_json = {
  "type": "tab",
  "id": SCENE_TREE_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Scene Tree Hierarchy",
  "component": "SceneTreeHierarchy"
}

let renderer_json = {
  "type": "tab",
  "id": RENDERER_TAB_ID,
  "enableClose": true,
  "enableRename": false,
  "name": "Renderer",
  "component": "Renderer"
}

var layout_json = {
  global: {
    tabEnableClose: true,
    tabSetEnableMaximize: false,
  },
  layout: {
    "type": "row",
    "weight": 100,
    "children": [
      {
        "type": "tabset",
        "weight": 10,
        "selected": 0,
        "children": [
          objectProperties_json
        ]
      },
      {
        "type": "tabset",
        "weight": 10,
        "selected": 0,
        "children": [
          sceneTreeHierarchy_json
        ]
      },
      {
        "type": "tabset",
        "weight": 50,
        "selected": 0,
        "children": [
          renderer_json
        ]
      },
      {
        "type": "tabset",
        "weight": 10,
        "selected": 0,
        "children": [
          navigation_json
        ]
      }
    ]
  }
};

interface WebEditRouterProps {

}

interface WebEditRouterState {
  Component: string
  showObjectProperties: boolean
  showNavigation: boolean
  renderToolSelect: boolean
  renderObjectProperties: boolean
  renderSceneTree: boolean
  renderNavigation: boolean
  renderRenderer: boolean
  layoutDef: FlexLayout.Model
  exitButtonClicked: boolean
  savedModalUsed: boolean
}


//---------------------------------------------------------------------
// LightRightRouter
export default class WebEditRouter extends Component<WebEditRouterProps, WebEditRouterState>
{
  constructor(props) {
    super(props);

    this.state =
    {
      Component: "renderNormalView",
      showObjectProperties: true,
      showNavigation: false,

      renderToolSelect: true,
      renderObjectProperties: true,
      renderSceneTree: true,
      renderNavigation: true,
      renderRenderer: true,
      layoutDef: FlexLayout.Model.fromJson(layout_json as any),
      exitButtonClicked: false,
      savedModalUsed: false
    };
  }

  beforeSave(e: BeforeUnloadEvent){
    e = e || window.event;
  
    // For IE and Firefox prior to version 4
    if (e) {
        e.returnValue = (LocalizedStrings as any).SaveExitQuestionText;
    }

    // For Safari
    return (LocalizedStrings as any).SaveExitQuestionText;
  }

  componentDidMount = async () => {
    // add event listener to the tabs
    let oipNode = this.state.layoutDef.getNodeById(String(OBJECT_PROPERTIES_TAB_ID))
    let navigationNode = this.state.layoutDef.getNodeById(String(NAVIGATION_TAB_ID))
    let sceneTreeNode = this.state.layoutDef.getNodeById(String(NAVIGATION_TAB_ID))
    let rendererNode = this.state.layoutDef.getNodeById(String(NAVIGATION_TAB_ID))

    if (oipNode) oipNode.setEventListener("close", () => { this.setState({ renderObjectProperties: false }) })
    if (navigationNode) navigationNode.setEventListener("close", () => { this.setState({ renderNavigation: false }) })
    if (sceneTreeNode) sceneTreeNode.setEventListener("close", () => { this.setState({ renderSceneTree: false }) })
    if (rendererNode) rendererNode.setEventListener("close", () => { this.setState({ renderRenderer: false }) })

    let json = await window.LR_GetAppDisplayState()
    if (json.global) { this.setState({ layoutDef: FlexLayout.Model.fromJson(json) }) }


    globalCallbacks.toggleContainer = (index) => {
      this.toggleContainer(index)
    }

    window.addEventListener("beforeunload", this.beforeSave)
  }

  componentWillUnmount(): void {
    window.removeEventListener("beforeunload", this.beforeSave)
  }

  render() {
    if(this.state.exitButtonClicked){
      if(this.state.savedModalUsed){
        this.setState({exitButtonClicked: false, savedModalUsed: false})
        return <Navigate to={`/${lrServerConnection.__USERNAME}/${lrServerConnection.__PROJECT}`}/>
      }
    }

    return (
      <div>
        <Modal
          open={this.state.exitButtonClicked}
        >
          <Modal.Header>{(LocalizedStrings as any).SaveExitQuestionHeader}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {(LocalizedStrings as any).SaveExitQuestionText}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => this.setState({exitButtonClicked: false})}>
              {(LocalizedStrings as any).CancelButton}
            </Button>
            <Button positive onClick={() => window.LR_CommitToServer()}>
            {(LocalizedStrings as any).CommitButton}
            </Button>
            <Button negative onClick={() => this.setState({savedModalUsed: true})}>
            {(LocalizedStrings as any).ExitButton}
            </Button>
          </Modal.Actions>
        </Modal>
        <MenuBar noCollapse position="sticky" onChangeView={this.changeComponent} activeView={this.state.Component} saveExitRoute={() => this.setState({ exitButtonClicked: true })} />

        <div style={{ marginTop: "10px", MozUserSelect: "none", WebkitUserSelect: 'none', msUserSelect: 'none', WebkitTouchCallout: 'none', userSelect: 'none', height: this.state.Component === "renderElectricalGraph" ? "100vh" : "calc(100vh - 7em)" }}>
          <DefaultModalContainer />
          <LongPressHandler/>

          {this.renderComponent()}
          <Footer size="40px" webApp/>
        </div>
      </div>
    )
  }

  thisModelChange = () => {
    window.LR_SetAppDisplayState(this.state.layoutDef.toJson())
  }

  factory = (node) => {
    var component = node.getComponent();
    let retVal = null
    let style = {}

    if (component === "SceneTreeHierarchy") { retVal = <SceneTreeHierarchy node={node} onClose={() => { this.setState({ renderSceneTree: false }) }} />; style = { height: "100%", } }
    else if (component === "ObjectProperties") { retVal = <ObjectProperties node={node} onClose={() => { this.setState({ renderObjectProperties: false }) }} />; style = { height: "100%", } }
    else if (component === "NavigationContainer") { retVal = <NavigationContainer node={node} onClose={() => { this.setState({ renderNavigation: false }) }} />; style = { height: "100%", } }
    else if (component === "Renderer") {
      if (this.state.Component !== "renderNormalView") {
        retVal = <TableView webEdit node={node} onClose={() => { this.setState({ renderRenderer: false }) }} />; style = { height: "100%", }
      }
      else {
        retVal = <Renderer rootUuid={0} />; style = { height: "100%" }
      }

    }

    return <div style={{ ...style, overflowX: "hidden", overflowY: "auto", position: 'relative' }}>{retVal}</div>
  }

  titleFactory = (node) => {
    // compute tab width in tabset
    let width = node._rect.width / node._parent._children.length
    let widthStr = String(width) + "px"

    // compute tab position in tabset by computing needed margin
    let pre = 0
    let child = node._parent._children[pre]
    while (child.getName() !== node.getName()) {
      pre += 1
      child = node._parent._children[pre]
    }
    let marginLeft = width * pre
    let marginLeftStr = String(marginLeft) + "px"

    // focused tab coloring
    let backgroundColor = "#aaaaaa"
    if (!node._visible) {
      backgroundColor = "#dddddd"
    }

    return <div style={{ overflow: "hidden", marginLeft: marginLeftStr, backgroundColor: backgroundColor, width: widthStr, height: "100%", paddingLeft: "2px", position: "absolute" }}>
      {!node._visible && node.getName()}
    </div>
  }

  renderComponent() {
    if (this.state.Component === "renderNormalView") {
      return this.renderNormalView()
    }
    else if (this.state.Component === "renderResourceManager") {
      return this.renderFullSizeComponent(<ResourceManager />)
    }
    else if (this.state.Component === "renderTableView") {
      return this.renderWorksheetView()
    }
    else if (this.state.Component === "renderElectricalGraph") {
      return this.renderFullSizeComponent(<ElectricalGraph />)
    }
    else if (this.state.Component === "renderDMXView") {
      return this.renderDmxView();
    }
    else if (this.state.Component === "renderLightPaperwork") {
      return this.renderFullSizeComponent(<LightingPaperwork />)
    }
    else if (this.state.Component === "renderTimeline") {
      return this.renderFullSizeComponent(<Timeline />)
    }

    return this.renderNormalView();
  }

  changeComponent = (newComponent) => {
    this.setState({ Component: newComponent })
  }

  showObjectProperties = () => {
    this.setState({ showObjectProperties: !this.state.showObjectProperties })
  }

  showNavigation = () => {
    this.setState({ showNavigation: !this.state.showNavigation })
  }

  renderNormalView() {
    return (
      <div style={{ height: "100%" }}>
        <div style={{ overflowY: "auto", overflowX: "hidden", width: "100vW" }}>
          <ToolSelect />
        </div>
        <div className='web' style={{ overflowY: "auto", overflowX: "hidden", position: "relative", width: "100vw", marginTop: "10px", height: "100%" }}>
          <FlexLayout.Layout
            model={this.state.layoutDef}
            onModelChange={this.thisModelChange}
            factory={this.factory.bind(this)}
            titleFactory={this.titleFactory.bind(this)}
          />
        </div>
      </div>
    )
  }

  renderWorksheetView() {
    return (
      <div className='web' style={{ overflowY: "visible", overflowX: "hidden", width: "100vw", position: "relative", height: "100%" } as any}>
        <FlexLayout.Layout
          model={this.state.layoutDef}
          onModelChange={this.thisModelChange}
          factory={this.factory.bind(this)}
          titleFactory={this.titleFactory.bind(this)}
        />
      </div>
    )
  }

  renderDmxView() {

    let sizeObjectProperty = 20;

    if (!this.state.showNavigation) { sizeObjectProperty = 0 }

    let spaceleft = 100 - sizeObjectProperty

    return (
      <div style={{ display: "flex", "flexDirection": "row", marginTop: "10px", "paddingRight": "10px" }}>
        <div style={{ height: "100%", overflowY: "clip", overflowX: "hidden", width: spaceleft + "vW" }}>
          <DmxOverview WebEdit />
        </div>
        <div style={{ height: "100%", overflowY: "clip", overflowX: "hidden", width: sizeObjectProperty - 0.5 + "vW" }}>
          {this.state.showObjectProperties ? <ObjectProperties /> : null}
        </div>
      </div>
    )

  }

  renderFullSizeComponent(child) {
    return (
      <React.Fragment>
        <div style={{ overflowY: "clip", overflowX: "hidden", width: "99.5vW" }}>
          {child}
        </div>
      </React.Fragment>
    )
  }


  toggleContainer = (index) => {
    switch (index) {
      case TOOL_SELECT_INDEX:
        break;
      case OBJECT_PROPERTIES_INDEX:
        if (this.state.renderObjectProperties) {
          this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(String(OBJECT_PROPERTIES_TAB_ID)))
          this.setState({ renderObjectProperties: false })
        }
        else {
          let newObjectProperties = new FlexLayout.TabNode(this.state.layoutDef, objectProperties_json, false)
          this.newTabset(newObjectProperties)
          this.setState({ renderObjectProperties: true })
        }
        break;
      case SCENE_TREE_INDEX:
        if (this.state.renderSceneTree) {
          this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(String(SCENE_TREE_TAB_ID)))
          this.setState({ renderSceneTree: false })
        }
        else {
          let newSceneTree = new FlexLayout.TabNode(this.state.layoutDef, sceneTreeHierarchy_json, false)
          this.newTabset(newSceneTree)
          this.setState({ renderSceneTree: true })
        }
        break;
      case NAVIGATION_CONTAINER_INDEX:
        if (this.state.renderNavigation) {
          this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(String(NAVIGATION_TAB_ID)))
          this.setState({ renderNavigation: false })
        }
        else {
          let newNavigation = new FlexLayout.TabNode(this.state.layoutDef, navigation_json, false)
          this.newTabset(newNavigation)
          this.setState({ renderNavigation: true })
        }
        break;
      case RENDERER_INDEX:
        if (this.state.renderRenderer) {
          this.state.layoutDef.doAction(FlexLayout.Actions.deleteTab(String(RENDERER_TAB_ID)))
          this.setState({ renderRenderer: false })
        }
        else {
          let newRenderer = new FlexLayout.TabNode(this.state.layoutDef, renderer_json, false)
          this.newTabset(newRenderer)
          this.setState({ renderRenderer: true })
        }
        break;
      default: break;
    }
  }

  newTabset = (node) => {
    let idChild = CHILD_TAB_ID;
    let rightChild = 0;
    let tempModel = node.getModel();
    let rootNode = tempModel.getRoot();
    let modelChildren = rootNode.getChildren();

    if (node instanceof FlexLayout.TabNode || node instanceof FlexLayout.TabSetNode) {
      if (modelChildren.length <= 1) {
        let tabSet = new FlexLayout.TabSetNode();
        rootNode._addChild(tabSet);
        this.state.layoutDef.doAction(FlexLayout.Actions.moveNode(node.getId(), tabSet.getId(), FlexLayout.DockLocation.BOTTOM, 0));
      }
      else {
        for (var i = 0; i <= (modelChildren.length - 1); i++) {
          if (modelChildren[i].getRect().getRight() > rightChild) {
            rightChild = modelChildren[i].getRect().getRight();
            idChild = i;
          }
        }
        let toNode = modelChildren[idChild];
        if (toNode instanceof FlexLayout.TabSetNode || toNode instanceof FlexLayout.BorderNode || toNode instanceof FlexLayout.RowNode) {
          this.state.layoutDef.doAction(FlexLayout.Actions.moveNode(node.getId(), toNode.getId(), FlexLayout.DockLocation.BOTTOM, 0));
        }
      }
    }
  }

}
